

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CAbpTableForFunctional from '@/modules/budget/execution/form/tabs/components/AbpTableForFunctional.vue';
import CAbpTable from '@/modules/budget/execution/form/tabs/components/AbpTable.vue';
import axios from "axios";
import {Ax} from "@/utils";
import store from '@/services/store';
import VueElementLoading from "vue-element-loading";

@Component({
    components: {
        'c-abp-table-for-functional': CAbpTableForFunctional,
        'c-abp-table-prg': CAbpTable,
        'loading': VueElementLoading
    }
})
export default class CInsideWindow extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private filter!: any;

    @Prop({
        required: true,
        default: 0
    })
    private selectedStatus!:any;

    @Prop({
        required: true,
        default: null
    })
    private abp!: any;

    @Prop({
        required: true,
        default: null
    })
    private region!: any;

    @Prop({
        required: true,
        default: null
    })
    private location!: any;

    @Prop({
        required: false,
        default: null
    })
    private currentForm!: any;

    @Prop({
        required: true,
        default: null
    })
    private checkedGU!: any;
    private checkedGUL:boolean = true;
    private progress: number | null = null;

    private showObligation = true;
    private showPayment = true;

    // private curSpfs = [];
    // private obligationData: any = [];
    // private paymentData: any = [];
    private isLoad = false;
    private isSuccess = false;
    private loading = false;

    private async created() {
        this.$watch('filter', await this.chgFilter);
    }
    private async chgFilter() {
        this.progress = 10;
        this.$emit('chgLoad', true);
        this.budgetFormObligation.splice(0);
        this.budgetFormPayment.splice(0);
        // await this.loadDataForObligation();
        // await this.loadDataForPayment();

        this.checkedGUL = this.checkedGU
        await this.prepareFormObligation();
        this.progress = 50;
        await this.prepareFormPayment();
        this.progress = 100;
        this.$emit('chgLoad', false);
    }

    private budgetFormObligation: any = [];
    private budgetFormPayment: any = [];

    private calcFlds =  [
        'plan',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];


    private get sortDepth() {
        return this.checkedGUL? ['gr', 'abp', 'gu', 'prg', 'ppr', 'spf', 'bip_code']: ['gr', 'abp', 'prg', 'ppr', 'spf', 'bip_code']
    }
    private sortedData(data:any[], by: any) {
        if (['bip_code'].includes(by)) data.sort((a:any, b:any) => a[by].localeCompare(b[by]))
        else data.sort((a:any, b:any) => a[by] - b[by])
    }

    private colapsable(data:any, sortKeys: any[]) {
        this.$set(data, 'collapse', true);
        if ('child_list' in data && data.child_list) {
            if (sortKeys.length) {
                this.sortedData(data.child_list, sortKeys.pop())
            }
            for (const el of data.child_list) { this.colapsable(el, Object.assign([], sortKeys)) }
        } else if ('form_bip_list' in data && data.form_bip_list) {
            if (sortKeys.length) {
                this.sortedData(data.form_bip_list, sortKeys.pop())
            }
            for (const el of data.form_bip_list) { this.colapsable(el, Object.assign([], sortKeys)) }
        }
    }
    private async prepareFormObligation() {
        const sortDepth = Object.assign([], this.sortDepth).reverse()
        this.budgetFormObligation.splice(0);
        const dataOblig = await this.getData('obligation');
        if (dataOblig && dataOblig.length > 0) {
            this.budgetFormObligation = dataOblig
        }
        this.sortedData(this.budgetFormObligation, sortDepth.pop())
        for (const el of this.budgetFormObligation) {
            this.colapsable(el, Object.assign([], sortDepth))
        }
    } // подготовка отображения данных обязательств

    private async prepareFormPayment() {
        const sortDepth = Object.assign([], this.sortDepth).reverse()
        this.budgetFormPayment.splice(0);
        const dataPayment = await this.getData('payment');
        if (dataPayment && dataPayment.length > 0) {
            this.budgetFormPayment = dataPayment
        }
        this.sortedData(this.budgetFormPayment, sortDepth.pop())
        for (const el of this.budgetFormPayment) {
            this.colapsable(el, Object.assign([], sortDepth))
        }
    } // подготовка отображения данных платежей

    private async getData(type: string) {
        let values = [];
        try {
            let response: any = [];
            const queryString = {...this.filter, type: type};
            if (this.selectedStatus && this.selectedStatus.hasOwnProperty('code') && this.selectedStatus.code) {
                queryString.status = this.selectedStatus.code
            }
            if (this.location && this.location.hasOwnProperty('code') && this.location.code) {
                queryString.id_region = this.location.code
            }
            queryString.gu = this.checkedGU
            response = await axios.get('/api/budget-execution/pf', {params: queryString});
            response = response.data;
            values = await response;

            if (values.length === 0) {
                this.$emit('chgLoad', false);
                return;
            }

            // eslint-disable-next-line consistent-return
            return values;
        } catch (error) {
            this.$emit('chgLoad', false);
            this.makeToast('danger', `Ошибка запроса ${type} in loadData()`, error.toString());
        }
    }

    private toggleShowObligation() {
        this.showObligation = !this.showObligation;
    }

    private toggleShowPayment() {
        this.showPayment = !this.showPayment;
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private flk0agr() {
        let total = 0
        for (const el of this.budgetFormPayment) {
            for (const mon of this.calcFlds.slice(1)) {
                total += el[mon]
            }
        }
        return total > 0
    }

    private async downloadApplication(app: number) {
        this.loading = true;
        let d: any = {};
        if (!this.currentForm) {
            this.makeToast('warning', 'Сообщение', 'Не выбрана форма');
            this.loading = false;
        } else {
            d = {
                'id': this.currentForm.id,
                'signers': [],
                'year': this.filter.year,
                'abp': this.filter.abp,
                'abp_name': this.abp.name_ru,
                'abp_name_kz': this.abp.name_kk,
                'region': this.region.code,
                'region_name': this.region.name_ru,
                'region_name_kz': this.region.name_kk,
                'app': app,
                'treeData': [],
                'user_id': this.$store.getters.user_uuid,
                'prg': this.filter.prg ? this.filter.prg : 0,
                'ppr': this.filter.ppr ? this.filter.ppr : 0,
                'spf': this.filter.spf ? this.filter.spf : 0,
                'status': this.selectedStatus ? this.selectedStatus.code : null,
                'location': this.location ? this.location.code : 0
            }
        }

        if (d.id) {
            Ax(
                {
                    url: '/api-py/get-budget-execution-report/',
                    method: 'POST',
                    responseType: 'blob',
                    data: d
                },
                (data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Приложение ${app}_${d.abp}_${d.year}.xlsx`);

                    document.body.appendChild(link);
                    link.click();
                    this.loading = false;

                },
                (error: any) => {
                    this.makeToast('danger', 'Ошибка запроса downloadApplication()', error.toString());
                    this.loading = false;
                }
            )
        }
    }
}
