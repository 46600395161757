







































































































































































































































































































































































































































































































































































































































































































































































































































































import type { BvModalEvent } from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import i18nDb from '@/services/i18n-db';
import { store } from '../common';
import { CollapsiblePane, ComponentBase, ListItem, ListItemPart, Overlay, SvgIcon } from '../components';
import I18n from '../I18n';
import i18n from "@/services/i18n";
import { Comp, Org, Report2, Utils, Version } from '../types';
import type { BoolEx, ReportSelection } from './types';
import GenerationPane from './GenerationPane.vue';
import FilterSortPane from './FilterSortPane.vue';
import ReportListPane from './ReportListPane.vue';
import ReportSelectionPane from './ReportSelectionPane.vue';
import TemplateKey = Utils.TemplateKey;
import ConfirmModal from "@/modules/budget/staffing-table/components/ConfirmModal.vue";


const translates = {
    tools: {
        get generateReportBut(): string {
            return i18n.choose(
                'Фильтр сформировать',
                'Сүзгі пішіні',
                'Generate filter',
            );
        },
        get filterBut(): string {
            return i18n.choose(
                'Перечень отчетов',
                'Есептер тізімі',
                'List of Reports',
            );
        },
        get sendToTotalBut(): string {
            return i18n.choose(
                'Отправить в свод',
                'Жиынтыққа жіберу',
                'Submit to summary',
            );
        }
    },
    error: {
        get cannotSendToTotal(): string {
            return i18n.choose(
                'Не удалось отправить в свод',
                'Не удалось отправить в свод (каз)',
                'Cannot send to total',
            );
        },
        get cannotRemoveToReports(): string {
            return i18n.choose(
                'Не удалось удалить отчеты',
                'Есептерді жою мүмкін болмады',
                'Cannot remove reports',
            );
        },
    },
    sendToTotal: {
        get success(): string {
            return i18n.choose(
                'Отправлено в свод',
                'Отправлено в свод (каз)',
                'Sent to total',
            );
        },
        get title(): string {
            return i18n.choose(
                'Отправить в свод',
                'Отправить в свод (каз)',
                'Send to total',
            );
        },
    },
    removeToReports: {
        get success(): string {
            return i18n.choose(
                'Отчеты удалены',
                'Есептер жойылды',
                'Reports are deleted',
            );
        },
        get title(): string {
            return i18n.choose(
                'Удалить отчет',
                'Есепті жою',
                'Delete report',
            );
        },
         confirmation(Ids: string): string {
            return i18n.choose(
                `Список отчетов на удаление: (${Ids})`,
                `Жоюға арналған есептер тізімі: (${Ids})`,
                `List of reports for deletion: (${Ids})`,
            );
        },
    },
    templateTitles: {
        title(key: TemplateKey | null): string {
            switch (key) {
                case "variant-budget-reports": {
                    return i18n.choose(
                        'Отчеты по версии бюджета',
                        'Бюджет нұсқасы бойынша есептер',
                        'Reports by budget version',);
                }
                case "variant-budget-staff-version-reports": {
                    return i18n.choose(
                        'Отчеты по версии штатного расписания и версии бюджета',
                        'Штаттық кесте нұсқасы мен бюджет нұсқасына қатысты есептер',
                        'Reports by staffing table version and budget version');
                }
                case "budget-program-reports":{
                    return i18n.choose(
                        'Отчеты по бюджетной программе',
                        'Бюджеттік бағдарлама бойынша есептер',
                        'Reports on the budget program',);
                }
                case "specificity-reports":{
                    return i18n.choose(
                        'Отчеты по спецификам',
                        'Ерекшеліктер бойынша есептер',
                        'Reports by Specifics');
                }
                case "form-reports":{
                    return i18n.choose(
                        'Отчеты по формам',
                        'Формалар бойынша есептер',
                        'Reports by Forms');
                }
                default: {
                    return i18n.choose(
                        'Шаблон не выбран',
                        'Үлгі таңдалмады',
                        'Template not selected');
                }

            }
        }
    },
};


// region Types
interface IReportListPane {
    reloadReports(): void;
}


interface IReportSendToTotalSelection {
    reportId: number;
    year: number;
}
// endregion


const i18nT = new I18n('modules.budget.staffing_table.reports');


@Component({
    components: {
        CollapsiblePane,
        FilterSortPane,
        GenerationPane,
        ListItem,
        ListItemPart,
        Overlay,
        ReportListPane,
        ReportSelectionPane,
        SvgIcon,
        ConfirmModal
    },
})
export default class Page extends ComponentBase {
    constructor() {
        super('');
    }


    // region Lifecycle
    protected created() {
        // region Организация, версия ШР
        this.$watch('selectedOrg', (selectedOrg: Org | null) => {
            store.org = selectedOrg;
        });
        this.$watch('selectedVersion', (selectedVersion: Version | null) => {
            store.version = selectedVersion;
        });
        // endregion

        this.$watch('templateKey', (templateKey: TemplateKey | null) => {
            store.templateKey = templateKey;
        });
        // endregion
    }

    protected mounted() {
        this.registerTogglePackageReportDebugVisibility();
    }

    protected beforeDestroy() {
        this.deregisterTogglePackageReportDebugVisibility();
    }

    private confirmModalVisible: boolean = false;

    private showConfirmModal() {
        this.confirmModalVisible = true;
    }

    private closeConfirmModal() {
        this.confirmModalVisible = false;
    }

    removeReports() {
        const reportIds = this.selectedReports.map(value => value.id);

        if (reportIds.length === 0) {
            return;
        }

        this.request(
            {
                url: `/api/budget/staffing_table/report-v2/delete-to-reports`,
                method: 'POST',
                data: reportIds,
            },
            () => {
                this.toast('success', '', translates.removeToReports.success);
                this.reloadReports()

            },
            () => (translates.error.cannotRemoveToReports),
            () => {
                this.closeConfirmModal()
            },
        );
    }
    // endregion


    // region Утилиты
    public translates = translates;
    public i18n = i18nT;
    public i18nDb = i18nDb;
    // endregion


    // region Генерация отчетов
    public generationModalVisible = false;
    public lastReportEventTime = 0;

    // noinspection JSUnusedLocalSymbols
    public onReportSaved(report: Report2) {
        this.toast(
            'success',
            '',
            this.i18nDb.translateByKey('modules.budget.staffing-table.reports.*GenerationPane*.report-saved', report.id),
        );

        this.lastReportEventTime = Date.now();
        this.generationModalVisible = false;
    }
    // endregion


    // region Фильтрация, сортировка
    public filterSortModalVisible = false;
    public templateKey = store.templateKey;
    public filterData = store.conditionExp;

    public handleUpdateCondition(filterData: BoolEx.Expression<boolean> | null, templateKey: TemplateKey) {
        this.templateKey = templateKey;
        this.filterData = filterData;
        if (filterData !== null){
            this.filterSortModalVisible = false;
        }
    }
    // endregion


    // region Список отчетов
    public selectedReports: Array<Report2> = [];

    get selectedIds(): string{
        return this.selectedReports.map(report => report.id).join(',');
    }

    public reloadReports() {
        (this.$refs.reportListPane as unknown as IReportListPane).reloadReports();
    }

    public onReportSelectionChanged(reports: Array<Report2>) {
        this.selectedReports = reports;
    }
    // endregion


    // region Отправка в свод
    public sendToTotalModalLoading = false;
    public sendToTotalModalVisible = false;
    public sendToTotalModalSelections: Array<ReportSelection> = [];

    public showSendToTotalModal() {
        this.sendToTotalModalSelections = [];
        this.sendToTotalModalVisible = true;
    }

    public onSendToTotalModalSelectionsChanged(...args: Array<unknown>) {
        this.sendToTotalModalSelections = args[0] as Array<ReportSelection>;
    }

    public onSendToTotalModalOkClicked(...args: Array<unknown>) {
        if (this.sendToTotalModalLoading) {
            console.error('Cannot send to total - another loading is running');
            return;
        }

        const selections = this.sendToTotalModalSelections.map((selection): IReportSendToTotalSelection => {
            return {
                reportId: selection.report.id!!,
                year: selection.year,
            };
        });
        if (selections.length === 0) {
            console.error('Cannot send to total - no selections');
            return;
        }

        const event: BvModalEvent = args[0] as BvModalEvent;
        event.preventDefault();

        this.sendToTotalModalLoading = true;
        this.request(
            {
                url: `/api/budget/staffing_table/report-v2/send-to-total`,
                method: 'POST',
                data: selections,
            },
            () => {
                this.toast('success', '', translates.sendToTotal.success);
                this.sendToTotalModalVisible = false;
            },
            () => (translates.error.cannotSendToTotal),
            () => {
                this.sendToTotalModalLoading = false;
                this.reloadReports()
            },
        );
    }
    // endregion


    // region Пакетная выгрузка
    public packageReportDebugVisible = false;

    public registerTogglePackageReportDebugVisibility() {
        const self = this;
        (window as unknown as Record<string, unknown>).stafftabPackageDebug = () => {
            self.packageReportDebugVisible = !self.packageReportDebugVisible;
        };
    }

    public deregisterTogglePackageReportDebugVisibility() {
        delete (window as unknown as Record<string, unknown>).stafftabPackageDebug;
    }

    public downloadReportPackageTest1(aggregated: boolean) {
        if (this.selectedReports.length === 0) {
            console.error('Report package downloading cancelled - no selected reports');
            return;
        }

        const idList = this.selectedReports.map((report) => (report.id!!));

        const parameterMap: Array<[string, unknown]> = [
            ['aggregate', String(aggregated)],
        ];
        idList.forEach((id) => {
            parameterMap.push(['report-id', String(id)]);
        });

        const url = this.prepareUrl(
            '/api/budget/staffing_table/report-v2/package/test-1',
            parameterMap,
        );

        this.openUrl(url, '_blank', false);
    }


    public reportPackageTest2ModalVisible = false;
    public reportPackageTest2GuIdString = '';
    public reportPackageTest2KgkpIdString = '';
    public reportPackageTest2KgkpSuperiorGuForm = '';
    public reportPackageTest2Aggregate = false;
    public reportPackageTest2PackageBranchVariant: string | null = null;
    public reportPackageTest2OutputLanguage = 'ru';
    public reportPackageTest2MainGuCode = '';

    public reportPackageTest2PackageBranchVariantOptions: Array<Comp.DropdownItemDef<string | null>> = [
        // <Выбор по данным>
        {
            text: '<Выбор по данным>',
            value: null,
        },
        // Пакетная выгрузка по АБП (с КГКП)
        {
            text: 'Пакетная выгрузка по АБП (с КГКП)',
            value: 'ABP_WITH_KGKP',
        },
        // Пакетная выгрузка по АБП (без КГКП)
        {
            text: 'Пакетная выгрузка по АБП (без КГКП)',
            value: 'ABP_WITHOUT_KGKP',
        },
        // Пакетная выгрузка по ГУ (с КГКП)
        {
            text: 'Пакетная выгрузка по ГУ (с КГКП)',
            value: 'GU_WITH_KGKP',
        },
        // Пакетная выгрузка по ГУ
        {
            text: 'Пакетная выгрузка по ГУ',
            value: 'GU',
        },
        // Пакетная выгрузка по КГКП
        {
            text: 'Пакетная выгрузка по КГКП',
            value: 'KGKP',
        },
    ];

    public get reportPackageTest2GuIdList(): Array<number> {
        return this.reportPackageTest2GuIdString
            .split(',')
            .map((part) => (parseInt(part.trim())))
            .filter((id) => (Number.isSafeInteger(id)))
    }

    public get reportPackageTest2KgkpIdList(): Array<number> {
        return this.reportPackageTest2KgkpIdString
            .split(',')
            .map((part) => (parseInt(part.trim())))
            .filter((id) => (Number.isSafeInteger(id)))
    }

    public showReportPackageTest2Modal() {
        this.reportPackageTest2ModalVisible = true;
    }

    public downloadReportPackageTest2() {
        const parameterMap: Array<[string, unknown]> = [
            ['aggregate', String(this.reportPackageTest2Aggregate)],
            ['output-language', this.reportPackageTest2OutputLanguage],
        ];
        this.reportPackageTest2GuIdList.forEach((id) => {
            parameterMap.push(['gu-total-id', String(id)]);
        });
        this.reportPackageTest2KgkpIdList.forEach((id) => {
            parameterMap.push(['kgkp-total-id', String(id)]);
        });

        const reportPackageTest2KgkpSuperiorGuForm = this.reportPackageTest2KgkpSuperiorGuForm.trim();
        if (reportPackageTest2KgkpSuperiorGuForm.length > 0) {
            parameterMap.push(['kgkp-superior-gu-form', reportPackageTest2KgkpSuperiorGuForm]);
        }

        const reportPackageTest2PackageBranchVariant = this.reportPackageTest2PackageBranchVariant;
        if (reportPackageTest2PackageBranchVariant !== null) {
            parameterMap.push(['package-branch-variant', reportPackageTest2PackageBranchVariant]);
        }

        const reportPackageTest2MainGuCode = this.reportPackageTest2MainGuCode.trim();
        if (reportPackageTest2MainGuCode.length > 0) {
            parameterMap.push(['main-gu-code', reportPackageTest2MainGuCode]);
        }

        const url = this.prepareUrl(
            '/api/budget/staffing_table/report-v2/package/test-2',
            parameterMap,
        );

        this.openUrl(url, '_blank', false);
    }


    public reportPackageTest3ModalVisible = false;

    public showReportPackageTest3Modal() {
        this.reportPackageTest3ModalVisible = true;
    }


    public reportPackageTest3AbpPanelVisible = false;

    public reportPackageTest3AbpPanelBudgetVariantUuid = '';
    public reportPackageTest3AbpPanelYearString = new Date().getFullYear().toString();
    public reportPackageTest3AbpPanelAbpCodeString = '';
    public reportPackageTest3AbpPanelRegionCode = '';
    public reportPackageTest3AbpPanelFormCode = '';
    public reportPackageTest3AbpPanelBudgetProgramCodeString = '';
    public reportPackageTest3AbpPanelBudgetSubprogramCodeString = '';
    public reportPackageTest3AbpPanelOutputLanguage = 'ru';
    public reportPackageTest3AbpPanelMainGuCode = '';

    public get reportPackageTest3AbpPanelYearNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3AbpPanelYearString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3AbpPanelAbpCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3AbpPanelAbpCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3AbpPanelBudgetProgramCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3AbpPanelBudgetProgramCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3AbpPanelBudgetSubprogramCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3AbpPanelBudgetSubprogramCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3AbpPanelValid(): boolean {
        return (
            (this.reportPackageTest3AbpPanelBudgetVariantUuid.length > 0)
            &&
            (this.reportPackageTest3AbpPanelYearNumber !== null)
            &&
            (this.reportPackageTest3AbpPanelAbpCodeNumber !== null)
            &&
            (this.reportPackageTest3AbpPanelRegionCode.length > 0)
            &&
            (this.reportPackageTest3AbpPanelFormCode.length > 0)
            &&
            (this.reportPackageTest3AbpPanelBudgetProgramCodeNumber !== null)
            &&
            (this.reportPackageTest3AbpPanelOutputLanguage.length > 0)
        );
    }

    public reportPackageTest3AbpLoad() {
        const parameterMap: Array<[string, unknown]> = [
            ['budget-variant-uuid', this.reportPackageTest3AbpPanelBudgetVariantUuid],
            ['year', this.reportPackageTest3AbpPanelYearNumber],
            ['abp-code', this.reportPackageTest3AbpPanelAbpCodeNumber],
            ['region-code', this.reportPackageTest3AbpPanelRegionCode],
            ['form-code', this.reportPackageTest3AbpPanelFormCode],
            ['budget-program-code', this.reportPackageTest3AbpPanelBudgetProgramCodeNumber],
            ['output-language', this.reportPackageTest3AbpPanelOutputLanguage],
        ];
        if (this.reportPackageTest3AbpPanelBudgetSubprogramCodeNumber !== null) {
            parameterMap.push(['budget-subprogram-code', this.reportPackageTest3AbpPanelBudgetSubprogramCodeNumber]);
        }

        const reportPackageTest3AbpPanelMainGuCode = this.reportPackageTest3AbpPanelMainGuCode.trim();
        if (reportPackageTest3AbpPanelMainGuCode.length > 0) {
            parameterMap.push(['main-gu-code', reportPackageTest3AbpPanelMainGuCode]);
        }

        const url = this.prepareUrl('/api/budget/staffing_table/report-v2/package/test-3/abp', parameterMap);

        window.open(url, '_blank');
    }


    public reportPackageTest3GuPanelVisible = false;

    public reportPackageTest3GuPanelBudgetVariantUuid = '';
    public reportPackageTest3GuPanelYearString = new Date().getFullYear().toString();
    public reportPackageTest3GuPanelGuCode = '';
    public reportPackageTest3GuPanelRegionCode = '';
    public reportPackageTest3GuPanelFormCode = '';
    public reportPackageTest3GuPanelBudgetProgramCodeString = '';
    public reportPackageTest3GuPanelBudgetSubprogramCodeString = '';
    public reportPackageTest3GuPanelOutputLanguage = 'ru';

    public get reportPackageTest3GuPanelYearNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3GuPanelYearString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3GuPanelBudgetProgramCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3GuPanelBudgetProgramCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3GuPanelBudgetSubprogramCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3GuPanelBudgetSubprogramCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3GuPanelValid(): boolean {
        return (
            (this.reportPackageTest3GuPanelBudgetVariantUuid.length > 0)
            &&
            (this.reportPackageTest3GuPanelYearNumber !== null)
            &&
            (this.reportPackageTest3GuPanelGuCode.length > 0)
            &&
            (this.reportPackageTest3GuPanelRegionCode.length > 0)
            &&
            (this.reportPackageTest3GuPanelFormCode.length > 0)
            &&
            (this.reportPackageTest3GuPanelBudgetProgramCodeNumber !== null)
            &&
            (this.reportPackageTest3GuPanelOutputLanguage.length > 0)
        );
    }

    public reportPackageTest3GuLoad() {
        const parameterMap: Array<[string, unknown]> = [
            ['budget-variant-uuid', this.reportPackageTest3GuPanelBudgetVariantUuid],
            ['year', this.reportPackageTest3GuPanelYearNumber],
            ['gu-code', this.reportPackageTest3GuPanelGuCode],
            ['region-code', this.reportPackageTest3GuPanelRegionCode],
            ['form-code', this.reportPackageTest3GuPanelFormCode],
            ['budget-program-code', this.reportPackageTest3GuPanelBudgetProgramCodeNumber],
            ['output-language', this.reportPackageTest3GuPanelOutputLanguage],
        ];
        if (this.reportPackageTest3GuPanelBudgetSubprogramCodeNumber !== null) {
            parameterMap.push(['budget-subprogram-code', this.reportPackageTest3GuPanelBudgetSubprogramCodeNumber]);
        }

        const url = this.prepareUrl('/api/budget/staffing_table/report-v2/package/test-3/gu', parameterMap);

        window.open(url, '_blank');
    }


    public reportPackageTest3KgkpPanelVisible = false;

    public reportPackageTest3KgkpPanelBudgetVariantUuid = '';
    public reportPackageTest3KgkpPanelYearString = new Date().getFullYear().toString();
    public reportPackageTest3KgkpPanelKgkpBinString = '';
    public reportPackageTest3KgkpPanelRegionCode = '';
    public reportPackageTest3KgkpPanelFormCode = '';
    public reportPackageTest3KgkpPanelSuperiorGuFormCode = '';
    public reportPackageTest3KgkpPanelBudgetProgramCodeString = '';
    public reportPackageTest3KgkpPanelBudgetSubprogramCodeString = '';
    public reportPackageTest3KgkpPanelOutputLanguage = 'ru';
    public reportPackageTest3KgkpPanelMainGuCode = '';

    public get reportPackageTest3KgkpPanelYearNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3KgkpPanelYearString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3KgkpPanelKgkpBinList(): Array<string> {
        const result: Array<string> = [];

        this.reportPackageTest3KgkpPanelKgkpBinString.split(',').forEach((bin) => {
            const trimmedBin = bin.trim();
            if (trimmedBin.length > 0) {
                try {
                    const checkedBin = parseInt(trimmedBin);
                    if (Number.isSafeInteger(checkedBin)) {
                        result.push(trimmedBin);
                    }
                } catch (e) {
                    // Ignore
                }
            }
        });

        return result;
    }

    public get reportPackageTest3KgkpPanelBudgetProgramCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3KgkpPanelBudgetProgramCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3KgkpPanelBudgetSubprogramCodeNumber(): number | null {
        try {
            const result = parseInt(this.reportPackageTest3KgkpPanelBudgetSubprogramCodeString);
            if (Number.isSafeInteger(result)) {
                return result;
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public get reportPackageTest3KgkpPanelValid(): boolean {
        return (
            (this.reportPackageTest3KgkpPanelBudgetVariantUuid.length > 0)
            &&
            (this.reportPackageTest3KgkpPanelYearNumber !== null)
            &&
            (this.reportPackageTest3KgkpPanelKgkpBinList.length > 0)
            &&
            (this.reportPackageTest3KgkpPanelRegionCode.length > 0)
            &&
            (this.reportPackageTest3KgkpPanelFormCode.length > 0)
            &&
            (this.reportPackageTest3KgkpPanelSuperiorGuFormCode.length > 0)
            &&
            (this.reportPackageTest3KgkpPanelBudgetProgramCodeNumber !== null)
            &&
            (this.reportPackageTest3KgkpPanelOutputLanguage.length > 0)
        );
    }

    public reportPackageTest3KgkpLoad() {
        const parameterMap: Array<[string, unknown]> = [
            ['budget-variant-uuid', this.reportPackageTest3KgkpPanelBudgetVariantUuid],
            ['year', this.reportPackageTest3KgkpPanelYearNumber],
            ['region-code', this.reportPackageTest3KgkpPanelRegionCode],
            ['form-code', this.reportPackageTest3KgkpPanelFormCode],
            ['superior-gu-form-code', this.reportPackageTest3KgkpPanelSuperiorGuFormCode],
            ['budget-program-code', this.reportPackageTest3KgkpPanelBudgetProgramCodeNumber],
            ['output-language', this.reportPackageTest3KgkpPanelOutputLanguage],
        ];
        this.reportPackageTest3KgkpPanelKgkpBinList.forEach((bin) => {
            parameterMap.push(['kgkp-bin', bin]);
        });
        if (this.reportPackageTest3KgkpPanelBudgetSubprogramCodeNumber !== null) {
            parameterMap.push(['budget-subprogram-code', this.reportPackageTest3KgkpPanelBudgetSubprogramCodeNumber]);
        }

        const reportPackageTest3KgkpPanelMainGuCode = this.reportPackageTest3KgkpPanelMainGuCode.trim();
        if (reportPackageTest3KgkpPanelMainGuCode.length > 0) {
            parameterMap.push(['main-gu-code', reportPackageTest3KgkpPanelMainGuCode]);
        }

        const url = this.prepareUrl('/api/budget/staffing_table/report-v2/package/test-3/kgkp', parameterMap);

        window.open(url, '_blank');
    }
    // endregion
}
