export const nameMap: Record<string, string> = {
    'INCOMES': 'Доходы',
    'EXPENSES': 'Расходы',
    'REGULATED': 'Регламентированный',
    'UNREGULATED': 'Нерегламентированный',
    'IISK': 'ИИСК',
    'MIXED': 'Смешанный',
    'LOADER': 'Загрузчик',
    'WITHOUT': 'Без ВЗК',
    'WITH': 'с ВЗК',
    'ONLY': 'Только ВЗК',
    'TENGE': 'Тенге',
    'THOUSAND': 'Тыс. тенге',

    'kat': 'Категория',
    'cls': 'Класс',
    'pcl': 'Подкласс',
    'spf': 'Специфика',

    'gr': 'Ф. гр.',
    'pgr': 'Ф. подгр.',
    'abp': 'АБП',
    'gu': 'ГУ',
    'prg': 'БП',
    'ppr': 'БПП'
}
