<template>
    <div class="inner-container">
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="goodsData"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >   
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAllGoods ? getDecText('del_all_recs') : getDecText('del_selected_recs') }`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td></td>
                    <td colspan="3" class="td-numbering table-success text-center">1</td>
                    <td class="td-numbering table-danger text-center">2</td>
                    <td class="td-numbering table-info text-center">3</td>
                    <td class="td-numbering table-primary text-center">4</td>
                    <td class="td-numbering table-warning text-center">5</td>
                    <td class="td-numbering table-success text-center">6</td>
                    <td></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAllGoods" 
                            @change="e => $emit('setIsAllDelete', e, goodsData)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAllGoods = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(good_name)="data">
                    <div class="form-01-144-multiselect-group error">
                        <multiselect
                            v-b-popover.hover.topright="currGoodName ? currGoodName.name : null"
                            v-if="variantAttribute && data.item.isNew"
                            v-model="currGoodName"
                            style="width: 270px"
                            label="name"
                            :placeholder="getDecText('enter_name')"
                            :options="ensTruNamePrt"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            :internal-search="false"
                            @blur="() => ensTruNamePrt.splice(0)"
                            @search-change="(e) => $emit('searchName', e)"
                            @input="setCurrGoodDesc(data.item)"
                        >
                        <span slot="noResult">
                            {{ getDecText('name_not_found') }}
                        </span>
                        <span slot="noOptions">
                            {{ getDecText('start_typing_name') }}
                        </span>
                        </multiselect>
                        <div v-else :id="`ens-item-name-${data.item.id}`" v-bind:class="{'ens-item-non-active': !data.item.esnActive}">{{ data.item.name }}</div>
                        <b-spinner v-if="data.item.isNew && parNameLoading" small label="Small Spinner"></b-spinner>
                        <b-popover v-if="!data.item.esnActive" placement="topright" :target="`ens-item-name-${data.item.id}`" triggers="hover focus">
                            {{ getDecText('code_isnt_active') }}
                        </b-popover>

                        <template v-if="(data.item.isHasDouble)">
                            <i class="icon icon-danger decodes-error-icon"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("exist") }}</p>
                            </div>
                        </template>
                    </div>                    
                </template>
                <template #cell(shrt_descr)="data">
                    <b-spinner class="form-01-144-spinner" v-if="data.item.isNew && descIsLoading" small label="Small Spinner"></b-spinner>
                    <multiselect
                        v-b-popover.hover.topright="data.item.currGoodDesc ? data.item.currGoodDesc.detail : null"
                        v-if="variantAttribute && data.item.ensTruName && data.item.ensTruName.length > 1"
                        v-model="data.item.currGoodDesc"
                        style="width: 270px"
                        label="detail"
                        :placeholder="getDecText('select_characteristics')"
                        :options="data.item.ensTruName"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="descSelectedNameChanged(data.item)"
                    >
                    <span slot="noOptions">
                        {{ getDecText('select_name') }}
                    </span>
                    </multiselect>

                    <div v-else>{{ data.item.shrDescr }}</div>
                </template>
                <template #cell(additional_descr)="data">
                    <b-form-textarea v-if="variantAttribute && data.item.enstru_code"
                        style="min-height: 100px"
                        v-model.trim="data.item.add_detail"
                        @change="onAdditionalDescrChanged(data.item)"
                        :formatter="formatText"
                    ></b-form-textarea>
                    <div v-else>{{ data.item.add_detail }}</div>
                </template>
                <template #cell(unit)="data">
                    <multiselect
                        v-if="variantAttribute && data.item.uom && data.item.uom.length > 1"
                        style="max-width: 130px"
                        :value="data.item.uom.filter(u => u.code === data.item.unit_code)[0]"
                        @input="v => onUnitChanged(data.item, v)"
                        label="name"
                        :options="data.item.uom"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                    >
                    </multiselect>
                    <div v-else>{{ getUnitName(data.item.unit_code) }}</div>
                </template>
                <template #cell(amount)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right number-input-width"
                                  :value="data.item.amount"
                                  @change="v => inputFixed(data.item, 'amount', v, 3)"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.amountPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(price)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right number-input-width"
                                  :value="data.item.price"
                                  @change="v => inputFixed(data.item, 'price', v, 2)"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.pricePassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.file_hash ? data.item.file_hash.split(';').length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" :disabled="goodsAreSaving" v-if="variantAttribute" @click="deleteItem(`${getDecText('del_curr_rec')}`, data.item, data.index)"></i>
                </template>

                <template #bottom-row="data">
                    <td class="text-left" colspan="7">{{ getCommonText("total") }}</td>
                    <td class="text-right">{{ $n(totalTotal) }}</td>
                    
                    <td colspan="2"></td>
                </template>
                
            </b-table>

            <modal-files-management 
                ref="modalFilesManagement"
                :allFiles="allFiles"
                :variantAttribute="variantAttribute"
                @showFileUploadModal="showFileUploadModal"
                @setNewFileHash="setNewFileHash"
                @triggDownloadFile="triggDownloadFile"
            ></modal-files-management>
            
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ModalFilesManagement from './modal-files-management.vue';
import BpI18nHandlerMixin from '../mixins/bp-i18n-handler-mixin';

export default {
    name: 'FormTemplate8',
    components: { ModalFilesManagement },
    mixins: [BpI18nHandlerMixin],
    props: {
        goodsData: Array,
        initialGoodsData: Object,
        ensTruDict: Array,
        variantAttribute: Boolean,
        currCategory: Object,
        lang: String,
        deleteGood: Function,
        ifAllFieldsFilled: Function,
        getUnitName: Function,
        ensTruNamePrt: Array,
        ensTruName: Array,
        currUnits: Array,
        loadCurUnits: Function,
        getEnstruByName: Function,
        showFileUploadModal: Function,
        parNameLoading: Boolean,
        descIsLoading: Boolean,
        triggDownloadFile: Function,
        allFiles: Array,
        formCode: String,
        goodsAreSaving: Boolean,
        isDoubleDecodeExist: Function
    },

    watch: {
        goodsData: {
            handler(newValue, oldValue) {
                this.calcTotals();
            },
            deep: true
        },
    },
    data() {
        return {
            dataTypeFilter: null,
            variantName: null,
            formFilter: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            currUnit: null,
            currGoodName: null,
            totalTotal: 0,
            totalTotalSaved: 0,
            currGoodDesc: null,
            selectAllGoods: false,
        };
    },

    created() {
    },

    methods: {
        addItem() {
            if (this.isDoubleDecodeExist()) return;
            // проверяем все ли поля заполнены            
            if (!this.ifAllFieldsFilled()) return;
            this.currGoodName = null;     
            const item = { id: new Date() * -1 };
            this.$set(item, 'isNew', true);
            this.$set(item, 'rowToSave', true);
            this.$set(item, 'amountPassVal', false);
            this.$set(item, 'pricePassVal', false);
            this.$set(item, 'currGoodDesc', null);
            this.$set(item, 'esnActive', true);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'isHasDouble', false);
            this.goodsData.push(item);
            this.ensTruDict.splice(0);
        }, // добавление записи в таблицу


        async setCurrGoodDesc(item) {
            await this.getEnstruByName(this.currGoodName.name);
            process.nextTick(() => {
                if (this.ensTruName.length) {
                    const relativeDecrIdx = this.ensTruName.findIndex(itm => itm.code === this.currGoodName.code);
                    this.$set(item, 'currGoodDesc', this.ensTruName[relativeDecrIdx !== -1 ? relativeDecrIdx : 0]);
                    this.$set(item, 'ensTruName', this.ensTruName);
                    this.descSelectedNameChanged(item);
                };
            })
        },
        
        makeToast(variant = null, title, msg) {
            this.$bvToast.toast(msg, {
            title: title,
            variant: variant,
            solid: true
            })
        },

        async descSelectedNameChanged(item) {
            await this.loadCurUnits(item.currGoodDesc.code)
            process.nextTick(() => {
                this.$set(item, 'enstru_code', item.currGoodDesc.code);
                const uom = [];
                this.currUnits.forEach(u => {
                    const unitName = this.getUnitName(u.unit_code);
                    uom.push({name: unitName, code: u.unit_code});
                })
                if (uom.length) {
                    this.currUnit = uom[0];
                } else this.currUnit = [];
                this.$set(item, 'unit_code', this.currUnit.code);
                this.$set(item, 'uom', uom);
                this.$set(item, 'shrDescr', item.currGoodDesc['detail_'+this.lang]);
                this.$set(item, 'name', item.currGoodDesc['name_'+this.lang]);
                this.$emit('onDataChanged', item);
                this.$emit('doublesValidationDecodes');
            });
        },

        formatText(text) {
            if (text.length > 254) {
                return text.slice(0, 254);
            } else {
                return text;
            }
        },

        inputFixed(item, field, value, digit) {
            this.$set(item, field, value);
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? parseFloat(parseFloat(value).toFixed(digit)) : 0;
                this.$set(item, field, newVal);
                if (item.amount && item.price) {
                    this.$emit('setTotals', item)
                } else {
                    this.$set(item, 'total', null);
                };
                this.$emit('onDataChanged', item);
            })
        }, // форматирует введенное значение до digit цифр после запятой

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || (!row && this.goodsData.findIndex(itm => itm.itemToDelete) === -1)) return;
            let deletingRow = null;
            this.$bvModal.msgBoxConfirm(
                `${msg}...`,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            if ((!row.id || row.id < 0) && row.rowToSave) {
                                this.goodsData.splice(index, 1);
                                this.$emit('doublesValidationDecodes');
                            }
                            else {
                                deletingRow = this.goodsData.splice(index, 1);
                                this.calcTotals();
                                this.deleteGood([row]);       
                            }  
                        } else {
                            const existingRowsToDel = [];
                            let totalDeleting = 0;
                            for (let i = this.goodsData.length - 1; i >=0; i--) {
                                if (this.goodsData[i].itemToDelete) {
                                    const rowId = this.goodsData[i].id;
                                    if (rowId >= 0) {
                                        existingRowsToDel.push(rowId);
                                        const initialRow = this.initialGoodsData[rowId];
                                        const curTotal = initialRow.total;
                                        totalDeleting += curTotal || 0;
                                    };
                                    this.goodsData.splice(i, 1);
                                };
                            };
                            this.calcTotals();
                            if (!existingRowsToDel.length) { 
                                this.selectAllGoods = false;
                            } else {
                                const roundedTotalDeleting = totalDeleting.toFixed(3);
                                this.$emit('deleteSeveralGoods', existingRowsToDel, roundedTotalDeleting);
                            }
                            this.$emit('doublesValidationDecodes');
                        }
                    }
                })
                .catch(error => {
                    if (deletingRow) this.goodsData = [...this.goodsData, ...deletingRow].sort((a, b) => a.id - b.id);
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // удаление записи

        calcTotals() {
            let sumTotal = 0;
            let sumSavedTotal = 0;
            for (const row of this.goodsData) {
                const amount = row.amount ? row.amount : 0;
                const price = row.price ? row.price : 0;
                
                const roundedSum = Math.round(parseFloat(price) * parseFloat(amount) * 100) / 100;
                const total = this.roundNumber(roundedSum / 1000, 3);
                if (row.id > 0) {
                    sumSavedTotal += total;
                }
                    
                sumTotal += total; 
            };
            this.totalTotalSaved = this.roundNumber(sumSavedTotal, 3);
            this.totalTotal = this.roundNumber(sumTotal, 3);
        },

        getTotal() {
            return {'totalAll': this.totalTotal, 'totalSaved': this.totalTotalSaved};
        }, // передача тотала данной категории родителю

        onFilesClick(item) {
            this.allFiles.forEach(item => {
                if (item.new_file) this.$set(item, 'new_file', false)
            });
            this.$refs.modalFilesManagement.showModal(item);
        },

        setNewFileHash(id, codes) {
            for (const good of this.goodsData) {
                if (good.id === id) {
                    this.$set(good, 'file_hash', codes);
                    this.$emit('onDataChanged', good);
                    break;
                }
            }
        },

        roundNumber(input, decimalPlaces) {
            const numberValue = Number(input) || 0;
            const decimalPlacesValue = Number(decimalPlaces) || 0;
            const rank = Math.pow(10, decimalPlacesValue);
            const mult = Math.round(numberValue * rank * 100) / 100;
            const roundedNumber = Math.round(mult) / rank;
            return roundedNumber;
        },

        onAdditionalDescrChanged(item) {
            this.$emit('onDataChanged', item);
            this.$emit('doublesValidationDecodes');
        }, 

        onUnitChanged(item, val) {
            this.currUnit = val; 
            this.$set(item, 'unit_code', val.code); 
            this.$emit('onDataChanged', item);
            this.$emit('doublesValidationDecodes');
        },
    },
    computed: {
        getFormText() {
            return this.setFormText('form_01_144.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'good_name',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'shrt_descr',
                        label: this.getCommonText('short_info')
                    },
                    {
                        key: 'additional_descr',
                        label: this.getCommonText('add_info')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('measure')
                    },
                    {   key: 'amount',
                        label: this.getFormText('amount')
                    },
                    {   key: 'price',
                        label: this.getCommonText('price')
                    },
                    {   key: 'total',
                        label: this.getFormText('expense_sum_spec')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    },
                ];
            };
            return [];
        },

    }
    
}
</script>

<style scoped>
.form-01-144-multiselect-group {
    display: flex;
}

.form-01-144-multiselect-group .spinner-border {
    position: relative;
    right: 50px;
    opacity: 0.3;
    margin: auto;
    color: #7A87A0;
    z-index: 999;
}

.form-01-144-spinner {
    display: block;
    margin: auto;
    color: #7A87A0;
}

.goods-form-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}

.ens-item-non-active {
    color: #6087a099;
}

.decodes-error-icon {
    top: -20px;
    left: 0px;
}

</style>
