
























































































import {Vue, Component, Watch, Prop} from "vue-property-decorator";
import VueElementLoading from "vue-element-loading";
import "../user-management.scss";
import {
    IKeyCloakUser,
    IModule,
    IUserOperations,
} from "../user-management-int";
import BaseUserLink from "../base-user-link";
import MultyCheck from "./multy-check.vue";

@Component({
    components: {
        loading: VueElementLoading,
        multycheck: MultyCheck,
    },
})
export default class ModulesUserData extends BaseUserLink {
    private modulesBase: any = [];
    private modulesList: any[] = [];
    @Prop() private operations!: any;
    private selectedUserFgrList: IModule[] = [];
    private loading = false;
    private access_levels = [
        {value: 1, text: "Просмотр"},
        {value: 2, text: "Редактирование"},
        {value: 3, text: "Админ"},
    ];
    
    @Watch("selectedUser")
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        console.log(value.id, oldValue?.id)
        this.loading = true;
        if (value ! == oldValue) {
            if (oldValue ! == null){
                this.selectedUserFgrList = [];
                await this.loadModules();
            }
        }
        if (oldValue !== null) {
            this.selectedUserFgrList = [];
            this.modulesList = this.modulesBase.map((item: IModule) => {
                return {
                    id: item.id,
                    code: item.code,
                    par_id: item.par_id,
                    name_ru: item.name_ru,
                    name_kk: item.name_kk,
                    status: item.status
                }
            });
            this.modulesList.sort((a, b) => a.code > b.code ? 1 : -1);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private async loadModules() {
        try {
            const response = await fetch("/api-py/sic-modules");
            this.modulesBase = (await response.json()) as IModule;
            this.modulesList = [...this.modulesBase];
            this.modulesList.sort((a, b) => a.code > b.code ? 1 : -1);
        } catch (error) {
            this.makeToast("danger", "Ошибка загрузки категорий", error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserModules(user);
        if (this.selectedUser !== null) {
            for (const item of this.selectedUserFgrList) {
                for (const it of this.modulesList) {
                    if (it.code === item.code) {
                        const ind = this.modulesList.indexOf(it);
                        this.modulesList.splice(ind, 1);
                    }
                }
            }
        }
    }

    removeAllModules() {
        for (const abpListElement of this.selectedUserFgrList) {
            this.modulesList.push(abpListElement);
        }
        this.modulesList.sort((a, b) => a.code - b.code);
        this.selectedUserFgrList = [];
        this.saveUserFgrData(this.selectedUser);
    }

    addAllModules() {
        for (const abpListElement of this.modulesList) {
            if (!this.selectedUserFgrList.find(value => value.id === abpListElement.id)) {
                if (!abpListElement.access_level) {
                    abpListElement.access_level = 1;
                }
                if (!abpListElement.userId) {
                    abpListElement.userId = this.selectedUser.id;
                }
                abpListElement.modules = abpListElement.code;
                this.selectedUserFgrList.push(abpListElement);
            }
        }
        this.selectedUserFgrList.sort((a, b) => (a.code > b.code ? 1 : -1));
        this.modulesList = [];
        this.saveUserFgrData(this.selectedUser);
    }

    private addFgrToSelectedUser(modules: IModule, save: boolean) {
        if (this.selectedUserFgrList.indexOf(modules) < 0) {
            if (!modules.access_level) {
                modules.access_level = 1;
            }
            if (!modules.userId) {
                modules.userId = this.selectedUser.id;
            }
            modules.modules = modules.code;
            this.selectedUserFgrList.push(modules);
            const ind = this.modulesList.indexOf(modules);
            this.modulesList.splice(ind, 1);
        }
        this.selectedUserFgrList.sort((a, b) => (a.code > b.code ? 1 : -1));
        if (save) {
            this.saveUserFgrData(this.selectedUser);
        }
    }

   private removeFgrFromSelectedUser(modules: any, save: boolean) {
        const ind = this.selectedUserFgrList.indexOf(modules);
        this.selectedUserFgrList.splice(ind, 1);
        this.modulesList.push({
                    id: modules.id,
                    code: modules.code,
                    par_id: modules.par_id,
                    name_ru: modules.name_ru,
                    name_kk: modules.name_kk,
                    status: modules.status
        })
        this.modulesList.sort((a, b) => a.code > b.code ? 1 : -1);
        if (save) {
            this.saveUserFgrData(this.selectedUser);
        }
    }

    private async saveUserFgrData(user: IKeyCloakUser) {
        const modulesList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserFgrList) {
            const d = {
                modules: item.code,
                access_level: item.access_level,
                user_id: user?.id,
            };
            modulesList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                modulesList: modulesList,
            };
            try {
                await fetch("/api-py/save-user-modules", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    body: JSON.stringify(saveData),
                });
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка сохранения данных: " + user.id,
                    error.toString()
                );
            }
        }
    }

    right_max_count = 0;

    private async loadUserModules(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.loading = true;
                this.selectedUserFgrList = [];
                // const response = await fetch("/api-py/user-modules/" + user?.id);
                const response = await fetch("/api/um/module/links/" + user?.id);
                const data = await response.json();
                this.right_max_count = data.length;
                for (const item of data) {
                    for (const t of this.modulesList) {
                        if (item.modules === t.code) {
                            t.access_level = item.accessLevel;
                            t.operations = item.operations ? item.operations : [];
                            t.userId = item.userId;
                            t.baseId = item.id;
                            t.modules = item.modules;
                            this.addFgrToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки данных категорий : " + user.id,
                    error.toString()
                );
            } finally {
                this.loading = false;
            }
    }

   public async mounted() {
        await this.loadModules();
        if (this.selectedUser !== null) {
            this.loadUserModules(this.selectedUser);
        }
    }
   

}
