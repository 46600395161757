


























































































































import {Vue, Prop, Component} from 'vue-property-decorator';

@Component({
    filters: {
        totalCBC(value: any[]) {
            let sum = 0;
            if (value) {
                for (const gr of value) {
                    for (const r of gr.children) {
                        sum += r.value
                    }
                }
            }
            return parseFloat(Math.abs(sum).toFixed(3));
        }
    }
})
export default class app9ReasonInput extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private reasonsFull!:any[];

    private reasonsTreePl:any[] = [];
    private reasonsTreeOB:any[] = [];

    @Prop({
        required: true,
        default: []
    })
    private cbcList!:any[];
    private changedCbc:any[] = []
    private changeCbc() {
    }
    private get sortedCbcList() {
        return this.cbcList.sort((a:any, b:any) => a['ppr_key'].localeCompare(b['ppr_key']));
    }
    private currentCBC:any = null;
    private created () {
        const that = this;
        this.$watch('currentCBC', (n, o) => {
            if (o !== null) {
                if (o.chagedReasons) {
                    that.onSave(o)
                }
            }
            if (this.currentCBC) {
                const reasonsFullPL:any[] = that.reasonsFull.map((r) => ({...r}))
                const reasonsFullOB:any[] = that.reasonsFull.map((r) => ({...r}))
                for (const r of reasonsFullPL) {
                    if (r.id in that.currentCBC.note_pl_reasonid) {
                        r.value = Math.abs(that.currentCBC.note_pl_reasonid[r.id])
                    } else {
                        r.value = 0
                    }
                }
                for (const r of reasonsFullOB) {
                    if (r.id in that.currentCBC.note_ob_reasonid) {
                        r.value = Math.abs(that.currentCBC.note_ob_reasonid[r.id])
                    } else {
                        r.value = 0
                    }
                }
                that.reasonsTreePl = that.createReasonsTreeFlat(null, 2, reasonsFullPL)
                that.reasonsTreeOB = that.createReasonsTreeFlat(null, 1, reasonsFullOB)
            }
        })
        if (this.cbcList.length > 0) {
            this.currentCBC = this.sortedCbcList[0]
        }
    }


    private createReasonsTreeFlat(type:any = null, obaz_plat:any = 1, reasonsFull:any[] = []) {
        const that = this;
        const dict_ob:any = {}
        const dict_pl:any = {}
        for (const r of reasonsFull) {
            if (type !== null) {
                if (r.type !== type && r.type !== 3) {
                    continue
                }
            }
            if (!dict_ob[r.gr_ob_id] && obaz_plat === 1) {
                const grob =  {children: [], open: false, ...r}
                Object.defineProperty(grob, 'label', {
                    get() {
                        return grob[`gr_ob_name_${that.$i18n.locale}`]
                    }
                })
                Object.defineProperty(grob, 'value', {
                    get: function () {
                        let sum = 0
                        for (const ch of grob.children) {
                            sum += ch.value
                        }
                        return sum
                    }
                })
                dict_ob[r.gr_ob_id] =grob
            }
            if (!dict_pl[r.gr_pl_id] && obaz_plat === 2) {
                const grpl =  {children: [], open: false, ...r}
                Object.defineProperty(grpl, 'label', {
                    get() {
                        return grpl[`gr_pl_name_${that.$i18n.locale}`]
                    }
                })
                Object.defineProperty(grpl, 'value', {
                    get: function () {
                        let sum = 0
                        for (const ch of grpl.children) {
                            sum += ch.value
                        }
                        return sum
                    }
                })
                dict_pl[r.gr_pl_id] =grpl
            }
            if (obaz_plat === 1) {
                const roob:any = {...r}
                Object.defineProperty(roob, 'label', {
                    get() {
                        return roob[`name_${that.$i18n.locale}`]
                    }
                })
                dict_ob[r.gr_ob_id].children.push(roob)
            }
            if (obaz_plat === 2) {
                const ropl:any = {...r}
                Object.defineProperty(ropl, 'label', {
                    get() {
                        return ropl[`name_${that.$i18n.locale}`]
                    }
                })
                dict_pl[r.gr_pl_id].children.push(ropl)
            }

        }
        if (obaz_plat === 1) return Object.values(dict_ob)
        else return Object.values(dict_pl)
    }

    private get flk1() {
        return parseFloat(Math.abs(this.currentCBC.sum_not_executed_payment).toFixed(3)) !== this.total(this.reasonsTreePl)
    }
    private get flk2() {
        return parseFloat(Math.abs(this.currentCBC.sum_discarded_oblig).toFixed(3)) !== this.total(this.reasonsTreeOB)
    }
    private total(value: any[]) {
        let sum = 0;
        if (value) {
            for (const gr of value) {
                for (const r of gr.children) {
                    sum += r.value
                }
            }
        }
        return parseFloat(Math.abs(sum).toFixed(3));
    }
    private onClearReasons() {
        this.$bvModal.msgBoxConfirm(
            this.$t('modules.budget_execution_monitoring.reports.app_9.reason_input.confirm_delete').toString(),
            {
                title: this.$t('modules.budget_execution_monitoring.reports.app_9.confirmation').toString(),
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.yes').toString(),
                cancelTitle: this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.cancellation').toString(),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    for (const gr of this.reasonsTreePl) {
                        for (const r of gr.children) {
                            if (r.value !== 0) {
                                r.value = 0
                                this.currentCBC.note_pl_reasonid[r.id] = 0
                                this.currentCBC.chagedReasons = true
                            }
                        }
                    }
                    for (const gr of this.reasonsTreeOB) {
                        for (const r of gr.children) {
                            if (r.value !== 0) {
                                r.value = 0
                                this.currentCBC.note_ob_reasonid[r.id] = 0
                                this.currentCBC.chagedReasons = true
                            }
                        }
                    }
                }
            })
            .catch(error => {
                this.makeToast('danger', this.$t('modules.budget_execution_monitoring.reports.app_9.save_error'), error.toString());
            });
    }

    private onBack() {
        if (this.currentCBC.chagedReasons) {
            this.onSave(this.currentCBC);
        }
        this.$emit('onBack')
    }
    private onNext() {
        this.onSave(this.currentCBC)
        const totalCBC = (cbk:any) => {
            let sum = 0;
            for (const r of Object.keys(cbk)) {
                sum += cbk[r]
            }
            return parseFloat(Math.abs(sum).toFixed(3));
        }
        const currentCBCIndex = this.sortedCbcList.findIndex((v:any) => parseInt(v.ppr_key.replace(/\s+/g, '')) === parseInt(this.currentCBC.ppr_key.replace(/\s+/g, '')))
        const success = this.sortedCbcList.findIndex((v:any) => (
                totalCBC(v.note_pl_reasonid) !== parseFloat(Math.abs(v.sum_not_executed_payment).toFixed(3))
                || totalCBC(v.note_ob_reasonid) !== parseFloat(Math.abs(v.sum_discarded_oblig).toFixed(3))
            )
        ) < 0
        if (currentCBCIndex < this.sortedCbcList.length - 1 || success) {
            let newIndex = this.sortedCbcList.findIndex((v:any) => v.ppr_key > this.currentCBC.ppr_key
                && (
                    totalCBC(v.note_pl_reasonid) !== parseFloat(Math.abs(v.sum_not_executed_payment).toFixed(3))
                    || totalCBC(v.note_ob_reasonid) !== parseFloat(Math.abs(v.sum_discarded_oblig).toFixed(3))
                )
            )
            if (newIndex < 0) {
                newIndex = 0
            }
            this.currentCBC = this.sortedCbcList[newIndex]
        } else {
            this.currentCBC = this.sortedCbcList[0]
        }
        if (success) {
            this.makeToast('success',
                this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.massage'),
                this.$t('modules.budget_execution_monitoring.reports.app_9.reason_input.warn_success_reasons')
            )
        }
    }

    private onSave(currentCBC:any = null) {
        if (currentCBC === null) {
            currentCBC = this.currentCBC
        }
        currentCBC.note_pl_reasonid = {}
        currentCBC.note_ob_reasonid = {}
        for (const gr of this.reasonsTreePl) {
            for (const r of gr.children) {
                if (r.value !== 0) {
                    currentCBC.note_pl_reasonid[r.id] = -Math.abs(r.value)
                }
            }
        }
        for (const gr of this.reasonsTreeOB) {
            for (const r of gr.children) {
                if (r.value !== 0) {
                    currentCBC.note_ob_reasonid[r.id] = -Math.abs(r.value)
                }
            }
        }
        if (currentCBC && currentCBC.hasOwnProperty('chagedReasons')) {
            currentCBC.hasChanges = true
            delete currentCBC.chagedReasons;
        }
    }
    private onSaveAndClose() {
        this.onSave(this.currentCBC)
        this.onBack()
    }
    // -----------------
    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private nextInput(event: any) {
        event.preventDefault();
        // Isolate the node that we're after
        const currentNode = event.target;
        // find all tab-able elements
        const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
        // Find the current tab index.
        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
        if (!event.target.value) {
            event.target.value = 0;
        }
        // select/focus the following element
        const targetIndex = (currentIndex + 1) % allElements.length;
        if (targetIndex < allElements.length) {
            allElements[targetIndex].select();
        }
    }// enter работает как tab

    private getNextInputIndx(prgIdx: number, pprIdx: number, spfIndx: number, chldIndx: number | null) {
    }
    // ---------

    private inputFocus(event: any) {
        event.target.select();
    }

    private inputFixed(item: any, field: any, value: any, digit: any) {
        if (value) {
            this.currentCBC.chagedReasons = true
            this.$set(item, field, parseFloat(Math.abs(parseFloat(value)).toFixed(digit)));
        } else {
            this.currentCBC.chagedReasons = true
            this.$set(item, field, 0);
        }
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
