









































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"
import { ReportDviRow } from "../other-types"
import { Classificator } from "../../../common/types/Classificator"

type Column = keyof ReportDviRow | 'months'

export interface TableField {
    key: Column,
    label: string,
    active: boolean,

    expense?: string
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportDvi extends Vue {

    @Prop({
        type: Array,
        required: true,
    })
    public tableData!: ReportDviRow[]

    @Prop({
        type: Array,
        required: true
    })
    private readonly expenses!: Classificator[]

    public activeExpenseKeys = this.expenses.filter(it => it.active).map(it => it.key)

    public tableFields: TableField[] = []

    private readonly allColumns: Column[] = [
        'plan',
        'months',
        'correctedPlan'
    ]
    private readonly allLowerColumns: Column[] = [
        'plan',
        'month1',
        'month2',
        'month3',
        'month4',
        'month5',
        'month6',
        'month7',
        'month8',
        'month9',
        'month10',
        'month11',
        'month12',
        'correctedPlan'
    ]

    public columnsNameMap: Record<string, string> = {
        'gr': 'Функциональная группа',
        'pgr': 'Функция',
        'abp': 'Администратор',
        'prg': 'Программа',
        'ppr': 'Подпрограмма',
        'spf': 'Специфика',
        'plan': 'Утвержденный план на год',
        'name': 'Наименование расходов',
        'months': 'Cумма внесенных годовых изменений по месяцам',
        'month1': 'январь',
        'month2': 'февраль',
        'month3': 'март',
        'month4': 'апрель',
        'month5': 'май',
        'month6': 'июнь',
        'month7': 'июль',
        'month8': 'август',
        'month9': 'сентябрь',
        'month10': 'октябрь',
        'month11': 'ноябрь',
        'month12': 'декабрь',
        'correctedPlan': 'Скорректированный план',
    }

    public currentPage = 1
    public currentItems: ReportDviRow[] = []

    private created() {
        this.constructTableFields()
        this.handlePageChange(1)
    }

    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.tableData.slice(startIndex, endIndex)
    }

    private constructTableFields() {
        const tempTableFields: TableField[] = []

        // Классификаций (Боковик)
        const bokovikColumns: Column[] = []
        const activeExpenseKeys = this.expenses.filter(it => it.active).map(it => it.key)
        activeExpenseKeys.forEach(it =>
            bokovikColumns.push(it as Column)
        )
        bokovikColumns.push('name')

        bokovikColumns.forEach(it => {
            tempTableFields.push({ key: it, label: this.columnsNameMap[it], active: true })
        })

        // Показатели
        this.allColumns.forEach(column => {
            tempTableFields.push({ key: column, label: this.columnsNameMap[column], active: true })
        })

        this.tableFields = tempTableFields
    }

    public formatDigitsGrouping = formatDigitsGrouping
}
