














































































































































































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { ReportIRBOTableData } from "../table-data/table-data-types"
import { Indicator } from "../../../common/types/Indicator"
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"

type Column = 'region_name' | 'skor_plan' | 'plan_na_period' | 'kass' | 'unfulfilled' | 'percent_to_period' | 'deviation_from_annual' | 'percent_to_year'

export interface TableField {
    key: Column,
    label: string,
    active: boolean
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportIRBO extends Vue {

    @Prop({
        type: Object,
        required: true,
    })
    public tableData!: ReportIRBOTableData

    @Prop({
        type: Array,
        required: true
    })
    private readonly propIndicators!: Indicator[]

    @Prop({
        type: Date,
        required: true
    })
    private readonly reportDate!: Date
    private readonly nextYear: number = this.reportDate.getFullYear()

    // public variant: 'UNFULFILLED' | 'OVERFULFILLED' = 'UNFULFILLED'
    public tableFields: TableField[] = []
    public indicators: Indicator[] = []

    public columnsNameMap: Record<Column, string> = {
        'region_name': 'Наименование',
        'skor_plan': `План на ${this.nextYear} год`,
        'kass': 'Фактическое исполнение',
        'plan_na_period': 'План на отчетный период',
        'unfulfilled': 'Сумма неисполнения',
        'percent_to_period': '% к периоду',
        'deviation_from_annual': 'Отклонение к годовому плану',
        'percent_to_year': '% к году',
    }
    public totalExpand: boolean = true

    private created() {
        this.indicators = cloneDeep(this.propIndicators)
        this.constructTableFields()
    }

    private constructTableFields() {
        const tempTableFields: TableField[]  = []

        // Боковик
        const region_name_key = 'region_name'
        tempTableFields.push({ key: region_name_key, label: this.columnsNameMap[region_name_key], active: true })

        // Показатели
        this.propIndicators.forEach(indicator => {
            const key: Column = indicator.key as unknown as Column
            tempTableFields.push({ key: key, label: this.columnsNameMap[key], active: true })
        })

        this.tableFields = tempTableFields
    }

    public draggableHeader(columnKey: string) {
        return ['plan', 'fact', 'delta1', 'delta2', 'reasons'].includes(columnKey)
    }

    public isTotalColumn(columnKey: string) {
        return ['plan', 'fact', 'delta1'].includes(columnKey)
    }

    public expand(template2_item_index: number){
        if (this.tableData.template2_items){
            this.tableData.template2_items[template2_item_index].is_expanded = !this.tableData.template2_items[template2_item_index].is_expanded
        }
    }
    public expandAll(){
        if (this.tableData.template2_items){
            for (let i: number = 0; i < this.tableData.template2_items.length; i++) {
                this.tableData.template2_items[i].is_expanded = !this.totalExpand
            }
        }
        this.totalExpand = !this.totalExpand
    }
    

    public synchronizeIndicatorsOrder() {
        // const indicatorKeys = ['plan', 'fact', 'delta1', 'delta2', 'reasons']

        // const indicatorsOrder = this.tableFields
        //     .filter(it => indicatorKeys.includes(it.key))
        //     .map(it => it.key)

        // indicatorKeys.forEach(indicatorKey => {
        //     if (indicatorsOrder.notIncludes(indicatorKey)) {
        //         indicatorsOrder.push(indicatorKey)
        //     }
        // })

        // const tempIndicators: Indicator[] = []
        // indicatorsOrder.forEach(indicatorKey => {
        //     const IndicatorObject = this.indicators.find(it => it.key === indicatorKey)!
        //     tempIndicators.push(IndicatorObject)
        // })

        // this.indicators = tempIndicators
        // this.$emit('update-indicators', cloneDeep(tempIndicators))
    }

    public synchronizeVisiblityInTable() {
        // this.$emit('update-indicators', cloneDeep(this.indicators))

        // const tempTableFields: TableField[] = []
        // tempTableFields.push({ key: 'index', label: '№', active: true })
        // const activeIncomeCategories = this.incomes.filter(item => item.active)

        // if (this.isStandartHierarchy) {
        //     tempTableFields.push({ key: 'fullcode', label: 'КБК поступлений', active: true })
        // } else {
        //     activeIncomeCategories.forEach(item => {
        //         switch (item.key) {
        //             case 'kat':
        //                 tempTableFields.push({ key: 'kat', label: 'Категория', active: true })
        //                 break
        //             case 'cls':
        //                 tempTableFields.push({ key: 'cls', label: 'Класс', active: true })
        //                 break
        //             case 'pcl':
        //                 tempTableFields.push({ key: 'pcl', label: 'Подкласс', active: true })
        //                 break
        //             case 'spf':
        //                 tempTableFields.push({ key: 'spf', label: 'Специфика', active: true })
        //                 break
        //             default:
        //                 break
        //         }
        //     })
        // }

        // tempTableFields.push({ key: 'nameRu', label: 'Наименование КБК', active: true })

        // this.indicators.filter(it => it.active).forEach(indicator => {
        //     switch (indicator.key) {
        //         case 'plan':
        //             tempTableFields.push({ key: 'plan', label: this.indicatorsNameMap.plan, active: true })
        //             break
        //         case 'fact':
        //             tempTableFields.push({ key: 'fact', label: this.indicatorsNameMap.fact, active: true })
        //             break
        //         case 'delta1':
        //             tempTableFields.push({ key: 'delta1', label: this.indicatorsNameMap.delta1, active: true })
        //             break
        //         case 'delta2':
        //             tempTableFields.push({ key: 'delta2', label: this.indicatorsNameMap.delta2, active: true })
        //             break
        //         case 'reasons':
        //             tempTableFields.push({ key: 'reasons', label: this.indicatorsNameMap.reasons, active: true })
        //             break
        //         default:
        //             break
        //     }
        // })

        // this.tableFields = tempTableFields
    }

    public formatDigitsGrouping = formatDigitsGrouping
}
