import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/root/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { reportIRBOFilterOptions, reportIRBOIndicators } from "../report-irbo";
import { FilterSettings } from "../../../common/types/root/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/root/FilterSettingsForm";
import { ReportIRBOFilterSettingsForm } from "../settings-form/ReportIRBOFilterSettingsForm";
import { ReportIRBOFilterSettings } from "../settings/ReportIRBOFilterSettings";
import { ReportIRBOResponse } from "../other-types/ReportIRBOResponse";
import { ReportIRBOTableDataRequest } from "../other-types/ReportIRBOTableDataRequest";
import { ReportIRBOTableData } from "../table-data/table-data-types";
import { ReportIRBOJournalRequest } from "../journal/ReportIRBOJournalRequest";
import store from '@/services/store';

export class ReportIRBOFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportIRBOFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        if (settings.reportName !== 'IRBO') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'IRBO') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let mutuallyRedeemingOption = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeemingOption) {
            throw new Error('Не найден mutuallyRedeeming')
        }
        mutuallyRedeemingOption = { ...mutuallyRedeemingOption }

        let measureUnitOption = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnit')
        }
        measureUnitOption = { ...measureUnitOption } 

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: ReportIRBOFilterSettingsForm = {
            key: 'IRBO',

            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,

            date: new Date(settings.date),
            measureUnit: measureUnitOption,
            mutuallyRedeeming: mutuallyRedeemingOption,
            decodingActualRegionalBudget: settings.decodingActualRegionalbudget,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null,
            
            indicators: cloneDeep(settings.indicators)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'IRBO') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.mutuallyRedeeming == null) {
            throw new Error('')
        }
        if (form.measureUnit == null) {
            throw new Error('')
        }

        if (!form.date) {
            throw new Error('')
        }
        const strDate = moment(form.date).format('YYYY-MM-DD')

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: ReportIRBOFilterSettings = {
            reportName: 'IRBO',

            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,

            date: strDate,
            measureUnit: form.measureUnit.value,
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            decodingActualRegionalbudget: form.decodingActualRegionalBudget ?? false,
            roundUpTo: roundUpTo,
            indicators: cloneDeep(form.indicators),
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportIRBOFilterOptions)

        const yesterdayMoment = moment().subtract(1, 'days')
        const yesterdayDate = yesterdayMoment.toDate()

        const emptyForm: ReportIRBOFilterSettingsForm = {
            key: 'IRBO',

            reportName: options.reportNames.find(it => it.value === 'IRBO')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'MIXED')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            
            date: yesterdayDate,
            mutuallyRedeeming: options.mutuallyRedeeming.find(it => it.value === 'WITHOUT')!,
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            decodingActualRegionalBudget: true,
            roundUp: false,
            roundUpTo: null,
            indicators: cloneDeep(reportIRBOIndicators),
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций
        if (form.key !== 'IRBO') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        let katoRegion = store.state._instanceCode;

        // для тестового сервера
        if (!katoRegion) {
            katoRegion = '35'
        }
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        if (form.decodingActualRegionalBudget == null) {
            throw new Error('Необходимо ввести "Расшифровка собственно областного бюджета"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        // 2. Формирование данных для таблицы
        const params: ReportIRBOTableDataRequest = {
            region: katoRegion,
            report_date: strDate,
            mutually_redeeming: form.mutuallyRedeeming.value,
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
            decoding_actual_regional_budget: form.decodingActualRegionalBudget,
        }


        const response = await fetch('/api-py/monitoring/reports-constructor/report-irbo',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseData: ReportIRBOResponse = await response.json()
        if ('template2_items' in responseData) {
            responseData.template2_items = responseData.template2_items!.map(item => ({
                ...item,
                is_expanded: true
            }))
        };
        const tableData: ReportIRBOTableData = responseData

        // 3. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportIRBOJournalRequest = {
            reportName: "IRBO",

            name: templateName,
            filter_settings: settings,
            user_id: userId,
            indicators_order: form.indicators.filter(it => it.active).map(it => it.key),

            region: katoRegion,
            report_date: strDate,
            measure_unit: form.measureUnit.value,
            actually_regional: tableData.actually_regional ?? null,
            template1_items: tableData.template1_items ?? null,
            template1_items_total: tableData.template1_items_total ?? null,
            template1_regional_budget_decrypt_items: tableData.template1_regional_budget_decrypt_items ?? null,
            template2_items: tableData.template2_items ?? null,
            template2_items_districts_total: tableData.template2_items_districts_total ?? null,
            template2_items_total: tableData.template2_items_total ?? null,
            decoding_actual_regional_budget: form.decodingActualRegionalBudget,
        }

        const result: TableDataAndJournalParams = {
            tableData: cloneDeep(tableData),
            journalParams: journalParams
        }

        return result
    }
}
