
import {dataTypes, Expression, prepareForBackend} from "@/components/custom/bool-ex";
import {Org, BudgetVariants, Utils} from "@/modules/budget/staffing-table/types";
import TemplateKey = Utils.TemplateKey;
import {store} from "@/modules/budget/staffing-table/common";
import * as common from "@/modules/budget/staffing-table/common";

function transformReportForm(input: string | null): string | null {
    if (input) {
        const withoutPrefix = input.replace(/^F_/, '');
        return withoutPrefix.replace('_', '-');
    }
   return null
}

export function generateExpression(key: TemplateKey | null): Expression<boolean> | null {
    const orgCode = common.store.org?.org.code ?? null;
    const variantUuid= store.budgetVariant?.variantUuid ?? null
    const staffVersion = store.version?.id ?? null;

    //const selectedOptionsForms = store.selectedOptionsForms
    const form =  transformReportForm(store.form)

    const budgetProgram = store.budgetProgram ?? null
   // const selectedBudgetProgramsOptions = store.multiSelectedBudgetProgram;

   // const selectedSpecificOptions = store.selectedSpecificOptions;
    const specific = store.specificity

    switch (key) {
        case 'variant-budget-reports': {
            if (orgCode === null || variantUuid === null) {
                return null;
            }

            const item = {
                type: 'boolean-group',
                dataType: 'boolean',
                operation: 'AND',
                items: [
                    {
                        type: 'two-value-comparison',
                        dataType: 'boolean',
                        first: {
                            type: 'parameter',
                            dataType: 'string',
                            value: orgCode
                        },
                        operation: 'equals',
                        second: {
                            type: 'custom-field',
                            dataType: 'string',
                            key: 'version--org-code',
                            title: 'code org',
                        },
                    },
                    {
                        type: 'two-value-comparison',
                        dataType: 'boolean',
                        first: {
                            type: 'parameter',
                            dataType: 'string',
                            value: variantUuid
                        },
                        operation: 'equals',
                        second: {
                            type: 'custom-field',
                            dataType: 'string',
                            key: 'budget-variant-uuid',
                            title: 'Budget Variant UUID',
                        }
                    }
                ]
            } as Expression<boolean>;
            prepareForBackend(item)
            return item
        }
        case 'variant-budget-staff-version-reports': {
            if (staffVersion === null || variantUuid === null) {
                return null;
            }
            const item =   {
                type: 'boolean-group',
                    dataType: 'boolean',
                    operation: 'AND',
                    items: [
                    {
                        type: 'two-value-comparison',
                        dataType: 'boolean',
                        first: {
                            type: 'parameter',
                            dataType: 'number',
                            value: staffVersion
                        },
                        operation: 'equals',
                        second: {
                            type: 'custom-field',
                            dataType: 'number',
                            key: 'version-id',
                            title: 'staffTab version'
                        }
                    },
                    {
                        type: 'two-value-comparison',
                        dataType: dataTypes.boolean,
                        first: {
                            type: 'parameter',
                            dataType: 'string',
                            value: variantUuid
                        },
                        operation: 'equals',
                        second: {
                            type: 'custom-field',
                            dataType: 'string',
                            key: 'budget-variant-uuid',
                        }
                    }
                ]
            } as Expression<boolean>
            prepareForBackend(item)
            return item
        }
        case 'budget-program-reports':{
            if (budgetProgram === null || variantUuid === null) {
                return null;
            }

          const item= {
                type: 'boolean-group',
                    dataType: 'boolean',
                operation: 'AND',
                items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: orgCode
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'version--org-code',
                        title: 'code org'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: variantUuid
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'budget-variant-uuid',
                        title: 'Budget Variant UUID'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'number',
                        value: budgetProgram.prg
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'budg-prog-code',
                        title: 'Budget Subprogram Code'
                    }
                }
            ]
            } as Expression<boolean>;
            prepareForBackend(item)
            return item;
        }
        case 'specificity-reports':{
            const combinedBudgetProgArray = [
                ...(budgetProgram !== null ? [budgetProgram] : []),
            ];

            const multiSelectedBudgetPrograms = combinedBudgetProgArray.map(value => ({
                type: 'parameter',
                dataType: 'number',
                value: value.prg
            }));

            const combinedSpecificArray = [
                ...(specific !== null ? [specific] : []),
            ];

            const multiSelectedSpecifics = combinedSpecificArray.map(specific => ({
                type: 'parameter',
                dataType: 'number',
                value: specific.spf
            }));

            if (staffVersion === null || variantUuid === null || multiSelectedSpecifics.isEmpty || multiSelectedBudgetPrograms.isEmpty) {
                return null;
            }

         const item ={
                type: 'boolean-group',
                    dataType: 'boolean',
                operation: 'AND',
                items: [
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'string',
                        value: variantUuid
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'string',
                        key: 'budget-variant-uuid',
                        title: 'Budget Variant UUID'
                    }
                },
                {
                    type: 'two-value-comparison',
                    dataType: 'boolean',
                    first: {
                        type: 'parameter',
                        dataType: 'number',
                        value: staffVersion
                    },
                    operation: 'equals',
                    second: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'version-id',
                        title: 'staffTab version'
                    }
                },
                {
                    type: 'in',
                    dataType: 'boolean',
                    negative: false,
                    target: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'budg-prog-code',
                        title: 'Budget Subprogram Code'
                    },
                    valueVariants: multiSelectedBudgetPrograms
                } as Expression<boolean>,
                {
                    type: 'in',
                    dataType: 'boolean',
                    negative: false,
                    target: {
                        type: 'custom-field',
                        dataType: 'number',
                        key: 'specificity-code',
                        title: 'form code'
                    },
                    valueVariants: multiSelectedSpecifics
                } as Expression<boolean>,
            ]
            } as Expression<boolean>;
            prepareForBackend(item)
            return item;
        }
        case 'form-reports':{
            const combinedArrayForms = [...(form !== null ? [form] : [])];
            const multiSelectedForms = combinedArrayForms.map(from => ({
                type: 'parameter',
                dataType: 'string',
                value: from
            }));

            const combinedBudgetProgArray = [
                ...(budgetProgram !== null ? [budgetProgram] : []),
            ];

            const multiSelectedBudgetPrograms = combinedBudgetProgArray.map(value => ({
                type: 'parameter',
                dataType: 'number',
                value: value.prg
            }));

            if (staffVersion === null || variantUuid === null || multiSelectedForms.isEmpty || multiSelectedBudgetPrograms.isEmpty) {
                return null;
            }

            const item = {
                type: 'boolean-group',
                dataType: 'boolean',
                operation: 'AND',
                items: [
                    {
                        type: 'two-value-comparison',
                        dataType: 'boolean',
                        first: {
                            type: 'parameter',
                            dataType: 'number',
                            value: staffVersion
                        },
                        operation: 'equals',
                        second: {
                            type: 'custom-field',
                            dataType: 'number',
                            key: 'version-id',
                            title: 'staffTab version'
                        }
                    },
                    {
                        type: 'two-value-comparison',
                        dataType: 'boolean',
                        first: {
                            type: 'parameter',
                            dataType: 'string',
                            value: variantUuid
                        },
                        operation: 'equals',
                        second: {
                            type: 'custom-field',
                            dataType: 'string',
                            key: 'budget-variant-uuid',
                            title: 'Budget Variant UUID'
                        }
                    },
                    {
                        type: 'in',
                        dataType: 'boolean',
                        negative: false,
                        target: {
                            type: 'custom-field',
                            dataType: 'number',
                            key: 'budg-prog-code',
                            title: 'Budget Subprogram Code'
                        },
                        valueVariants: multiSelectedBudgetPrograms
                    } as Expression<boolean>,
                    {
                        type: 'in',
                        dataType: 'boolean',
                        negative: false,
                        target: {
                            type: 'custom-field',
                            dataType: 'string',
                            key: 'form-code',
                            title: 'form code'
                        },
                        valueVariants: multiSelectedForms
                    } as Expression<boolean>,
                ]
            } as Expression<boolean>;
            prepareForBackend(item)
            return item;
        }
    }

    return null
}