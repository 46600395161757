<template>
    <div>
        <div class="table-container" style="overflow:auto; height: 60vh !important; background-color: #EEF1F8;">
            <table class="table b-table table-bordered b-table-no-border-collapse sticky-header">
                <thead class="thead-light text-center" style="position: sticky; top: 0px;">
                    <tr>
                        <b-th></b-th>
                        <b-th colspan="2">Заказчик/Поставщик</b-th>
                        <b-th colspan="2">Договоры</b-th>
                        <b-th colspan="5">Проведенные платежи</b-th>
                        <b-th colspan="3">План ГЗ</b-th>
                    </tr>
                    <tr>
                        <th v-for="header of tableFields" :key="header.key">
                            <template v-if="header.key === 'action'">
                                <b-button @click="openAll()">
                                    <i
                                        class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="open"
                                    ></i>
                                    <i
                                        class="icon icon-chevron-circle"
                                        v-if="!open"
                                    ></i>
                                </b-button>
                            </template>
                            <template v-else>
                                {{ header.label }}
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) of data">
                        <template v-if="!item.children">
                            <tr :key="item.number + '_' + index">
                                <template v-if="item.visible">
                                    <td v-for="header of tableFields" :key="header.key" style="padding: .75rem; font-size: .7777777778rem; font-weight: 500; line-height: 1.3; color: #0d1a2e!important; text-transform: none;" :style="item.gu_code ? 'background-color: rgba(176, 224, 255, 0.5) !important;' : item.supplier_bin ? 'background-color: #ecf7ff !important;' : ''">
                                        <template v-if="header.key === 'action' && (item.gu_code || item.supplier_bin)">
                                            <b-button @click="openChilds(item)" v-model="item.open">
                                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="item.open"></i>
                                                <i class="icon icon-chevron-circle" v-if="!item.open"></i>
                                            </b-button>
                                        </template>
                                        <template v-if="header.key === 'name_gu'">
                                            <div class="text-left w-200px">
                                                {{ item.name_gu }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'supplier_nameru'">
                                            <div class="text-left w-200px">
                                                {{ item.supplier_nameru }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'number'">
                                            <div class="text-left w-200px">
                                                <a :href="item.number_url" target="_blank">{{ item.number }}</a>
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'contractsum'">
                                            <div v-if="item.contractsum">
                                                {{ $n(item.contractsum) }}
                                            </div>
                                        </template>
                                        <template v-else-if="(header.key === 'pay_amount') && (item.pay_amount !== 0)">
                                            <div>
                                                {{ $n(item.pay_amount) }}
                                            </div>
                                        </template>
                                        <template v-else-if="(header.key === 'pay_rest') && (item.supplier_bin || item.gu_code)">
                                            <div v-if="item.contractsum">
                                                {{ $n(item.contractsum - item.pay_amount) }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'pay_date'">
                                            <div class="text-left w-100px">
                                                {{ item.pay_date }}
                                            </div>
                                        </template>
                                        <template v-else-if="(header.key === 'plan_amount') && (item.plan_amount && item.plan_amount != 0)">
                                            <div>
                                                {{ $n(item.plan_amount) }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'plan_nameru'">
                                            <div class="text-left w-200px">
                                                <a :href="item.plan_url" target="_blank">{{ item.plan_nameru }}</a>
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'status'">
                                            <div class="text-left w-200px">
                                                {{ item.status }}
                                            </div>
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <template v-else>
                            <tr :key="item.number + '_' + index">
                                <template v-if="item.visible">
                                    <td v-for="header of tableFields.slice(0, 10)" :key="header.key" :rowspan="item.children.length + 1" style="padding: .75rem; font-size: .7777777778rem; font-weight: 500; line-height: 1.3; color: #0d1a2e!important; text-transform: none;" :style="item.gu_code ? 'background-color: rgba(176, 224, 255, 0.5) !important;' : item.supplier_bin ? 'background-color: #ecf7ff !important;' : ''">
                                        <template v-if="header.key === 'action' && (item.gu_code || item.supplier_bin)">
                                            <b-button @click="openChilds(item)" v-model="item.open">
                                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="item.open"></i>
                                                <i class="icon icon-chevron-circle" v-if="!item.open"></i>
                                            </b-button>
                                        </template>
                                        <template v-if="header.key === 'name_gu'">
                                            <div class="text-left w-200px">
                                                {{ item.name_gu }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'supplier_nameru'">
                                            <div class="text-left w-200px">
                                                {{ item.supplier_nameru }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'number'">
                                            <div class="text-left w-200px">
                                                <a :href="item.number_url" target="_blank">{{ item.number }}</a>
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'contractsum'">
                                            <div v-if="item.contractsum">
                                                {{ $n(item.contractsum) }}
                                            </div>
                                        </template>
                                        <template v-else-if="(header.key === 'pay_amount') && (item.pay_amount !== 0)">
                                            <div>
                                                {{ $n(item.pay_amount) }}
                                            </div>
                                        </template>
                                        <template v-else-if="(header.key === 'pay_rest') && (item.supplier_bin || item.gu_code)">
                                            <div v-if="item.contractsum">
                                                {{ $n(item.contractsum - item.pay_amount) }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'pay_date'">
                                            <div class="text-left w-100px">
                                                {{ item.pay_date }}
                                            </div>
                                        </template>
                                    </td>
                                    
                                </template>
                            </tr>
                            <tr v-for="(child, index) in item.children" :key="child.number + '_' + index">
                                <template v-if="item.visible">
                                    <td v-for="header of tableFields.slice(10, 13)" :key="header.key" style="padding: .75rem; font-size: .7777777778rem; font-weight: 500; line-height: 1.3; color: #0d1a2e!important; text-transform: none;" :style="child.gu_code ? 'background-color: rgba(176, 224, 255, 0.5) !important;' : child.supplier_bin ? 'background-color: #ecf7ff !important;' : ''">
                                        <template v-if="(header.key === 'plan_amount') && (child.plan_amount && child.plan_amount != 0)">
                                            <div>
                                                {{ $n(child.plan_amount) }}
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'plan_nameru'">
                                            <div class="text-left w-200px">
                                                <a :href="child.plan_url" target="_blank">{{ child.plan_nameru }}</a>
                                            </div>
                                        </template>
                                        <template v-else-if="header.key === 'status'">
                                            <div class="text-left w-200px">
                                                {{ child.status }}
                                            </div>
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerTable',
    props: {
        data: []
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'name_gu',
                    label: 'Заказчик'
                },
                {
                    key: 'supplier_nameru',
                    label: 'Поставщик'
                },
                {
                    key: 'number',
                    label: '№ договора'
                },
                {
                    key: 'contractsum',
                    label: 'Сумма'
                },
                {
                    key: 'pay_amount',
                    label: 'Оплачено'
                },
                {
                    key: 'pay_rest',
                    label: 'Остаток'
                },
                {
                    key: 'pay_date',
                    label: 'Дата'
                },
                {
                    key: 'purpose',
                    label: 'Назначение'
                },
                {
                    key: 'knp',
                    label: 'КНП'
                },
                {
                    key: 'plan_amount',
                    label: 'Сумма ПП ГЗ'
                },
                {
                    key: 'plan_nameru',
                    label: 'Назначение ПП ГЗ'
                },
                {
                    key: 'status',
                    label: 'Статус закупки'
                }
            ],
            open: false
        }
    },
    methods: {
        openAll() {
            this.open = !this.open;
            for (const r of this.data) {
                if (r.gu_code || r.supplier_bin) {
                    r.open = this.open;
                }
                if (!r.gu_code) {
                    r.visible = this.open;
                }
            }
        },
        openChilds(item) {
            item.open = !item.open;
            if (item.gu_code) {
                if (!item.open) {
                    for (const r of this.data.filter(x => x.parent_gu == item.gu_code)) {
                        r.open = item.open;
                        r.visible = item.open;
                    }   
                } else {
                    for (const r of this.data.filter(x => (x.supplier_bin || x.supplier_bin === null) && (x.parent_gu == item.gu_code))) {
                        r.visible = item.open;
                    }
                }
            }
            if (item.supplier_bin) {
                if (!item.open) {
                    for (const r of this.data.filter(x => x.number !== undefined && (x.parent_supplier == item.supplier_bin) && (item.parent_gu == x.parent_gu))) {
                        r.open = item.open;
                        r.visible = item.open;
                    }
                    for (const r of this.data.filter(x => x.number !== undefined && (x.parent_supplier == item.supplier_bin) && (item.parent_gu == x.parent_gu) && x.children)) {
                        r.open = item.open;
                        r.visible = item.open;
                    }
                } else {
                    for (const r of this.data.filter(x => x.number !== undefined && (x.parent_supplier == item.supplier_bin) && (item.parent_gu == x.parent_gu))) {
                        r.visible = item.open;
                    }
                    for (const r of this.data.filter(x => x.number !== undefined && (x.parent_supplier == item.supplier_bin) && (item.parent_gu == x.parent_gu) && x.children)) {
                        r.visible = item.open;
                    }
                }
            }
        },
    }
}
</script>