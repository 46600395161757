import { cloneDeep } from "lodash";
import { ReportIRBOFilterOptions } from "./options/ReportIRBOFilterOptions";
import { Indicator } from "../../common/types/Indicator";
import { ReportIRBOFilterSettings } from "./settings/ReportIRBOFilterSettings";
import { Template } from "../../common/types/Template";
import { allClassificationTypesOptions, allDataSourcesOptions, allMeasureUnitsOptions, allMutuallyRedeemingOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions";
import { Option } from "../../common/types/Option";
import { MeasureUnit } from "../../common/types/root/fields";


export const reportIRBOIndicators: Indicator[] = [
    { key: 'skor_plan', active: true },
    { key: 'plan_na_period', active: true },
    { key: 'kass', active: true },
    { key: 'unfulfilled', active: true },
    { key: 'percent_to_period', active: true },
    { key: 'deviation_from_annual', active: true },
    { key: 'percent_to_year', active: true },
]


// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportIRBOFilterSettings = {
    // Base:
    reportName: 'IRBO',
    reportType: 'REGULATED',
    classificationType: 'EXPENSES',
    dataSource: 'LOADER',
    periodicity: 'DAY',
    // Custom:
    date: '',
    measureUnit: 'TENGE',
    mutuallyRedeeming: 'WITHOUT',
    decodingActualRegionalbudget: true,
    roundUpTo: null,
    indicators: cloneDeep(reportIRBOIndicators)
}

export const reportIRBOBaseTemplate: Template = {
    id: null,
    name: 'Информация об исполнении расходов бюджета области и областного бюджета',
    description: null,
    category: '50',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

const measureUnitsOptions: Option<MeasureUnit>[] = [
    { value: 'TENGE', label: 'тенге' },
    { value: 'THOUSAND', label: 'тыс. тенге' },
    { value: 'MILLION', label: 'млн. тенге' }
]

export const reportIRBOFilterOptions: ReportIRBOFilterOptions = {
    // Base:
    key: 'IRBO',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    mutuallyRedeeming: cloneDeep(allMutuallyRedeemingOptions),
    measureUnits: cloneDeep(measureUnitsOptions),
    indicators: cloneDeep(reportIRBOIndicators),
}
