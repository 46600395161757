import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/root/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/root/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/root/FilterSettingsForm";
import { report420ExpensesClassificators, report420FilterOptions } from "../report-4-20";
import { Report420TableDataRequest } from "../Report420TableDataRequest";
import { Report420JournalRequest } from "../journal/Report420JournalRequest";
import { Report420FilterSettingsForm } from "../settings-form/Report420FilterSettingsForm";
import { Report420FilterSettings } from "../settings/Report420FilterSettings";
import { Report420Row } from "../other-types";

export class Report420Facade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(report420FilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== '4-20') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== '4-20') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        // 2. Формирование
        const form: Report420FilterSettingsForm = {
            // Base:
            key: '4-20',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            region: regionOption,
            date: new Date(settings.date),
            expenses: cloneDeep(settings.expenses),
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== '4-20') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        
        const strDate = moment(form.date).format('YYYY-MM-DD')

        const settings: Report420FilterSettings = {
            // Base:
            reportName: '4-20',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            region: form.region.value,
            date: strDate,
            expenses: cloneDeep(form.expenses),
            indicators: []
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(report420FilterOptions)

        const emptyForm: Report420FilterSettingsForm = {
            key: '4-20',
            // Base fields
            reportName: options.reportNames.find(it => it.value === '4-20')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'EXPENSES')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            // Custom fields            
            region: null,
            date: null,
            expenses: cloneDeep(report420ExpensesClassificators),
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== '4-20') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        const abps = (form.expenses.find(it => it.key === 'abp')?.selected ?? []).map(it => String(it))
        
        const date = form.date
        if (!date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        
        const regionOption = form.region
        if (!regionOption) {
            throw new Error('Необходимо ввести "Регион"')
        }

        let isSpfIncluded = false
        if (form.expenses.find(it => it.key === 'spf')?.active) {
            isSpfIncluded = true
        }

        const strDate = moment(date).format('YYYY-MM-DD')

        const params: Report420TableDataRequest = {
            admin: abps,
            date: strDate,
            region: regionOption.value,
            spf: isSpfIncluded,
        }

        const queryString = new URLSearchParams(
            ((params as unknown) as Record<string, string>)
        ).toString()

        const response = await fetch(`/api/budget-execution-report-constructor/data-4-20-spf?${queryString}`)
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseData = await response.json()
        const tableData: Report420Row[] = responseData.list

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: Report420JournalRequest = {
            reportName: "4-20", // Приходится задавать для поддержки generics
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            date: strDate,
            spf: isSpfIncluded,
            table_data: tableData
        }

        // Итоговая строка не передается с бэкенда, нужно расчитвать самому
        const totalRow = calculateTotalRow(tableData)
        const tableDataWithTotalRow = [totalRow].concat(cloneDeep(tableData))

        const result: TableDataAndJournalParams = {
            tableData: tableDataWithTotalRow,
            journalParams: journalParams
        }

        return result
    }
}

const calculateTotalRow = (allRows: Report420Row[]): Report420Row => {
    const totalRow: Report420Row = {
        abp: null,
        prg: null,
        ppr: null,
        spf: null,
        name: 'ИТОГО',
        year: 0,
        month: 0,
        iiskSumrg: 0,
        st4: 0,
        st5: 0,
        st6: 0,
        st7: 0,
        obligationYear: 0,
        obligationMonth: 0,
        iiskRegistrob: 0,
        st11: 0
    }
    allRows
        .filter(row => row.abp && !row.prg && !row.ppr && !row.spf)
        .forEach(row => {
            totalRow.year += row.year
            totalRow.month += row.month
            totalRow.iiskSumrg += row.iiskSumrg
            totalRow.st4 += row.st4
            totalRow.st5 += row.st5
            totalRow.st6 += row.st6
            totalRow.st7 += row.st7
            totalRow.obligationYear += row.obligationYear
            totalRow.obligationMonth += row.obligationMonth
            totalRow.iiskRegistrob += row.iiskRegistrob
            totalRow.st11 += row.st11
        })
    return totalRow
}
