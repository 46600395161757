




















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CIpfTableForObligation from '@/modules/budget/execution/form/tabs/components/IpfTableForObligation.vue';
import CIpfTableForPayment from '@/modules/budget/execution/form/tabs/components/IpfTableForPayment.vue';
import CIpfAddItemModal from '@/modules/budget/execution/form/tabs/components/IpfAddItemModal.vue';
import axios from "axios";
import {makeToast} from "@/modules/budget/bip/bip-types";
import {Ax} from "@/utils";
import SpfImportFromFileModal from '@/modules/budget/alteration/form/tabs/components/CreateForm/SpfImportFromFileModal.vue';
import VueElementLoading from "vue-element-loading";

@Component({
    components: {
        'c-ipf-table-for-obligation': CIpfTableForObligation,
        'c-ipf-table-for-payment': CIpfTableForPayment,
        'c-ipf-add-item-modal': CIpfAddItemModal,
        'loading': VueElementLoading,
        SpfImportFromFileModal
    }
})
export default class CInsideWindow extends Vue {
    private addItemToogler(){
        this.showAddItemModal = !this.showAddItemModal
    }
    @Prop({
        required: true,
        default: false
    })
    private import_acces!: any;

    @Prop({
        required: true,
        default: null
    })
    private filter!: any;

    @Prop({
        required: true,
        default: false
    })
    private edit_access!: boolean;
    @Prop({
        required: true,
        default: false
    })
    private delete_access!: boolean;

    @Prop({
        required: false,
        default: null
    })
    private currentForm!: any;

    @Prop({
        required: true,
        default: null
    })
    private abp!: any;

    @Prop({
        required: true,
        default: null
    })
    private region!: any;
    private progress: number | null = null;

    private filterPrg: any = null;
    private filterPpr: any = null;
    private filterSpf: any = null;

    private showObligation = true;
    private showPayment = true;
    private showAddItemModal = false
    private showImportFileModal:boolean = false;
    private importTypeTable:number = 1;
    private loading = false;

    private isLoad = false;
    private isSuccess = false;
    private isEmptyTotal = false;
    private checkMonth = [
        {
            key: 'january',
            check: 'january'
        },
        {
            key: 'february',
            check: 'checkFeb'
        },
        {
            key: 'march',
            check: 'checkMar'
        },
        {
            key: 'april',
            check: 'checkApr'
        },
        {
            key: 'may',
            check: 'checkMay'
        },
        {
            key: 'june',
            check: 'checkJun'
        },
        {
            key: 'july',
            check: 'checkJul'
        },
        {
            key: 'august',
            check: 'checkAug'
        },
        {
            key: 'september',
            check: 'checkSep'
        },
        {
            key: 'october',
            check: 'checkOct'
        },
        {
            key: 'november',
            check: 'checkNov'
        },
        {
            key: 'december',
            check: 'checkDec'
        }
    ]

    private beforeUpdate() {
        this.budgetFormObligation.forEach((row: any) => {
            row._rowVariant = 'info';
        });
        this.budgetFormPayment.forEach((row: any) => {
            row._rowVariant = 'info';
        });
    }

    private async chgFilter() {
        this.filterPrg = this.filter.prg
        this.filterPpr = this.filter.ppr
        this.filterSpf = this.filter.spf
        this.showAddItemModal = false
        this.expiredSpfList = []
        await this.loadSpfList()
        this.progress = 10;
        this.$emit('chgLoad', true);
        this.budgetFormObligation.splice(0);
        this.budgetFormPayment.splice(0);
        this.budgetFormObligationTotal = 0;
        this.budgetFormPaymentTotal = 0;
        await this.prepareFormObligation();
        this.progress = 50;
        await this.prepareFormPayment();
        this.progress = 100;
        if (this.expiredSpfList.length) {
            const m = "Внимание, присутствуют строки с неактуальными спецификами. Пожалуйста, удалите специфику(и) " + this.expiredSpfList.sort((a, b) => a - b).join(', ');
            this.makeToast('warning', 'Ошибка', m)
        }
        this.isEmptyTotal = (this.budgetFormPayment.length === 0 && this.budgetFormObligation.length === 0) || (this.budgetFormObligationTotal === 0 && this.budgetFormPaymentTotal === 0)
        this.$emit('chgLoad', false);
    }



    private budgetFormObligation: any = [];
    private budgetFormObligationTotal: any = 0;
    private budgetFormPayment: any = [];
    private budgetFormPaymentTotal: any = 0;

    private calcFlds =  [
        'plan',
        'deviation',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];

    private uniqueId: number = 0;
    private isInsideSpf: boolean = false;

    private async prepareFormObligation() {
        this.budgetFormObligation.splice(0);
        const dataOblig = await this.getData('obligation');
        if (dataOblig && dataOblig.length > 0) {
            dataOblig.sort((a: any, b: any) => a.prg - b.prg)
            for (const item of dataOblig) {
                this.isInsideSpf = false;
                this.createTable(item, this.uniqueId, 'obligation', true);
                this.uniqueId++;
            }
        }
        this.budgetFormObligation.forEach((el: any, ind: number) => {
            this.templateDataForObligation(el);
            this.budgetFormObligationTotal += el.plan;
        });
    } // подготовка отображения данных обязательств

    private async prepareFormPayment() {
        this.budgetFormPayment.splice(0);
        const dataPayment = await this.getData('payment');
        if (dataPayment && dataPayment.length > 0) {
            dataPayment.sort((a: any, b: any) => a.prg - b.prg)
            for (const item of dataPayment) {
                this.isInsideSpf = false;
                this.createTable(item, this.uniqueId, 'payment', true);
                this.uniqueId++;
            }
        }
        this.budgetFormPayment.forEach((el: any, ind: number) => {
            this.templateData(el);
            this.budgetFormPaymentTotal += el.plan;
        });
    } // подготовка отображения данных платежей

    private createTable(elem: any, parent_id: any, typeTable: string, isMain: boolean = false) {
        const that = this;
        // создание таблицы на основе дерева
        const item = Object.assign({}, elem);

        this.$set(item, 'parent_id', parent_id);
        this.$set(item, 'visible', isMain);
        this.uniqueId++;
        this.$set(item, 'id', this.uniqueId);
        Object.defineProperty(item, 'parent', {
            get: function () {
                if (typeTable === 'obligation') {
                    for (const row of that.budgetFormObligation) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                } else if (typeTable === 'payment') {
                    for (const row of that.budgetFormPayment) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                }
            }
        });
        Object.defineProperty(item, 'rowFlk1', {
            get: function () {
                return (parseFloat(item.plan) !== parseFloat(item.deviation) && !this.isEmptyTotal && !item.newSpec && !item.newPrg && !item.newPpr)
            }
        })
        // debugger
        this.$set(item, 'open', false);
        if (item.hasOwnProperty('type') && item.type < 6) {
            this.$set(item, 'hasChild', true);
        } else if (item.hasOwnProperty('child_list') && item.child_list) {
            this.$set(item, 'hasChild', true);
        }
        this.calcFields(item, typeTable);

        if (item) {
            if (typeTable === 'obligation') {
                this.$set(item, 'index', this.budgetFormObligation.length);
                this.$set(this.budgetFormObligation, this.budgetFormObligation.length, item);
            } else if (typeTable === 'payment') {
                this.$set(item, 'index', this.budgetFormPayment.length);
                this.$set(this.budgetFormPayment, this.budgetFormPayment.length, item);
            }
        }
        if (item.type === 4 && (item.hasOwnProperty('spf_list') && item.spf_list && item.spf_list.length > 0)) {
            const newPpr = {
                ...item
            }
            this.uniqueId++;
            newPpr.id = this.uniqueId
            this.uniqueId++;
            newPpr.ppr = null
            newPpr.name_ru = null
            newPpr.type = 5
            newPpr.spf_list = Object.assign([], item.spf_list)
            newPpr.child_list = null
            if (item.hasOwnProperty('child_list') && item.child_list && item.child_list.length) {
                item.child_list.splice(0, 0, newPpr)
            } else {
                item.child_list = [newPpr]
            }
            item.spf_list = null
        }

        if (item.hasOwnProperty('hasChild')) {
            if (item.hasOwnProperty('form_child_list') && item.form_child_list && item.form_child_list.length > 0) {
                // if (this.isInsideSpf) {
                //     for (const ch of item.form_child_list) {
                //         this.createTable(ch, parent_id, typeTable);
                //     }
                // } else {
                for (const ch of item.form_child_list) {
                    this.createTable(ch, item.id, typeTable);
                }
                // }
                item.form_child_list = [];
            } else if (item.hasOwnProperty('child_list') && item.child_list && item.child_list.length > 0) {
                if (this.isInsideSpf) {
                    let sum = 0;
                    item.child_list.sort((a:any, b:any) => a.bip_code.localeCompare(b.bip_code))
                    for (const [ind, ch] of item.child_list.entries()) {
                        for (const mon of this.calcFlds.slice(2)) {
                            sum += ch[mon]
                        }
                        if (ch.spf === item.spf && ch.hasOwnProperty('bip_code') && ch.bip_code) {
                            item['notEdit'] = true;
                            ch['type'] = 7
                            this.createTable(ch, item.id, typeTable);
                        } else {
                            ch['notEdit'] = false;
                            ch['type'] = 7
                            this.createTable(ch, parent_id, typeTable);
                        }
                    }
                    item.zeroBip = sum === 0
                    item.typeTable = typeTable
                    this.calcFields(item, typeTable, true, sum === 0);
                } else {
                    item.child_list.sort((a: any, b: any) => a.ppr - b.ppr)
                    for (const ch of item.child_list) {
                        this.createTable(ch, item.id, typeTable);
                    }
                }
                delete item.child_list;
            } else if (item.hasOwnProperty('spf_list') && item.spf_list && item.spf_list.length > 0) {
                this.isInsideSpf = true;
                item.spf_list.sort((a: any, b: any) => a.spf - b.spf)
                for (const ch of item.spf_list.sort((a: any, b: any) => { return (a.spf - b.spf); })) {
                    ch['type'] = 6
                    if (!this.spfMap.includes(ch.spf)) {
                        if (!this.expiredSpfList.includes(ch.spf)) {
                            this.expiredSpfList.push(ch.spf)
                        }
                        ch.expired = true
                    }
                    if (this.filter.spf) {
                        if (ch.spf === this.filter.spf) {
                            this.createTable(ch, item.id, typeTable);
                        }
                    } else {
                        this.createTable(ch, item.id, typeTable);
                    }
                }
                delete item.spf_list;
            }
        }
    }// древовидную выборку преобразовывает в таблицу (для отображения)

    private reSum(parent: any, field: string, typeTable: string) {
        let sum = 0;
        if (field[0] === '_') {
            field = field.replace('_', '');
        }
        if (typeTable === 'obligation') {
            if (this.budgetFormObligation && this.budgetFormObligation.length) {
                this.budgetFormObligation.forEach((row: any) => {
                    if (row.hasOwnProperty('parent_id') && row.parent_id && parent.hasOwnProperty('id') && parent.id) {
                        if ((row.parent_id === parent.id)) {
                            if (!row.hasOwnProperty(field)) {
                                sum += 0;
                            } else {
                                if (row.hasOwnProperty(field) && row[field]) {
                                    sum += parseFloat(row[field]);
                                }
                            }
                        }
                    }
                });
            }
        } else if (typeTable === 'payment') {
            if (this.budgetFormPayment && this.budgetFormPayment.length) {
                this.budgetFormPayment.forEach((row: any) => {
                    if (row.hasOwnProperty('parent_id') && row.parent_id && parent.hasOwnProperty('id') && parent.id) {
                        if ((row.parent_id === parent.id)) {
                            if (!row.hasOwnProperty(field)) {
                                sum += 0;
                            } else {
                                if (row.hasOwnProperty(field) && row[field]) {
                                    sum += parseFloat(row[field]);
                                }
                            }
                        }
                    }
                });
            }
        }

        return parseFloat(sum.toFixed(1));
    } // пересчет суммы

    private calcFields(item: any, typeTable: string, isHaveBip: boolean = false, zeroBip:any = false) {
        const that = this;
        this.calcFlds.forEach(field => {
            if (!item.hasOwnProperty(field) || isHaveBip) {
                if (isHaveBip && zeroBip) return;
                if (field !== 'deviation') {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, `_${field}`, typeTable);
                        },
                        set: function (value) {
                            item[`_${field}`] = value;
                        }
                    });
                }
            }
        });
    }


    private templateData(item: any) {
        const dataItem: any = item;
        Object.defineProperty(dataItem, 'checkFeb', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkMar', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkApr', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkMay', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkJun', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkJul', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkAug', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkSep', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkOct', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september) + parseFloat(dataItem.october);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkNov', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september) + parseFloat(dataItem.october)
                    + parseFloat(dataItem.november);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkDec', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september) + parseFloat(dataItem.october)
                    + parseFloat(dataItem.november) + parseFloat(dataItem.december);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'deviation', {
            get: function () {
                const sum = (parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                        + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june)
                        + parseFloat(dataItem.july) + parseFloat(dataItem.august) + parseFloat(dataItem.september)
                        + parseFloat(dataItem.october) + parseFloat(dataItem.november) + parseFloat(dataItem.december));
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
    }


    private templateDataForObligation(item: any) {
        const dataItem: any = item;
        Object.defineProperty(dataItem, 'checkFeb', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkMar', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkApr', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkMay', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkJun', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkJul', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkAug', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkSep', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkOct', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september) + parseFloat(dataItem.october);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkNov', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september) + parseFloat(dataItem.october)
                    + parseFloat(dataItem.november);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'checkDec', {
            get: function () {
                const sum = parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june) + parseFloat(dataItem.july)
                    + parseFloat(dataItem.august) + parseFloat(dataItem.september) + parseFloat(dataItem.october)
                    + parseFloat(dataItem.november) + parseFloat(dataItem.december);
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
        Object.defineProperty(dataItem, 'deviation', {
            get: function () {
                const sum = (parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                        + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june)
                        + parseFloat(dataItem.july) + parseFloat(dataItem.august) + parseFloat(dataItem.september)
                        + parseFloat(dataItem.october) + parseFloat(dataItem.november) + parseFloat(dataItem.december));
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
    }

    private async getData(type: string) {
        let values = [];
        try {
            const queryString = {...this.filter, type: type};
            queryString.level = 'prg'
            const response = await axios.get('/api/budget-execution-pf-ipf/tree', {params: queryString});
            values = response.data;

            values.sort((a: any, b: any) => { return (a.prg - b.prg); });

            if (values.length === 0) {
                this.$emit('chgLoad', false);
                return [];
            }
            // values.sort((a: any, b: any) => (a.spf - b.spf > 0) ? 1 : -1);
            // eslint-disable-next-line consistent-return
            return values;
        } catch (error) {
            this.$emit('chgLoad', false);
            this.makeToast('danger', "Ошибка запроса", "Нет индивидуального плана финансирования за заданный период");
            return []
        }
    }

    private toggleShowObligation() {
        this.showObligation = !this.showObligation;
    }

    private toggleShowPayment() {
        this.showPayment = !this.showPayment;
    }

    private async deleteSpfRemote(row: any) {
        let deleted = false
        const path = '/api/budget-execution-pf-ipf'
        const item = Object.assign({}, this.filter);
        this.$set(item, 'id', row.id);
        this.$set(item, 'spf', row.spf);
        this.$set(item, 'gr', row.gr);
        this.$set(item, 'prg', row.prg);
        this.$set(item, 'ppr', row.ppr);
        this.$set(item, 'bip_code', row.bip_code);
        this.$set(item, 'form', row.form);
        this.$set(item, 'user_id', this.$store.state.user.sub);

        this.$set(item, 'january', row.january);
        this.$set(item, 'february', row.february);
        this.$set(item, 'march', row.march);
        this.$set(item, 'april', row.april);
        this.$set(item, 'may', row.may);
        this.$set(item, 'june', row.june);
        this.$set(item, 'july', row.july);
        this.$set(item, 'august', row.august);
        this.$set(item, 'september', row.september);
        this.$set(item, 'october', row.october);
        this.$set(item, 'november', row.november);
        this.$set(item, 'december', row.december);
        await axios.delete(path, {data: [item]})
            .then(response => {
                if (response.status === 200) deleted = true
            })
            .catch(error => {
                console.log('Error making delete request:', error.message);
            });
        return deleted
    }

    private async deleteSpec(body: any) {
        let deleted = false;
        const data = body['type'] === 'obligation'? body['data'] : this.budgetFormObligation.find((el: any) => (el.type === body['data'].type && el.prg === body['data'].prg && el.ppr === body['data'].ppr && el.spf === body['data'].spf && el.bip_code === body['data'].bip_code));
        const dataPay = body['type'] === 'obligation'? this.budgetFormPayment.find((el: any) => (el.type === data.type && el.prg === data.prg && el.ppr === data.ppr && el.spf === data.spf && el.bip_code === data.bip_code)): body['data']
        const el: any = Object.assign({}, data)
        const el1: any = Object.assign({}, dataPay)
        // delete from Obligation
        for (let i = 0; i < this.budgetFormObligation.length; i++) {
            if (this.budgetFormObligation[i].id === data.id) {
                if ((this.budgetFormObligation[i].type === 6 && !this.budgetFormObligation[i].newSpec) || this.budgetFormObligation[i].type === 7) {
                    this.budgetFormObligation.splice(i, 1);
                    i--;
                    deleted = await this.deleteSpfRemote(el)
                } else {
                    this.budgetFormObligation.splice(i, 1);
                    i--;
                }
            } else if (this.budgetFormObligation[i].parent_id === data.id) {
                for (let j = 0; j < this.budgetFormObligation.length; j++) {
                    if (this.budgetFormObligation[j].parent_id === this.budgetFormObligation[i].id) {
                        this.budgetFormObligation.splice(j, 1);
                        j--;
                    }
                }
                this.budgetFormObligation.splice(i, 1);
                i--;
            }
        }
        // delete from Payment\
        for (let i = 0; i < this.budgetFormPayment.length; i++) {
            if (this.budgetFormPayment[i].id === dataPay.id) {
                if ((this.budgetFormPayment[i].type === 6 && !this.budgetFormPayment[i].newSpec) || this.budgetFormPayment[i].type === 7) {
                    this.budgetFormPayment.splice(i, 1);
                    i--;
                    if (!deleted) await this.deleteSpfRemote(el1)
                } else {
                    this.budgetFormPayment.splice(i, 1);
                    i--;
                }
            } else if (this.budgetFormPayment[i].parent_id === dataPay.id) {
                for (let j = 0; j < this.budgetFormPayment.length; j++) {
                    if (this.budgetFormPayment[j].parent_id === this.budgetFormPayment[i].id) {
                        this.budgetFormPayment.splice(j, 1);
                        j--;
                    }
                }
                this.budgetFormPayment.splice(i, 1);
                i--;
            }
        }
    }

    private spfList: any = [];
    private spfMap:string[] = []
    private expiredSpfList:any[] = []
    private async loadSpfList() {
        try {
            this.spfList = [];
            this.spfMap = [];
            const response = await fetch('/api-py/get-spf-list/' + this.filter.year)
                .then(response => response.json());
            this.spfList = response.list;
            this.spfMap = this.spfList.map((v:any) => v.spf)
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadSpfList', error.toString());
        }
    }

    private addNewPrg(item: any, table: any, type: any = 'payment') {
        let prgExist = false;
        let prgIndex = 0
        for (let i = 0; i < table.length; i++) {
            const el = table[i]
            if (el.type === 4) {
                if (el.prg === item.prg) {
                    prgExist = true
                    prgIndex = i
                    return prgIndex
                }
            }
        }
        if (!prgExist) {
            this.uniqueId++;
            const dataItem: any = {
                id: null,
                type: 4,
                selectPrg: item.prg,
                newPrg: true,
                name_ru: item.prg_name,
                parent_id: this.uniqueId,
                visible: true,
                open: false,
                gr: item.gr,
                prg: item.prg,
                ppr: null,
                form: null,
                bip_code: null,
                plan: 0,
                deviation: 0
            }
            this.uniqueId++;
            dataItem.id = this.uniqueId;
            this.uniqueId++;
            if (type === 'obligation') {
                this.calcFields(dataItem, type);
                this.templateDataForObligation(dataItem);
            } else if (type === 'payment') {
                this.calcFields(dataItem, type);
                this.templateData(dataItem);
            }
            let lowIndex = table.length
            for (let i = 0; i < table.length; i++) {
                const el = table[i]
                if (el.type === 4) {
                    if (item.prg < el.prg) {
                        lowIndex = i;
                        break;
                    }
                }
            }
            table.splice(lowIndex, 0, dataItem)
            prgIndex = lowIndex
        }

        return prgIndex
    }
    private addNewPpr(item: any, prgIndex: number = 0, table: any, type: any = 'payment')  {
        let pprExist = false;
        let pprIndex = 0
        const parent = table[prgIndex];
        let tableref: any = null
        if (type === 'payment') tableref = this.$refs.tablePaymentRef;
        else if (type === 'obligation') tableref = this.$refs.tableObligationRef;
        for (let i = prgIndex + 1; i < table.length && table[i].type !== 4; i++) {
            const el = table[i]
            if (el.type === 5 &&  el.parent_id === parent.id) {
                if (el.ppr === item.ppr) {
                    pprExist = true
                    pprIndex = i
                    if (!parent.open) if (!parent.open) tableref.openChilds(parent);
                    return pprIndex
                }
            }
        }
        if (!pprExist) {
            this.uniqueId++;
            const dataItem: any = {
                id: null,
                type: 5,
                selectPpr: item.prg,
                newPpr: true,
                name_ru: item.ppr_name,
                parent_id: parent.id,
                visible: true,
                open: false,
                gr: item.gr,
                prg: item.prg,
                ppr: item.ppr,
                form: null,
                bip_code: null,
                plan: 0,
                deviation: 0
            }
            this.uniqueId++;
            dataItem.id = this.uniqueId;
            this.uniqueId++;
            if (type === 'obligation') {
                this.calcFields(dataItem, type);
                this.templateDataForObligation(dataItem);
            } else if (type === 'payment') {
                this.calcFields(dataItem, type);
                this.templateData(dataItem);
            }
            let lowIndex = table.length
            for (let i = prgIndex + 1; i < table.length; i++) {
                const el = table[i]
                if (el.type === 4) {
                    lowIndex = i
                    break
                }
                if (el.type === 5 && el.parent_id === parent.id) {
                    if (item.ppr < el.ppr) {
                        lowIndex = i;
                        break;
                    }
                }
            }
            table.splice(lowIndex, 0, dataItem);
            if (!parent.hasChild) parent.hasChild = true;
            pprIndex = lowIndex;
        }
        if (!parent.open) tableref.openChilds(parent);
        return pprIndex;
    }
    private addNewSpf(item: any, pprIndex: number = 0, table: any, type: any = 'payment') {
        let spfExist = false;
        let spfIndex = 0
        const parent = table[pprIndex];
        let tableref: any = null
        if (type === 'payment') tableref = this.$refs.tablePaymentRef;
        else if (type === 'obligation') tableref = this.$refs.tableObligationRef;
        for (let i = pprIndex + 1; i < table.length && table[i].type !== 4 && table[i].type !== 5; i++) {
            const el = table[i]
            if (el.type !== 5 && el.type !== 4 && el.parent_id === parent.id) {
                if (el.spf === item.spf) {
                    spfExist = true
                    spfIndex = i
                    if (!parent.open) tableref.openChilds(parent);
                    return spfIndex
                }
            }
        }
        if (!spfExist) {
            this.uniqueId++;
            const dataItem = {
                id: this.uniqueId,
                newSpec: true,
                selectSpf: null,
                parent_id: parent.id,
                spf: item.spf,
                type: 6,
                name_ru: item.name_ru,
                visible: true,
                open: false,
                gr: item.gr,
                prg: parent.prg,
                ppr: parent.ppr,
                form: null,
                bip_code: null,

                plan: 0,
                deviation: item.deviation? item.deviation: 0,
                january: item.january? item.january: 0,
                february: item.february? item.february: 0,
                march: item.march? item.march: 0,
                april: item.april? item.april: 0,
                may: item.may? item.may: 0,
                june: item.june? item.june: 0,
                july: item.july? item.july: 0,
                august: item.august? item.august: 0,
                september: item.september? item.september: 0,
                october: item.october? item.october: 0,
                november: item.november? item.november: 0,
                december: item.december? item.december: 0
            }
            this.uniqueId++;
            dataItem.id = this.uniqueId;
            this.uniqueId++;
            if (type === 'obligation') {
                this.calcFields(dataItem, type);
                this.templateDataForObligation(dataItem);
            } else if (type === 'payment') {
                this.calcFields(dataItem, type);
                this.templateData(dataItem);
            }
            let lowIndex = table.length
            for (let i = pprIndex + 1; i < table.length; i++) {
                const el = table[i]
                if (el.type === 4 || el.type === 5) {
                    lowIndex = i
                    break
                }
                if (el.type !== 4 && el.type !== 5 && el.parent_id === parent.id) {
                    if (item.spf < el.spf) {
                        lowIndex = i;
                        break;
                    }
                }
            }
            table.splice(lowIndex, 0, dataItem);
            if (!parent.hasChild) parent.hasChild = true
            spfIndex = lowIndex;
        }
        if (!parent.open) tableref.openChilds(parent);
        return spfIndex;
    }
    private async loadSpfDict(spfs: any[]) {
        const req = {
            abp: this.filter.abp,
            spf_list: spfs
        }
        let dict:any = {}
        await axios.post('/api-py/dict-ebk-spf-map', req).then(response => {
            dict = response.data
        })
        return dict
    }
    private addNewItems(items: any[]) {
        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            //oblig
            const obligprogIndex = this.addNewPrg(item, this.budgetFormObligation, 'obligation')
            const obligsubProgIndex = this.addNewPpr(item, obligprogIndex, this.budgetFormObligation, 'obligation')
            const obligspfIndex = this.addNewSpf(item, obligsubProgIndex, this.budgetFormObligation, 'obligation')
            //payment
            const progIndex = this.addNewPrg(item, this.budgetFormPayment)
            const subProgIndex = this.addNewPpr(item, progIndex, this.budgetFormPayment)
            const spfIndex = this.addNewSpf(item, subProgIndex, this.budgetFormPayment)
        }

    }

    private openModal() {
        this.$bvModal.msgBoxConfirm(
            'Вы хотите скопировать данные у обязательства на платежи?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    if (!this.budgetFormPayment.length) {
                        let curentPrg:any = null
                        let curentPpr:any = null
                        const locale_i18 = 'name_' + this.$i18n.locale
                        this.budgetFormObligation.forEach((el: any) => {
                            if (el.type === 4) {
                                curentPrg = el
                            }
                            if (el.type === 5) {
                                curentPpr = el
                            }
                            if (el.hasOwnProperty('spf') && el.spf) {
                                const newPayment = Object.assign({}, el)
                                newPayment.prg_name = curentPrg[locale_i18]
                                newPayment.ppr_name = curentPpr[locale_i18]
                                this.addNewItems([newPayment])
                            }
                        })
                    } else {
                        let ind = 0;
                        this.budgetFormObligation.forEach((el: any) => {
                            if (!el.hasOwnProperty('newSpec') && el.hasOwnProperty('spf') && el.spf && this.budgetFormPayment[ind] &&
                                this.budgetFormPayment[ind].prg === el.prg && this.budgetFormPayment[ind].ppr === el.ppr && this.budgetFormPayment[ind].spf === el.spf) {
                                this.budgetFormPayment[ind].january = el.january;
                                this.budgetFormPayment[ind].february = el.february;
                                this.budgetFormPayment[ind].march = el.march;
                                this.budgetFormPayment[ind].april = el.april;
                                this.budgetFormPayment[ind].may = el.may;
                                this.budgetFormPayment[ind].june = el.june;
                                this.budgetFormPayment[ind].july = el.july;
                                this.budgetFormPayment[ind].august = el.august;
                                this.budgetFormPayment[ind].september = el.september;
                                this.budgetFormPayment[ind].october = el.october;
                                this.budgetFormPayment[ind].november = el.november;
                                this.budgetFormPayment[ind].december = el.december;
                                this.budgetFormPayment[ind].hasChanges = true
                            } else if (el.hasOwnProperty('newSpec') && el.hasOwnProperty('spf') && el.spf && this.budgetFormPayment[ind] &&
                                this.budgetFormPayment[ind].prg === el.prg && this.budgetFormPayment[ind].ppr === el.ppr && this.budgetFormPayment[ind].spf === el.spf) {
                                this.budgetFormPayment[ind].spf = el.spf;
                                this.budgetFormPayment[ind].name_ru = el.name_ru;
                                this.budgetFormPayment[ind].selectSpf = el.selectSpf;
                                this.budgetFormPayment[ind].january = el.january;
                                this.budgetFormPayment[ind].february = el.february;
                                this.budgetFormPayment[ind].march = el.march;
                                this.budgetFormPayment[ind].april = el.april;
                                this.budgetFormPayment[ind].may = el.may;
                                this.budgetFormPayment[ind].june = el.june;
                                this.budgetFormPayment[ind].july = el.july;
                                this.budgetFormPayment[ind].august = el.august;
                                this.budgetFormPayment[ind].september = el.september;
                                this.budgetFormPayment[ind].october = el.october;
                                this.budgetFormPayment[ind].november = el.november;
                                this.budgetFormPayment[ind].december = el.december;
                                this.budgetFormPayment[ind].hasChanges = true
                            } else if (el.hasOwnProperty('newSpec')) {
                                ind--;
                            }
                            ind++;
                        });
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    private getItemToSave(row: any, errorObligation: any) {
        const item = Object.assign({}, this.filter);
        this.$set(item, 'id', row.id);
        this.$set(item, 'spf', row.spf);
        this.$set(item, 'gr', row.gr);
        this.$set(item, 'prg', row.prg);
        this.$set(item, 'ppr', row.ppr);
        this.$set(item, 'bip_code', row.bip_code);
        this.$set(item, 'form', row.form);
        this.$set(item, 'user_id', this.$store.state.user.sub);
        // if (parseFloat(row.deviation) === 0) {
            this.$set(item, 'january', row.january);
            this.$set(item, 'february', row.february);
            this.$set(item, 'march', row.march);
            this.$set(item, 'april', row.april);
            this.$set(item, 'may', row.may);
            this.$set(item, 'june', row.june);
            this.$set(item, 'july', row.july);
            this.$set(item, 'august', row.august);
            this.$set(item, 'september', row.september);
            this.$set(item, 'october', row.october);
            this.$set(item, 'november', row.november);
            this.$set(item, 'december', row.december);
        // } else {
        //     errorObligation.error = true;
        // }
        return item;
    }
    private beforeExit() {
        let hasChanges = false
        for (const el of this.budgetFormObligation) {
            if (el.hasChanges || el.newSpec) {
                hasChanges = true
            }
        }
        for (const el of this.budgetFormPayment) {
            if (el.hasChanges || el.newSpec) {
                hasChanges = true
            }
        }
        return hasChanges
    }
    private flk1Agreement() {
        const errorObligation = {
            error: false
        };
        const errorPayment = {
            error: false
        };
        let prgParent = this.budgetFormPayment[0]
        for (const [ind, row] of this.budgetFormPayment.entries()) {
            if (row.type === 4) prgParent = row
            // флк по сохранению на уровне родителя
            if (prgParent.plan !== prgParent.deviation && prgParent.plan) {
                errorPayment.error = true;
            }
        }
        for (const row of this.budgetFormObligation) {
            // флк по сохранению на уровне родителя
            if (row.type === 4) prgParent = row;
            if (prgParent.plan !== prgParent.deviation && prgParent.plan) {
                errorObligation.error = true;
            }
        }
        return {payment: errorPayment.error, obligation: errorObligation.error}
    }
    private flk2save() { // false if error// true if not
        if (this.budgetFormPayment.length !== this.budgetFormObligation.length) return  false
        for (const [ind, row] of this.budgetFormPayment.entries()) {
            const elO:any = this.budgetFormObligation[ind]
            if ((row.type === 4 && elO.prg !== row.prg) || (row.type === 5 && elO.ppr !== row.ppr) || (row.type === 6 && elO.spf !== row.spf) || (row.type === 7 && elO.bip_code !== row.bip_code)) {
                return false
            }
        }
        return true
    }
    private async prepareForSave() {
        const isNegative = (val: any) => {
            for (const mon of this.checkMonth) {
                if (val[mon.key] < 0) {
                    this.$set(val, `${mon.check}Error`, true)
                    return true;
                } else {
                    this.$set(val, `${mon.check}Error`, false)
                }
            }
            return false
        }
        const obligationValues = [];
        const paymentValues = [];
        const errorObligation = {
            error: false
        };
        const errorPayment = {
            error: false
        };
        let negativeValueError = false;

        let prgParent = this.budgetFormObligation[0]
        for (const row of this.budgetFormObligation) {
            // флк по сохранению на уровне родителя
            if (row.type === 4) prgParent = row;
            if (prgParent.plan !== prgParent.deviation && prgParent.plan) {
                errorObligation.error = true;
                // break;
            }
            if ((row.type === 6 && !row.hasChild) || row.type === 7) {
                negativeValueError = isNegative(row)
                if (negativeValueError) {
                    this.makeToast('warning', 'Сообщение', 'Сохранение не возможно так как присутствуют отрицательные значения');
                    return this.isSuccess;
                }
                obligationValues.push(this.getItemToSave(row, errorObligation));
            }
        }

        let checkPayment = false;
        if (!this.flk2save()) {
            this.makeToast('warning', 'Сообщение', 'Данные не сохранены. Имеются расхождения по данным обязательств и платежей!');
            return;
        }
        let finPlanError = false;
        prgParent = this.budgetFormPayment[0]
        for (const [ind, row] of this.budgetFormPayment.entries()) {

            if (row.type === 4) prgParent = row
            // флк по сохранению на уровне родителя
            if (prgParent.plan < prgParent.deviation && prgParent.plan) {
                errorPayment.error = true;
                // checkPayment = true;
                // break;
            }
            if ((row.type === 6 && !row.hasChild) || row.type === 7) {
                negativeValueError = isNegative(row)
                if (negativeValueError) {
                    this.makeToast('warning', 'Сообщение', 'Сохранение не возможно так как присутствуют отрицательные значения');
                    return this.isSuccess;
                }
                if (parseFloat(row.january) > this.budgetFormObligation[ind].january ||
                    parseFloat(row.checkFeb) > this.budgetFormObligation[ind].checkFeb ||
                    parseFloat(row.checkMar) > this.budgetFormObligation[ind].checkMar ||
                    parseFloat(row.checkApr) > this.budgetFormObligation[ind].checkApr ||
                    parseFloat(row.checkMay) > this.budgetFormObligation[ind].checkMay ||
                    parseFloat(row.checkJun) > this.budgetFormObligation[ind].checkJun ||
                    parseFloat(row.checkJul) > this.budgetFormObligation[ind].checkJul ||
                    parseFloat(row.checkAug) > this.budgetFormObligation[ind].checkAug ||
                    parseFloat(row.checkSep) > this.budgetFormObligation[ind].checkSep ||
                    parseFloat(row.checkOct) > this.budgetFormObligation[ind].checkOct ||
                    parseFloat(row.checkNov) > this.budgetFormObligation[ind].checkNov ||
                    parseFloat(row.checkDec) > this.budgetFormObligation[ind].checkDec) {
                    checkPayment = true;
                    for (const mon of this.checkMonth) {
                        if ((parseFloat(row[mon.check]) > this.budgetFormObligation[ind][mon.check])) {
                            this.$set(row, `${mon.check}Error`, true)
                            this.$set(this.budgetFormObligation[ind], `${mon.check}Error`, true)
                        } else {
                            this.$set(row, `${mon.check}Error`, false)
                            this.$set(this.budgetFormObligation[ind], `${mon.check}Error`, false)
                        }
                    }

                }
                if (parseFloat(row.deviation) != this.budgetFormObligation[ind].deviation) {
                    this.$set(row, 'deviationError', true)
                    this.$set(this.budgetFormObligation[ind], 'deviationError', true)
                    finPlanError = true;
                } else {
                    this.$set(row, 'deviationError', false)
                    this.$set(this.budgetFormObligation[ind], 'deviationError', false)
                }
                paymentValues.push(this.getItemToSave(row, errorPayment));
            }
        }

        if (checkPayment || finPlanError) {
            if (checkPayment) this.makeToast('danger', 'Ошибка', 'Данные не сохранены. Сумма планов по платежам за период с начала года превышает сумму планов по обязательствам.');
            if (finPlanError) this.makeToast('danger', 'Ошибка', 'Данные не сохранены. Годовая сумма по обязательствам не равна годовой сумме по платежам.');
            return this.isSuccess;
        }
        if (obligationValues.length > 0) {
            if (errorObligation.error) {
                this.makeToast('warning', 'Сообщение', 'Имеются расхождения сумм по обязательствам и бюджетным заявкам');
            }
            await this.save(obligationValues, 'obligation', false, false);
        }
        // else {
        //     this.makeToast('warning', 'Сообщение', 'Данные не сохранены по обязательствам');
        // }

        if (paymentValues.length > 0) {
            if (errorPayment.error) {
                this.makeToast('warning', 'Сообщение', 'Имеются расхождения сумм по платежам и бюджетным заявкам');
            }
            await this.save(paymentValues, 'payment', false, false);
        }
        // else {
        //     this.makeToast('warning', 'Сообщение', 'Данные не сохранены по платежам');
        // }
        if (this.isSuccess) {
            this.makeToast('success', 'Сообщение', 'Данные сохранены!');
        }
        return this.isSuccess;
    }
    private flk0agr() {
        let total = 0
        for (const el of this.budgetFormPayment) {
            if (el.type === 4) {
                total += el.deviation
            }
        }
        return total > 0
    }

    private async save(values: any, typeTable: string, errorObligation = false, errorPayment = false) {
        this.isLoad = true;
        try {
            // const response = await fetch('/api-py/save-budget-execution-ipf/' + typeTable, {
            const response = await fetch('/api/budget-execution-pf-ipf/' + typeTable, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });
            if (response.status === 200) {
                if (errorObligation && errorPayment) {
                    if (typeTable === 'obligation') {
                        this.makeToast('danger', 'Сообщение', 'Ошибочно введенные данные не были сохранены по обязательствам');
                    } else if (typeTable === 'payment') {
                        this.makeToast('danger', 'Сообщение', 'Ошибочно введенные данные не были сохранены по платежам');
                    }
                } else {
                    if (typeTable === 'obligation') {
                        // this.makeToast('success', 'Сообщение', 'Данные сохранены по обязательствам');
                        this.budgetFormObligation.splice(0);
                        this.budgetFormObligationTotal = 0;
                        await this.prepareFormObligation();
                    } else if (typeTable === 'payment') {
                        // this.makeToast('success', 'Сообщение', 'Данные сохранены по платежам');
                        this.budgetFormPayment.splice(0);
                        this.budgetFormPaymentTotal = 0;
                        await this.prepareFormPayment();
                    }
                    this.isSuccess = true;
                }
            } else {
                throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
            }
        } catch (e) {
            this.makeToast('danger', 'Предупреждение', e.toString());
        } finally {
            this.isLoad = false;
        }
    } // сохранение данных

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private async downloadApplication(app: number) {
        this.loading = true;
        let d = {};
        const dat: any = this.$refs.ipf
        d = {
            'year': this.currentForm.year,
            'signers': [],
            'abp': this.currentForm.abp,
            'gu': this.currentForm.gu,
            'id': this.currentForm.id,
            'region_name': this.region.name_ru,
            'region_name_kz': this.region.name_kk,
            'abp_name': this.abp.name_ru,
            'abp_name_kz': this.abp.name_kk,
            'region': this.currentForm.region,
            'gr': this.filter.curGr,
            'prg': this.filter.prg ? this.filter.prg : 0,
            'ppr': this.filter.ppr ? this.filter.ppr : 0,
            'spf': this.filter.spf ? this.filter.spf : 0,
            'app': app,
            'treeData': []
        }
        Ax(
            {
                url: '/api-py/get-budget-execution-report/',
                method: 'POST',
                responseType: 'blob',
                data: d
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Приложение ${app}_${this.currentForm.gu}_${this.currentForm.year}.xlsx`);
                document.body.appendChild(link);
                link.click();
                this.loading = false;

            },
            (error: any) => {
                this.makeToast('danger', 'Ошибка запроса downloadApplication()', error.toString());
                this.loading = false;
            }
        )
    }

    private importSpf(type:number=1) {
        this.showImportFileModal = true
        this.importTypeTable = type
    }

    private async clearTree() {
        this.$bvModal.msgBoxConfirm(
            'Вы хотите удалить данные безвозвратно?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async (value:any) => {
                if (value) {
                    const deleteItems:any = {}
                    const path = '/api/budget-execution-pf-ipf'
                    for (const row of this.budgetFormPayment) {
                        if ((row.type === 6 && !row.hasChild) || row.type === 7) {
                            const item = Object.assign({}, this.filter);
                            this.$set(item, 'id', row.id);
                            this.$set(item, 'spf', row.spf);
                            this.$set(item, 'gr', row.gr);
                            this.$set(item, 'prg', row.prg);
                            this.$set(item, 'ppr', row.ppr);
                            this.$set(item, 'bip_code', row.bip_code);
                            this.$set(item, 'form', row.form);
                            this.$set(item, 'user_id', this.$store.state.user.sub);

                            this.$set(item, 'january', row.january);
                            this.$set(item, 'february', row.february);
                            this.$set(item, 'march', row.march);
                            this.$set(item, 'april', row.april);
                            this.$set(item, 'may', row.may);
                            this.$set(item, 'june', row.june);
                            this.$set(item, 'july', row.july);
                            this.$set(item, 'august', row.august);
                            this.$set(item, 'september', row.september);
                            this.$set(item, 'october', row.october);
                            this.$set(item, 'november', row.november);
                            this.$set(item, 'december', row.december);
                            deleteItems[`${item.abp}_${item.gu}_${item.prg}_${item.ppr}_${item.spf}_${item.bip_code}`] = item
                        }
                    }
                    for (const row of this.budgetFormObligation) {
                        if ((row.type === 6 && !row.hasChild) || row.type === 7) {
                            if (deleteItems[`${row.abp}_${row.gu}_${row.prg}_${row.ppr}_${row.spf}_${row.bip_code}`]) {
                                continue
                            }
                            const item = Object.assign({}, this.filter);
                            this.$set(item, 'id', row.id);
                            this.$set(item, 'spf', row.spf);
                            this.$set(item, 'gr', row.gr);
                            this.$set(item, 'prg', row.prg);
                            this.$set(item, 'ppr', row.ppr);
                            this.$set(item, 'bip_code', row.bip_code);
                            this.$set(item, 'form', row.form);
                            this.$set(item, 'user_id', this.$store.state.user.sub);

                            this.$set(item, 'january', row.january);
                            this.$set(item, 'february', row.february);
                            this.$set(item, 'march', row.march);
                            this.$set(item, 'april', row.april);
                            this.$set(item, 'may', row.may);
                            this.$set(item, 'june', row.june);
                            this.$set(item, 'july', row.july);
                            this.$set(item, 'august', row.august);
                            this.$set(item, 'september', row.september);
                            this.$set(item, 'october', row.october);
                            this.$set(item, 'november', row.november);
                            this.$set(item, 'december', row.december);
                            deleteItems[`${item.abp}_${item.gu}_${item.prg}_${item.ppr}_${item.spf}_${item.bip_code}`] = item
                        }
                    }

                    await axios.delete(path, {data: Object.values(deleteItems)})
                        .then(response => {
                            if (response.status === 200) {
                                this.budgetFormPayment = []
                                this.budgetFormObligation = []
                                this.budgetFormObligationTotal = 0
                                this.budgetFormPaymentTotal = 0
                                this.makeToast('info', 'Удаление', 'Данные успешно удалены!')
                            }
                        })
                        .catch(error => {
                            this.makeToast('danger', 'Удаление', 'Не далось вернуть данные!')
                        });
                }
            })
            .catch(error => {
                console.log(error);
            });

    }

    private async imortObligations(data:any) {
        if (this.budgetFormObligation.length) {
            this.makeToast('danger', 'Импорт', 'Таблицы не очищены')
            return
        }
        const arrData = data.plan;
        const error:any = data.error
        if (error.incorrectRows) {
            this.makeToast('danger', 'Импорт', 'Ошибка в данных! Пожалуйста, перепроверьте введенную информацию и исправьте ошибки.')
        }
        if (error.negativeValue) {
            this.makeToast('danger', 'Импорт', 'Ошибка в данных! Пожалуйста, перепроверьте введенную информацию присутствуют отрицательные значения')
            return
        }
        if (error.duplicationError) {
            const duplicates:any[] = error.duplicates;
            const text = duplicates.map(val => `${val.prg}->${val.ppr}->${val.spf}`).join('\n')
            this.makeToast('danger', 'Импорт', 'Ошибка в данных! Пожалуйста, перепроверьте введенную информацию присутствуют дубли')
            return
        }
        if (!arrData.length) {
            this.makeToast('danger', 'Импорт', 'Пустая таблица. Пожалуйста, добавьте данные.')
            return
        }
        for (const el of arrData) {
            const obligprogIndex = this.addNewPrg(el, this.budgetFormObligation, 'obligation')
            const obligsubProgIndex = this.addNewPpr(el, obligprogIndex, this.budgetFormObligation, 'obligation')
            this.addNewSpf(el, obligsubProgIndex, this.budgetFormObligation, 'obligation')

        }
    }
    private async imortPayments(data:any) {
        if (this.budgetFormPayment.length) {
            this.makeToast('danger', 'Импорт', 'Таблицы не очищены')
            return
        }
        const arrData = data.plan;
        const error:any = data.error
        if (error.incorrectRows) {
            this.makeToast('danger', 'Импорт', 'Ошибка в данных! Пожалуйста, перепроверьте введенную информацию и исправьте ошибки.')
        }
        if (error.negativeValue) {
            this.makeToast('danger', 'Импорт', 'Ошибка в данных! Пожалуйста, перепроверьте введенную информацию присутствуют отрицательные значения')
            return
        }
        if (error.duplicationError) {
            const duplicates:any[] = error.duplicates;
            const text = duplicates.map(val => `${val.prg}->${val.ppr}->${val.spf}`).join('\n')
            this.makeToast('danger', 'Импорт', 'Ошибка в данных! Пожалуйста, перепроверьте введенную информацию присутствуют дубли')
            return
        }
        if (!arrData.length) {
            this.makeToast('danger', 'Импорт', 'Пустая таблица. Пожалуйста, добавьте данные.')
            return
        }
        for (const el of arrData) {
            const progIndex = this.addNewPrg(el, this.budgetFormPayment)
            const subProgIndex = this.addNewPpr(el, progIndex, this.budgetFormPayment)
            this.addNewSpf(el, subProgIndex, this.budgetFormPayment)
        }
    }

    private infoModule(info_id:any, file:any) {
        const that = this;
        Ax(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data:any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error:any) => {
                this.makeToast('danger', 'Ошибка загрузки', error.toString());
            }
        );
    }
}
