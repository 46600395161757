


























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from "@/services/store";
import {Ax} from "@/utils";
import BExecAgrHistModal from '@/modules/budget/execution/form/tabs/components/BExecAgrHistModal.vue'

@Component({
    components: {
        'b-exec-agr-hist-modal': BExecAgrHistModal
    }
})
export default class CAbpWindow extends Vue {
    $refs!: {
        refHistModal: HTMLFormElement
    }
    @Prop({
        required: true,
        default: null
    })
    private filter!: any;

    @Prop({
        required: true,
        default: null
    })
    private nameAbp!: string;

    @Prop({
        required: true,
        default: null
    })
    private editAccess!: boolean;

    @Prop({
        required: true,
        default: []
    })
    private pfData!: any[];

    @Prop({
        required: true,
        default: []
    })
    private statusTypes!: any[]

    @Prop({
        required: true,
        default: {}
    })
    private apbDict!: any[]
    @Prop({
        required: true,
        default: false
    })
    private import_acces!: any;

    private progress: number | null = null;

    private tableFields = [
        {
            key: 'action',
            label: ' '
        },
        {
            key: 'name_ru',
            label: 'Наименование'
        },
        {
            key: 'defective',
            label: 'Дефектная ведомость'
        },
        {
            key: 'status',
            label: 'Статус'
        },
        {
            key: 'executor',
            label: 'Исполнитель'
        },
        {
            key: 'date_creation',
            label: 'Дата создания'
        },
        {
            key: 'comment',
            label: 'комментарий'
        },
        {
            key: 'more',
            label: ''
        }
    ];

    private modalTableFields = [
        {
            key: 'number',
            label: '№'
        },
        {
            key: 'code',
            label: 'Код ГУ'
        },
        {
            key: 'name_ru',
            label: 'Наименование'
        },
        {
            key: 'status',
            label: 'Статус'
        }
    ];

    private abpData: any = [];
    private guList: any = [];
    private verifiedStatuses = 0;

    // private async created() {
    //     this.progress = 10;
    //     await this.chgFilter();
    // }

    @Watch('filter')
    async onPropertyChanged(value: string, oldValue: string) {
        await this.chgFilter();
    }

    private async chgFilter() {
        await this.loadCompany();
    }

    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    private async mounted() {
        this.$watch('pfData', () => { this.selectedPf = null; })
    }

    private async loadCompany() {
        this.guList = [];
        this.verifiedStatuses = 0;
        let result = [];
        try {
            result = await fetch(`/api-py/all-gu-by-region-abp-user-id/${this.filter.region}/${this.filter.abp}/${this.usrId}`)
                .then(response => response.json());
            this.progress = 75;
            result.forEach((el: any, ind: number) => {
                const item: any = {
                    number: ind + 1,
                    code: el.code,
                    name_ru: el.name_ru,
                    name_kk: el.name_kk,
                    status: 'Отсутствует'
                };
                this.guList.push(item);
            });
        } catch (error) {
            this.progress = 100;
            this.makeToast('danger', 'Ошибка запроса loadCompany1()', error.toString());
        }

        let existingGu: any = [];
        try {
            existingGu = await fetch('/api-py/get-budget-execution-gu-list/' + JSON.stringify(this.filter))
                .then(response => response.json());
            this.progress = 100;
            this.guList.forEach((el: any) => {
                existingGu.forEach((item: any) => {
                    if (el.code === item.gu) {
                        this.verifiedStatuses++;
                        el.status = item.defective;
                    }
                });
            });
        } catch (error) {
            this.progress = 100;
            this.makeToast('danger', 'Ошибка запроса loadCompany2()', error.toString());
        }
    }

    private toggleOpenWindowBool(row:any = null) {
        this.$emit('toggleOpenWindowBool', row);
    }

    private haveEditAccess(row: any) {
        return 'status' in row && row.status !== null && [1, 27].includes(row.status.status)
    }
    private haveDeleteAccess(row: any) {
        return 'status' in row && row.status !== null && [1].includes(row.status.status)
    }
    onChange(item:any){
        // this.$emit('onChange', item);
    }
    onDelete(item:any) {
        this.$bvModal.msgBoxConfirm(
            'Вы действительно хотите удалить данный ПФ?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                if (value) {
                    this.$emit('onDelete', item);
                }
            })
            .catch(error => {});

    }

    private getFullName(row:any) {
        if (row.abp && this.apbDict[row.abp]) {
            return `План финансирования на ${this.filter.year} год по АБП «${row.abp} - ${this.apbDict[row.abp].name_ru}»`
        }
        return `План финансирования на ${this.filter.year} год`
    }

    getLocalStatus(value:any) {
        const status = value.status.status;
        const curStatus = this.statusTypes.filter((item) => item.code === status);
        if (!curStatus || !curStatus.length) return status;
        const localName = curStatus[0]["name_" + this.$i18n.locale];
        return localName;
    }

    private formatDate(value: string): string | null {
        if (value) {
            const date = new Date(value);
            return date.toLocaleDateString();
        }
        return null;
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private selectedPf = null;

    private downloadApp(item: any) {
        for (const r of this.pfData) {
            r.selected = false;
        }
        item.selected = !item.selected;
        this.selectedPf = item;
        this.$emit('changeForm', item)
        item.id += 1;
        item.id -= 1;
    }

    private async openHistory(agrObj: any) {
        if (agrObj !== null) {
            await this.$refs.refHistModal.showEvent(agrObj);
        }
    }
}
