






































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Vue, Prop, Component} from 'vue-property-decorator';
import axios from 'axios';
import {Ax} from "@/utils";
import VueElementLoading from "vue-element-loading";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {BootstrapVueIcons} from 'bootstrap-vue';
import app9ReasonInput from "@/modules/budget/monitoring/input-forms/components/app9ReasonInput.vue";

Vue.use(BootstrapVueIcons);
@Component({
    computed: {},
    components: {
        'loading': VueElementLoading,
        'treeselect': Treeselect,
        'reason-input': app9ReasonInput
    },
    filters: {
        formatDate(value: any) {
            if (value) {
                const date = new Date(value*1000);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
})
export default class ApplcationForm9 extends Vue {
    $refs!: {
        reasonInputForm: HTMLFormElement
    }
    private cutness: any = {code: 1};
    @Prop({
        required: false,
        default: null
    })
    private reg!:any;
    @Prop({
        required: false,
        default: null
    })
    private date_to!:Date;
    @Prop({
        required: false,
        default: ''
    })
    private user_id!:any;
    @Prop({
        required: false,
        default: null
    })
    private period!:any;
    @Prop({
        required: false,
        default: 1000, // Set the desired default here
    })
    private convert!:any;
    @Prop({
        required: true,
        default: null
    })
    private currForm!:any;
    @Prop({
        required: true,
        default: null
    })
    private app9repForm!:any;
    @Prop({
        required: true,
        default: null
    })
    private editAccess!:any;
    @Prop({
        required: true,
        default: null
    })
    private deleteAccess!:any;
    @Prop({
        required: true,
        default: null
    })
    private childStatus!:any;

    private loading: boolean = false;

    private showAbpInfo: boolean = false; //модальное информанионное окно
    private infoAbp:any = {}

    private showReasonInput: boolean = false;

    private funcDict: any = {};
    private spfDict: any = {};
    private currProgData: any[] = [];
    private currProgTreeData: any[] = [];
    private devProgData: any[] = [];
    private devProgTreeData: any[] = [];

    private reasonsFull:any[] = [];
    private reasonsDictOB:any[] = [];
    private get reasonsUOB():any[] {
        return this.createReasonsTree(1, 1)
    }
    private get reasonsEOB():any[] {
        return this.createReasonsTree(2, 1)
    }
    private reasonsDictPL:any[] = [];
    private get reasonsUPL():any[] {
        return this.createReasonsTree(1, 2)
    }
    private get reasonsEPL():any[] {
        return this.createReasonsTree(2, 2)
    }
    private dictReasons:any = {};
    private spfTable:any = [];

    private userNames:any = {};

    private spfIN18:number[] = [111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 131, 135];
    private spfIN19:number[] = [161, 162];
    private get totalTreeCollumn() {
        if (this.cutness.code === 0) return 3
        if (this.cutness.code === 1) return 4
    }
    private numValues:string[] = [
        'plg',
        'obaz',
        'plat',
        'registrob',
        'sumrg',
        'execution_rate',
        'expectation',
        'execution_expectation_rate',
        'sum_not_executed_expec',
        'sum_not_executed_payment',
        'sum_discarded_oblig',
        'sum_econ',
        'savings_gz',
        'savings_fot',
        'savings_etc',
        'rez_gov',
        'sum_unspent',
        'nd_ob',
        'nd_contests',
        'nd_abp',
        'nd_etc',
        'note_pl_reasonid',
        'note_ob_reasonid'
    ]
    private get numCols() {
        if (this.app9repForm) {
            return [
                'plg',
                'obaz',
                'plat',
                'registrob',
                'sumrg',
                'expectation',
                'sum_not_executed_payment',
                'sum_discarded_oblig',
            ]
        } else {
            return [
                'sum_discarded_oblig',
                'sum_not_executed_payment',
                'note_pl_reasonid',
                'note_ob_reasonid'
            ]
        }
    }
    private column_names:any = {
        'modal_abp_info': {
            infoAbp: {abp: null},
            get ru() {
                return `Информация по ${this.infoAbp.abp} абп`;
            },
            get kk() {
                return `${this.infoAbp.abp} ББӘ бойынша ақпарат`;
            }
        },
    }
    private inputFields:string[] = [
        // 'plg',
        // 'obaz',
        // 'plat',
        // 'registrob',
        // 'sum_discarded_oblig',
        // 'sumrg',
        // 'execution_rate',
        'expectation',
        // 'execution_expectation_rate',
        // 'sum_not_executed_expec',
        // 'sum_not_executed_payment',
        // 'sum_econ',
        // 'savings_gz',
        // 'savings_fot',
        // 'savings_etc',
        // 'rez_gov',
        // 'sum_unspent',
        // 'nd_ob',
        // 'nd_contests',
        // 'nd_abp',
        // 'nd_etc',
        // 'note_pl_reasonid',
        // 'note_ob_reasonid'
    ]
    private totalSum:any = {}
    private isOnCreate:boolean = true;
    async created() {
        const that = this;
        const sumFields:any[] = [
            'plg',
            'obaz',
            'plat',
            'registrob',
            // 'sum_discarded_oblig',
            'sumrg',
            // 'execution_rate',
            'expectation',
            // 'execution_expectation_rate',
            // 'sum_not_executed_expec',
            // 'sum_not_executed_payment',
            // 'sum_econ',
            'savings_gz',
            'savings_fot',
            'savings_etc',
            'rez_gov',
            // 'sum_unspent',
            'nd_ob',
            'nd_contests',
            'nd_abp',
            'nd_etc'
        ]
        const item = that.totalSum
        for (const val of sumFields) {
            Object.defineProperty(item, val, {
                get: function () {
                    return that.total(`${val}`)
                },
                set: function (value) {
                    item[`_${val}`] = value;
                }
            })
        }
        Object.defineProperty(item, 'sum_discarded_oblig', {
            get: function () {
                return that.total('sum_discarded_oblig')
            }
        })
        Object.defineProperty(item, 'execution_rate', {
            get: function () {
                if (item.plat === 0) return 0
                return parseFloat((item.sumrg / item.plat * 100).toFixed(3))
            }
        })
        Object.defineProperty(item, 'execution_expectation_rate', {
            get: function () {
                if (item.plg === 0) return 0
                return parseFloat((item.expectation / item.plg * 100).toFixed(3))
            }
        })
        Object.defineProperty(item, 'sum_not_executed_expec', {
            get: function () {
                return that.total('sum_not_executed_expec')
            }
        })
        Object.defineProperty(item, 'sum_not_executed_payment', {
            get: function () {
                return that.total('sum_not_executed_payment')
            }
        })
        Object.defineProperty(item, 'sum_econ', {
            get: function () {
                return that.total('sum_econ')
            }
        })
        Object.defineProperty(item, 'sum_unspent', {
            get: function () {
                return that.total('sum_unspent')
            }
        })
        await this.loadReasons()
        this.$watch('childStatus', () => {
            if (this.childStatus && this.childStatus.hasOwnProperty('code') && !this.isOnCreate) {
                this.loadData()
            }
        }, {deep: true})
    }
    private async mounted() {
        await this.loadData()
        await this.updateFormFlk()
        this.isOnCreate = false;
    }

    private formatedDate(date_to: Date) {
        return `${date_to.getFullYear()}-${date_to.getMonth() + 1}-${date_to.getDate()}`
    }

    private async  getData(date_to: Date, region: any, cutness: any, uuid: string, abp:number|null = null, isUdate:boolean = false) {
        let treeS:any = {
            currTree: [],
            devTree: [],
            funcDict: {},
            spfDict: {}
        };
        const formatedDate = `${date_to.getFullYear()}-${date_to.getMonth() + 1}-${date_to.getDate()}`
        const request = {
            'cutness': 1, // cutness.code, для того чтобы видеть все спф и их суммы
            'region': region.code,
            'repdate': formatedDate,
            'user_id': uuid,
            'abp': abp,
            'is_update': isUdate,
            'convert': this.convert,
            'year': this.currForm.year,
            'month': this.currForm.month,
            'status': this.childStatus && this.childStatus.hasOwnProperty('code') && this.childStatus.code? this.childStatus.code: null,
            'form': this.currForm.form_code
        }
        await axios.post('/api-py/fulfillment-report/app-9/data', request).then(resp => {
            if (resp.status === 200) {
                treeS = resp.data;
            }
        }).catch(error => {
        })
        return treeS
    }

    private async loadUsernames() {
        for (const u in this.userNames) {
            try {
                await axios.get(`/api-py/get-realm-user/${u}`).then(resp => {
                    if (resp.status === 200) {
                        this.userNames[u] = resp.data.firstName + ' ' + resp.data.lastName
                    }

                })
            } catch (e) {

            }
        }
    }
    private user_name(uuid:string) {
        if (uuid in this.userNames) {
            return this.userNames[uuid]
        }
        return ''
    }


    public async loadData() {
        this.loading = true
        const that = this
        try {
            const treeS:any = await this.getData(this.date_to, this.reg, this.cutness, this.user_id, this.currForm.abp)
            this.collapse = true;
            this.currProgData = treeS.currTree
            this.devProgData = treeS.devTree
            this.funcDict = treeS.funcDict
            this.spfDict = treeS.spfDict

            that.currProgTreeData = that.createTree(that.currProgData, this.funcDict, this.spfDict)
            that.devProgTreeData = that.createTree(that.devProgData, this.funcDict, this.spfDict)
            that.loading = false
            this.loadUsernames()
        } catch (e) {
            console.log(e)
            that.loading = false
        }
    }

    private async updateData(abpEll:any, isUpdate: boolean = false) {
        const updateValues:string[] = ['plg', 'obaz', 'plat', 'registrob', 'sumrg', 'expectation', 'savings_gz', 'savings_fot', 'savings_etc', 'rez_gov', 'nd_ob', 'nd_contests', 'nd_abp', 'nd_etc', 'note_pl_reasonid', 'note_ob_reasonid', 'status', 'update_date', 'user_id']

        const treeS:any = await this.getData(this.date_to, this.reg, this.cutness, this.user_id, abpEll.abp, isUpdate)
        const currTree = treeS.currTree
        const devTree = treeS.devTree
        for (const el of currTree) {
            if (!(el.user_id in this.userNames && el.user_id)) {
                this.userNames[el.user_id] = ''
            }
            const abpNode:any = this.currProgTreeData.find((v:any) => v.abp === el.abp)
            const prgNode:any = abpNode.child_list.find((v:any) => v.prg === el.prg)
            const pprNode:any = prgNode.child_list.find((v:any) => v.ppr === el.ppr)
            for (const v of updateValues) {
                pprNode[v] = el[v]
                if (!['note_pl_reasonid', 'note_ob_reasonid'].includes(v)) this.chgValue(pprNode, v, pprNode.develop_type)
            }
            for (const v of updateValues.slice(-2)) {
                abpNode[v] = pprNode[v]
            }
            pprNode._note_pl_reasonid_eco = this.reasonsFull.filter((v:any) => v.id in pprNode.note_pl_reasonid && (v.type === 2 || v.type === 3)).map((x:any) => `${x.gr_pl_id}_${x.id}`)
            pprNode._note_ob_reasonid_eco = this.reasonsFull.filter((v:any) => v.id in pprNode.note_ob_reasonid && (v.type === 2 || v.type === 3)).map((x:any) => `${x.gr_ob_id}_${x.id}`)
            pprNode._note_pl_reasonid_und = this.reasonsFull.filter((v:any) => v.id in pprNode.note_pl_reasonid && (v.type === 1 || v.type === 3)).map((x:any) => `${x.gr_pl_id}_${x.id}`)
            pprNode._note_ob_reasonid_und = this.reasonsFull.filter((v:any) => v.id in pprNode.note_ob_reasonid && (v.type === 1 || v.type === 3)).map((x:any) => `${x.gr_ob_id}_${x.id}`)
            this.chgValue(pprNode, 'note_pl_reasonid', pprNode.develop_type)
            this.chgValue(pprNode, 'note_ob_reasonid', pprNode.develop_type)

        }
        for (const el of devTree) {
            const abpNode:any = this.devProgTreeData.find((v:any) => v.abp === el.abp)
            const prgNode:any = abpNode.child_list.find((v:any) => v.prg === el.prg)
            const pprNode:any = prgNode.child_list.find((v:any) => v.ppr === el.ppr)
            for (const v of updateValues) {
                pprNode[v] = el[v]
                if (!['note_pl_reasonid', 'note_ob_reasonid'].includes(v)) this.chgValue(pprNode, v, pprNode.develop_type)
            }
            for (const v of updateValues.slice(-2)) {
                abpNode[v] = pprNode[v]
            }
            pprNode._note_pl_reasonid_eco = this.reasonsFull.filter((v:any) => v.id in pprNode.note_pl_reasonid && (v.type === 2 || v.type === 3)).map((x:any) => `${x.gr_pl_id}_${x.id}`)
            pprNode._note_ob_reasonid_eco = this.reasonsFull.filter((v:any) => v.id in pprNode.note_ob_reasonid && (v.type === 2 || v.type === 3)).map((x:any) => `${x.gr_ob_id}_${x.id}`)
            pprNode._note_pl_reasonid_und = this.reasonsFull.filter((v:any) => v.id in pprNode.note_pl_reasonid && (v.type === 1 || v.type === 3)).map((x:any) => `${x.gr_pl_id}_${x.id}`)
            pprNode._note_ob_reasonid_und = this.reasonsFull.filter((v:any) => v.id in pprNode.note_ob_reasonid && (v.type === 1 || v.type === 3)).map((x:any) => `${x.gr_ob_id}_${x.id}`)
            this.chgValue(pprNode, 'note_pl_reasonid', pprNode.develop_type)
            this.chgValue(pprNode, 'note_ob_reasonid', pprNode.develop_type)

        }
        if (isUpdate) this.makeToast('info', this.$t('modules.common.update'), this.$t('modules.common.data_updated'));
    }

    private async saveData(abpEll:any=null) {
        let dataSet:any[] = []
        let flkExist = false
        let flk1 = false;
        let flk2 = false;
        if (!abpEll) {
            dataSet = this.currProgTreeData.concat(this.devProgTreeData)
        } else {
            for (const el of abpEll.develop_type? this.currProgTreeData : this.devProgTreeData) {
                if (el.abp === abpEll.abp && el.data_type === 4) {
                    if (!flk1) {
                        flk1 = this.flk1(el);
                    }
                    if (!flk2) {
                        flk2 = this.flk2(el);
                    }
                    if (!flkExist) {
                        flkExist = flk1 || flk2
                    }
                    dataSet.push(el)
                }
            }
        }
        if (flkExist) {
            if (flk1) {
                this.makeToast('danger', this.$t('modules.common.error'),   this.$t('modules.budget_execution_monitoring.reports.app_9.warn_obaz'))
            }
            if (flk2) {
                this.makeToast('danger', this.$t('modules.common.error'), this.$t('modules.budget_execution_monitoring.reports.app_9.warn_plat'))
            }
        }
        const req = {
            save_list: dataSet,
            repdate: this.formatedDate(this.date_to),
            region: this.reg.code,
            user_id: this.user_id
        }
        await axios.post('/api-py/fulfillment-report/app-9/data/save', req).then(resp => {
            if (resp.status === 200) {
                for (const cbc of this.currProgTreeData) {
                    if (cbc.hasChanges) {
                        cbc.hasChanges = false
                    }
                }
                for (const cbc of this.devProgTreeData) {
                    if (cbc.hasChanges) {
                        cbc.hasChanges = false
                    }
                }

                this.makeToast('success', this.$t('modules.common.saving'), this.$t('modules.common.data_saved_successfully'))
                this.updateFormFlk()
                this.updateData(abpEll)
            }
        })
    }
    private collapse: boolean = true;

    private clkCollapseAll(data: any, collapse = true) {
        this.$set(data, 'collapse', collapse);
        if ('child_list' in data && data.child_list && data.child_list.length) {
            for (const el of data.child_list) {
                this.clkCollapseAll(el, collapse)
            }
        }
    }

    private clkCollapse(abp: any = null, prg: any = null, ppr: any = null, spf: any = null, collapse: boolean = false, dev_type = 0) {
        if (abp !== null) {
            let obj = !dev_type ? this.currProgTreeData[abp] : this.devProgTreeData[abp]
            if (prg !== null) {
                obj = obj.child_list[prg]
            }
            if (ppr !== null) {
                obj = obj.child_list[ppr]
            }
            if (collapse) {
                this.clkCollapseAll(obj, collapse)
            }
            this.$set(obj, 'collapse', collapse);
        } else {
            this.collapse = collapse;
            for (const el of this.currProgTreeData) {
                this.clkCollapseAll(el, collapse)
            }
            for (const el of this.devProgTreeData) {
                this.clkCollapseAll(el, collapse)
            }

        }
    }
    private total(field: string) {
        let sum = 0
        for (const el of this.currProgTreeData.concat(this.devProgTreeData)) {
            sum += el[field]
        }
        return sum
    }
    private downloadRep(rep_type:number = 4) {
        // this.loading = true
        const formatedDate = `${this.date_to.getFullYear()}-${this.date_to.getMonth() + 1}-${this.date_to.getDate()}`
        const request = {
            'cutness': 0,
            'region': this.reg.code,
            'repdate': formatedDate,
            'user_id': this.user_id,
            'abp': this.currForm.abp,
            'is_update': false,
            'period': this.period,
            'locale': this.$i18n.locale,
            'convert': this.convert,
            'year': this.currForm.year,
            'month': this.currForm.month,
            'status': this.childStatus && this.childStatus.hasOwnProperty('code') && this.childStatus.code? this.childStatus.code: null,
            'form': this.currForm.form_code
        }
        const that = this
        try {
            this.makeToast('info', this.$t('modules.common.attention'), this.$t('modules.budget_execution_monitoring.reports.app_9.warn_download'));
            Ax(
                {
                    url: `/api-py/fulfillment-report/app-9/report/${rep_type}`,
                    method: 'POST',
                    responseType: 'blob',
                    data: request
                },
                (data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let name = that.$t('modules.budget_execution_monitoring.reports.app_9.app9')
                    if (rep_type === 1) name = that.$t('modules.budget_execution_monitoring.reports.app_9.app9_analysis')
                    if (rep_type === 2) name = that.$t('modules.budget_execution_monitoring.reports.app_9.app9_reason_info')
                    if (rep_type === 3) name = that.$t('modules.budget_execution_monitoring.reports.app_9.app9_analysis_wide')
                    if (rep_type === 8) name = that.$t('modules.budget_execution_monitoring.reports.app_9.app9_form_8')
                    if (rep_type === 9) name = that.$t('modules.budget_execution_monitoring.reports.app_9.app9_form_9')
                    link.setAttribute('download', name + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    that.loading = false
                },
                (error) => {
                    that.loading = false
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        } catch (error) {
            that.loading = false
            this.makeToast('danger', this.$t('modules.common.warning'), this.$t('modules.budget_execution_monitoring.reports.app_9.warn_filter'));
        }
    }

    private createReasonsTree(type:any = null, obaz_plat:any = 1) {
        const dict_ob:any = {}
        const dict_pl:any = {}
        for (const r of this.reasonsFull) {
            if (type !== null) {
                if (r.type !== type && r.type !== 3) {
                    continue
                }
            }
            if (!dict_ob[r.gr_ob_id]) {
                dict_ob[r.gr_ob_id] = {
                    id: r.gr_ob_id.toString(),
                    label: r[`gr_ob_name_${this.$i18n.locale}`],
                    children: [],
                    value: Object.assign({}, r)
                }
            }
            if (!dict_pl[r.gr_pl_id]) {
                dict_pl[r.gr_pl_id] = {
                    id: r.gr_pl_id.toString(),
                    label: r[`gr_pl_name_${this.$i18n.locale}`],
                    children: [],
                    value: Object.assign({}, r)
                }
            }
            dict_ob[r.gr_ob_id].children.push({
                id: `${r.gr_ob_id}_${r.id}`,
                label: r[`name_${this.$i18n.locale}`],
                value: Object.assign({}, r)
            })
            dict_pl[r.gr_pl_id].children.push({
                id: `${r.gr_pl_id}_${r.id}`,
                label: r[`name_${this.$i18n.locale}`],
                value: Object.assign({}, r)
          })

        }
        if (obaz_plat === 1) return Object.values(dict_ob)
        else return Object.values(dict_pl)
    }

    private async loadReasons() {
        await axios.get(`/api-py/fulfillment-application-reasons/${this.formatedDate(this.date_to)}`).then(resp => {
            if (resp.status === 200) {
                const dictsList:any = resp.data[0];
                const grDicts:any = resp.data[1];
                for (const d of dictsList) {
                    let grob:any = grDicts[d.gr_ob_id]
                    if (!grob) {
                        grob = {
                            name_ru: '',
                            name_kk: '',
                            name_en: ''
                        }
                    }
                    d.gr_ob_name_ru = grob.name_ru
                    d.gr_ob_name_kk = grob.name_kk
                    d.gr_ob_name_en = grob.name_en
                    let grpl:any = grDicts[d.gr_pl_id]
                    if (!grpl) {
                        grpl = {
                            name_ru: '',
                            name_kk: '',
                            name_en: ''
                        }
                    }
                    d.gr_pl_name_ru = grpl.name_ru
                    d.gr_pl_name_kk = grpl.name_kk
                    d.gr_pl_name_en = grpl.name_en
                }
                this.reasonsFull = dictsList
                for (const r of dictsList) {
                    this.dictReasons[r.id] = r
                }
                this.reasonsDictPL = this.createReasonsTree(null, 2)
                this.reasonsDictOB = this.createReasonsTree(null, 1)
            }
        })
    }

    private async loadSPFTable() {
        await axios.get('/api-py/fulfillment-report/app-9/spf-table').then(resp => {
            if (resp.status === 200) {
                this.spfTable = resp.data
                console.log(resp.data)
            }
        })
    }

    private reasonText(id:number) {
        const it = this.reasonsFull.find((v:any) => v.id === id)
        const l_name = `name_${this.$i18n.locale}`
        if (it) return it[l_name]
        else return 1
    }
    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    private reSum(item:any, field:string) {
        let sum = 0;
        if (field[0] === '_') {
            field = field.replace('_', '');
        }
        for (const ch1 of item.child_list) {
            sum += ch1[field]
        }
        return sum

    }
    private sumReasons(item:any, field:string, is_ppr:boolean = false) {
        let child_reasons:any[] = []
        child_reasons = item[field].map((v: any) => v.id)

        return [...new Set(child_reasons)]
    }

    private calcFields(item:any, sum:boolean = true) {
        const that = this;
        const sumFields:any[] = [
            'plg',
            'obaz',
            'plat',
            'registrob',
            // 'sum_discarded_oblig',
            'sumrg',
            // 'execution_rate',
            'expectation',
            // 'execution_expectation_rate',
            // 'sum_not_executed_expec',
            // 'sum_not_executed_payment',
            // 'sum_econ',
            'savings_gz',
            'savings_fot',
            'savings_etc',
            'rez_gov',
            // 'sum_unspent',
            'nd_ob',
            'nd_contests',
            'nd_abp',
            'nd_etc'
        ]
        if (sum) {
            for (const val of sumFields) {
                Object.defineProperty(item, val, {
                    get: function () {
                        return that.reSum(item, `_${val}`)
                    },
                    set: function (value) {
                        item[`_${val}`] = value;
                    }
                })
            }
            Object.defineProperty(item, 'status', {
                get: function () {
                    for (const ch of item.child_list) {
                        if (!ch.status) return false;
                    }
                    return true;
                },
                set: function (value) {
                    for (const ch of item.child_list) {
                        ch.status = value
                    }
                }
            })
            Object.defineProperty(item, 'flk1', {
                get: function () {
                    for (const ch of item.child_list) {
                        if (ch.flk1) return true;
                    }
                    return false;
                }
            })
            Object.defineProperty(item, 'flk2', {
                get: function () {
                    for (const ch of item.child_list) {
                        if (ch.flk2) return true;
                    }
                    return false;
                }
            })

        } else { // ppr node level
            const sumR:string[] = ['savings_gz', 'savings_fot', 'savings_etc', 'rez_gov', 'nd_ob', 'nd_contests', 'nd_abp', 'nd_etc']
            for (const f of sumFields) {
                if (!sumR.includes(f)) item[f] = parseFloat(item[f].toFixed(3));
            }
            for (let i = 1; i < 10; i++) {
                const f = sumR[i-1]
                Object.defineProperty(item, f, {
                    get: function () {
                        let sum = 0
                        for (const r_id in item.note_pl_reasonid) {
                            if (r_id in that.dictReasons) {
                                const reason = that.dictReasons[r_id]
                                if (reason.gr_pl_id === i) {
                                    sum += item.note_pl_reasonid[r_id]
                                }
                            }
                        }
                        return parseFloat(sum.toFixed(3))
                    }
                });
            }
            Object.defineProperty(item, 'flk1', {
                get: function () {
                    return that.flk1(item)
                }
            })
            Object.defineProperty(item, 'flk2', {
                get: function () {
                    return that.flk2(item)
                }
            })
            Object.defineProperty(item, 'status', {
                get: function () {
                    return item._status
                },
                set: function (value) {
                    item._status = value
                    that.chgValue(item, 'status', item.develop_type)
                }
            })
        }
        Object.defineProperty(item, 'sum_discarded_oblig', {
            get: function () {
                return item.registrob - item.obaz
            }
        })
        Object.defineProperty(item, 'execution_rate', {
            get: function () {
                if (item.plat === 0) return 0
                return parseFloat((item.sumrg / item.plat * 100).toFixed(3))
            }
        })
        Object.defineProperty(item, 'execution_expectation_rate', {
            get: function () {
                if (item.plg === 0) return 0
                return parseFloat((item.expectation / item.plg * 100).toFixed(3))
            }
        })
        Object.defineProperty(item, 'sum_not_executed_expec', {
            get: function () {
                return item.expectation - item.plg
            }
        })
        Object.defineProperty(item, 'sum_not_executed_payment', {
            get: function () {
                return item.sumrg - item.plat
            }
        })
        Object.defineProperty(item, 'sum_econ', {
            get: function () {
                return item.savings_gz + item.savings_fot + item.savings_etc
            }
        })
        Object.defineProperty(item, 'sum_unspent', {
            get: function () {
                return item.sumrg - item.plat - item.sum_econ - item.rez_gov
            }
        })



    }
    private createTree(tableData: any[], funcDict:any, spfDict:any) {

        const tree:any = {}
        for (const [idx, item] of tableData.entries()) {
            if (!(item.user_id in this.userNames && item.user_id) && item.update_date) this.userNames[item.user_id] = '';

            const newItem = Object.assign({idx: idx, collapse: this.collapse}, item)
            const dev_type = newItem.develop_type
            const abp:number = newItem.abp
            const prg:number = newItem.prg
            const ppr:number = newItem.ppr
            const ppr_key:string = `${abp.toString().padStart(3, '0')} ${prg.toString().padStart(3, '0')} ${ppr? ppr.toString().padStart(3, '0'): '000'}`
            let pprNode = tree[ppr_key]
            if (!pprNode) {
                '_note_pl_reasonid_eco'
                '_note_ob_reasonid_eco'
                '_note_pl_reasonid_und'
                '_note_ob_reasonid_und'

                pprNode = Object.assign({idx: idx, collapse: this.collapse, child_list: []}, newItem)
                pprNode._note_pl_reasonid_eco = this.reasonsFull.filter((v:any) => v.id in pprNode.note_pl_reasonid && (v.type === 2 || v.type === 3)).map((x:any) => `${x.gr_pl_id}_${x.id}`)
                pprNode._note_ob_reasonid_eco = this.reasonsFull.filter((v:any) => v.id in pprNode.note_ob_reasonid && (v.type === 2 || v.type === 3)).map((x:any) => `${x.gr_ob_id}_${x.id}`)
                pprNode._note_pl_reasonid_und = this.reasonsFull.filter((v:any) => v.id in pprNode.note_pl_reasonid && (v.type === 1 || v.type === 3)).map((x:any) => `${x.gr_pl_id}_${x.id}`)
                pprNode._note_ob_reasonid_und = this.reasonsFull.filter((v:any) => v.id in pprNode.note_ob_reasonid && (v.type === 1 || v.type === 3)).map((x:any) => `${x.gr_ob_id}_${x.id}`)
                pprNode._status = pprNode.status
                pprNode.ppr_key = ppr_key
                this.calcFields(pprNode, false)
                tree[ppr_key] = pprNode
            }
            if (funcDict) {
                if (abp in funcDict) {
                    if (funcDict[abp] && funcDict[abp].child_list && prg in funcDict[abp].child_list) {
                        if (funcDict[abp].child_list[prg].child_list && ppr in funcDict[abp].child_list[prg].child_list) {
                            if (!pprNode.name_ru && funcDict[abp].child_list[prg].child_list[ppr] && funcDict[abp].child_list[prg].child_list[ppr].name_ru) {
                                pprNode.name_ru = funcDict[abp].child_list[prg].child_list[ppr].name_ru;
                                pprNode.name_kk = funcDict[abp].child_list[prg].child_list[ppr].name_kk;
                            }
                        }
                    }
                }
            }

        }
        return Object.values(tree).sort((a:any, b:any) => a['ppr_key'].localeCompare(b['ppr_key']))

    }


    private flk1(pprNode:any) {
        // flk graph 28 reasons_ob === graph 9 discarde
        let sum = 0
        for (const r in pprNode.note_ob_reasonid) {
            if (r in this.dictReasons) sum += pprNode.note_ob_reasonid[r];
        }
        return parseFloat((pprNode.registrob - pprNode.obaz).toFixed(3)) !==  parseFloat(sum.toFixed(3))
    }
    private flk2(pprNode:any) {
        let sum = 0
        for (const r in pprNode.note_pl_reasonid) {
            if (r in this.dictReasons) sum += pprNode.note_pl_reasonid[r];
        }
        // flk graph 27 reasons_pl === graph 15 not_exec_paym
        return parseFloat((pprNode.sumrg - pprNode.plat).toFixed(3)) !== parseFloat(sum.toFixed(3))
    }
    private flkAgree() {
        let isValid = true
        for (const ppr of this.currProgTreeData.concat(this.devProgTreeData)) {

            if (ppr.flk1 || ppr.flk2) {
                isValid = false
                break
            }
        }
        return isValid
    }
    private async updateFormFlk() {
        if (this.childStatus && this.childStatus.hasOwnProperty('code') && this.childStatus.code) return
        const req:any = {
            flk: !this.flkAgree(),
            form_id: this.currForm.id
        }
        await axios.post('/api-py/input-forms/flk', req).catch(e => {})
    }

    private chgStatus(node:any) {
        if (node.data_type === 4) {

        }
        const flk1 = node.flk1;
        const flk2 = node.flk2;
        const errors = flk1 || flk2
        if (errors) {
            if (flk1) {
                this.makeToast('danger', this.$t('modules.common.error'),   this.$t('modules.budget_execution_monitoring.reports.app_9.warn_obaz'))
            }
            if (flk2) {
                this.makeToast('danger', this.$t('modules.common.error'),  this.$t('modules.budget_execution_monitoring.reports.app_9.warn_plat'))
            }
        }
        if (errors) {
            node.status = false
        }
        if (node.status) this.saveData(node)
    }

    private chgValue(pprObj: any, fieldName: any, dev_type: any, change_code: any = null) {
        if (!dev_type) {
            this.currProgData[pprObj.idx][fieldName] = pprObj[fieldName];
            this.currProgData[pprObj.idx].changed = true;
        } else {
            this.devProgData[pprObj.idx][fieldName] = pprObj[fieldName];
            this.devProgData[pprObj.idx].changed = true;
        }

    }
    // ------
    public onSaveReason() {
        if (this.$refs.reasonInputForm) {
            console.log('this.$refs.reasonInputForm')
            this.$refs.reasonInputForm.onSave()
        }
    }

    // close reason input
    private onReasonBack() {
        this.showReasonInput = false;
        this.$emit('onCloseReasons');
    }
    private async dataNotSaved() {
        let returnVal = false
        await this.$bvModal.msgBoxConfirm(
            this.$t('modules.budget_execution_monitoring.reports.app_9.unsaved_data_warning').toString(),
            {
                title: this.$t('modules.budget_execution_monitoring.reports.app_9.confirmation').toString(),
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.yes').toString(),
                cancelTitle: this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.cancellation').toString(),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    returnVal = true
                } else {
                    returnVal = false
                }
            })
            .catch(error => {
                this.makeToast('danger', this.$t('modules.budget_execution_monitoring.reports.app_9.save_error'), error.toString());
            });
        return returnVal
    }
    public async onBack() {
        if (this.currProgTreeData.concat(this.devProgTreeData).some(cbc => cbc.hasChanges)) {
            return await this.dataNotSaved()
        } else {
            return true
        }
    }

    // ------

    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private nextInput(event: any) {
        event.preventDefault();
        // Isolate the node that we're after
        const currentNode = event.target;
        // find all tab-able elements
        const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
        // Find the current tab index.
        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
        if (!event.target.value) {
            event.target.value = 0;
        }
        // select/focus the following element
        const targetIndex = (currentIndex + 1) % allElements.length;
        if (targetIndex < allElements.length) {
            allElements[targetIndex].select();
        }
    }// enter работает как tab

    private getNextInputIndx(prgIdx: number, pprIdx: number, spfIndx: number, chldIndx: number | null) {
    }
    // ---------

    private inputFocus(event: any) {
        event.target.select();
    }

    private inputFixed(item: any, field: any, value: any, digit: any) {
        item.hasChanges = true
        if (value) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        } else {
            this.$set(item, field, 0);
        }
    }
}
