








































































































import { calcProgn } from '@/modules/budget-request/components/js/budgetCalcProgn';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/services/store';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';

@Component({
    name: 'c-bud-sum-calc-tab',
    components: {
    }
})
export default class CBudgetSumCalcTab extends Vue {
    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        required: false,
        default: true
    })
    private edited!: boolean;

    private editable0: boolean | null = null;
    private editable1: boolean | null = null;

    private progress = 0;

    private curYear = (new Date()).getFullYear();

    private curDataType: string | null = null;
    private curGr: number | null = null;
    private curAbp: number | null = null;
    private curGu: string | null = null;
    private curProg: number | null = null;
    private curSubProg: number | null = null;
    private region = '450101';
    private variantEndDate: any | null = null;
    private variantStartDate: any | null = null;

    private calcData: any = [];
    private curVariant: string | null = null;
    private versYear: number | null = null;

    private totalSum: any = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };

    private newRowData: any = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0 };

    // --------------Спецификация --------
    private spfMap: Map<number, any> = new Map();
    private spfBaseMap: Map<number, any> = new Map(); // map для всех spf, value = []
    private spfBase: any[] = [];
    private curSpf: any | null = null;

    private getSpfLst(): any[] {
        const newArr = JSON.parse(JSON.stringify(Array.from(this.spfMap.values())));
        for (const el of this.calcData) {
            for (let i=0; i< newArr.length; i++) {
                if (el.consExp.spf === newArr[i].spf) {
                    newArr.splice(i, 1);
                    break;
                }
            }
        }
        return newArr;
    }

    private get spfLst() {
        const newArr = this.getSpfLst();
        const result: any[] = [];
        for (const el of newArr) {
            result.push(setNameLang(this.$i18n.locale, el, 'spf'));
        }
        // if (this.curRegion !== null && !this.curRegion.name) { this.curRegion = setNameLang(this.$i18n.locale, this.curRegion, 'code'); }
        return result;
    }

    private async loadEditable(spf: number) {
        const params = {spf: spf, curYear: this.curYear}
        try {
            const response: any = await fetch(`/api-py/get-budget-sum-calc-spf-editable/${encodeURI(JSON.stringify(params))}`);
            const result = await response.json();
            return result;
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-sum-calc-spf-editable', (error as Error).toString());
            this.progress = 0;
            return {editable0: false, editable1: false};
        }
    }


    // загрузка данных
    private async loadData() {
        this.progress = 25;
        this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };
        if (!this.curVariant) {
            makeToast(this, 'warning', 'Загрузка данных', 'Не выбрана версия бюджета!');
            this.progress = 0;
            return;
        }
        const mapConsol = new Map();
        this.calcData = [];
        const calcData: any[] = [];
        let result: any = null;
        if (!this.curGu) {
            makeToast(this, 'warning', 'Загрузка данных', 'Не выбрано гос. учреждение!');
            this.progress = 0;
            return;
        }
        const params = JSON.stringify({ curYear: this.curYear, abp: this.curAbp, dataType: this.curDataType, gr: this.curGr, gu: this.curGu, prg: this.curProg, ppr: this.curSubProg, region: this.region, variant: this.curVariant });
        try {
            const response: any = await fetch(`/api-py/get-budget-consolidate-expens/${encodeURI(params)}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens', (error as Error).toString());
            this.progress = 0;
            return;
        }
        for (const el of result) {
            mapConsol.set(el.spf, el);
        }
        this.progress = 75;
        // ------------------------------------
        result = null;
        try {
            const response: any = await fetch(`/api-py/get-req-total/${encodeURI(params)}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-req-total', (error as Error).toString());
            this.progress = 0;
            return;
        }
        const newMap = new Map();
        for (const el of result) {
            const fieldName = this.getSumFieldName(el.year);
            const findObj = newMap.get(el.spf);
            if (el.form || (el.form === null && el.bip_code !== null)) { // проверка на form = null или если bip
                if (findObj === undefined) {
                    const newObj: any = { prog0: new Map(), prog1: new Map(), prog2: new Map(), trash: new Map(), editable0: el.editable0, editable1: el.editable1 };
                    newObj[fieldName].set(el.form, el);
                    newMap.set(el.spf, newObj);
                } else if (findObj[fieldName]) {
                    if (el.form === null && findObj[fieldName].get(null)) {
                        el.value += findObj[fieldName].get(null).value;
                    }
                    findObj[fieldName].set(el.form, el);
                    newMap.set(el.spf, findObj);
                }
            }
        }
        for (const [key, value] of newMap) {
            const obj: any = { editable0: value.editable0, editable1: value.editable1 };
            if (mapConsol.get(key) !== undefined) {
                obj.consExp = mapConsol.get(key);
                obj.consExpOldData = JSON.parse(JSON.stringify(obj.consExp));
                mapConsol.delete(key);
            } else {
                obj.consExp = { id: -1, 'cur_year': this.curYear, 'data_type': this.curDataType, abp: this.curAbp, gr: this.curGr, gu: this.curGu, prg: this.curProg, ppr: this.curSubProg, spf: key, 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0 };
            }
            for (const colName of Object.keys(value)) {
                if (colName !== 'trash' && colName !== 'editable0' && colName !== 'editable1') {
                    const v = calcProgn(value[colName], key);
                    obj[colName] = v;
                    if (v !== null) { this.totalSum[colName] = Math.round((this.totalSum[colName] + v) * 100) / 100; }
                }
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.totalSum.kass_ras = Math.round((this.totalSum.kass_ras + obj.consExp.kass_ras) * 100) / 100;
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.totalSum.fact_ras = Math.round((this.totalSum.fact_ras + obj.consExp.fact_ras) * 100) / 100;
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.totalSum.utoch_plan = Math.round((this.totalSum.utoch_plan + obj.consExp.utoch_plan) * 100) / 100;
            calcData.push(obj);
        }

        for (const [key, value] of mapConsol) {
            const obj = { prog0: null, prog1: null, prog2: null, consExp: {}, consExpOldData: {}, editable0: value.editable0, editable1: value.editable1 };
            obj.consExp = { id: value.id, 'cur_year': value.cur_year, 'data_type': value.data_type, abp: value.abp, gr: value.gr, gu: value.gu, prg: value.prg, ppr: value.ppr, spf: value.spf, 'kass_ras': value.kass_ras, 'fact_ras': value.fact_ras, 'utoch_plan': value.utoch_plan };
            obj.consExpOldData = JSON.parse(JSON.stringify(obj.consExp));
            calcData.push(obj);
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (value.kass_ras) { this.totalSum.kass_ras = Math.round((this.totalSum.kass_ras + value.kass_ras) * 100) / 100; }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (value.fact_ras) { this.totalSum.fact_ras = Math.round((this.totalSum.fact_ras + value.fact_ras) * 100) / 100; }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (value.utoch_plan) { this.totalSum.utoch_plan = Math.round((this.totalSum.utoch_plan + value.utoch_plan) * 100) / 100; }
        }
        for (const el of calcData) {
            //this.editableYearBySpf(el);
            el.consExp.spfObj = this.spfMap.get(el.consExp.spf);
        }
        calcData.sort((a: any, b: any) => (a.consExp.spf > b.consExp.spf) ? 1 : -1);

        this.calcData = calcData;
        this.progress = 100;
        makeToast(this, 'success', 'Загрузка', 'Данные загружены');
        this.curSpf = null;
        this.newRowData = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0 };
    }

    /**
     * проверить доступно ли редактирование по году и дате действия spf
     * @param el
     */
    private editableYearBySpf(el: any) {
        el.consExp.spfObj = this.spfMap.get(el.consExp.spf);
        if (!el.consExp.spfObj) { return; }
        el.editable0 = false;
        el.editable1 = false;
        const spfArr = this.spfBaseMap.get(el.consExp.spf);
        for (let i = 0; i < spfArr.length; i++) {
            if (el.editable0 && el.editable1) { break; }
            const bDate = spfArr[i].beg_date ? new Date(spfArr[i].beg_date): null;
            const eDate = spfArr[i].end_date ? new Date(spfArr[i].end_date): null;
            if (!bDate && !eDate) {
                el.editable0 = true;
                el.editable1 = true;
                return;
            }
            const date0 = new Date(`${this.curYear-2}-12-31`);
            if ((!bDate || bDate <= date0) && (!eDate || eDate >= date0)) { el.editable0 = true; }
            const year1 = this.curYear - 1;
            if ((!bDate || bDate.getFullYear() <= year1) && (!eDate || eDate.getFullYear() >= year1)) { el.editable1 = true; }
        }
    }

    private async chgSpf() {
        this.editable0 = null;
        this.editable1 = null;
        if (!this.curSpf) { return; }
        /*const obj: any = {consExp: {spf: this.curSpf.spf}};
        //this.editableYearBySpf(obj);
        this.editable0 = obj.editable0;
        this.editable1 = obj.editable1; */
        const editObj: any = await this.loadEditable(this.curSpf.spf);
        this.editable0 = editObj.editable0;
        this.editable1 = editObj.editable1;
        this.newRowData = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0 };
    }


    // получение имени поля прогноза (trash - не подходящий год)
    private getSumFieldName(year: number): string {
        if (year - this.curYear < 0 || year - this.curYear > 3) { return 'trash'; }
        return `prog${(year - this.curYear)}`;
    }

    // загрузка спецификаций
    private async loadSpf() {
        let result: any = [];
        this.spfMap = new Map();
        this.spfBaseMap = new Map();
        try {
            const params = { date_b: `${this.curYear-2}-12-31`, date_e: this.variantEndDate, vers_year: this.versYear };
            const response: any = await fetch(`/api-py/dict-ebk-all-spf-actual/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
            this.spfBase = result;
            for (const el of result) {
                if (el.spf !== 0) {
                    // --
                    let spfArr: any[] = this.spfBaseMap.get(el.spf);
                    if (!spfArr) { spfArr = []; }
                    spfArr.push(el);
                    this.spfBaseMap.set(el.spf, spfArr);
                    // --
                    const spfEl = this.spfMap.get(el.spf);
                    if (!spfEl  || (spfEl.end_date && (!el.end_date || new Date (el.end_date) > new Date (spfEl.end_date)))) {
                        this.spfMap.set(el.spf, el);
                    }
                }
            }
        } catch (error) {
            this.spfBase = [];
            this.spfMap = new Map();
            makeToast(this, 'danger', 'Ошибка запроса регионов', (error as Error).toString());
        }
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }


    private created() {
        this.$watch('filter', this.chgFilter);
        this.$watch('curSpf', this.chgSpf);
    }

    private async chgFilter() {
        this.curAbp = this.filter.abp.abp;
        this.region = this.filter.region.code;
        this.curYear = parseInt(this.filter.curYear);
        this.curDataType = this.filter.dataType.code;
        this.curGr = this.filter.gr;
        this.variantEndDate = this.filter.variantEndDate;
        this.versYear = null;
        if (this.filter.curVariant) {
            this.curVariant = this.filter.curVariant.variant_uuid;
            this.variantStartDate = this.filter.curVariant.date_start;
            this.versYear = (this.curDataType == '2' ? this.filter.curVariant.year: null);
        }
        if (this.filter.gu !== null) { this.curGu = this.filter.gu.code; } else { this.curGu = null; }
        if (this.filter.prg !== null) { this.curProg = this.filter.prg.prg; } else { this.curProg = null; }
        if (this.filter.ppr !== null) { this.curSubProg = this.filter.ppr.ppr; } else { this.curSubProg = null; }
        this.calcData = [];
        await this.loadSpf();
        this.loadData();
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined || txt === null) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined && obj[codeName] !== null) {
            txt = `${obj[codeName]}  -  ${txt}`;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private async save() {
        const saveObj: any[] = [];
        if (this.curSpf && (this.newRowData.kass_ras || this.newRowData.fact_ras || this.newRowData.utoch_plan)) {
            this.addRow();
        }
        for (const el of this.calcData) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.consExp.fact_ras = parseFloat(el.consExp.fact_ras);
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.consExp.utoch_plan = parseFloat(el.consExp.utoch_plan);
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.consExp.kass_ras = parseFloat(el.consExp.kass_ras);
            if (el.consExp.id < 0) {
                if (!(!el.consExp.fact_ras && !el.consExp.utoch_plan && !el.consExp.kass_ras)) {
                    saveObj.push(Object.assign({ region: this.region, userId: this.usrId }, el.consExp));
                }
            } else if (this.isChanged(el)) {
                saveObj.push(Object.assign({ region: this.region, userId: this.usrId }, el.consExp));
            }
        }
        if (!saveObj.length) {
            makeToast(this, 'warning', 'Сохранение', 'Нет изменений для сохранения');
            this.loadData();
            return;
        }
        try {
            const url = '/api-py/budget-consolidate-expens-save';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(saveObj) // body data type must match "Content-Type" header
            });
            response = await response.json();
            let success = true;
            for (const r of response) {
                if (r.result !== 'success') {
                    success = false;
                    makeToast(this, 'danger', 'Сообщение', `Сохранение ${response[0].result}`);
                }
            }
            if (success) { makeToast(this, 'success', 'Сообщение', 'Данные сохранены'); }
            this.loadData();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка сохранения', (error as Error).toString());
        }
    }

    private isChanged(el: any): boolean { // проверка изменений
        // eslint-disable-next-line @typescript-eslint/camelcase
        el.consExp.fact_ras = parseFloat(el.consExp.fact_ras);
        // eslint-disable-next-line @typescript-eslint/camelcase
        el.consExp.utoch_plan = parseFloat(el.consExp.utoch_plan);
        // eslint-disable-next-line @typescript-eslint/camelcase
        el.consExp.kass_ras = parseFloat(el.consExp.kass_ras);

        // eslint-disable-next-line @typescript-eslint/camelcase
        el.consExpOldData.fact_ras = parseFloat(el.consExpOldData.fact_ras);
        // eslint-disable-next-line @typescript-eslint/camelcase
        el.consExpOldData.utoch_plan = parseFloat(el.consExpOldData.utoch_plan);
        // eslint-disable-next-line @typescript-eslint/camelcase
        el.consExpOldData.kass_ras = parseFloat(el.consExpOldData.kass_ras);

        if (el.consExp.fact_ras !== el.consExpOldData.fact_ras) { return true; }
        if (el.consExp.utoch_plan !== el.consExpOldData.utoch_plan) { return true; }
        if (el.consExp.kass_ras !== el.consExpOldData.kass_ras) { return true; }
        return false;
    }

    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private chgValue(obj: any, fieldName: string) {
        if (parseFloat(String(obj[fieldName])).isNaN) { obj[fieldName] = 0; }
        obj[fieldName] = Math.round(parseFloat(String(obj[fieldName])) * 100) / 100;
        this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };
        for (const el of this.calcData) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.totalSum.kass_ras += Math.round(parseFloat(el.consExp.kass_ras) * 100) / 100;
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.totalSum.fact_ras += Math.round(parseFloat(el.consExp.fact_ras) * 100) / 100;
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.totalSum.utoch_plan += Math.round(parseFloat(el.consExp.utoch_plan) * 100) / 100;
            if (el.consExp.prog1) { this.totalSum.prog1 += Math.round(parseFloat(el.consExp.prog1) * 100) / 100; }
            if (el.consExp.prog2) { this.totalSum.prog2 += Math.round(parseFloat(el.consExp.prog2) * 100) / 100; }
            if (el.consExp.prog0) { this.totalSum.prog0 += Math.round(parseFloat(el.consExp.prog0) * 100) / 100; }
        }
    }

    private chgAddVal(fieldName: string) {
        if (parseFloat(String(this.newRowData[fieldName])).isNaN) { this.newRowData[fieldName] = 0; }
        this.newRowData[fieldName] = Math.round(parseFloat(String(this.newRowData[fieldName])) * 100) / 100;
    }

    private addRow() {
        if (!this.curSpf) {
            makeToast(this, 'warning', 'Сообщение', 'Выберите спецификацию');
            return;
        }
        if (!this.editable0) {
            this.newRowData.kass_ras = 0;
            this.newRowData.fact_ras = 0;
        }
        if (!this.editable1) { this.newRowData.utoch_plan = 0; };
        const obj: any = { prog0: null, prog1: null, prog2: null, consExp: {}, editable0: this.editable0, editable1: this.editable1 };
        obj.consExp = { id: -1, 'cur_year': this.curYear, 'data_type': this.curDataType, abp: this.curAbp, gr: this.curGr, gu: this.curGu, prg: this.curProg, ppr: this.curSubProg, spf: this.curSpf.spf, 'kass_ras': this.newRowData.kass_ras, 'fact_ras': this.newRowData.fact_ras, 'utoch_plan': this.newRowData.utoch_plan };
        // this.editableYearBySpf(obj);
        obj.consExp.spfObj = this.spfMap.get(obj.consExp.spf);
        this.calcData.push(obj);
        this.curSpf = null;
        this.newRowData = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0 };
    }
}
