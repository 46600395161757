<template>
    <div class="table-container">
        <b-table
            :fields="tableFields"
            :items="budgetForm"
            responsive="true"
            bordered
            head-variant="light"
            no-border-collapse
        >
            <template #top-row="data">
                <td class="td-numbering"></td>
                <td class="td-numbering table-success">1</td>
                <td class="td-numbering table-danger">2</td>
                <td class="td-numbering table-info">3</td>
                <td class="td-numbering table-primary">4</td>
                <td class="td-numbering table-warning">5</td>
                <td class="td-numbering table-success">6</td>
                <td class="td-numbering table-danger">7</td>
                <td class="td-numbering table-info">8</td>
                <td class="td-numbering"></td>
            </template>
            <template #head(action)>
                <div class="text-center">
                    <b-form-checkbox
                        v-model="selectAll"
                        @change="onSelectAllChanged"
                        :value="true"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #head(more)>
                <div class="text-center">
                    <i
                        :title="getCommonText('del_selected')"
                        class="icon icon-close table-all-remove"
                        @click="$emit('deleteItemWithAttachedFiles', `${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                </div>
            </template>
            <template #cell(action)="data">
                <b-form-checkbox
                    v-model="data.item.itemToDelete"
                    @input="e => { if (!e) selectAll = false; }"
                    :value="true"
                    :unchecked-value="false" />
            </template>
            <template #cell(area)="data">
                <div class="error">
                    <b-form-input v-if="variantAttribute" class="text-right"
                        :value="data.item.area"
                        @change="v => data.item.area = v"
                        @keyup.enter.exact="$emit('keyup13')"
                        @keypress="$emit('keyPress', $event, '^\\d*\\.?\\d{0,9}$')"
                        @blur="onBlur(data.item, 'area', data.item.area, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.area === null || data.item.area === '' || data.item.area <= 0)">{{ getCommonText("positive") }}</p>
                    
                    <template v-if="(data.item.isHasDouble)">
                        <i class="icon icon-danger decodes-error-icon"></i>
                        <div class="pop-up">
                            <p class="red-text">{{ getCommonText("not_valid") }}</p>
                            <p>{{ getCommonText("exist") }}</p>
                        </div>
                    </template>
                </div>
            </template>
            <template #cell(cost_avg)="data">
                <b-form-input v-if="variantAttribute"
                                class="text-right"
                                :value="data.item.cost_avg"
                                @change="v => data.item.cost_avg = v"
                                @keyup.enter.exact="$emit('keyup13')"
                                @keypress="$emit('keyPress', $event, '^\\d*\\.?\\d{0,9}$')"
                                @blur="onBlur(data.item, 'cost_avg', data.item.cost_avg, 2)">
                </b-form-input>
                <div v-else>{{ data.value }}</div>
                <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost_avf === null || data.item.cost_avg === '' || data.item.cost_avg <= 0)">{{ getCommonText("positive") }}</p>
            </template>
            <template #cell(season)="data">
                <div v-if="variantAttribute" :class="{ 'error': data.item.season > 12 }">
                    <b-form-input class="text-right"
                                    :value="data.item.season"
                                    @change="v => data.item.season = v"
                                    @keyup.enter.exact="$emit('keyup13')"
                                    @keypress="$emit('keyPress', $event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="$emit('inputFixedVldtn', data.item, 'season', data.item.season, 1)">
                    </b-form-input>
                    <template v-if="data.item.season > 12">
                        <i class="icon icon-danger"></i>
                        <div class="pop-up">
                            <p class="red-text">{{ getCommonText('not_valid') }}</p>
                            <p>{{ getFormText('heating_period_less_12') }}</p>
                        </div>
                    </template>
                </div>
                <div v-else>{{ data.value }}</div>
                <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.season === null || data.item.season === '' || data.item.season <= 0)">{{ getCommonText("positive") }}</p>
            </template>
            <template #cell(correction_factors)="data">
            <b-form-input v-if="variantAttribute" class="text-right"
                        :value="data.item.correction_factors"
                        @change="v => data.item.correction_factors = v"
                        @keyup.enter.exact="$emit('keyup13')"
                        @keypress="$emit('keyPress', $event, '^\\d*\\.?\\-?\\d{0,9}$')"
                        @blur="$emit('inputFixedVldtn', data.item, 'correction_factors', data.item.correction_factors, 2)">
            </b-form-input>
                <div v-else>{{ data.value }}</div>
            </template>
            <template #cell()="data">
                <div class="text-right">{{ $n(data.value) }}</div>
            </template>
            <template #cell(note)="data">
                <div v-if="variantAttribute" :class="{ 'error': ((data.item.note && data.item.note.length > 255)
                || (data.item.correction_factors !== 0 && data.item.note.trim().length === 0)) }">
                    <div class="note-box">
                        <b-form-input
                            class="text-right"
                            style="min-width: 90%"
                            :value="data.item.note"
                            @change="v => data.item.note = v"
                            @keyup.enter.exact="$emit('keyup13')">
                        </b-form-input>
                        <i v-if="variantAttribute"
                            class="icon icon-more thin-blue pointer icon-18 icon-rotate-90" 
                            @click="$emit('onEditNote', data.item)">
                        </i>
                    </div>
                    <template v-if="(data.item.note && data.item.note.length > 255)">
                        <i class="icon icon-danger"></i>
                        <div class="pop-up">
                            <p class="red-text">{{ getCommonText('not_valid') }}</p>
                            <p>{{ getCommonText('len_limit_255') }}</p>
                        </div>
                    </template>
                    <template v-else-if="(data.item.correction_factors !== 0 && data.item.note.trim().length === 0)">
                        <i class="icon icon-danger"></i>
                        <div class="pop-up">
                            <p class="red-text">{{ getCommonText('not_valid') }}</p>
                            <p>{{ getCommonText('required') }}</p>
                        </div>
                    </template>
                </div>
                <div v-else>{{ data.value }}</div>
            </template>
            <template #cell(files)="data">
                <span
                    class="blue pointer underline"
                    @click="$emit('onFilesClick', data.item)"
                >({{data.item.files}})</span>
            </template>
            <template #cell(more)="data">
                <div class="text-center">
                    <i 
                        :title="getCommonText('del_rec')" 
                        class="icon icon-clear table-remove" 
                        v-if="variantAttribute" 
                        @click="$emit('deleteItemWithAttachedFiles', `${getCommonText('del_rec')}?`, data.item, data.index)"
                    ></i>
                </div>
            </template>
            <template #bottom-row="data">
                <td class="text-right" colspan="6">{{ getCommonText('total') }}</td>
                <td class="text-right">{{ $n(total) }}</td>
                <td colspan="3"></td>
            </template>
        </b-table>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="$emit('addItem')"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-04-151-tab1',

    props: {
        budgetForm: {type: Array, required: true},
        getCommonText: {type: Function, required: true},
        getDecText: {type: Function, required: true},
        getUnitsText: {type: Function, required: true},
        setFormText: {type: Function, required: true},
        variantAttribute: {required: true},
        total: {type: Number, required: true},
        isLoad: {type: Boolean, required: true},
    },

    data() {
        return {
            selectAll: false
            };
    },

    methods: {
        onSelectAllChanged(val) {
            this.$emit('setSelectAll', val)
        },

        setSelectAll(value) {
            this.selectAll = value;
        },

        onBlur(item, colName, val, digs) {
            this.$emit('inputFixedVldtn', item, colName, val, digs);
            this.$emit('doublesValidation', this.budgetForm);
        }
    },
    
    computed: {
        getFormText() {
            return this.setFormText('form_n_151.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'area',
                        label: this.getFormText('heated_area')
                    },
                    {
                        key: 'cost_avg',
                        label: this.getFormText('avg_heat_cost')
                    },
                    {
                        key: 'amount',
                        label: this.getFormText('heated_area_expense')
                    },
                    {
                        key: 'season',
                        label: `${this.getFormText('heating_period')} (${this.getCommonText('months')})`
                    },
                    {
                        key: 'correction_factors',
                        label: `${this.getFormText('correction_sum')}  (+/- ${this.getUnitsText('thousand_tenge')})`
                    },
                    {
                        key: 'total',
                        label: this.getFormText('total_expense_sum')
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
}
</script>

<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .table-plus {
        border: none;
    }
    .note-box {
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .icon-more {
        align-self: center;
        position: static;
    }
</style>