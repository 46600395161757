import { render, staticRenderFns } from "./AlterationApplications.vue?vue&type=template&id=9f9adf64&scoped=true&"
import script from "./AlterationApplications.vue?vue&type=script&lang=js&"
export * from "./AlterationApplications.vue?vue&type=script&lang=js&"
import style0 from "./AlterationApplications.vue?vue&type=style&index=0&id=9f9adf64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f9adf64",
  null
  
)

export default component.exports