import { cloneDeep } from "lodash";
import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allMeasureUnitsOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions";
import { Classificator } from "../../common/types/Classificator";
import { Indicator } from "../../common/types/Indicator";
import { Template } from "../../common/types/Template";
import { ReportDviFilterSettings } from "./settings/ReportDviFilterSettings";
import { ReportDviFilterOptions } from "./options/ReportDviFilterOptions";
import { PaymentType } from "../../common/types/root/fields";
import { Option } from "../../common/types/Option";


export const reportDviExpensesClassificators: Classificator[] = [
    { key: 'gr',  active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: false, selected: [] },
    { key: 'prg', active: false, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] },
]

export const reportDviBudgetTypesOptions = cloneDeep(
    allBudgetTypesOptions.filter(it => [2, 3].includes(it.value))
)

export const reportDviPaymentTypesOptions: Option<PaymentType>[] = [
    { value: 'OBLIGATIONS', label: 'Обязательства' },
    { value: 'PAYMENTS', label: 'Платежи' }
]

export const reportDviIndicators: Indicator[] = []

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportDviFilterSettings = {
    // Base:
    reportName: 'DVI',
    reportType: 'REGULATED',
    classificationType: 'EXPENSES',
    dataSource: 'LOADER',
    periodicity: 'DAY',
    // Custom:
    date: '',
    budgetType: 2,
    paymentType: 'OBLIGATIONS',
    measureUnit: 'THOUSAND',
    roundUpTo: null,
    expenses: []
}

export const reportDviBaseTemplate: Template = {
    id: null,
    name: 'Динамика внесенных изменений в планы финансирования по обязательствам годовые изменения',
    description: null,
    category: '60',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const reportDviFilterOptions: ReportDviFilterOptions = {
    // Base:
    key: 'DVI',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    budgetTypes: cloneDeep(reportDviBudgetTypesOptions),
    paymentTypes: cloneDeep(reportDviPaymentTypesOptions),
    expenses: cloneDeep(reportDviExpensesClassificators)
}
