

























import { Component, Vue, Prop } from 'vue-property-decorator';
import axios from 'axios';

@Component({
    name: 'b-exec-agr-hist-modal'
})
export default class BExecAgrHistModal extends Vue {
    @Prop({
        required: false,
        default: false
    })
    private mode_code!:any;
    @Prop({
        required: false,
        default: false
    })
    private statusTypes!:any[];
    private agrObj:any = null;

    private showModal = false;
    private realmUsers:any[] = [];
    private histList:any[] = [];
    private async created() {
    }

    private async showEvent(agrObj: any) {
        if (agrObj === null) return;
        if (!this.realmUsers.length) {
            await this.loadExecutors()
        }
        this.agrObj = agrObj
        this.histList = Object.assign([], agrObj.status_history)
        if (agrObj.hasOwnProperty('status')) {
            this.histList.push(agrObj.status)
        }
        for (const his of this.histList) {
            his.userName = this.getLocalUser(his.user_id)
        }
        this.histList.sort((a, b) => b.create_date - a.create_date);
        this.showModal = true;
    }

    private getLocalStatus(value:any) {
        const status = value.status;
        const curStatus = this.statusTypes.filter((item) => item.code === status);
        if (!curStatus || !curStatus.length) return status;
        const localName = curStatus[0]["name_" + this.$i18n.locale];
        return localName;
    }

    private async loadExecutors() {
        await axios.get('/api-py/get-realm-users/')
            .then(resp => {
                if (resp.status === 200) {
                    this.realmUsers = resp.data
                }
            }).catch((error:any) => {})
    }

    private getLocalUser(user_id: any) {
        const infoAboutUser = this.realmUsers.find((d:any) => d.id === user_id)
        if (!infoAboutUser) {
            return ''
        }
        let name = ''
        if (infoAboutUser['lastName']) {
            name += infoAboutUser['lastName']
        }
        if (infoAboutUser['firstName']) {
            name += ' ' + infoAboutUser['firstName']
        }
        return name
    }

}
