



































































































































































































































































































































































































































































































































































































































































































































































































import VueElementLoading from "vue-element-loading"
import { cloneDeep } from "lodash"
import VueDraggable from 'vuedraggable'
import Datepicker from "vue2-datepicker"
import moment from 'moment'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Application2 from "@/modules/budget/monitoring/reports-constructor/reports/application-2/table-component/application-2.vue"
import Report127 from "@/modules/budget/monitoring/reports-constructor/reports/report-1-27/table-component/report-1-27.vue"
import Report420 from "@/modules/budget/monitoring/reports-constructor/reports/4-20/table-component/report-4-20.vue"
import ReportIRBO from "@/modules/budget/monitoring/reports-constructor/reports/report-irbo/table-component/report-irbo.vue"
import ReportDvi from "@/modules/budget/monitoring/reports-constructor/reports/dvi/table-component/report-dvi.vue"
import { getLastDateOfQuarter, getLastDateOfMonth } from '@/modules/budget/monitoring/reports-constructor/common/utils/dateUtils'
import { Classificator } from "../../common/types/Classificator"
import { TemplateCreateUpdateRequest } from "../../common/types/TemplateCreateUpdateRequest"
import { Template, TemplateForm } from "../../common/types/Template"
import { Option } from "../../common/types/Option"
import { FilterSettingsForm } from "../../common/types/root/FilterSettingsForm"
import { FilterOptions } from "../../common/types/root/FilterOptions"
import { Indicator } from "../../common/types/Indicator"
import { BudgetType, ExpenseType } from "../../common/types/root/fields"
import { allBudgetTypesOptions, allIncomeClassificators } from "../../common/baseOptions"
import { FilterSettings } from "../../common/types/root/FilterSettings"
import { makeToast } from "../../common/utils/otherUtils"
import { Facade } from "../../common/Facade"
import { Report127Facade } from "../../reports/report-1-27/facade/Report127Facade"
import { Application2Facade } from "../../reports/application-2/facade/Application2Facade"
import { TableData } from "../../common/types/root/TableData"
import { LoadToJournalRequest } from "../../common/types/root/LoadToJournalRequest"
import { Report420Facade } from "../../reports/4-20/facade/Report420Facade"
import { ReportIRBOFacade } from "../../reports/report-irbo/facade/ReportIRBOFacade"
import { ReportDviFacade } from "../../reports/dvi/facade/ReportDviFacade"

const personalCategoryOption = { label: 'Личные шаблоны', value: null }
const tempTemplateForm: TemplateForm = {
    id: null,
    name: '',
    description: null,
    category: { ...personalCategoryOption },
    regions: null
}
const emptyIncomeOptions: Record<string, number[]> = {
    kat: [],
    cls: [],
    pcl: [],
    spf: [],
}
const emptyExpenseOptions: Record<string, number[]> = {
    gr: [],
    pgr: [],
    abp: [],
    gu: [],
    prg: [],
    ppr: [],
    spf: [],
}

@Component({
    components: {
        'loading': VueElementLoading,
        'draggable': VueDraggable,
        'date-picker': Datepicker,
        'application-2': Application2,
        'report-1-27': Report127,
        'report-4-20': Report420,
        'report-irbo': ReportIRBO,
        'report-dvi': ReportDvi
    }
})
export default class FilterSettingsPanel extends Vue {
    // #region Props
    @Prop({
        type: Object,
        required: true
    })
    public readonly template!: Template

    @Prop({
        type: Function,
        required: true
    })
    public onSelectReport!: any
    // #endregion

    // #region Data
    public templateName = this.template.name
    private readonly moduleCode = '005.003.007'
    // Если тест/релиз то "00"
    private oblastKato: string = ''
    private facade: Facade | null = null
    public loading = false
    public regionLoading = false
    public isFilterPanelExpanded = true
    public saveAsNewModal = false
    public tableData: TableData | null = null
    public progress = 100
    private datePeriod: string | null = null

    public templateForm: TemplateForm = cloneDeep(tempTemplateForm)
    public templateFormOptions = {
        reportCategoryOptions: [] as Option<string | null>[],
        regionOptions: [] as Option<string>[]
    }

    public filterSettingsForm: FilterSettingsForm | null = null
    public filterOptions: FilterOptions | null = null

    public isAllSelectedTemplateRegion: boolean = false

    // Дублирующие переменные incomes/outcomes
    // нужны для того чтобы при драггинге (dragging)
    // компоненты отрисовки таблиц не ререндерился с новым порядком
    // и сохранял тот порядок который был при нажатий "Сформировать" 
    public incomesToPass: Classificator[] = []
    public expensesToPass: Classificator[] = []

    public categoryNameMap: Record<string, string> = {
        'kat': 'КАТ:',
        'cls': 'КЛ:',
        'pcl': 'ПКЛ:',
        'spf': 'СПФ:',
        'gr': 'ФГ:',
        'pgr': 'ФПГ:',
        'abp': 'АБП:',
        'gu': 'ГУ:',
        'prg': 'БП:',
        'ppr': 'БПП:'
    }

    public incomeOptions: Record<string, number[]> = cloneDeep(emptyIncomeOptions)
    public expenseOptions: Record<string, number[]> = cloneDeep(emptyExpenseOptions)

    // Чтобы сохранить параметры в момент нажатия 'сформировать',
    // нужно потому что показатели (indicators) задаются после формирования
    private journalParams: LoadToJournalRequest | null = null

    // Нужно чтобы затригерить re-render компонентов отрисовок таблиц
    public tableKey = 0

    private oblastCode: string = ''
    // #endregion

    // #region Computed
    get userId(): string {
        return this.$store.state.user.sub;
    }

    get username(): string {
        return this.$store.state.user.preferred_username
    }

    get hasEditAccess(): boolean {
        const accessLevel = this.$store.state.user.userModules.find((it: any) => it.modules === this.moduleCode)?.access_level
        return accessLevel >= 2
    }

    get isAdmin(): boolean {
        const accessLevel = this.$store.state.user.userModules.find((it: any) => it.modules === this.moduleCode)?.access_level
        return accessLevel == 3
    }

    get isStructuredBudgetPossible(): boolean {
        if (this.filterSettingsForm == null 
        || !('incomes' in this.filterSettingsForm) 
        || !('expenses' in this.filterSettingsForm)) {
            return false
        }
        let isPossible: boolean = true

        // 1. Проверка по выбранности (должны быть оба)
        const hasChosenIncome = this.filterSettingsForm.incomes.filter(it => it.active).length > 0
        const hasChosenExpense = this.filterSettingsForm.expenses.filter(it => it.active).length > 0
        if (!hasChosenIncome || !hasChosenExpense) {
            isPossible = false
        }

        // Side-effect костыль (чтобы не создавать watch)
        if (!isPossible) {
            if (this.filterSettingsForm?.key === '1-27' && this.filterOptions?.key === '1-27') {
                this.filterSettingsForm.budgetStructure = this.filterOptions.budgetStructures.find(it => it.value === 'WITHOUT') ?? null         
            }
        }

        return isPossible
    }

    get isSeparateByRegionCheckboxDisabled(): boolean {
        if (this.filterSettingsForm?.key !== '1-27') {
            return false
        }
        const form = this.filterSettingsForm

        const anyActiveIncomeClassificator = form.incomes.filter(it => it.active).length > 0
        const anyActiveExpenseClassificator = form.expenses.filter(it => it.active).length > 0

        if (anyActiveIncomeClassificator || anyActiveExpenseClassificator) {
            // Side-effect to uncheck 'В разрезе бюджета'
            this.filterSettingsForm.separateByRegion = false
            return true
        }
        return false
    }
    // #endregion

    // #region Lifecycles
    private async created() {
        try {
            this.loading = true

            this.setFacade()
            await this.setOblastKato()
            await this.setOblastCode()

            // Options
            await this.setTemplateRegionOptions()
            await this.setReportCategoryOptions()
            await this.setClassificationOptions()
            this.setFilterOptions()
            await this.setRegionOptionsOnCreate()

            // Forms
            this.setFilterSettingsForm()
            this.setTemplateForm()

            this.loading = false
        } catch (error) {
            this.loading = true
            if (error instanceof Error) {
                makeToast(this, error.message, 'Ошибка', 'danger')
                console.error(error.message)
            } else {
                makeToast(this, 'Ошибка загрузки', 'Ошибка', 'danger')
                console.error(error);
            }
        }
    }
    // #endregion

    // #region Methods
    private setFacade() {
        switch (this.template.filterSettings.reportName) {
            case 'PR2': this.facade = new Application2Facade(); break;
            case '1-27': this.facade = new Report127Facade(); break;
            case '4-20': this.facade = new Report420Facade(); break;
            case 'IRBO': this.facade = new ReportIRBOFacade(); break;
            case 'DVI': this.facade = new ReportDviFacade(); break;
            default: throw new Error('Не поддерживаемый исходный шаблон');
        }
    }

    private async setOblastKato() {
        const response = await fetch('/api/dict/server-description')
        if (!response.ok) {
            throw new Error('Ошибка загрузки данных о среде')
        }
        const serverDescription = await response.text()
        if (serverDescription === 'release' || this.$store.state._instanceCode === '') {
            this.oblastKato = '00'
        } else {
            this.oblastKato = this.$store.state._instanceCode
        }
    }

    private async setOblastCode() {
        const isTestOrNsi = this.oblastKato === '00' || this.oblastKato === '99'
        if (!isTestOrNsi) {
            const response = await fetch(`/api-py/monitoring/reports-constructor/tax-region/${this.oblastKato}`)
            if (!response.ok) {
                throw new Error('')
            }
            const oblastCode = await response.json()
            this.oblastCode = oblastCode
        }
    }

    public onTemplateRegionInput(currentValues: Option<string>[] | Option<string>) {
        const allRegionCode = '000000'

        // 'Отдельные регионы' -> 'Все'
        if (Array.isArray(currentValues)) {
            const lastSelected = currentValues[currentValues.length - 1]
            if (lastSelected.value === allRegionCode) {
                this.isAllSelectedTemplateRegion = true
                this.templateForm.regions = { ...lastSelected }
            }
            return
        }
        
        // 'Все' -> 'Отдельные регионы'
        const isObjectButNotArray = typeof currentValues === "object" && currentValues !== null && !Array.isArray(currentValues)
        const fromAllToSeparateRegions = isObjectButNotArray && currentValues.value !== allRegionCode
        if (fromAllToSeparateRegions) {
            this.isAllSelectedTemplateRegion = false
            this.templateForm.regions = [currentValues]
        }
    }

    public onBackToTemplatesList() {
        this.$emit("set-journal-params", null)
        this.onSelectReport(null)
    }

    public onExpenseTypeInput(selectedOption: Option<ExpenseType>) {
        if (this.filterSettingsForm == null || !('expenses' in this.filterSettingsForm)) {
            return
        }

        if (selectedOption.value === 'FUNCTIONAL') {
            const emptyForm = this.facade!.getEmptyForm()
            if ('expenses' in emptyForm) {
                const functionalExpenseClassificators = emptyForm.expenses
                this.filterSettingsForm.expenses = functionalExpenseClassificators
            }
        } else if (selectedOption.value === 'ECONOMICAL') {
            const economicalClassificators = cloneDeep(allIncomeClassificators)
            this.filterSettingsForm.expenses = economicalClassificators
        } else {
            throw new Error('not supported expenseType')
        }
    }

    public setBudgetTypeFromRegion(regionOptionsParam: Option<string>[] | Option<string> | null) {
        let regionOptions: Option<string>[] | Option<string> = []

        if (regionOptionsParam) {
            regionOptions = regionOptionsParam 
        }
        
        if (
            !this.filterSettingsForm
            || !this.filterOptions
            || !('budgetTypes' in this.filterOptions)
            || !('budgetTypes' in this.filterSettingsForm)
        ) {
            return
        }
        
        let regionOptionsArray: Option<string>[] = []
        if (Array.isArray(regionOptions)) {
            regionOptionsArray = regionOptions
        } else {
            regionOptionsArray = [regionOptions]
        }

        this.filterSettingsForm.budgetTypes = []
        // Проставить соответсвующий budget_type
        const budgetTypes: BudgetType[] = []
        regionOptionsArray.forEach(regionOption => {
            const code: string = regionOption.value
            if (['302001', '301901', '300401'].includes(code) && this.template.filterSettings.reportName === 'PR2') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
            } else if (code.substring(2) === '0000') {
                if (budgetTypes.notIncludes(2)) {
                    budgetTypes.push(2)
                }
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            } else if (code.substring(4) === '00') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            } else if (code.substring(2) === '0101') {
                if (budgetTypes.notIncludes(2)) {
                    budgetTypes.push(2)
                }
            } else if (code.substring(4) === '01') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
            } else if (code.substring(4) !== '01') {
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            }
        })

        const tempAllBudgetTypesOptions = cloneDeep(allBudgetTypesOptions) 
        const tempOptions: Option<BudgetType>[] = []
        budgetTypes.sort((a: number, b: number) => a - b).forEach((budget: number) => {
            const budgetTypeOption = tempAllBudgetTypesOptions.find(item => item.value === budget)!
            tempOptions.push({...budgetTypeOption})
        })

        this.filterOptions.budgetTypes = tempAllBudgetTypesOptions.filter(it => budgetTypes.includes(it.value))
        this.filterSettingsForm.budgetTypes = tempOptions
    }

    public async onYearChange() {
        if (!this.filterSettingsForm) {
            return
        }
        switch (this.filterSettingsForm.periodicity.value) {
            case 'QUARTER':
                await this.onYearOrQuarterChange()
                break
            case 'MONTH':
                await this.onYearOrMonthChange()
                break
            default:
        }
    }

    public async onYearOrQuarterChange() {
        if (this.filterSettingsForm == null || this.filterOptions == null) {
            return
        }
        if (!('quarter' in this.filterSettingsForm)) {
            return
        }
        if (!('regions' in this.filterOptions)) {
            return
        }
        if (!this.filterSettingsForm.quarter?.value) {
            return
        }

        this.datePeriod = null

        const year = this.filterSettingsForm.year
        const quarter = this.filterSettingsForm.quarter.value
        if (!year || !quarter) {
            return
        }
        this.datePeriod = getLastDateOfQuarter(year, quarter)
        await this.setRegionOptions()
        if ('region' in this.filterSettingsForm) {
            // @ts-ignore
            const region = this.filterSettingsForm.region as Option<string> | null
            this.setBudgetTypeFromRegion(region)
        } 
        if ('regions' in this.filterSettingsForm) {
            // @ts-ignore
            const regions: Option<string>[] = this.filterSettingsForm.regions as Option<string>[]
            this.setBudgetTypeFromRegion(regions)
        }
    }

    public async onYearOrMonthChange() {
        if (this.filterSettingsForm == null || this.filterOptions == null || !('regions' in this.filterOptions) || !('month' in this.filterSettingsForm)) {
            return
        }

        this.datePeriod = null
        const year = this.filterSettingsForm.year
        const month = (this.filterSettingsForm.month as (Option<number> | undefined))?.value
        if (!year || !month) {
            return
        }
        this.datePeriod = getLastDateOfMonth(year, month)
        await this.setRegionOptions()
        if ('region' in this.filterSettingsForm) {
            // @ts-ignore
            const region = this.filterSettingsForm.region as Option<string> | null
            this.setBudgetTypeFromRegion(region)
        } 
        if ('regions' in this.filterSettingsForm) {
            // @ts-ignore
            const regions: Option<string>[] = this.filterSettingsForm.regions as Option<string>[]
            this.setBudgetTypeFromRegion(regions)
        }
    }

    public async onDateChange() {
        if (this.filterSettingsForm == null 
        || this.filterOptions == null 
        || !('regions' in this.filterOptions) 
        || !('date' in this.filterSettingsForm)
        || !('region' in this.filterSettingsForm)) {
            return
        }

        this.datePeriod = null
        const date = this.filterSettingsForm.date
        if (!date) {
            return
        }

        const strDate = moment(date).format('YYYY-MM-DD')
        this.datePeriod = strDate
        await this.setRegionOptions()
        if ('region' in this.filterSettingsForm) {
            // @ts-ignore
            const region = this.filterSettingsForm.region as Option<string> | null
            this.setBudgetTypeFromRegion(region)
        } 
        if ('regions' in this.filterSettingsForm) {
            // @ts-ignore
            const regions: Option<string>[] = this.filterSettingsForm.regions as Option<string>[]
            this.setBudgetTypeFromRegion(regions)
        }
    }

    public async onDateInput(date: Date) {
        await this.onDateChange()
    }

    // Поскольу настройка порядка/исключения индикатора находится
    // в дочернем компоненте отрисовки таблиц, а не в самом filter-settings-panel,
    // при каждом изменений индикаторов в дочернем компоненте, надо обновлять параметры
    // выгрузки в журнал через этот метод
    public updateIndicators(newIndicators: Indicator[]) {
        if (this.journalParams == null || !('indicators_order' in this.journalParams)) {
            return
        }
        if (!this.filterSettingsForm || !('indicators' in this.filterSettingsForm)) {
            return
        }

        this.filterSettingsForm.indicators = cloneDeep(newIndicators)
        const newOrder = newIndicators.filter(item => item.active).map(item => item.key)
        this.journalParams.indicators_order = newOrder
        this.$emit("set-journal-params", cloneDeep(this.journalParams))
    }

    private async setClassificationOptions() {
        if (['INCOMES', 'MIXED'].includes(this.template.filterSettings.classificationType)) {
            const incomeResponse = await fetch('/api-py/monitoring/reports-constructor/income-options')
            if (!incomeResponse.ok) {
                makeToast(this, 'Не удалось получить данные для выбора доходов', 'Ошибка', 'danger')
                return
            }
            const incomeData = await incomeResponse.json()
            for (const key in incomeData) {
                this.incomeOptions[key] = incomeData[key]
            }
        }

        if (['EXPENSES', 'MIXED'].includes(this.template.filterSettings.classificationType)) {
            const expenseResponse = await fetch(`/api-py/monitoring/reports-constructor/expense-options/${this.template.filterSettings.reportName}`)
            if (!expenseResponse.ok) {
                makeToast(this, 'Не удалось получить данные для выбора расходов', 'Ошибка', 'danger')
                return
            }
            const expenseData = await expenseResponse.json()

            for (const key in expenseData) {
                this.expenseOptions[key] = expenseData[key]
            }
        }
    }

    // Промежуточный метод для setRegionOptions
    // поскольку при созданий компонента год и квартал берутся с props template 
    private async setRegionOptionsOnCreate() {
        if (!('periodicity' in this.template.filterSettings)) {
            return
        }

        const resetRegionAndDatePeriod = () => {
            this.datePeriod = null
            if (this.filterSettingsForm != null) {
                if ('regions' in this.filterSettingsForm) {
                    this.filterSettingsForm.regions = []
                } else if ('region' in this.filterSettingsForm) {
                    this.filterSettingsForm.region = null
                }
            }
        }

        if ('month' in this.template.filterSettings && this.template.filterSettings.periodicity === 'MONTH') {
            const year = this.template.filterSettings.year
            const month = Number(this.template.filterSettings.month)
            if (year && month) {
                this.datePeriod = getLastDateOfMonth(year, month)
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
        else if ('quarter' in this.template.filterSettings && this.template.filterSettings.periodicity === 'QUARTER') {
            const year = this.template.filterSettings.year
            const quarter = Number(this.template.filterSettings.quarter)
            if (year && quarter) {
                this.datePeriod = getLastDateOfQuarter(year, quarter)
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
        else if ('date' in this.template.filterSettings && this.template.filterSettings.periodicity === 'DAY') {
            const date = this.template.filterSettings.date
            if (date) {
                this.datePeriod = date
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
    }

    private async setTemplateRegionOptions() {
        const response = await fetch(`/api-py/monitoring/reports-constructor/template-region-options/${this.oblastKato}`)
        if (!response.ok) {
            throw new Error('Ошибка запроса получения регионов')
        }

        const regionData = await response.json()

        const options: Option<string>[] = regionData.map((it: any) => {
            const option: Option<string> = { value: it.code, label: `${it.code} - ${it.name_ru}` }
            return option
        })
        options.unshift({ value: '000000', label: '000000 - Все' })

        this.templateFormOptions.regionOptions = options
    }

    private async setReportCategoryOptions() {
        const response = await fetch('/api-py/monitoring/reports-constructor/report-categories')
        if (!response.ok) {
            throw new Error('Ошибка запроса получения категорий отчетов')
        }
        const categoriesData = await response.json()

        const options: Option<string | null>[] = categoriesData.map((it: any) => ({ 
            label: it.name_ru, value: it.code
        }))
        options.push({ ...personalCategoryOption })

        // Админу доступно все, другим доступно только в личных
        const accessibleOptions = options.filter(it => {
            if (this.isAdmin) {
                return true
            }
            const isOnlyPersonal = it.value === null
            return isOnlyPersonal
        })

        this.templateFormOptions.reportCategoryOptions = accessibleOptions
    }

    private async setRegionOptions() {
        if (
            !this.datePeriod
            || this.filterOptions == null 
            || !('regions' in this.filterOptions)
            || !this.filterSettingsForm
        ) {
            return
        }

        this.regionLoading = true
        try {
            const response = await fetch(`/api-py/monitoring/reports-constructor/regions/${this.userId}/${this.datePeriod}`)
            if (!response.ok) {
                throw new Error('Ошибка загрузки регионов')
            }
            const data = await response.json()

            const regions: Option<string>[] = data.map((it: any) => ({
                value: it.code,
                label: `${it.code} - ${it.name_ru}`
            }))

            this.filterOptions.regions = regions

            if ('region' in this.filterSettingsForm) {
                const regionCode = this.filterSettingsForm.region?.value
                const option: Option<string> | null = this.filterOptions.regions.find(it => it.value === regionCode) ?? null
                this.filterSettingsForm.region = option
            } 
            else if ('regions' in this.filterSettingsForm) {
                const regionCodes = this.filterSettingsForm.regions.map(it => it.value)
                const options = this.filterOptions.regions.filter(it => regionCodes.includes(it.value))
                this.filterSettingsForm.regions = options
            }
        } catch (error) {
            makeToast(this, 'Не удалось загрузить регионы', 'Ошибка', 'danger')
        } finally {
            this.regionLoading = false
        }
    }

    private setFilterOptions() {
        this.filterOptions = this.facade!.getFilterOptions()
    }

    private async setFilterSettingsForm() {
        if (this.filterOptions == null) {
            throw new Error('Не загружены данные для фильтров')
        }

        let form: FilterSettingsForm | null = null
        const isNewForm = !this.template.id
        if (isNewForm) {
            form = this.facade!.getEmptyForm()
        } else {
            form = this.facade!.settingsToForm(
                cloneDeep(this.template.filterSettings),
                cloneDeep(this.filterOptions)
            )
        }

        this.filterSettingsForm = form
    }

    private setTemplateForm() {
        const allRegionCode = '000000'
        if (this.template.id) {

            let tempRegions: Option<string>[] | Option<string> | null = null
            if (this.template.regions.includes(allRegionCode)) {
                const allRegionOption = this.templateFormOptions.regionOptions.find(it => it.value = allRegionCode)!
                tempRegions = allRegionOption
            }
            else {
                const selectedOptions = this.templateFormOptions.regionOptions.filter(it => this.template.regions.includes(it.value))
                tempRegions = selectedOptions
            }

            const form: TemplateForm = {
                id: this.template.id,
                name: this.template.name,
                description: this.template.description,
                category: this.templateFormOptions.reportCategoryOptions.find(it => it.value === this.template.category)!,
                regions: tempRegions,
            }

            this.templateForm = form
        }
    }

    async onConstructReport() {
        this.loading = true
        try {
            await this.constructReport()
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message)
                makeToast(this, error.message, 'Ошибка', 'danger')
            } else {
                console.error(error)
                makeToast(this, 'Не удалось сформировать отчет', 'Ошибка', 'danger')
            }
        } finally {
            this.loading = false
        }
    }

    async constructReport() {
        if (this.filterSettingsForm == null) {
            throw new Error('Не загружена форма для формирования отчета')
        }

        let templateName: string = this.template.name // Base name
        const hasOwnTemplateName = Boolean(this.templateForm.id)
        if (hasOwnTemplateName) {
            templateName = this.templateForm.name
        }

        if ('incomes' in this.filterSettingsForm) {
            this.incomesToPass = cloneDeep(this.filterSettingsForm.incomes)
        }
        if ('expenses' in this.filterSettingsForm) {
            this.expensesToPass = cloneDeep(this.filterSettingsForm.expenses)
        }

        const { tableData, journalParams } = await this.facade!.constructReport(
            cloneDeep(this.filterSettingsForm),
            templateName,
            this.userId
        )

        this.tableData = tableData
        this.journalParams = cloneDeep(journalParams)
        this.$emit("set-journal-params", cloneDeep(journalParams))

        // Мануально затригерить re-render таблицы
        this.tableKey += 1

        makeToast(this, 'Сформировано', 'Сообщение', 'success')
    }

    public async settingsToDefault() {
        this.tableData = null
        await this.setRegionOptionsOnCreate()
        this.setFilterSettingsForm()
    }

    public toggleSaveAsNewModal(value: boolean | null = null) {
        if (typeof value === 'boolean') {
            this.saveAsNewModal = value    
        } else {
            this.saveAsNewModal = !this.saveAsNewModal
        }
    }

    public async onSaveAsNew() {
        if (this.filterSettingsForm == null) {
            return
        }

        if (!this.templateForm.name) {
            makeToast(this, 'Необходимо ввести название', 'Сообщение', 'danger')
            return
        }

        const reservedNames = ['приложение 2', 'отчет 1-27', 'отчет о принятых обязательствах и кассовом исполнении (по форме 4-20)']
        if (reservedNames.includes(this.templateForm.name.toLowerCase())) {
            makeToast(this, 'Необходимо изменить название', 'Сообщение', 'danger')
            return
        }
        
        let settings: FilterSettings | null = null
        try {
            settings = this.facade!.formToSettings(cloneDeep(this.filterSettingsForm))            
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message)
                makeToast(this, error.message, 'Ошибка', 'danger')
            } else {
                console.error(error)
                makeToast(this, 'Не удалось сохранить шаблон (ошибка конвератций формы)', 'Ошибка', 'danger')
            }
            return
        }

        const requestBody = await this.convertTemplateFormToRequestBody(
            cloneDeep(this.templateForm),
            cloneDeep(settings),
            this.userId,
            this.oblastKato
        )
        const response = await fetch('/api-py/monitoring/reports-constructor/templates', {
                method: 'POST',
                body: JSON.stringify(requestBody)
            }
        )
        if (!response.ok) {
            makeToast(this, 'Не удалось сохранить шаблон', 'Ошибка', 'danger')
            return
        }
        const addedItem = await response.json()
        this.templateForm.id = addedItem.id
        makeToast(this, 'Сохранено', 'Сообщение', 'success')
        this.toggleSaveAsNewModal(false)
        this.templateName = this.templateForm.name
    }

    private async convertTemplateFormToRequestBody(
        form: TemplateForm,
        filterSettings: FilterSettings,
        userId: string,
        oblastKato: string
    ): Promise<TemplateCreateUpdateRequest> {
        let categoryCode: string | null = null
        if (form.category && form.category.value) {
            categoryCode = String(form.category.value)
        }

        let regions: string[] = []
        if (categoryCode != null) {
            if (form.regions === null) {
                throw new Error('Должен быть указан регион')
            } else if (Array.isArray(form.regions)) {
                regions = form.regions.map(it => String(it.value))
            } else {
                regions = [String(form.regions.value)]
            }
        } else {
            let tempRegion = '000000'

            const isTestOrNsi = oblastKato === '00' || oblastKato === '99'
            if (!isTestOrNsi) {
                tempRegion = this.oblastCode + '0101'
            }

            regions = [tempRegion]
        }

        const requestBody: TemplateCreateUpdateRequest = {
            id: form.id,
            name: form.name,
            description: form.description,
            category: categoryCode,
            regions: regions,
            filter_settings: filterSettings,
            user_id: userId,
            oblast_kato: oblastKato
        }

        return requestBody
    }

    public async onUpdate() {
        if (this.filterSettingsForm == null) {
            return
        }

        const settings: FilterSettings = this.facade!.formToSettings(cloneDeep(this.filterSettingsForm))

        const requestBody = await this.convertTemplateFormToRequestBody(
            cloneDeep(this.templateForm),
            cloneDeep(settings),
            this.userId,
            this.oblastKato
        )
        const response = await fetch('/api-py/monitoring/reports-constructor/templates', {
                method: 'PUT',
                body: JSON.stringify(requestBody)
            }
        )
        if (!response.ok) {
            makeToast(this, 'Не удалось редактировать шаблон', 'Ошибка', 'danger')
            return
        }

        makeToast(this, 'Изменения сохранены', 'Сообщение', 'success')
        this.toggleSaveAsNewModal(false)
    }

    public isClassificatorCheckboxDisabled(classificatorKey: string): boolean {
        if (this.template.filterSettings.reportName === '4-20' && classificatorKey === 'abp') {
            return true
        }
        return false
    }

    public isClassificatorMultiselectDisabled(classificatorKey: string): boolean {
        if (this.template.filterSettings.reportName === '4-20' && classificatorKey === 'spf') {
            return true
        }
        return false
    }

    public isClassificatorDraggingDisabled(): boolean {
        if (this.template.filterSettings.reportName === '4-20') {
            return true
        }
        return false
    }
    // #endregion
}
