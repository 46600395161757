<template>
    <div v-if="availableForUser" class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Исполнение бюджета</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Бюджетные кредиты</div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="handleCloseFilter"></i>
                    </div>
                    <!-- Номер договора -->
                    <div class="filter-block">
                        <b-form-group label="Номер договора">
                            <multiselect
                                :value="filterTempParameters.contractNumber"
                                @input="value => handleFilterInput('contractNumber', value)"
                                placeholder="Введите номер договра"
                                :options="filterOptions.contractNumber" label="label" track-by="id"
                                @search-change="(query) => handleLoadSelects('contractNumber', query)" ref="contractNumber"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Уровень Бюджета -->
                    <div class="filter-block">
                        <b-form-group label="Уровень бюджета">
                            <multiselect
                                :value="filterTempParameters.budgetLevel"
                                @input="value => handleFilterInput('budgetLevel', value)"
                                placeholder="Выбрать уровень"
                                :options="filterOptions.budgetLevel" label="label" track-by="id"
                                @search-change="(query) => handleLoadSelects('budgetLevel', query)" ref="budgetLevel"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Кредитор -->
                    <div class="filter-block">
                        <b-form-group label="Кредитор">
                            <multiselect v-model="filterTempParameters.creditor" placeholder="Выбрать кредитора"
                                :options="filterOptions.creditor" label="label" track-by="id"
                                @search-change="(query) => handleLoadSelects('creditor', query)" ref="creditor"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Администратор -->
                    <div class="filter-block">
                        <b-form-group label="Администратор">
                            <multiselect
                                :value="filterTempParameters.administrator"
                                @input="value => handleFilterInput('administrator', value)"
                                placeholder="Выбрать администратора"
                                :options="filterOptions.administrator" label="label" track-by="id" ref="administrator"
                                @search-change="(query) => handleLoadSelects('administrator', query)"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Заемщик -->
                    <div class="filter-block">
                        <b-form-group label="Заемщик">
                            <multiselect v-model="filterTempParameters.contractor" placeholder="Выбрать заемщика"
                                :options="filterOptions.contractor" label="label" track-by="id" ref="contractor"
                                @search-change="(query) => handleLoadSelects('contractor', query)"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Статус -->
                    <div class="filter-block">
                        <b-form-group label="Статус">
                            <multiselect v-model="filterTempParameters.status" label="nameRu" track-by="id"
                                placeholder="Выбрать статус" :options="filterOptions.status" ref="status"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Год -->
                    <div class="filter-block">
                        <b-form-group label="Год">
                            <multiselect v-model="filterTempParameters.year" placeholder="Выбрать год"
                                :options="filterOptions.year" ref="year"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Квартал -->
                    <div class="filter-block">
                        <b-form-group label="Квартал">
                            <multiselect v-model="filterTempParameters.quarter"
                                label="label"
                                placeholder="Выбрать квартал" :options="filterOptions.quarter" ref="quarter"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Регион -->
                    <div class="filter-block">
                        <b-form-group label="Регион">
                            <multiselect v-model="filterTempParameters.region" label="label" track-by="id"
                                placeholder="Выбрать регион" :options="filterOptions.region" ref="region"
                                @search-change="(query) => handleLoadSelects('region', query)"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!-- Единица измерения валюты TODO: specify -->
                    <!-- <div class="filter-block">
                        <b-form-group label="Ед. измерения">
                            <multiselect v-model="filterTempParameters.currency" label="nameRu" track-by="id"
                                placeholder="Выбрать валюту" :options="filterOptions.currency" ref="currency"
                                :allow-empty="false">
                            </multiselect>
                        </b-form-group>
                    </div> -->
                    <div class="filter-block">
                        <b-button @click="onApplyFilters" variant="primary">Применить</b-button>
                        <b-button @click="handleDropFilterParameters">Сбросить</b-button>
                    </div>
                </div>
            </b-dropdown>
            <div class="filter-actions">
                <!-- Для мануальных перерасчетов -->
                <b-button v-show="false" variant="success" @click="recalculateForecastInEveryForecastPresentAgreement">Перерасчет и сохранение прогноза у договоров имеющий прогноз</b-button>
                <b-button v-show="false" variant="success" @click="setInitialInterestDateEveryAgreement">Проставить даты вознаграждений (12-20 если есть)</b-button>
                <b-button v-show="false" variant="success" @click="calculateEveryAgreementLeftAmount">Перерасчет остатка текущего долга</b-button>
                <b-button v-show="false" variant="success" @click="calculateEveryAgreementRegionCode">Перерасчет код регионов</b-button>
                <b-button v-show="false" variant="success" @click="calculateEveryAgreementActualityFlag">Перерасчет флаг актуальности</b-button>
                <router-link v-if="isWriteAccess" :to="{ name: 'budget-loan-form', params: { mode: 'add' } }">
                    <b-button variant="success">Добавить</b-button>
                </router-link>
                <!-- Скачивание отчетов -->
                <div class="actions-tab" style="display: inline-block;">
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="generate130Report">Отчет 130</b-dropdown-item>
                        <b-dropdown-item @click="generate131Report">Отчет 131</b-dropdown-item>
                        <b-dropdown-item @click="generate015Report">Лимит по БП - 015</b-dropdown-item>
                        <b-dropdown-item @click="generate016Report">Лимит по БП - 016</b-dropdown-item>
                        <b-dropdown-item @click="generate3Report">Приложение 3</b-dropdown-item>
                        <b-dropdown-item @click="generateRegisterReport">Реестр бюджетных кредитов</b-dropdown-item>
                        <b-dropdown-item @click="generateRegisterReport2">Реестр бюджетных кредитов (приложение 2)</b-dropdown-item>
                        <b-dropdown-item @click="generatePrincipalRepaymentReport">Погашение осн. долга по кредитам сельс. спец</b-dropdown-item>
                        <b-dropdown-item @click="generatePlan1Or2Report(1)">План 1 ур помесяч</b-dropdown-item>
                        <b-dropdown-item @click="generatePlan1Or2Report(2)">План 2 ур помесяч</b-dropdown-item>
                    </b-dropdown>
                </div>
                <!-- Инструкций -->
                <div class="actions-tab" style="display: inline-block;">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                    <span class="left">
                                        <i class="icon icon-info"></i>
                                    </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="downloadInstruction">Режим «Бюджетные кредиты»<i
                                    class="icon icon-folder"></i></span>
                            </div>
                            <div class="info-text">
                                <p>Настоящий режим предназначен как инструмент для ведения, отслеживания договоров. Бюджетные кредиты предоставляются на цели соответствующих бюджетных программ.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <!-- Хлебная крошка -->
        <div class="filter-breadcrumb custom-position">
            <span class="item-breadcrumb" v-if="filterParameters.budgetLevel" @click="openFilterByRef('budgetLevel')">
                {{ filterParameters.budgetLevel.label }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.creditor" @click="openFilterByRef('creditor')">
                {{ filterParameters.creditor.shortLabel }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.administrator" @click="openFilterByRef('administrator')">
                {{ filterParameters.administrator.shortLabel }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.contractor" @click="openFilterByRef('contractor')">
                {{ filterParameters.contractor.shortLabel }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.status" @click="openFilterByRef('status')">
                {{ filterParameters.status.label }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.year" @click="openFilterByRef('year')">
                {{ filterParameters.year }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.quarter" @click="openFilterByRef('quarter')">
                {{ filterParameters.quarter.label }}
            </span>
            <span class="item-breadcrumb" v-if="filterParameters.region" @click="openFilterByRef('region')">
                {{ filterParameters.region.label }}
            </span>
            <!-- TODO: specify -->
            <!-- <span class="item-breadcrumb" v-if="filterParameters.currency" @click="openFilterByRef('currency')">
                {{ filterParameters.currency.nameRu }}
            </span> -->
        </div>
        <div class="table-container b-table-sticky-header">
            <b-table-simple>
                <b-thead>
                    <b-tr>
                        <b-th rowspan="2" class="toggle-show">
                            <button type="button" class="btn btn-secondary" @click="tableCollapse(null, null, null)">
                                <i v-if="collapseAll" class="icon icon-chevron-circle icon-rotate-180"></i>
                                <i v-else class="icon icon-chevron-circle"></i>
                            </button>
                        </b-th>
                        <template v-for="col in tableFields">
                            <template v-if="col.key === 'section'">
                                <b-th :key="col.key" colspan="5" style="max-width: 400px;">Раздел / Цель / Номер договора</b-th>
                            </template>
                            <template v-else-if="['numberContract', 'goal', 'contractEndDate'].includes(col.key)">
                            </template>
                            
                            <template v-else>
                                <b-th :key="col.key" colspan="1">{{ col.label }}</b-th>
                            </template>
                        </template>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <!-- Раздел -->
                    <template v-for="(loanDirectionRow, loanDirectionRowIndex) of tableData">
                        <b-tr :key="'loanDirectionRow_' + loanDirectionRow.id" class="table-info loan-section">
                            <td class="toggle-show">
                                <button type="button" class="btn btn-secondary"
                                    @click="tableCollapse(loanDirectionRowIndex, null, null, !loanDirectionRow.collapse)">
                                    <i class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="loanDirectionRow.collapse"></i>
                                    <i class="icon icon-chevron-circle" v-else></i>
                                </button>
                            </td>
                            <b-td colspan="10">{{ nameMap.get('section').get(loanDirectionRow.name + '') }}</b-td>
                            <b-td>
                                <div class="text-right nowrap">
                                    {{ formatDigitReadability(loanDirectionRow.amount) }}
                                </div>
                            </b-td>
                            <b-td>
                                <div class="text-right nowrap">
                                    {{ formatDigitReadability(loanDirectionRow.leftAmount) }}
                                </div>
                            </b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                        </b-tr>
                        <!-- Цель -->
                        <template v-if="!loanDirectionRow.collapse">
                            <template
                                v-for="(budgetLoanPurposeRow, budgetLoanPurposeRowIndex) of loanDirectionRow.children">
                                <b-tr :key="'budgetLoanPurposeRow_' + budgetLoanPurposeRow.id"
                                    style="background-color: #ecf7ff">
                                    <b-td></b-td>
                                    <b-td colspan="1" class="toggle-show text-right">
                                        <button type="button" class="btn btn-secondary mr-3"
                                            v-if="budgetLoanPurposeRow.collapse !== null"
                                            @click="tableCollapse(loanDirectionRowIndex, budgetLoanPurposeRowIndex, null, !budgetLoanPurposeRow.collapse)">
                                            <i class="icon icon-chevron-circle icon-rotate-180"
                                                v-if="budgetLoanPurposeRow.collapse"></i>
                                            <i class="icon icon-chevron-circle" v-else></i>
                                        </button>
                                    </b-td>
                                    <b-td colspan="9">{{ nameMap.get('purpose').get(budgetLoanPurposeRow.name + '')
                                    }}</b-td>
                                    <b-td class="text-right nowrap">{{ formatDigitReadability(budgetLoanPurposeRow.amount) }}</b-td>
                                    <b-td class="text-right nowrap">{{ formatDigitReadability(budgetLoanPurposeRow.leftAmount) }}</b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                </b-tr>
                                <!-- Вышестоящие договоры -->
                                <template v-if="!budgetLoanPurposeRow.collapse">
                                    <template v-for="(republicLoanRow, republicLoanRowIndex) of budgetLoanPurposeRow.children">
                                        <b-tr :key="'republicLoanRow_' + republicLoanRow.id">
                                            <b-td></b-td>
                                            <b-td></b-td>
                                            <b-td colspan="1" class="toggle-show text-right">
                                                <button type="button" class="btn btn-secondary mr-3"
                                                    v-if="republicLoanRow.collapse !== null"
                                                    @click="tableCollapse(loanDirectionRowIndex, budgetLoanPurposeRowIndex, republicLoanRowIndex, !republicLoanRow.collapse)">
                                                    <i class="icon icon-chevron-circle icon-rotate-180"
                                                        v-if="republicLoanRow.collapse"></i>
                                                    <i class="icon icon-chevron-circle" v-else></i>
                                                </button>
                                            </b-td>

                                            <b-td colspan="3">
                                                <div v-if="retrieveErrorCode(republicLoanRow) !== 0" class="error on-left">
                                                    <i class="icon icon-danger"></i>
                                                    <div class="pop-up">
                                                        <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                                        <template v-if="retrieveErrorCode(republicLoanRow) === 3">
                                                            <p>"Необходимо устранить расхождение между основным и дочерними договорами"</p>
                                                            <p>"Необходимо устранить расхождение между карточкой и графиком погашения договора"</p>
                                                        </template>
                                                        <template v-else-if="retrieveErrorCode(republicLoanRow) === 2">
                                                            <p>"Необходимо устранить расхождение между карточкой и графиком погашения договора"</p>
                                                        </template>
                                                        <template v-else-if="retrieveErrorCode(republicLoanRow) === 1">
                                                            <p>"Необходимо устранить расхождение между карточкой и графиком погашения договора"</p>
                                                        </template>
                                                    </div>
                                                </div>
                                                <router-link :to="{ name: 'budget-loan-form', params: { id: republicLoanRow.id, mode: 'view' } }">
                                                    {{ republicLoanRow.numberContract }}
                                                </router-link>
                                                <div v-if="republicLoanRow.additionals" class="more-info on-right">
                                                    <i class="icon icon-document"></i>
                                                    <div class="pop-up hold-on-hover">
                                                        Дополнения:
                                                        <template
                                                            v-for="(additional, index) in republicLoanRow.additionals">
                                                            <div :key="additional.numberContract + index">
                                                                <router-link
                                                                    :to="{ name: 'budget-loan-form', params: { id: additional.id, mode: 'view' } }">
                                                                    {{ additional.numberContract }} от {{
                                                                        millisToDate(additional.dateContract) }}
                                                                </router-link>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </b-td>

                                            <template v-for="(item, index) in tableFields">
                                                <template v-if="index < 3"></template>
                                                <b-td v-else :key="'republicLoanCol_' + republicLoanRow.id + item.key">
                                                    <template v-if="['amount'].includes(item.key)">
                                                        <div class="text-right nowrap">
                                                            <template v-if="republicLoanRow.additionals">
                                                                {{ formatDigitReadability(republicLoanRow.additionals.find(it => it.flagActual === 1)[item.key]) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ formatDigitReadability(republicLoanRow[item.key]) }}
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.key === 'statusId'">
                                                        <template v-if="republicLoanRow.additionals">
                                                            <div
                                                                :class="nameMap.get('statusColor').get(republicLoanRow.additionals.find(it => it.flagActual === 1)[item.key] + '')">
                                                                {{ nameMap.get('status').get(republicLoanRow.additionals.find(it => it.flagActual === 1)[item.key]) }}
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div
                                                                :class="nameMap.get('statusColor').get(republicLoanRow[item.key] + '')">
                                                                {{ nameMap.get('status').get(republicLoanRow[item.key]) }}
                                                            </div>
                                                        </template>
                                                    </template>
                                                    <template v-else-if="item.key === 'dateContract'">
                                                        <div class="text-center nowrap">
                                                            {{ millisToDate(republicLoanRow[item.key]) }}
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.key === 'contractDateEnd'">
                                                        <div class="text-center nowrap">
                                                            <template v-if="republicLoanRow.additionals">
                                                                {{ millisToDate(republicLoanRow.additionals.find(it => it.flagActual === 1)[item.key]) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ millisToDate(republicLoanRow[item.key]) }}
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.key === 'action'">
                                                        <b-dropdown dropleft class="more-dropdown">
                                                            <template v-slot:button-content>
                                                            <i class="icon icon-more"></i>
                                                            </template>
                                                            <template>
                                                                <b-dropdown-item-btn>
                                                                    <router-link
                                                                        :to="{ name: 'budget-loan-form', params: { id: republicLoanRow.id, mode: 'view' } }"
                                                                        class="no-text-decoration"
                                                                    >
                                                                        Открыть
                                                                    </router-link>
                                                                </b-dropdown-item-btn>
                                                                <b-dropdown-item-btn 
                                                                    v-if="isWriteAccess"
                                                                    @click="handleOpenAgreement(republicLoanRow.id, 'edit')"
                                                                >
                                                                    Редактировать
                                                                </b-dropdown-item-btn>
                                                                <b-dropdown-item-btn
                                                                    v-if="republicLoanRow.statusId === 1 && isWriteAccess"
                                                                    @click="handleOpenAgreement(republicLoanRow.id, 'add-additional')"
                                                                >
                                                                    Добавить дополнениe
                                                                </b-dropdown-item-btn>
                                                                <b-dropdown-item-btn
                                                                    v-if="republicLoanRow.statusId === 1 && republicLoanRow.budgetLevel === 1 && isWriteAccess"
                                                                    @click="handleOpenAgreement(republicLoanRow.id, 'add-lower-level')"
                                                                >
                                                                    Добавить нижестоящий договор
                                                                </b-dropdown-item-btn>
                                                                <b-dropdown-item-btn
                                                                    v-if="isAdminAccess"
                                                                    @click="handleAgreementDeletion(republicLoanRow.id)"
                                                                >
                                                                    <span style="color: red">Удалить</span>
                                                                </b-dropdown-item-btn>
                                                            </template>
                                                        </b-dropdown>
                                                    </template>
                                                    <template v-else>
                                                        <div
                                                            v-if="['creditor', 'codeGu', 'contractor'].includes(item.key)"
                                                            class="more-info"
                                                            style="width: 100%; height: 100%;"
                                                        >
                                                            <div class="text-center show-on-hover">
                                                                {{ republicLoanRow[item.key] }}
                                                            </div>
                                                            <div class="pop-up">
                                                                <template v-if="codeMap[item.key]">
                                                                    <template v-if="republicLoanRow[item.key]">
                                                                        {{ codeMap[item.key][republicLoanRow[item.key]] }}                                                                    
                                                                    </template>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div v-else-if="item.key === 'budgetLevel'">
                                                            <template v-if="codeMap[item.key]">
                                                                <template v-if="republicLoanRow[item.key]">
                                                                    {{ codeMap[item.key][republicLoanRow[item.key]] }}                                                                    
                                                                </template>
                                                            </template>
                                                        </div>
                                                        <div v-else-if="item.key === 'leftAmount'" class="text-right nowrap">
                                                            {{ formatDigitReadability(republicLoanRow[item.key]) }}
                                                        </div>
                                                    </template>
                                                </b-td>
                                            </template>
                                        </b-tr>
                                        <!-- Нижестоящие договоры -->
                                        <template v-if="!republicLoanRow.collapse">
                                            <template v-for="oblastLoanRow of republicLoanRow.children">
                                                <b-tr :key="'oblastLoanRow_' + oblastLoanRow.id">
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td></b-td>
                                                    <b-td>
                                                        <div v-if="retrieveErrorCode(oblastLoanRow) !== 0" class="error on-left">
                                                            <i class="icon icon-danger"></i>
                                                            <div class="pop-up">
                                                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                                                <template v-if="retrieveErrorCode(oblastLoanRow) === 3">
                                                                    <p>"Есть расхождение между связанными договорами"</p>
                                                                    <p>"Есть расхождение между карточкой и графиком"</p>
                                                                </template>
                                                                <template v-else-if="retrieveErrorCode(oblastLoanRow) === 2">
                                                                    <p>"Есть расхождение между карточкой и графиком"</p>
                                                                </template>
                                                                <template v-else-if="retrieveErrorCode(oblastLoanRow) === 1">
                                                                    <p>"Есть расхождение между связанными договорами"</p>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <router-link :to="{ name: 'budget-loan-form', params: { id: oblastLoanRow.id, mode: 'view' } }">
                                                            {{ oblastLoanRow.numberContract }}
                                                        </router-link>
                                                        <div v-if="oblastLoanRow.additionals" class="more-info on-right">
                                                            <i class="icon icon-document"></i>
                                                            <div class="pop-up hold-on-hover">
                                                                Дополнения:
                                                                <template v-for="(additional, index) in oblastLoanRow.additionals">
                                                                    <div :key="oblastLoanRow.numberContract + index">
                                                                        <router-link :to="{ name: 'budget-loan-form', params: { id: additional.id, mode: 'view' } }">
                                                                            {{ additional.numberContract }} от {{ millisToDate(additional.dateContract) }}
                                                                        </router-link>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </b-td>
                                                    <template v-for="(item, index) in tableFields">
                                                        <template v-if="index < 3" />
                                                        <b-td v-else :key="'oblastLoanRow_' + oblastLoanRow.id + item.key">
                                                            <template v-if="['amount'].includes(item.key)">
                                                                <div class="text-right nowrap">
                                                                    <template v-if="oblastLoanRow.additionals">
                                                                        {{ formatDigitReadability(oblastLoanRow.additionals.find(it => it.flagActual === 1)[item.key]) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ formatDigitReadability(oblastLoanRow[item.key]) }}
                                                                    </template>
                                                                </div>
                                                            </template>
                                                            <template v-else-if="item.key === 'statusId'">
                                                                <template v-if="oblastLoanRow.additionals">
                                                                    <div :class="nameMap.get('statusColor').get(oblastLoanRow.additionals.find(it => it.flagActual === 1)[item.key] + '')">
                                                                        {{ nameMap.get('status').get(oblastLoanRow.additionals.find(it => it.flagActual === 1)[item.key]) }}
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <div :class="nameMap.get('statusColor').get(oblastLoanRow[item.key] + '')">
                                                                        {{ nameMap.get('status').get(oblastLoanRow[item.key]) }}
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else-if="item.key === 'dateContract'">
                                                                <div class="text-center nowrap">
                                                                    {{ millisToDate(oblastLoanRow[item.key]) }}
                                                                </div>
                                                            </template>
                                                            <template v-else-if="item.key === 'contractDateEnd'">
                                                            <div class="text-center nowrap">
                                                                <template v-if="republicLoanRow.additionals">
                                                                    {{ millisToDate(republicLoanRow.additionals.find(it => it.flagActual === 1)[item.key]) }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ millisToDate(republicLoanRow[item.key]) }}
                                                                </template>
                                                            </div>
                                                        </template>
                                                            <template v-else-if="item.key === 'action'">
                                                                <b-dropdown dropleft class="more-dropdown">
                                                                    <template v-slot:button-content>
                                                                    <i class="icon icon-more"></i>
                                                                    </template>
                                                                    <template>
                                                                        <b-dropdown-item-btn>
                                                                            <router-link
                                                                                :to="{ name: 'budget-loan-form', params: { id: oblastLoanRow.id, mode: 'view' } }"
                                                                                class="no-text-decoration"
                                                                            >
                                                                                Открыть
                                                                            </router-link>
                                                                        </b-dropdown-item-btn>
                                                                        <b-dropdown-item-btn 
                                                                            v-if="isWriteAccess"    
                                                                            @click="handleOpenAgreement(oblastLoanRow.id, 'edit')"
                                                                        >
                                                                            Редактировать
                                                                        </b-dropdown-item-btn>
                                                                        <b-dropdown-item-btn 
                                                                            v-if="oblastLoanRow.statusId === 1 && isWriteAccess" 
                                                                            @click="handleOpenAgreement(oblastLoanRow.id, 'add-additional')"
                                                                        >
                                                                            Добавить дополнениe
                                                                        </b-dropdown-item-btn>
                                                                        <b-dropdown-item-btn 
                                                                            v-if="isAdminAccess" 
                                                                            @click="handleAgreementDeletion(oblastLoanRow.id)"
                                                                        >
                                                                            <span style="color: red">Удалить</span>
                                                                        </b-dropdown-item-btn>
                                                                    </template>
                                                                </b-dropdown>
                                                            </template>
                                                            <template v-else-if="['creditor', 'codeGu', 'contractor'].includes(item.key)">
                                                                <div
                                                                    class="more-info"
                                                                    style="width: 100%; height: 100%;"
                                                                >
                                                                    <div class="text-center show-on-hover">
                                                                        {{ oblastLoanRow[item.key] }}
                                                                    </div>
                                                                    <div class="pop-up">
                                                                        <template v-if="codeMap[item.key]">
                                                                            <template v-if="oblastLoanRow[item.key]">
                                                                                {{ codeMap[item.key][oblastLoanRow[item.key]] }}                                                                 
                                                                            </template>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </template>

                                                            <template v-else-if="item.key === 'budgetLevel'">
                                                                <template v-if="codeMap[item.key]">
                                                                    <template v-if="oblastLoanRow[item.key]">
                                                                        {{ codeMap[item.key][oblastLoanRow[item.key]] }}
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            
                                                            <div v-else-if="item.key === 'leftAmount'" class="text-right nowrap">
                                                                {{ formatDigitReadability(oblastLoanRow[item.key]) }}
                                                            </div>
                                                        </b-td>
                                                    </template>
                                                </b-tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                </b-tbody>
            </b-table-simple>
        </div>
        <loading :active="loading" is-full-screen spinner="bar-fade-scale" color="#6495ED" />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';
import { Ax } from '@/utils';
import { debounce } from 'lodash';
import VueElementLoading from 'vue-element-loading';
//import CBreadcrumbs from './components/c-breadcrumbs.vue';

const budgetLoanModuleCode = '005.007'

export default {
    name: 'BudgetLoanForm',

    components: {
        'loading': VueElementLoading,
    },

    data() {
        return {
            availableForUser: true,
            tableData: [],
            collapseAll: true,
            nameMap: new Map(),
            codeMap: {},
            defaultParameters: {},
            filterParameters: {},
            filterTempParameters: {},
            filterOptions: {
                budgetLevel: [],
                creditor: [],
                administrator: [],
                contractor: [],
                year: [],
                status: []
            },
            earliestYear: null,
            userSub: store.state.user.sub,
            umConnected: true,
            userBudgetLevels: [],
            userRegions: [],
            userAccessLevel: null,
            tableFields: [
                {
                    key: 'section',
                    label: 'Раздел',
                    class: 'toggle-show'
                },
                {
                    key: 'goal',
                    label: 'Цель',
                    class: 'toggle-show'
                },
                {
                    key: 'numberContract',
                    label: 'Номер договора',
                    class: 'toggle-show'
                },
                // {
                //     key: 'numberContract2',
                //     label: 'Номер договора 2',
                //     class: 'toggle-show'
                // },
                {
                    key: 'dateContract',
                    label: 'Дата договора'
                },
                {
                    key: 'budgetLevel',
                    label: 'Уровень бюджета'
                },
                {
                    key: 'creditor',
                    label: 'Кредитор'
                },
                {
                    key: 'codeGu',
                    label: 'Администратор'
                },
                {
                    key: 'contractor',
                    label: 'Заемщик'
                },
                {
                    key: 'amount',
                    label: 'Сумма договора'
                },
                {
                    key: 'leftAmount',
                    label: 'Текущий остаток суммы договора'
                },
                {
                    key: 'statusId',
                    label: 'Статус'
                },
                {
                    key: 'contractDateEnd',
                    label: 'Срок возврата кредита'
                },
                {
                    key: 'action',
                    label: ''
                }
            ],
            loading: false
        };
    },

    computed: {
        isWriteAccess() {
            return this.userAccessLevel >= 2
        },

        isAdminAccess() {
            return this.userAccessLevel >= 3
        },
    },

    async created() {
        if (!this.checkUserModuleAccessAndSetAccessLevel()) {
            this.availableForUser = false
            this.makeToast('Отсутствует доступ к режиму', 'Сообщение', 'danger')
            return
        }
        this.loading = true

        // Регионы пользователя
        await this.setUserRegions()

        // Уровень бюджета пользователя
        await this.setUserBudgetLevels()

        // 1. Загрузить маппинги код-название
        await this.loadAgreementsAndSetNameMappings()
        await this.loadValueNames()
        // 2. Загрузить варианты для select-input-ов
        await this.loadInitialFilterOptions()
        // 3. Загрузить default вариант для select-input-ов
        await this.setInitialFilterParameters()
        // await this.setFilterParametersFromQuery()
        // 4. Загурзить договора
        await this.loadAgreementsFiltered()
        
        this.loading = false
    },

    methods: {
        async setUserBudgetLevels() {
            const response = await fetch(
                    "/api-py/get-user-budget-level-only/" +
                        this.$store.getters.user_uuid
                );
            const data = await response.json()
            const levels = data.map(it => Number(it))
            this.userBudgetLevels = levels
        },

        checkUserModuleAccessAndSetAccessLevel() {
            for (const userModuleData of store.state.user.userModules) {
                if (userModuleData.modules === budgetLoanModuleCode) {
                    this.userAccessLevel = userModuleData.access_level
                    return true
                }
            }
            return false
        },

        async setUserRegions() {
            const response = await fetch(`/api-py/user-region/${this.userSub}`)
            if (!response.ok) {
                this.makeToast('Не удалось загузить регионы пользователя', 'Ошибка', 'danger')
                return
            }
            const userRegionObjects = await response.json()
            const userRegions = userRegionObjects.map(it => it.region)
            this.userRegions = userRegions
        },

        handleOpenAgreement(agreementId, mode) {
            this.$router.push({ name: 'budget-loan-form', params: { id: agreementId, mode: mode } })
        },

        async handleAgreementDeletion(agreementId) {
            const response = await fetch(`api/budget-loan/validate-deletion/${agreementId}`)
            if (!response.ok) {
                const message = (await response.json())?.args[0]?.value[0]
                this.makeToast(message, 'Ошибка', 'danger')
                return
            }

            this.$bvModal.msgBoxConfirm(
                'После удаления данные не подлежать восстановлению. Вы уверены?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(confirmed => {
                    if (confirmed) {
                        this.deleteAgreement(agreementId)
                    }
                })
                .catch(error => {
                    this.makeToast('Не удалось удалить договор', 'Ошибка удаления', 'danger')
                    console.error(error.toString())
                })
        },

        async generate130Report() {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (typeof this.filterTempParameters.quarter.value !== 'number') {
                this.makeToast('Необходимо выбрать квартал в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            // if (this.filterTempParameters.region.label === 'Все') {
            //     this.makeToast('Необходимо выбрать регион в фильтре', 'Сообщение', 'danger')
            //     valid = false
            // }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }

            const params = {}
            params.quarter = this.filterTempParameters.quarter.value
            params.year = (typeof this.filterTempParameters.year === 'number') ? this.filterTempParameters.year : null
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }
            
            const queryString = new URLSearchParams(params).toString()
            this.loading = true
            await Ax(
                {
                    url: `/api/budget-loan-reports/130?${queryString}`,
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    // Загрузка файла
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'Отчет 130.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    this.loading = false
                    window.URL.revokeObjectURL(url)
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString())
                    this.loading = false
                }
            )
        },

        async generateRegisterReport() {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (typeof this.filterTempParameters.quarter.value !== 'number') {
                this.makeToast('Необходимо выбрать квартал в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }

            this.loading = true
            const parameters = this.retrieveConvertedFilterTempParameters()
            const queryString = new URLSearchParams(parameters)
            await Ax(
                {
                    url: `/api/budget-loan-reports/register?${queryString}`,
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    // Загрузка файла
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'Реестр бюджетных кредитов.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                    this.loading = false
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString())
                    this.loading = false
                }
            )
        },

        async generateRegisterReport2() {
            this.loading = true
            const parameters = this.retrieveConvertedFilterTempParameters()
            const filterLabels = this.getFilterKeyToLabelMap()
            parameters['filter-labels'] = JSON.stringify(filterLabels)
            const queryString = new URLSearchParams(parameters)
            await Ax(
                {
                    url: `/api/budget-loan-reports/register-2?${queryString}`,
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    // Загрузка файла
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'Реестр бюджетных кредитов (приложение 2).xlsx')
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                    this.loading = false
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString())
                    this.loading = false
                }
            )
        },

        async generate131Report() {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (typeof this.filterTempParameters.quarter.value !== 'number') {
                this.makeToast('Необходимо выбрать квартал в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }
            
            this.loading = true

            const params = {}
            params.quarter = this.filterTempParameters.quarter.value
            params.year = (typeof this.filterTempParameters.year === 'number') ? this.filterTempParameters.year : null
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }
            const queryString = new URLSearchParams(params)

            await Ax(
                {
                    url: `/api/budget-loan-reports/131?${queryString}`,
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    // Загрузка файла
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    const filename = 'Отчет 131'
                    const extension = '.xlsx'
                    link.setAttribute('download', filename + extension)
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    this.loading = false
                    window.URL.revokeObjectURL(url)
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString())
                    this.loading = false
                }
            )
        },

        async generatePrincipalRepaymentReport() {

            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }
            
            const params = {}
            params.userId = this.userSub
            params.year = (typeof this.filterTempParameters.year === 'number') ? this.filterTempParameters.year : null
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }
            const queryString = new URLSearchParams(params)

            this.loading = true
            await Ax(
                {
                    url: `/api/budget-loan-reports/principal-repayment?${queryString}`,
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    // Загрузка файла
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    const filename = `Отчет Погашение осн. долга по кредитам сельс. спец. на ${params.year}-${params.year+2} гг.`
                    const extension = '.xlsx'
                    link.setAttribute('download', filename + extension)
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                    this.loading = false
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString())
                    this.loading = false
                }
            )
        },

        async generate015Report() {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (typeof this.filterTempParameters.quarter.value !== 'number') {
                this.makeToast('Необходимо выбрать квартал в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }

            const params = {}
            params.userId = this.userSub
            params.quarter = this.filterTempParameters.quarter.value
            params.year = (typeof this.filterTempParameters.year === 'number') ? this.filterTempParameters.year : null
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }
            const queryString = new URLSearchParams(params)

            this.loading = true
            try {
                const response = await fetch(`/api/budget-loan-reports/015?${queryString}`);
                if (!response.ok) {
                    const errorResponse = await response.json()
                    const message = errorResponse?.args[0]?.value[0]
                    this.makeToast(message, 'Сообщение', '')
                    throw new Error()
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const filename = `Лимит по БП - 015 на ${params.year}г.`;
                const extension = '.xlsx';
                link.href = url;
                link.setAttribute('download', filename + extension);
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },

        async generate016Report() {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (typeof this.filterTempParameters.quarter.value !== 'number') {
                this.makeToast('Необходимо выбрать квартал в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }

            const params = {}
            params.userId = this.userSub
            params.quarter = this.filterTempParameters.quarter.value
            params.year = (typeof this.filterTempParameters.year === 'number') ? this.filterTempParameters.year : null
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }
            const queryString = new URLSearchParams(params)

            this.loading = true
            try {
                const response = await fetch(`/api/budget-loan-reports/016?${queryString}`);
                if (!response.ok) {
                    const errorResponse = await response.json()
                    const message = errorResponse?.args[0]?.value[0]
                    this.makeToast(message, 'Сообщение', '')
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const filename = `Лимит по БП - 016 на ${params.year}г.`;
                const extension = '.xlsx';
                link.href = url;
                link.setAttribute('download', filename + extension);
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },

        async generate3Report() {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (typeof this.filterTempParameters.quarter.value !== 'number') {
                this.makeToast('Необходимо выбрать квартал в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }

            const params = {}
            params.userId = this.userSub
            params.quarter = this.filterTempParameters.quarter.value
            params.year = (typeof this.filterTempParameters.year === 'number') ? this.filterTempParameters.year : null
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }

            const queryString = new URLSearchParams(params)

            this.loading = true
            try {
                const response = await fetch(`/api/budget-loan-reports/3?${queryString}`);
                if (!response.ok) {
                    const errorResponse = await response.json()
                    const message = errorResponse?.args[0]?.value[0]
                    this.makeToast(message, 'Сообщение', '')
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const filename = `Приложение 3 на ${params.year} г.`;
                const extension = '.xlsx';
                link.href = url;
                link.setAttribute('download', filename + extension);
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },

        async generatePlan1Or2Report(budgetLevel) {
            let valid = true
            if (typeof this.filterTempParameters.year !== 'number') {
                this.makeToast('Необходимо выбрать год в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (this.filterTempParameters.creditor.label === 'Все') {
                this.makeToast('Необходимо выбрать кредитора в фильтре', 'Сообщение', 'danger')
                valid = false
            }
            if (!valid) {
                return
            }

            const params = {}
            params['budget-level'] = budgetLevel
            params.userId = this.userSub
            params.year = this.filterTempParameters.year
            if (this.filterTempParameters.region.code) {
                params['region-code'] = this.filterTempParameters.region.code
            }
            if (this.umConnected) {
                if (this.filterTempParameters.creditor.code.slice(-4) !== '0000') {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                }
            } else {
                if (this.filterTempParameters.creditor.code) {
                    params['abp-code'] = this.filterTempParameters.creditor.code.substring(0, 3)
                    params['org-code'] = this.filterTempParameters.creditor.code
                } else {
                    params['org-code'] = this.filterTempParameters.creditor.abp
                }
            }
            const queryString = new URLSearchParams(params)

            this.loading = true
            try {
                const response = await fetch(`/api/budget-loan-reports/plan-1-or-2?${queryString}`);
                if (!response.ok) {
                    const errorResponse = await response.json()
                    const message = errorResponse?.args[0]?.value[0]
                    this.makeToast(message, 'Сообщение', '')
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const currentDate = new Date()
                const year = currentDate.getFullYear()
                let month = currentDate.getMonth() + 1
                let day = currentDate.getDate() + 1
                month = month < 10 ? '0' + month : month
                day = day < 10 ? '0' + day : day
                const filename = `План_${budgetLevel}_ур_помесяч_на ${params.year} год на_${day}_${month}_${year}_`;
                const extension = '.xlsx';
                link.href = url;
                link.setAttribute('download', filename + extension);
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
            } finally {
                this.loading = false;
            }
        },

        async deleteAgreement(id) { // TODO: account new load method with url params
            const response = await fetch(`/api/budget-loan/${id}`, { method: 'DELETE' })
            if (!response.ok) {
                const message = (await response.json())?.args[0]?.value[0]
                this.makeToast(message, 'Ошибка', 'danger')
                return
            }
            this.makeToast('Успешно удалено', 'Сообщение', 'success')

            // Перезагрузить реестр
            this.loading = true
            const agreementsResponse = await fetch('/api/budget-loan')
            if (!agreementsResponse.ok) {
                this.makeToast('Не получилось загрузить договоры', 'Ошибка запроса', 'danger');
                return
            }
            const agreements = await agreementsResponse.json()
            this.createTreeTableData(agreements)
            await this.loadAgreementsAndSetNameMappings()
            await this.loadValueNames()
            await this.loadInitialFilterOptions()
            await this.setInitialFilterParameters()
            this.loading = false
        },

        async loadInitialFilterOptions() {
            const years = ['Все']
            const maxYear = (new Date()).getFullYear() + 1
            for (let i = this.earliestYear; i <= maxYear; i++) {
                years.push(i)
            }
            this.filterOptions.year = years

            this.filterOptions.quarter = [{ label: 'Все', value: null }, { label: 'I', value: 1 }, { label: 'II', value: 2 }, { label: 'III', value: 3 }, { label: 'IV', value: 4 }]
            this.filterOptions.contractNumber = await this.fetchOptions('contractNumber', '')
            this.filterOptions.budgetLevel = await this.fetchOptions('budgetLevel', '')
            this.filterOptions.creditor = await this.fetchOptions('creditor', '')
            this.filterOptions.region = await this.fetchOptions('region', '')
            this.filterOptions.administrator = await this.fetchOptions('administrator', '')
            this.filterOptions.contractor = await this.fetchOptions('contractor', '')
            this.filterOptions.status = await this.fetchOptions('status', '')
            // this.filterOptions.currency = await this.fetchOptions('currency', 'тенге')
        },

        setEarliestYear(agreements) {
            let tempYear = (new Date()).getFullYear()
            agreements.forEach(item => {
                const year = (new Date(item.dateContract)).getFullYear()
                if (tempYear > year) {
                    tempYear = year
                }
            })
            this.earliestYear = tempYear
        },

        async setInitialFilterParameters() {
            const parameters = {}
            parameters.contractNumber = this.filterOptions.contractNumber[0]
            parameters.creditor = this.filterOptions.creditor[0]
            parameters.administrator = this.filterOptions.administrator[0]
            parameters.contractor = this.filterOptions.contractor[0]
            parameters.status = this.filterOptions.status[0]
            parameters.year = this.filterOptions.year[0]
            parameters.budgetLevel = this.filterOptions.budgetLevel[0]
            parameters.quarter = this.filterOptions.quarter[0]
            // parameters.currency = this.filterOptions.currency[0]
            parameters.region = this.filterOptions.region[0]
            
            this.defaultParameters = JSON.parse(JSON.stringify(parameters))
            this.filterParameters = JSON.parse(JSON.stringify(parameters))
            this.filterTempParameters = parameters
        },

        async setFilterParametersFromQuery() { // TODO: drop params on page refresh
            const queries = this.$router.currentRoute.query
            const parameters = JSON.parse(JSON.stringify(this.filterParameters))
            if (queries.budgetLevel) {
                parameters.budgetLevel = this.filterOptions.budgetLevel.find(item => item.code === queries.budgetLevel)
            }
            if (queries.status) {
                parameters.status = this.filterOptions.status.find(item => item.id == queries.status)
            }
            if (queries.year) {
                parameters.year = this.filterOptions.year.find(year => year == queries.year)
            }
            if (queries.quarter) {
                parameters.quarter = this.filterOptions.quarter.find(item => item.value == queries.quarter)
            }
            if (queries.contractNumber) {
                const agreements = await this.fetchOptions('contractNumber', queries.contractNumber)
                parameters.contractNumber = agreements[0]
            }
            if (queries.creditor) {
                const creditors = await this.fetchOptions('creditor', queries.creditor)
                this.filterOptions.creditor = creditors
                parameters.creditor = creditors[1]
            }
            if (queries.administrator) {
                const administrators = await this.fetchOptions('administrator', queries.administrator)
                this.filterOptions.administrator = administrators
                parameters.administrator = administrators[1]
            }
            if (queries.contractor) {
                const contractors = await this.fetchOptions('contractor', queries.contractor)
                this.filterOptions.contractor = contractors
                parameters.contractor = contractors[1]
            }
            if (queries.region) {
                const regions = await this.fetchOptions('region', queries.region)
                this.filterOptions.region = regions
                parameters.region = regions[1]
            }
            this.filterTempParameters = parameters
            this.filterParameters = JSON.parse(JSON.stringify(parameters))
        },

        async loadValueNames() {
            const response = await fetch('/api/dict/bc_section')
            if (response.ok) {
                const sections = await response.json()
                const sectionMap = new Map()
                sections.forEach(item => {
                    sectionMap.set(item.code, `${item.section} ${item.nameRu}`)
                })
                this.nameMap.set('section', sectionMap)
            } else {
                this.makeToast('Не получилось загрузить найменование секций', 'Ошибка запроса', 'danger');
            }


            const goalResponse = await fetch('/api/dict/bc_goal')
            if (goalResponse.ok) {
                const purposes = await goalResponse.json()
                const purposeMap = new Map()
                purposes.forEach(item => {
                    purposeMap.set(item.code, item.nameRu)
                })
                this.nameMap.set('purpose', purposeMap)
            } else {
                this.makeToast('Не получилось загрузить найменование целей', 'Ошибка запроса', 'danger');
            }

            const statusResponse = await fetch('/api/dict/bc_status')
            if (statusResponse.ok) {
                const statuses = await statusResponse.json()
                const statusMap = new Map()
                statuses.forEach(item => {
                    statusMap.set(item.id, item.nameRu)
                })
                this.nameMap.set('status', statusMap)
            } else {
                this.makeToast('Не получилось загрузить найменование статусов', 'Ошибка запроса', 'danger');
            }

            const statusColorMap = new Map()
            statusColorMap.set('1', 'text-green')
            statusColorMap.set('2', 'text-orange')
            statusColorMap.set('3', 'text-yellow')
            this.nameMap.set('statusColor', statusColorMap)
        },

        async loadAgreements() {
            const response = await fetch('/api/budget-loan')
            if (!response.ok) {
                this.makeToast('Не получилось загрузить договоры', 'Ошибка запроса', 'danger');
                return
            }
            const agreements = await response.json()
            this.createTreeTableData(agreements)
        },

        async loadAgreementsAndSetNameMappings() {
            const response = await fetch('/api/budget-loan')
            if (!response.ok) {
                this.makeToast('Не получилось загрузить договоры', 'Ошибка запроса', 'danger');
                return
            }

            const agreements = await response.json()
            this.setEarliestYear(agreements)
            this.loadCodeNameMappings(agreements)
        },

        async loadAgreementsFiltered() {
            this.loading = true
            const parameters = this.retrieveConvertedFilterParameters()
            parameters['user-id'] = this.userSub
            const queryString = new URLSearchParams(parameters)
            const response = await fetch(`/api/budget-loan/filtered?${queryString}`)
            if (!response.ok) {
                this.makeToast('Не получилось загрузить договоры', 'Ошибка запроса', 'danger');
                this.loading = false
                return
            }

            const agreements = await response.json()
            this.createTreeTableData(agreements)
            this.loading = false
        },

        async loadCodeNameMappings(agreements) {
            const codes = {
                budgetLevel: [],
                codeGu: [],
                creditor: [],
                contractor: []
            }
            agreements.filter(item => item.type === 0).forEach(item => {
                ['budgetLevel', 'codeGu', 'creditor', 'contractor'].forEach(key => {
                    const code = item[key]
                    if (!codes[key].includes(code)) {
                        codes[key].push(code)                            
                    }
                })
            })
            const response = await fetch('/api/budget-loan/generate/code-name-map', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(codes)
            })
            const data = await response.json()
            this.codeMap = data
        },

        retrieveConvertedFilterParameters() {
            const parameters = {}

            parameters['contract-number'] = this.filterParameters?.contractNumber?.numberContract
            parameters['budget-level'] = this.filterParameters?.budgetLevel?.id
            parameters.status = this.filterParameters?.status?.id
            parameters.contractor = this.filterParameters?.contractor?.code
            parameters.quarter = this.filterParameters?.quarter.value
            parameters.region = this.filterParameters?.region?.code

            if (this.umConnected) {
                if (this.filterParameters?.creditor?.code?.slice(-4) === '0000') {
                    parameters.creditor = this.filterParameters?.creditor?.abpOwner
                } else {
                    parameters.creditor = this.filterParameters?.creditor?.code
                }

                if (this.filterParameters?.administrator?.code?.slice(-4) === '0000') {
                    parameters.administrator = this.filterParameters?.administrator?.abpOwner
                } else {
                    parameters.administrator = this.filterParameters?.administrator?.code
                }
            } else {
                if (parameters['budget-level'] === 1) {
                    parameters.administrator = this.filterParameters?.administrator?.abp
                    parameters.creditor = this.filterParameters?.creditor?.abp
                } else {
                    parameters.administrator = this.filterParameters?.administrator?.code
                    parameters.creditor = this.filterParameters?.creditor?.code
                }
            }

            if (this.filterParameters?.year !== 'Все') {
                parameters.year = this.filterParameters?.year
            }

            for (const key in parameters) {
                if (Object.hasOwnProperty.call(parameters, key)) {
                    const element = parameters[key]
                    if (!parameters[key]) {
                        delete parameters[key]
                    }
                }
            }

            return parameters
        },

        retrieveConvertedFilterTempParameters() {
            const parameters = {}

            parameters['contract-number'] = this.filterTempParameters?.contractNumber?.numberContract
            parameters['budget-level'] = this.filterTempParameters?.budgetLevel?.id
            parameters.status = this.filterTempParameters?.status?.id
            parameters.contractor = this.filterTempParameters?.contractor?.code
            parameters.quarter = this.filterTempParameters?.quarter.value
            parameters.region = this.filterTempParameters?.region?.code

            if (parameters['budget-level'] === 1) {
                parameters.administrator = this.filterTempParameters?.administrator?.abp
                parameters.creditor = this.filterTempParameters?.creditor?.abp
            } else {
                parameters.administrator = this.filterTempParameters?.administrator?.code
                parameters.creditor = this.filterTempParameters?.creditor?.code
            }
            if (this.filterTempParameters?.year !== 'Все') {
                parameters.year = this.filterTempParameters?.year
            }

            for (const key in parameters) {
                if (Object.hasOwnProperty.call(parameters, key)) {
                    const element = parameters[key]
                    if (!parameters[key]) {
                        delete parameters[key]
                    }
                }
            }

            return parameters
        },

        getFilterKeyToLabelMap() {
            const map = {}
            for (const key in this.filterTempParameters) {
                if (this.filterTempParameters.hasOwnProperty(key)) { // Check if the key is the object's own property
                    if (key === 'year') {
                        map[key] = String(this.filterTempParameters[key])
                    } else {
                        map[key] = this.filterTempParameters[key].label
                    }
                }
            }
            return map
        },

        tableCollapse(loanDirectionIndex, loanPurposeIndex, higherLevelLoanIndex, collapse) {
            if (loanDirectionIndex === null) {
                this.collapseAll = !this.collapseAll;
                for (const item of this.tableData) {
                    item.collapse = this.collapseAll;
                    for (const subItem of item.children) {
                        if (subItem.collapse !== null) {
                            subItem.collapse = this.collapseAll
                            for (const subSubItem of subItem.children) {
                                subSubItem.collapse = this.collapseAll
                            }
                        }
                    }
                }
                return;
            }
            if (loanPurposeIndex === null) {
                this.tableData[loanDirectionIndex].collapse = collapse;
                for (const item of this.tableData[loanDirectionIndex].children) {
                    if (item.collapse !== null) {
                        item.collapse = true
                        for (const subItem of item.children) {
                            if (subItem.collapse !== null) {
                                subItem.collapse = collapse
                            }
                        }
                    }
                }
            } else if (higherLevelLoanIndex === null) {
                if (this.tableData[loanDirectionIndex].children[loanPurposeIndex].collapse !== null) {
                    this.tableData[loanDirectionIndex].children[loanPurposeIndex].collapse = collapse
                }
            } else {
                if (this.tableData[loanDirectionIndex].children[loanPurposeIndex].children[higherLevelLoanIndex].collapse !== null) {
                    this.tableData[loanDirectionIndex].children[loanPurposeIndex].children[higherLevelLoanIndex].collapse = collapse
                }
            }
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        async fetchOptions(optionName, query, setAll = false) {
            const url = this.getUrl(optionName)
            if (!url) {
                return []
            }

            const response = await fetch(url + query)

            if (!response.ok) {
                this.makeToast('Не получилось подтянуть варианты фильтров', 'Ошибка запроса', 'danger');
                return
            }

            const data = await response.json()
            let options = data.items ? data.items : data

            // excluding levels which are not supported
            if (optionName === 'budgetLevel') {
                options = options.filter(item => [1, 2].includes(item.id))
            }

            options.map(item => this.createLabel(item, optionName))

            if (optionName === 'region') {
                // Remove duplicate codes
                options = options.filter((current, index, self) => 
                    index === self.findIndex((item) => item.code === current.code)
                )
                options.sort((a, b) => a.code.localeCompare(b.code))
            }
            if (optionName === 'region' && this.userBudgetLevels.includes(2)) {
                options.unshift({ id: -1, code: '000000', label: '000000', shortLabel: '000000', nameRu: '000000' })
            }

            if (optionName !== 'currency') {
                options.unshift({ id: 0, isAll: true, label: 'Все', shortLabel: 'Все', nameRu: 'Все' })
            }
            
            // adding custom option
            if (optionName === 'contractNumber' && query) {
                let id
                do {
                    id = -1 * (Math.floor(Math.random() * 100))
                } while (id === this.filterTempParameters?.contractNumber?.id)
                options.unshift({ id: id, label: query, contractNumber: query, numberContract: query, shortLabel: query, nameRu: query })                
            }

            return options
        },

        // #region Handlers
        handleCloseFilter() {
            this.filterTempParameters = {...this.filterParameters}
            this.$refs.drop.hide(true)
        },

        handleLoadSelects: debounce(async function (col, query) {
            this.filterOptions[col] = await this.fetchOptions(col, query)
            this.filterOptions = JSON.parse(JSON.stringify(this.filterOptions))
        }, 300),

        onApplyFilters() {
            this.mapFilterToUrlQuery()
            this.filterParameters = {...this.filterTempParameters}
            this.loadAgreementsFiltered()
            this.$refs.drop.hide(true)
        },

        mapFilterToUrlQuery() {
            const filter = {}
            for (const key in this.filterTempParameters) {
                const currentFilter = this.filterTempParameters[key]
                if (currentFilter.label !== 'Все') {
                    filter[key] = currentFilter.code
                }
            }
            if (this.filterTempParameters?.contractNumber?.numberContract) {
                filter.contractNumber = this.filterTempParameters?.contractNumber?.numberContract
            }
            if (this.filterTempParameters?.status?.id) {
                filter.status = this.filterTempParameters?.status?.id
            }
            if (this.filterTempParameters?.year !== 'Все') {
                filter.year = this.filterTempParameters?.year
            }
            if (this.filterTempParameters?.quarter?.value) {
                filter.quarter = this.filterTempParameters?.quarter?.value
            } 
            this.$router.push({ query: { ...filter } })
        },

        async handleDropFilterParameters() {
            this.filterTempParameters = JSON.parse(JSON.stringify(this.defaultParameters))
            this.filterParameters = JSON.parse(JSON.stringify(this.defaultParameters))
            await this.loadAgreementsFiltered()
        },

        async handleFilterInput(key, value) {
            const oldValue = this.filterTempParameters[key]

            this.filterTempParameters = {...this.filterTempParameters, [key]: value}

            switch (key) {
                case 'budgetLevel': {
                    if (!oldValue || (oldValue?.code !== value?.code)) {
                        this.filterOptions.creditor = await this.fetchOptions('creditor', '')
                        this.filterTempParameters.creditor = this.filterOptions.creditor[0]

                        this.filterOptions.administrator = await this.fetchOptions('administrator', '')
                        this.filterTempParameters.administrator = this.filterOptions.administrator[0]

                        this.filterTempParameters.contractor = this.filterOptions.contractor[0]
                    }
                    return
                }
                case 'administrator': {
                    if (!oldValue || (oldValue?.code !== value?.code) || (oldValue?.abp !== value?.abp)) {
                        this.filterOptions.contractor = await this.fetchOptions('contractor', '')
                        this.filterTempParameters.contractor = this.filterOptions.contractor[0]
                    }
                    return
                }
                default:
                    break
            }
        },
        // #endregion

        createLabel(item, columnName = null) {
            const isRepublicanBudgetLevel = this.filterTempParameters?.budgetLevel?.id === 1

            switch (columnName) {
                case 'contractNumber': {
                    item.label = item.numberContract
                    return item
                }
                case 'status': {
                    item.label = item.nameRu
                    return item
                }
                case 'administrator':
                case 'creditor': {
                    if (this.umConnected) {
                        if (item?.code?.slice(-4) === '0000') {
                            item.label = `${item?.code?.substring(0, 3)} - ${item.nameRu}`
                            item.shortLabel = item.code
                        } else {
                            item.label = `${item.code} - ${item.nameRu}`
                            item.shortLabel = item.code
                        }
                    } else {
                        if (isRepublicanBudgetLevel) {
                            item.label = `${item.abp} - ${item.name_ru}`
                            item.shortLabel = item.abp
                        } else {
                            item.label = `${item.code} - ${item.nameRu}`
                            item.shortLabel = item.code
                        }
                    }
                    return item
                }
                default: {
                    const nameRu = item.nameRu ? item.nameRu : item.name_ru
                    const code = item.code ? item.code : item.fullCode
                    item.label = `${code} - ${nameRu}`
                    item.shortLabel = code
                    return item
                }
            }
        },

        getUrl(columnName) {
            const isRepublicanBudgetLevel = this.filterTempParameters?.budgetLevel?.id === 1

            switch (columnName) {
                case 'contractNumber': {
                    return `/api/budget-loan/filtered?user-id=${this.userSub}&contract-number=`
                }
                case 'budgetLevel':
                    return '/api/dict/dict_budget_level/pages?search-text='
                case 'creditor': {
                    if (this.umConnected) {
                        const budgetLevel = this.filterTempParameters?.budgetLevel?.id
                        let budgetLevelQuery = ''
                        if (budgetLevel) {
                            budgetLevelQuery = `budget-level=${budgetLevel}&`
                        }
                        return `/api/dict/gu/pages/creditor/filter/${this.userSub}?${budgetLevelQuery}search-text=`
                    } else {
                        if (isRepublicanBudgetLevel) {
                            return '/api/dict/ebk_func_types/pages/by-type-and-budget-level?type=3&budget-level=1&search-text='
                        }
                        return '/api/dict/gu/main/pages/creditor/filter?search-text='
                    }
                }
                case 'region': {
                    if (this.umConnected) {
                        return `/api/dict/dict_budget_regions/user-management/${this.userSub}`
                    }
                    else {
                        return '/api/dict/dict_budget_regions/pages?search-text='
                    }
                }
                // case 'currency': {
                //     return '/api/dict/currency/pages/distinct-by-code?search-text='
                // }
                case 'administrator': {
                    if (this.umConnected) {
                        return `/api/dict/gu/pages/administrator/filter/${this.userSub}?search-text=`
                    } else {
                        if (isRepublicanBudgetLevel) {
                            return '/api/dict/ebk_func_types/pages/by-type-and-budget-level-less-than?type=3&budget-level=3&search-text='
                        }
                        return '/api/dict/gu/pages/main?search-text='
                    }
                }
                case 'contractor': {
                    if (this.umConnected) {
                        return `/api/dict/gu/pages/contractor/filter/${this.userSub}?search-text=`
                    } else {
                        if (isRepublicanBudgetLevel) {
                            return '/api/dict/gu/pages/server-region?search-text='
                        }
                        const oblastRegionCode = this.filterParameters?.administrator?.idRegion?.substring(0, 2) || ''
                        return `/api/dict/gu/pages/by-code-or-name-and-region-code?region-code=${oblastRegionCode}&search-text=`
                    }
                }
                case 'status':
                    return "api/dict/bc_status?search-text="
                default:
                    return ''
            }
        },

        formatDigitReadability(value) {
            if (!value && value !== 0) {
                return '-'
            }
            value += ''
            const removedSpaceString = value.replace(/\s/g, "")
            value = removedSpaceString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return value
        },

        makeToast(message, title, variant) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true
            });
        },

        // millisToDate(millis) { TODO: import from util
        //     if (!millis) {
        //         return '';
        //     }
        //     const date = new Date(millis);
        //     const year = date.getFullYear();
        //     let month = date.getMonth() + 1;
        //     if (month.toString().length === 1) {
        //         month = '0' + month;
        //     }
        //     let day = date.getDate();
        //     if (day.toString().length === 1) {
        //         day = '0' + day;
        //     }
        //     return year + '-' + month + '-' + day;
        // },
        millisToDate(millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);

            // Check if time is 23:00:00 and add an hour if it is
            if (date.getHours() === 23 && date.getMinutes() === 0 && date.getSeconds() === 0) {
                date.setTime(date.getTime() + 3600000); // Adds 1 hour (3600000 milliseconds)
            }

            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month.toString();
            
            let day = date.getDate();
            day = day < 10 ? '0' + day : day.toString();

            return year + '-' + month + '-' + day;
        },

        createTreeTableData(flatData) {
            const resMap = new Map()

            // По направлениям кредита
            for (const el of flatData) {
                let tmp = resMap.get(el.loanDirection);
                if (tmp) {
                    tmp.push(el);
                } else {
                    tmp = [el];
                }
                resMap.set(el.loanDirection, tmp);
            }

            // По целям кредита
            for (const [key, value] of resMap) {
                const subProg = new Map();
                for (const el of value) {
                    let tmp = subProg.get(el.budgetLoanPurpose);
                    el.collapse = true
                    if (tmp) {
                        tmp.push(el);
                    } else {
                        tmp = [el];
                    }
                    subProg.set(el.budgetLoanPurpose, tmp);
                }

                for (const [key, value] of subProg) {
                    for (const el of value) {
                        if (el.numberContractMain) {
                            let childType
                            const parent = value.find(item => {
                                childType = el.type
                                return el.numberContractMain === item.id
                            })

                            if (parent !== undefined) {
                                if (childType === 0) {
                                    if (!parent.children) {
                                        parent.children = []
                                    }
                                    parent.children.push(el)
                                } else {
                                    if (!parent.additionals) {
                                        parent.additionals = []
                                    }
                                    parent.additionals.push(el)
                                }
                                el.remove = true
                            }
                        }
                    }

                    const filtered = value.filter(item => !item.remove)
                    subProg.set(key, filtered)
                }

                resMap.set(key, subProg);
            }

            let id = 0
            const resultArray = Array.from(resMap, ([name, value]) => ({ id: id++, name, collapse: true, loanDirection: name, children: value }))

            for (const item of resultArray) {
                const unsorted = Array.from(item.children, ([name, value]) => ({ id: id++, name, collapse: true, budgetLoanPurpose: name, children: value }))
                item.children = unsorted.sort((a, b) => a.budgetLoanPurpose - b.budgetLoanPurpose)
            }

            for (const section of resultArray) {
                let sectionSum = 0
                let sectionLeftAmount = 0
                for (const goal of section.children) {
                    let goalSum = 0
                    let goalLeftAmount = 0
                    for (const republicAgreement of goal.children) {
                        let agreementSum = 0
                        if (republicAgreement.additionals) { 
                            agreementSum = republicAgreement.additionals.find(it => it.flagActual === 1)?.amount ?? 0
                        } else {
                            agreementSum = republicAgreement.amount ?? 0
                        }

                        goalSum += agreementSum
                        goalLeftAmount += republicAgreement.leftAmount ?? 0
                    }
                    goal.amount = goalSum
                    goal.leftAmount = goalLeftAmount
                    sectionSum += goalSum
                    sectionLeftAmount += goalLeftAmount
                }
                section.amount = sectionSum
                section.leftAmount = sectionLeftAmount
            }

            this.tableData = resultArray
        },

        retrieveErrorCode(agreement) {
            let errorBetweenAgreements = false
            let errorSchedule = false

            if (agreement.flagError === 1) {
                errorBetweenAgreements = true
            } else if (agreement.flagError === 2) {
                errorSchedule = true
            }

            if (agreement.additionals) {
                agreement.additionals.forEach(item => {
                    if (item.flagError === 1) {
                        errorBetweenAgreements = true
                    } else if (item.flagError === 2) {
                        errorSchedule = true
                    }
                })
            }

            if (errorBetweenAgreements && errorSchedule) {
                return 3
            } else if (errorBetweenAgreements) {
                return 1
            } else if (errorSchedule) {
                return 2
            }
            return 0
        },

        async setInitialInterestDateEveryAgreement() {
            const response = await fetch(
                'api/budget-loan-schedule/set-initial-interest-date-every-agreement',
                { method: 'PUT' }
            )
            if (response.ok) {
                this.makeToast('Даты проставлены', 'Сообщение', 'success')
            }
        },

        async calculateEveryAgreementLeftAmount() {
            const response = await fetch(
                'api/budget-loan-schedule/calculate-every-agreement-left-amount',
                { method: 'PUT' }
                )
            if (response.ok) {
                this.makeToast('Перерасчет исполнен', 'Сообщение', 'success')
            }
        },

        async recalculateForecastInEveryForecastPresentAgreement() {
            const response = await fetch(
                'api/budget-loan-schedule/recalculate-forecast-in-every-forecast-present-agreement',
                { method: 'PUT' }
            )
            if (response.ok) {
                this.makeToast('Перерасчет исполнен', 'Сообщение', 'success')
            } else {
                this.makeToast('Не удалось', 'Ошибка', 'danger')
            }
        },

        async calculateEveryAgreementRegionCode() {
            const response = await fetch(
                'api/budget-loan/calculate-every-agreement-region-code',
                { method: 'PUT' }
                )
            if (response.ok) {
                this.makeToast('Перерасчет исполнен', 'Сообщение', 'success')
            }
        },

        async calculateEveryAgreementActualityFlag() {
            const response = await fetch(
                'api/budget-loan/agreements/assign-actuality-status',
                { method: 'PUT' }
                )
            if (response.ok) {
                this.makeToast('Перерасчет исполнен', 'Сообщение', 'success')
            }
        },

        async downloadInstruction() {
            this.loading = true
            Ax(
                {
                    url: '/api/budget-loan/instruction',
                    method: 'GET',
                    responseType: 'blob'
                },
                (data) => {
                    // Загрузка файла
                    const blob = new Blob([data])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    const filename = 'Инструкция БК'
                    const extension = '.pdf'
                    link.setAttribute('download', filename + extension)
                    document.body.appendChild(link)
                    link.click()
                    // Cleanup
                    document.body.removeChild(link)
                    this.loading = false
                    window.URL.revokeObjectURL(url)
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса', error.toString())
                    this.loading = false
                }
            )
        }
    },
};
</script>

<style lang="scss">

.filtered {
    color: #95B8CD;
    display: inline-block;
    margin-right: 5px;
}

.no-wrap-space {
    white-space: nowrap;
}

.no-text-decoration {
    color: #0D1A2E !important;
    font-weight: 400 !important;
    text-decoration: none !important;
}

.table-container table tbody tr.loan-section td {
    font-weight: bold !important;
    text-transform: uppercase;
}

.custom-position {
    width: 100%;
    /* background-color: rgb(0, 0, 0); */
    z-index: 1007;
    position: fixed;
    top: 176px;
    left: 56px;
    transition: 0.2s all ease;
}

.scrolled {
    .custom-position {
        top: 40px;
    }
}

.error,
.more-info {
    position: relative;

    &.on-left {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    &.on-right {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 10px;
    }

    input {
        padding: 0 10px 0 25px;
        color: red;
    }

    .pop-up {
        position: absolute;
        top: 35px;
        width: 240px;
        padding: 15px;
        background-color: #FFF9EB;
        border-radius: 5px;
        box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.07);
        opacity: 0;
        z-index: 1010;
        visibility: hidden;
        transition: 0.2s all ease;

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #FFF9EB transparent;
        }

        p {
            font-size: 12px;
            font-weight: 600;
        }

        .red-text {
            color: red;
        }

        table {
            border: none;
            width: 100%;

            tr {
                td {
                    padding: 10px;
                    border-left: none !important;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-right: none !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .pop-up-reverse {
        position: absolute;
        top: 35px;
        right: -1000px;
        width: 240px;
        padding: 15px;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.07);
        opacity: 0;
        z-index: 1010;
        visibility: hidden;
        transition: 0.2s all ease;

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #FFF9EB transparent;
        }

        .red-text {
            color: red;
        }

        table {
            border: none;
            width: 100%;

            tr {
                td {
                    padding: 10px;
                    border-left: none !important;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-right: none !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .hold-on-hover {
        &:hover {
            opacity: 1;
            visibility: visible;
        }
    }

    i {
        position: absolute;
        top: 0;
        left: 0;
        color: red;

        &:hover {
            &+.pop-up {
                opacity: 1;
                visibility: visible;
            }

            &+.pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .show-on-hover {
        &:hover {
            &+.pop-up {
                opacity: 1;
                visibility: visible;
            }
            &+.pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.text-only {
        &>span {
            color: #212529;
            text-decoration: underline;
        }

        &:hover {
            .pop-up {
                opacity: 1;
                visibility: visible;
            }

            .pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &:not(.more-info-right) {
        .pop-up {
            left: 0;

            &:before {
                left: 5px;
            }
        }

        .pop-up-reverse {
            left: 0;

            &:before {
                left: 5px;
            }
        }
    }

    &.more-info-right {
        .pop-up {
            right: 0;

            &:before {
                right: 5px;
            }
        }

        .pop-up-reverse {
            right: 0;

            &:before {
                right: 5px;
            }
        }
    }
}

.text-green {
    color: #02bf22;
}

.text-orange {
    color: #FF6800;
}

.text-yellow {
    color: #F7FF00;
}
</style>