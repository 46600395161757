




































import { Vue, Component } from 'vue-property-decorator'
import ReportList from "@/modules/budget/monitoring/reports-constructor/tabs/constructor/report-list.vue";
import FilterSettingsPanel from "@/modules/budget/monitoring/reports-constructor/tabs/constructor/filter-settings-panel.vue";
import { Template } from '../../common/types/Template';
import { makeToast } from '../../common/utils/otherUtils';
import { cloneDeep } from 'lodash'
import { LoadToJournalRequest } from '../../common/types/root/LoadToJournalRequest';

@Component({
    components: {
        'report-list': ReportList,
        'filter-settings-panel': FilterSettingsPanel
    }
})
export default class ReportsConstructor extends Vue {

    public journalParams: LoadToJournalRequest | null = null;

    public selectedTemplate = null as Template | null

    public onSelectTemplate(template: Template | null) {
        this.selectedTemplate = template
    }

    setJournalParams(params: LoadToJournalRequest | null) {
        this.journalParams = params
    }

    public async sendToJournal() {
        if (this.journalParams == null) {
            makeToast(this, 'Для выгрузки необходимо сформировать отчет', 'Сообщение', '')
            return
        }

        makeToast(this, 'Началась выгрузка. Для скачивания отчета перейдите во вкладку "Журнал".', 'Внимание!', 'info')
        try {
            if (this.journalParams.reportName === '4-20') {
                const requestBody = cloneDeep(this.journalParams)
                const response = await fetch('/api/budget-execution-report-constructor/journal-4-20-spf', {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: { 'Content-Type': 'application/json' }
                })
                if (!response.ok) {
                    throw new Error('')
                }
            } else {
                const requestBody = cloneDeep(this.journalParams)
                const response = await fetch(`/api-py/monitoring/reports-constructor/journal/${requestBody.reportName}`, {
                    method: 'POST',
                    body: JSON.stringify(requestBody)
                })
                if (!response.ok) {
                    throw new Error('')
                }
            }

            makeToast(this, 'Сохранено в журнал', 'Сообщение', 'success')
        } catch (error) {
            makeToast(this, 'Не удалось сохранить в журнал', 'Ошибка', 'danger')
        }

        this.$emit('update-journal')
    }
}
