<template>
    <div>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="3">
                <!-------------Период---------------------->
                <b-form-group label="Дата с:" label-cols-md="3" class="bolded-label">
                    <date-picker
                        placeholder="Выберите дату"
                        format="DD/MM/YYYY"
                        v-model="valueDateFromForInput"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
                <!-------------Период---------------------->
                <b-form-group label="Дата по:" label-cols-md="3" class="bolded-label">
                    <date-picker
                        placeholder="Выберите дату"
                        format="DD/MM/YYYY"
                        v-model="valueDateToForInput"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!---------------------регион------------------------->
                <b-form-group label="Регион (Область, город, район, село):" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать регион"
                        track-by="code"
                        label="text"
                        v-model="reg"
                        :options="listReg"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="chgRegions"
                        ref="regionRef"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-2 bb-row">
            <b-col cols="12" md="6">
                <!-------------Ед. измерения---------------------->
                <b-form-group label="Единица измерения:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать единицу измерения"
                        v-model="curUnit"
                        :options="unit"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        class="w-150px"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="6">
                <!-------------Ед. измерения---------------------->
                <b-form-group label="Агрегация:" label-cols-md="3" class="bolded-label">
                    <multiselect
                        placeholder="Выбрать Агрегацию"
                        v-model="aggregation"
                        :options="aggregationList"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        label="label"
                        @input="chgRegions"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <template v-for="ch of orderedChild">
            <b-row v-if="ch === 0" :key="`${ch}_inc_code`" class="py-2 bb-row">
                <b-col cols="12" md="6">
                    <!-------------Код дохода---------------------->
                    <b-form-group label="Код дохода:" label-cols-md="3" class="bolded-label">
                        <multiselect
                            track-by="specific"
                            label="name"
                            placeholder="Выбрать код дохода"
                            v-model="curSpf"
                            :options="specifics"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="chgSpf()"
                        >
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="ch === 1" :key="`${ch}_sender`" class="py-2 bb-row">
                <b-col cols="12" md="6">
                    <!-------------Код налогоплательщик---------------------->
                    <b-form-group label="Налогоплательщик:" label-cols-md="3" class="bolded-label">
                        <multiselect
                            track-by="name"
                            label="name"
                            placeholder="Выбрать налогоплательщика"
                            v-model="curSenderBin"
                            :options="sendersBins"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="chgSenderBin"
                        >
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import store from "../../../services/store";
import {Ax} from '@/utils';
import DatePicker from 'vue2-datepicker';

export default {
    name: "treasury-form-243",
    components: { DatePicker },
    data() {
        return {
            obl: null,
            listReg: [],
            reg: null,
            valueDateFrom: null,
            valueDateFromForInput: null,
            valueDateTo: null,
            valueDateToForInput: null,
            // languages: ['Казахский', 'Русский'],
            // selectedLanguage: 'Русский',
            unit: ['тенге', 'тыс. тенге', 'млн. тенге'],
            curUnit: 'тенге',
            aggregation: {},
            aggregationList: [
                {
                    label: 'По КБК',
                    key: 0
                },
                {
                    label: 'По плательщику',
                    key: 1
                }
            ],
            specifics: [],
            curSpf: null,
            sendersBins: [],
            curSenderBin: null,
            isLoad: false,
        }
    },
    async mounted() {
        this.aggregation = this.aggregationList[0]
        // сегодняшняя дата
        this.valueDateFromForInput = new Date();
        this.valueDateFrom = new Date().toLocaleDateString().split('.').reverse().join('-');

        // послд день текущего года
        this.valueDateToForInput = new Date(`${new Date().getFullYear()}-12-31`);
        this.valueDateTo = new Date().getFullYear() + '-12-31';
        await this.getObl();
    },
    methods: {
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        // this.reg = json.region;
                    });
                await this.loadRegions();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            try {
                this.listReg = [];
                this.reg = null;
                if (this.valueDateFrom.includes('/')) {
                    this.valueDateFrom = this.valueDateFrom.split('/').reverse().join('-')
                }
                if (this.valueDateTo.includes('/')) {
                    this.valueDateTo = this.valueDateTo.split('/').reverse().join('-')
                }
                const response = await fetch(`/api-py/get_code_tpk_for_243/${this.obl}/${this.valueDateFrom}/${this.valueDateTo}/${this.usrId}`);
                this.listReg = await response.json();
                if (this.listReg && this.listReg.length > 0) {
                    this.reg = this.listReg[0];
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
            if (this.reg && this.reg.code) {
                await this.chgRegions();
            }
        }, // справочник регионов

        async chgRegions() {
            if (this.aggregation.key === 0) {
                await this.loadSpec();
            } else {
                await this.loadSenderBins()
            }
        },

        async loadSpec() {
            let result = [];
            this.curSpf = null;
            this.specifics = [];
            let path = `/api-py/get_specifics_for_243/${this.valueDateFrom}/${this.valueDateTo}/${this.reg.code}/${this.usrId}`
            if (this.aggregation.key === 1) {
                path += `/${this.curSenderBin.senderbin}/${this.curSenderBin.name_ru}`
            }
            try {
                result = await fetch(path)
                    .then(response => response.json());

                const res = []
                if (this.aggregation.key === 1) {
                    res.push({
                        specific: null,
                        name_ru: "Вce",
                        name: "Вce"
                    })
                }
                for (const el of result) {
                    res.push(this.setNameLang(el, 'specific'));
                }
                this.specifics = res;
                if (this.specifics.length > 0) {
                    this.curSpf = this.specifics[0];
                    await this.chgSpf()
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки спецификации', error);
            }

        },
        async chgSpf() {
            if (this.aggregation.key === 0 && this.curSpf && this.curSpf.hasOwnProperty('specific') && this.curSpf.specific) await this.loadSenderBins();
        },
        async chgSenderBin() {
            if (this.aggregation.key === 1 && this.curSenderBin && this.curSenderBin.hasOwnProperty('senderbin') && this.curSenderBin.senderbin) await this.loadSpec();
        },
        async loadSenderBins() {
            let path = `/api-py/get_senderbins_for_243/${this.valueDateFrom}/${this.valueDateTo}/${this.reg.code}/${this.usrId}`
            if (this.aggregation.key === 0) {
                path += `/${this.curSpf.specific}`
            }
            Ax(
                {
                    url: path,
                    method: 'GET'
                },
                (data) => {
                    const res = []
                    if (this.aggregation.key === 0) {
                        res.push({
                            senderbin: null,
                            name_ru: "Вce",
                            name: "Вce"
                        })
                    }
                    for (const el of data) {
                        res.push(this.setNameLang(el, 'senderbin'));
                    }
                    this.sendersBins = res
                    if (this.sendersBins.length > 1) {
                        this.curSenderBin = this.sendersBins[0];
                    }
                    this.chgSenderBin()
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса loadSenderBins()', error.toString());
                }
            );
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },

        downloadRep() {
            try {
                if (
                    !(this.reg && this.reg.hasOwnProperty('code') && this.reg.code) ||
                    !(this.curUnit) ||
                    !(this.valueDateFrom) ||
                    !(this.valueDateTo)
                ) {
                    this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                    this.$emit('onDownload', 0)
                    return
                }
                this.makeToast('info', this.$i18n.t('modules.execution.attention')
                    , this.$i18n.t('modules.execution.treasury_forms_informative_message'));
                this.isLoad = true
                const filter = {
                    region: this.reg.code,
                    unit: this.curUnit,
                    valuedateFrom: this.valueDateFrom,
                    valuedateTo: this.valueDateTo,
                    senderbin: null,
                    sendername: '',
                    specific: null,
                    aggregation: this.aggregation.key
                };
                if (this.curSenderBin && this.curSenderBin.hasOwnProperty('senderbin') && this.curSenderBin.senderbin) {
                    filter.senderbin = this.curSenderBin.senderbin
                    filter.sendername = this.curSenderBin.name_ru
                }
                if (this.curSpf && this.curSpf.hasOwnProperty('specific') && this.curSpf.specific) {
                    filter.specific = this.curSpf.specific
                }
                Ax(
                    {
                        url: '/api-py/treasury-form-xls-rep-243',
                        method: 'POST',
                        data: filter,
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Форма 02-43.xlsx');// or any other extension
                        this.$emit('onDownload', 100)
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                        this.$emit('onDownload', 0)
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                this.$emit('onDownload', 0)
            } finally {
                this.isLoad = false
            }
        },
        downloadRepStatement() {
            try {
                this.makeToast('info', this.$i18n.t('modules.execution.attention')
                    , this.$i18n.t('modules.execution.treasury_forms_informative_message'));
                this.isLoad = true
                const filter = {
                    region: this.reg.code,
                    unit: this.curUnit,
                    valuedateFrom: this.valueDateFrom,
                    valuedateTo: this.valueDateTo,
                    senderbin: null,
                    sendername: '',
                    specific: null,
                    aggregation: this.aggregation.key
                };
                if (this.curSpf && this.curSpf.hasOwnProperty('specific') && this.curSpf.specific) {
                    filter.specific = this.curSpf.specific
                }
                if (this.curSenderBin && this.curSenderBin.hasOwnProperty('senderbin') && this.curSenderBin.senderbin) {
                    filter.senderbin = this.curSenderBin.senderbin
                    filter.sendername = this.curSenderBin.name_ru
                }
                Ax(
                    {
                        url: '/api-py/treasury-form-xls-rep-statement-243',
                        method: 'POST',
                        data: filter,
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Выписка по форме 2-43.xlsx');// or any other extension
                        this.$emit('onDownload', 100)
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                        this.$emit('onDownload', 0)
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                this.$emit('onDownload', 0)
            } finally {
                this.isLoad = false
            }
        },
    },
    watch: {
        async valueDateFromForInput() {
            this.valueDateFrom = `${this.valueDateFromForInput.getFullYear()}-${this.valueDateFromForInput.getMonth() + 1}-${this.valueDateFromForInput.getDate()}`
            await this.loadRegions();
        },
        async valueDateToForInput() {
            this.valueDateTo = `${this.valueDateToForInput.getFullYear()}-${this.valueDateToForInput.getMonth() + 1}-${this.valueDateToForInput.getDate()}`
            await this.loadRegions();
        }
    },
    computed: {
        usrId() {
            if (!store.state.user.sub) { return null; }
            return store.state.user.sub;
        },
        orderedChild() {
            if (this.aggregation.key === 0) {
                return [0, 1]
            } else {
                return [1, 0]
            }
        }
    }
}
</script>

<style scoped>
    .bolded-label legend {
        margin-right: 50px!important;
    }
</style>