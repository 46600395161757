




















































































import {Component, Prop, Watch} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import '../user-management.scss';
import {IKeyCloakUser, IRegion} from '../user-management-int';
import BaseUserLink from '../base-user-link';

@Component({
    components: {
        'loading': VueElementLoading
    }
})
export default class RegionUserData extends BaseUserLink {
    private msuBase: any = [];
    private msuList: any[] = [];
    private regionBase: any = [];
    @Prop({default: "45"}) private oblRegion: string | undefined;
    private selectedUserMsuList: IRegion[] = [];
    private loading = false;
    private leftFilter = '';
    private rightFilter = '';
    private filterRegion = null;

    @Watch('selectedUser')
    async onPropertyChanged(value: IKeyCloakUser, oldValue: IKeyCloakUser) {
        this.loading = true;
        if (oldValue !== null) {
            this.selectedUserMsuList = [];
            this.msuList = [...this.msuBase];
            this.msuList.sort((a, b) => (a.code > b.code) ? 1 : -1);
        }
        await this.selectUser(value);
        this.loading = false;
    }

    private get leftFilterMsuList() {
        const res = [];
        for (const item of this.msuList.filter(value => (value.code.substr(4)!=="00" && value.code.substr(4)!=="01"))) {
            if (item.code.toString().includes(this.leftFilter)) {
                res.push(item);
            } else if (item.name_ru.toUpperCase().toString().includes(this.leftFilter.toUpperCase())) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadRegions() {
        try {
            const response = await fetch('/api-py/get-regions-by-obl/' + this.oblRegion);
            const data = await response.json() as IRegion;
            this.regionBase = data;
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    private get rightFilterMsuList() {
        const res = [];
        for (const item of this.selectedUserMsuList) {
            if (item.code.toString().includes(this.rightFilter)) {
                res.push(item);
            } else if (item.name_ru.toString().includes(this.rightFilter)) {
                res.push(item);
            }
        }
        return res;
    }

    private async loadFuncGroup(cd: string) {
        try {
            const url = '/api-py/get-regions-parent-by-obl/' + this.oblRegion + '/' + cd;
            const response = await fetch(url);
            this.msuBase = await response.json() as IRegion;
            this.msuList = [...this.msuBase];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    public async selectUser(user: IKeyCloakUser) {
        this.selectedUser = user;
        await this.loadUserFuncGroup(user);
        if (this.selectedUser !== null) {
            for (const item of this.selectedUserMsuList) {
                for (const it of this.msuList) {
                    if (it.code === item.code) {
                        const ind = this.msuList.indexOf(it);
                        this.msuList.splice(ind, 1);
                    }
                }
            }
        }
    }

    removeAllMsu() {
        for (const msuListElement of this.selectedUserMsuList) {
            this.msuList.push(msuListElement)
        }
        this.msuList.sort((a, b) => a.id - b.id);
        this.selectedUserMsuList = []
        this.saveUserMsuData(this.selectedUser);
    }

    addAllMsu() {
        for (const msuListElement of this.leftFilterMsuList) {
            if (!this.selectedUserMsuList.find(value => value.id === msuListElement.id)) this.selectedUserMsuList.push(msuListElement)
        }
        this.selectedUserMsuList.sort((a, b) => a.id - b.id);
        this.msuList = []
        this.saveUserMsuData(this.selectedUser);
    }

    private addMsuToSelectedUser(msu: IRegion, save: boolean) {
        if (this.selectedUserMsuList.indexOf(msu) < 0) {
            this.selectedUserMsuList.push(msu);
            const ind = this.msuList.indexOf(msu);
            this.msuList.splice(ind, 1);
        }
        this.selectedUserMsuList.sort((a, b) => (a.code > b.code) ? 1 : -1);
        if (save) {
            this.saveUserMsuData(this.selectedUser);
        }
    }

    private removeMsuFromSelectedUser(msu: IRegion, save: boolean) {
        const ind = this.selectedUserMsuList.indexOf(msu);
        this.selectedUserMsuList.splice(ind, 1);
        this.msuList.push(msu);
        this.msuList.sort((a, b) => (a.code > b.code) ? 1 : -1);
        if (save) {
            this.saveUserMsuData(this.selectedUser);
        }
    }

    private async saveUserMsuData(user: IKeyCloakUser) {
        const msuList: any = [];
        // массив выбраных ФГ для базы
        for (const item of this.selectedUserMsuList) {
            const d = {
                region: item.code
            };
            msuList.push(d);
        }
        // объект для передачи
        if (user !== null) {
            const saveData = {
                user_id: user?.id,
                regionList: msuList
            };
            try {
                await fetch('/api-py/save-user-msu', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(saveData)
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка сохранения данных: ' + user.id, error.toString());
            }
        }
    }

    private async loadMsuByCode(code: string) {
        const guResponse = await fetch('/api-py/dict-region-by-code/' + code);
        const guData = await guResponse.json() as IRegion[];
        return guData;
    }

    right_max_count = 0;

    private async loadUserFuncGroup(user: IKeyCloakUser) {
        if (user !== null)
            try {
                this.loading = true;
                this.selectedUserMsuList = [];
                const response = await fetch('/api-py/user-msu/' + user?.id);
                const data = await response.json();
                this.right_max_count = data.length;
                for (const item of data) {
                    const msuData = await this.loadMsuByCode(item.region);
                    for (const t of msuData) {
                        if (item.region === t.code) {
                            this.addMsuToSelectedUser(t, false);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки данных регионов по : ' + user.id, error.toString());
            } finally {
                this.loading = false;
            }
    }

    public async mounted() {
        await this.loadRegions();
        // await this.loadFuncGroup();
        if (this.selectedUser !== null) {
            this.loadUserFuncGroup(this.selectedUser);
        }
    }

}
