









































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import BTablePag from '@/modules/program-monitor2/components/b-table-pag.vue';
import store from '@/services/store';

// справочник
interface IDict {
    id: number;
    code: string | null;
    name: string;
}
// справочник ЕБК
interface IEbk extends IDict {
    ebkId: number;
    kat: number;
    clss: number;
    pcl: number | null;
    spf: number | null;
}

interface IDistrStandard extends IEbk {
    data: [ IDistrStandardData ];
    general?: IDistrStandardDataValues;
}

interface IDistrStandardData extends IDistrStandardDataValues {
    id: number;
    region: IDict;
    year: number;
    curYear: number;
    dateB: Date | null;
    dateE: Date | null;
}

interface IDistrStandardDataValues {
    sum: number; // консолидированный объём
    regionPer: number;
    districtPer: number;
}

interface ISelectIndx {
    ebkIndx: null | number;
    regionIndx: null | number;
    pgNum: number | null; // номер страницы
}

interface ISelectedData {
    regionPer: number;
    dateB: Date | null;
    dateE: Date | null;
}

@Component({
    name: 'c-budg-distr',
    components: {
        multiselect: Multiselect,
        'date-picker': DatePicker,
        'b-table-pag': BTablePag
    }
})
export default class BudgetDistribution extends Vue {
    @Prop({
        required: false,
        default: false
    })
    private editable!: boolean;

    private curPage = 1;
    private perPage = 100000;
    private pgRows = 0;

    private showModal = false;

    private editBtn = false;

    private periodLst: any[] = [];
    private curPeriod: any | null = null;
    private year: number = new Date().getFullYear();

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = year - 2020 + 1; i > 0; i--) {
            this.periodLst.push({ name: `${2020 + i} - ${2020 + i + 2}`, year: 2020 + i });
        }
        this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
        this.curYear = this.curPeriod.year;
        this.year = this.curYear;
    }

    private selectIndx: ISelectIndx= { ebkIndx: null, regionIndx: null, pgNum: null }; // выбранный индекс текущей страницы
    private selectedData: ISelectedData = { regionPer: 100, dateB: null, dateE: null };

    // -----------------------distr data ----------------
    private distrBase: any[] = [];

    private get distr(): IDistrStandard[] {
        const result: IDistrStandard[] = [];
        for (const el of this.distrBase) {
            const obj = Object.assign({}, this.setNameLang(el));
            // let regSum = 0;
            // let distrSum = 0;
            for (const dt of obj.data) {
                if (dt.region !== null) {
                    dt.region = this.setNameLang(dt.region);
                }
                // distrSum += dt.districtPer;
                // regSum += dt.regionPer;
            }
            // distrSum = Math.round((distrSum / obj.data.length) * 100) / 100;
            // regSum = Math.round((regSum / obj.data.length) * 100) / 100;
            // obj.general = { sum: 100, districtPer: distrSum, regionPer: regSum };
            result.push(obj);
        }
        this.pgRows = result.length;
        // if (this.curPage > Math.ceil(this.pgRows / this.perPage)) { this.curPage = Math.ceil(this.pgRows / this.perPage); }
        return result;
    }

    private get distrForPage(): IDistrStandard[] {
        if (this.distr.length === 0) { return []; }
        const result: IDistrStandard[] = [];
        let bIndx = (this.curPage - 1) * this.perPage;
        if (bIndx < 0) { bIndx = 0; }
        let endIndx = this.curPage * this.perPage;
        if (endIndx >= this.pgRows) { endIndx = this.pgRows; }
        for (let i = bIndx; i < endIndx; i++) {
            result.push(this.distr[i]);
        }
        return result;
    }
    // ---------------------------------------------------

    private obl = '';
    private curYear: number = new Date().getFullYear();

    // ---------- region ----------------
    private regionBase: any[] = [];
    private curRegion: any | null = null;

    private get region(): any {
        const result: any[] = [];
        for (const el of this.regionBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        return result;
    }
    // ------- ebk ----------------
    private ebkBase: any[] = [];
    private curEbk: any | null = null;

    private get ebk(): any[] {
        const result: any[] = [];
        for (const el of this.ebkBase) {
            result.push(this.setNameLang(el, 'full_code'));
        }
        return result;
    }
    // ---------------------------

    private async mounted() {
        this.getPeriodLst();
        await this.getObl();
        await this.loadEBK();
        this.loadRegion();
        this.$watch('usrId', () => { if (this.usrId !== null && !this.regionBase.length) { this.loadRegion(); } })
        //this.$watch('curRegion', this.setSelectIndx);
        //this.$watch('curEbk', this.setSelectIndx);
        this.$watch('curPeriod', this.chgCurPeriod);
    }

    private chgCurPeriod() {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.year;
            this.year = this.curYear;
            this.loadData();
        }
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private setSelectIndx(findByDate?: boolean) {
        this.selectIndx = { ebkIndx: null, regionIndx: null, pgNum: null };
        if (this.curEbk === null) { return; }
        const findD = this.findDistr(findByDate);
        if (findD === null || findD[0] === null) { return; }
        const selPg = Math.ceil((findD[0] + 1) / this.perPage);
        this.curPage = selPg;
        this.selectIndx.pgNum = selPg;
        this.selectIndx.ebkIndx = findD[0] % this.perPage;
        this.selectIndx.regionIndx = findD[1];
        if (findD !== null && findD[0] !== null && findD[1] !== null) {
            this.selectedData.regionPer = this.distrForPage[findD[0]].data[findD[1]].regionPer;
            this.selectedData.dateB = this.distrForPage[findD[0]].data[findD[1]].dateB;
            this.selectedData.dateE = this.distrForPage[findD[0]].data[findD[1]].dateE;
        } else {
            this.selectedData = { regionPer: 100, dateB: null, dateE: null };
        }
    }

    private async loadEBK() {
        this.ebkBase = await fetch('/api-py/get-dict-income-high/').then(response => response.json());
    }

    private async getObl() {
        try {
            await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                .then(response => response.json())
                .then(json => {
                    this.obl = json.obl;
                    // this.region = json.region;
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getObl', (error as Error).toString());
        }
    }

    private async loadRegion() {
        let result: any = [];
        if (this.usrId === null) { return; }
        try {
            const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl +'/'+  this.usrId);
            result = await response.json();
            /* for (let i = 0; i < result.length; i++) {
                const el = result[i];
                if (el.code !== null && el.code.substr(-4) === '0101') { result.splice(i, 1); }
            } */
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка запроса регионов', (error as Error).toString());
        }
        this.regionBase = result;
        this.loadData();
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined || txt === null) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined && obj[codeName] !== null) {
            txt = `${obj[codeName]}  -  ${txt}`;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    // ----- сопоставление данных с базы и текущих, есть ли изменения
    private compareOldNewDistr(obj: any): boolean {
        // { dateB: data.dateB, dateE: data.dateE, regionPer: data.regionPer, districtPer: data.districtPer };
        if (obj.dateB !== obj.oldData.dateB || obj.dateE !== obj.oldData.dateE || obj.regionPer !== obj.oldData.regionPer || obj.districtPer !== obj.oldData.districtPer) {
            return false;
        }
        return true;
    }

    private examDateBDateE() {
        if (this.selectedData.dateB !== null && this.selectedData.dateE !== null) {
            if (this.selectedData.dateB.getTime() > this.selectedData.dateE.getTime()) {
                this.makeToast('danger', 'Ошибка', 'Дата конца больше даты начала');
                return false;
            }
        } else {
            this.makeToast('danger', 'Ошибка', 'Не заполлнена дата начала или конца');
            return false;
        }
        return true;
    }

    private saveClk() {
        if (!this.examDateBDateE()) { return; }
        if (!this.editBtn) {
            if (!this.distrAdd()) { return; };
        } else {
            this.saveData();
        }
        this.showModal = false;
    }

    private async saveData() {
        if (this.selectIndx.ebkIndx === null || this.selectIndx.regionIndx === null || this.selectIndx.pgNum === null) { return; }

        const indx = (this.selectIndx.pgNum - 1) * this.perPage + this.selectIndx.ebkIndx;
        const el = this.distr[indx];
        const dt = this.distr[indx].data[this.selectIndx.regionIndx];
        dt.dateB = this.selectedData.dateB;
        dt.dateE = this.selectedData.dateE;
        this.selectedData.regionPer = Math.abs(this.selectedData.regionPer);
        dt.regionPer = this.selectedData.regionPer;
        dt.districtPer = Math.round((dt.sum - dt.regionPer) * 10) / 10;
        try {
            const saveObj: any[] = [{ id: dt.id, kat: el.kat, clss: el.clss, pcl: el.pcl, spf: el.spf, region: dt.region.code, year: dt.year, curYear: dt.curYear, 'region_per': dt.regionPer, 'district_per': dt.districtPer, 'beg_date': null, 'end_date': null, userId: this.usrId }];
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (dt.dateB !== null) { saveObj[0].beg_date = dt.dateB.getTime(); }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (dt.dateE !== null) { saveObj[0].end_date = dt.dateE.getTime(); }

            const url = '/api-py/budg-distr-stand-save';
            let response: any = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(saveObj) // body data type must match "Content-Type" header
            });
            response = await response.json();
            if (response[0].result === 'success') {
                if (dt.id === undefined || dt.id < 0) {
                    dt.id = response[0].data.__data__.id;
                }
                this.$emit('data-updated');
                this.makeToast('success', 'Сообщение', 'Элемент сохранен');
            } else {
                this.makeToast('danger', 'Сообщение', `Сохранение ${response[0].result}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения', (error as Error).toString());
        }
    }

    /* -----oldSaveData
    private async saveData() {
        const saveObj: any[] = [];
        for (const el of this.distrBase) {
            for (const dt of el.data) {
                if (dt.id !== undefined && dt.id >= 0) {
                    if (!this.compareOldNewDistr(dt)) {
                        saveObj.push({ id: dt.id, 'ebk_kat': el.kat, 'ebk_cls': el.cls, 'ebk_pcl': el.pcl, 'ebk_spf': el.spf, kato: dt.kato.code, year: Number.parseFloat(dt.year), 'region_per': Number.parseFloat(dt.regionPer), 'district_per': Number.parseFloat(dt.districtPer), 'beg_date': dt.dateB, 'end_date': dt.dateE });
                    }
                } else { // новая запись
                    saveObj.push({ id: -1, 'ebk_kat': el.kat, 'ebk_cls': el.cls, 'ebk_pcl': el.pcl, 'ebk_spf': el.spf, kato: dt.kato.code, year: Number.parseFloat(dt.year), 'region_per': Number.parseFloat(dt.regionPer), 'district_per': Number.parseFloat(dt.districtPer), 'beg_date': dt.dateB, 'end_date': dt.dateE });
                }
            }
        }
        const url = '/api-py/budg-distr-stand-save';
        let response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(saveObj) // body data type must match "Content-Type" header
        });
        response = await response.json();
        this.loadData();
    }
*/
    private async loadData() {
        let response: any | null = null;
        const params = { curYear: this.curYear, obl: this.obl, year: this.year }
        try {
            response = await fetch(`/api-py/get-budg-distr-stand/${encodeURI(JSON.stringify(params))}`);
            response = await response.json();
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка запроса get-budg-distr-stand', (error as Error).toString());
        }
        this.distrBase = [];
        const distMap = new Map();
        for (const el of response) {
            const distr = el.data;
            let ebk: any | null = null;
            if (distr.ebk !== undefined) { ebk = distr.ebk; }
            if (ebk !== null) {
                let region: null | any = null;
                if (distr.region !== null) {
                    region = { id: distr.region.id, code: distr.region.code, 'name_kk': distr.region.name_kk, 'name_ru': distr.region.name_ru };
                }
                const data: any = { id: distr.id, region: region, sum: 100, year: distr.year, dateB: (distr.bDate !== null ? new Date(distr.bDate) : null), dateE: (distr.eDate !== null ? new Date(distr.eDate) : null), regionPer: distr.region_per, districtPer: distr.district_per };
                data.oldData = { dateB: data.dateB, dateE: data.dateE, regionPer: data.regionPer, districtPer: data.districtPer };
                const mapEl = distMap.get(ebk.id);
                if (mapEl === undefined) {
                    distMap.set(ebk.id, { ebkId: ebk.id, code: ebk.full_code, name: '', 'name_kk': ebk.name_kk, 'name_ru': ebk.name_ru, kat: ebk.kat, clss: ebk.clss, pcl: ebk.pcl, spf: ebk.spf, data: [data] });
                } else {
                    mapEl.data.push(data);
                    distMap.set(ebk.id, mapEl);
                }
            }
        }
        const result = [];
        for (const value of distMap.values()) {
            result.push(value);
        }
        result.sort((a, b) => (a.code !== null && b.code !== null && a.code > b.code) ? 1 : -1);
        this.distrBase = result;
    }

    public dateFormat(dt: any | null): string {
        if (dt === null) { return ''; }
        const f = new Intl.DateTimeFormat('ru');
        return f.format(dt);
    }

    private chgRegion() {
        let val = Math.abs(Math.round(Number.parseFloat(String(this.selectedData.regionPer)) * 10) / 10);
        if (val > 100) { val = 100; }
        if (val !== this.selectedData.regionPer) { this.selectedData.regionPer = val; }
    }

    /* private chgRegion(data: IDistrStandardData) {
        let val = Math.round(Number.parseFloat(String(data.regionPer)) * 10) / 10;
        if (val > data.sum) {
            data.regionPer = data.sum;
            val = data.regionPer;
        }
        data.districtPer = data.sum - val;
        if (val !== data.regionPer) { data.regionPer = val; }
    } */

    private distrAdd() {
        if (!this.examDateBDateE()) { return false; }
        const findArr = this.findDistr();
        if (findArr && findArr[0]!==null && findArr[1]!==null) {
            const findObj = this.distrBase[findArr[0]].data[findArr[1]];
            if (findObj.dateB === this.selectedData.dateB && findObj.dateE === this.selectedData.dateE) {
                this.makeToast('warning', 'Ошибка добавления', 'Запись с такими датами уже существует!');
                return false;
            }
        }
        const region = { id: this.curRegion.id, code: this.curRegion.code, 'name_kk': this.curRegion.name_kk, 'name_ru': this.curRegion.name_ru };
        const data = { id: -1, region: region, sum: 100, year: parseInt(this.year.toString()), curYear: this.curYear, dateB: this.selectedData.dateB, dateE: this.selectedData.dateE, regionPer: this.selectedData.regionPer, districtPer: 0 };
        if (findArr === null) {
            const obj = { ebkId: this.curEbk.id, code: this.curEbk.full_code, name: '', 'name_kk': this.curEbk.name_kk, 'name_ru': this.curEbk.name_ru, kat: this.curEbk.kat, clss: this.curEbk.clss, pcl: this.curEbk.pcl, spf: this.curEbk.spf, data: [data] };
            this.distrBase.push(obj);
        } else if (findArr[0] !== null) {
            this.distrBase[findArr[0]].data.push(data);
        }
        this.setSelectIndx(true);
        this.saveData();
        return true;
    }

    private findDistr(findByDate?: boolean) {
        for (let i = 0; i < this.distr.length; i++) {
            const el = this.distr[i];
            if (el.ebkId === this.curEbk.id) {
                if (this.curRegion !== null) {
                    for (let j = 0; j < el.data.length; j++) {
                        const d = el.data[j];
                        if (d.region.id === this.curRegion.id) {
                            if (findByDate) {
                                if (d.dateB === this.selectedData.dateB && d.dateE === this.selectedData.dateE) {
                                    return [i, j];
                                }
                            } else {
                                return [i, j];
                            }
                        }
                    }
                }
                return [i, null];
            }
        }
        return null;
    }

    private clkSelect(ebkIndx: number, dataIndx: number) {
        const ebkId = this.distrForPage[ebkIndx].ebkId;
        const regionId = this.distrForPage[ebkIndx].data[dataIndx].region.id;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.ebk.length; i++) {
            if (this.ebk[i].id === ebkId) {
                this.curEbk = this.ebk[i];
                break;
            }
        }
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.region.length; i++) {
            if (this.region[i].id === regionId) {
                this.curRegion = this.region[i];
                break;
            }
        }
        this.selectedData.regionPer = this.distrForPage[ebkIndx].data[dataIndx].regionPer;
        this.selectedData.dateB = this.distrForPage[ebkIndx].data[dataIndx].dateB;
        this.selectedData.dateE = this.distrForPage[ebkIndx].data[dataIndx].dateE;

        this.setSelectIndx(true);
       /*  const selPg = Math.ceil((ebkIndx + 1) / this.perPage);
        this.curPage = selPg;
        this.selectIndx.pgNum = selPg;
        this.selectIndx.ebkIndx = ebkIndx;
        this.selectIndx.regionIndx = dataIndx;*/
    }

    private deleteRow(disIndx: number, dataIndx: number) {
        this.$bvModal.msgBoxConfirm(
            'Удалить запись?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.deleteData(disIndx, dataIndx);
                }
            })
            .catch(error => {
                this.makeToast('danger', 'Ошибка удаления', error.toString());
            });
    }

    private async deleteData(disIndx: number, dataIndx: number) {
        const indx = (this.curPage - 1) * this.perPage + disIndx;
        if (this.distrBase[indx].data[dataIndx].id >= 0) {
            try {
                let result: any | null = null;
                result = await fetch('/api-py/delete-budg-distr-stand/' + this.distrBase[indx].data[dataIndx].id)
                    .then(response => response.json());
                if (result.result === 'success') {
                    this.makeToast('success', 'Сообщение', 'Элемент удалён');
                } else if (result.result == 'existInLimit') {
                    this.makeToast('danger', 'Ошибка флк', 'По данному коду ЕБК введены данные в Лимиты доход, необходимо удалить данные');
                    return;
                } else {
                    this.makeToast('danger', 'Ошибка удаления', result.result);
                    return;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка удаления', (error as Error).toString());
                return;
            }
        }
        this.selectIndx = { ebkIndx: null, regionIndx: null, pgNum: null };
        if (this.distrBase[indx].data.length < 2) {
            this.distrBase.splice(indx, 1);
        } else {
            this.distrBase[indx].data.splice(dataIndx, 1);
        }
        this.setSelectIndx();
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private noAbc(evt: any) {
        // eslint-disable-next-line require-unicode-regexp
        const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
        if (!regex.test(key)) {
            evt.preventDefault();
            return false;
        }
    }

    private clkAdd(editBtn: boolean) {
        this.editBtn = false;
        this.showModal = true;
    }

    private clkEdit(disIndx: number, dataIndx: number) {
        this.clkSelect(disIndx, dataIndx);
        this.editBtn = true;
        this.showModal = true;
    }
}
