








































































































































































































































































import {Vue, Component} from 'vue-property-decorator';
import BExecAgrHistModal from '@/modules/budget/monitoring/input-forms/components/BExecAgrHistModal.vue'
import BEIFOpenWindow from "@/modules/budget/monitoring/input-forms/BEIFOpenWindow.vue";
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue";
import VueElementLoading from "vue-element-loading";
import axios from "axios";
import store from "@/services/store";

@Component({
    components: {
        'b-exec-agr-hist-modal': BExecAgrHistModal,
        'beif-open-window': BEIFOpenWindow,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'loading': VueElementLoading
    },
    filters: {
        formatDate(value: any) {
            if (value) {
                const date = new Date(value*1000);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
})
export default class InputForms extends Vue {
    // $refs!: {
    //     refHistModal: HTMLFormElement,
    //     app_window: HTMLFormElement
    // }
    private tableFields:any[] = [
        {
            label: '',
            key: 'action'
        },
        {
            label: '№',
            key: 'number'
        },
        {
            label: 'Регион',
            key: 'region'
        },
        {
            label: 'АБП',
            key: 'abp'
        },
        // {
        //     label: 'Тип',
        //     key: 'type'
        // },
        {
            label: 'Форма',
            key: 'form'
        },
        {
            label: 'Отчётный период',
            key: 'rep_period'
        },
        {
            label: 'Статус',
            key: 'status'
        },
        {
            label: 'Дата обновления',
            key: 'update_date'
        },
        {
            label: 'Пользователь',
            key: 'user'
        },
        {
            label: '',
            key: 'more'
        }
    ]
    private user_id:string|null = null
    private userBudgetLevel: string[] = []
    private moduleAccess:any = null;
    private statusList:any[] = []
    private realmUsers:any[] = []
    private currYear:number = new Date().getFullYear();
    private months:any[] = [];
    private selectedMonth:any = null;
    private selectedStatus:any = null;
    private childStatus:any = null;
    private obl:string|null = null;
    private region:any = null
    private regionBase:any[] = []
    private regionDict:any = {}
    private currAbp:any = null
    private abpBase:any[] = []
    private get regionList() {
        const regList:any[] = []
        for (const r of this.regionBase) {
            regList.push(Object.assign({
                name: `${r.code} - ${r[`name_${this.$i18n.locale}`]}`
            }, r))
        }
        return regList
    }
    private get abpList() {
        const list = []
        let inputList:any[] = [...this.abpBase]
        if (this.abpBase.length > 1) {
            inputList = [{
                abp: null,
                name_ru: 'Все',
                name_kk: 'Барлығы'
            }, ...this.abpBase]
        }
        for (const abp of inputList) {
            const abpObj = Object.assign({}, abp)
            abpObj.name = abpObj.abp !== null? `${abpObj.abp} - ${abpObj[`name_${this.$i18n.locale}`]}`: abpObj[`name_${this.$i18n.locale}`]
            list.push(abpObj)
        }
        list.sort((a, b) => a.abp - b.abp)
        return list
    }
    private get filterYears() {
        const now = new Date();
        const yearNow = now.getFullYear();
        const years = [];
        for (let i = yearNow; i >= yearNow - 10; i--) {
            if (i >= 2020) {
                years.push(i);
            }
        }
        return years;
    }
    private get repdate() {
        const month = this.selectedMonth.key
        const year = this.currYear
        // if (month === 0) {
        //     month = 11
        //     year = year - 1
        // } else {
        //     month -= 1
        // }
        return new Date(year, month, 0)
    }
    private get repdateText() {
        return `${this.repdate.getFullYear()}-${this.repdate.getMonth() + 1}-${this.repdate.getDate()}`
    }

    private get repPeriod() {
        const periods = [[1, 2, 3], [1], [1], [1, 2], [1], [1], [1, 2], [1], [1], [1, 2], [1], [1]]

        const period = Math.max(...periods[this.selectedMonth.key])
        if (period === 1) {
            return {text: 'Месяц', code: 1}
        } else if (period === 2) {
            return {text: 'Квартал', code: 4}
        } else if (period === 3) {
            return {text: 'Годовой', code: 12}
        }
        return {text: 'День', code: 0}
    }
    private get getPeriodText() {
        if (this.repPeriod.code === 1) {
            return this.months[this.repdate.getMonth()].label
        } else if (this.repPeriod.code === 4) {
            let q = 'I'
            if (this.selectedMonth.key === 6) {
                q += 'I'
            }
            if (this.selectedMonth.key === 9) {
                q += 'II'
            }
            return `${q}  ${this.$t('modules.budget_execution_monitoring.input_forms.period_4').toString().toLowerCase()}`
        } else if (this.repPeriod.code === 12) {
            return `${this.currYear - 1} ${this.$t('modules.budget_execution_monitoring.input_forms.period_12').toString().toLowerCase()}`
        } else {
            return this.repdateText
        }

    }
    private get isZeroReg() {
        return this.region && this.region.hasOwnProperty('code') && (this.region.code.slice(4, 6) === '00')
    }
    private get isReg() {
        if (this.isZeroReg) {
            return this.region.code.slice(2, 4) !== '00'
        } else {
            return true
        }
    }
    private get haveEditAccess() {
        return this.moduleAccess.access_level > 1
    }
    private get haveDeleteAccess() {
        return this.haveEditAccess
    }
    private repType:number = 2
    private app9repForm = 1
    private currForm:any = null;
    private selectAll:boolean = false;
    private selectAllFroms() {
        this.selectedFormList.splice(0)
        if (this.selectAll) {
            for (const f of this.filteredDataList) {
                this.selectedFormList.push(f)
            }
        }
        // if (this.selectedFormList.length > 0) {this.agreementEvent(false)} else {this.agrEnabled = false}
    }
    private selectedFormList:any[] = []
    private inputFormList:any[] = []
    private form:any = null
    private async created() {
        const that = this;
        this.user_id = this.$store.state.user.sub
        const m:any = {
            en: ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'],
            kk: ['Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым',
                'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан'],
            ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь',
                'Октябрь', 'Ноябрь', 'Декабрь']
        }

        for (let i = 0; i < 12; i++) {
            const mo:any = {
                key: i
            }
            Object.defineProperty(mo, 'label', {
                get: function () {
                    return m[that.$i18n.locale][i]
                }
            })
            this.months.push(mo)
        }
        await this.loadOperations()
        this.userBudgetLevel = this.$store.getters.userBudgetLevel.map((v:string) => v.replace('level_', ''))
        this.moduleAccess = this.$store.state.user.userModules.find((v:any) => v.modules === this.moduleCode)
        if (!this.moduleAccess) {
            this.moduleAccess = {
                modules: this.moduleCode,
                access_level: 1
            }
        }
        this.selectedMonth = this.months[new Date().getMonth()];
        await this.loadRegion()
        await this.loadAbpList()
        await this.loadFormList()
        await this.getAllAgreemenSteps()
        this.statusList = await this.getStatusList(this.mode)
        await this.loadExecutors()
        for (const f of this.tableFields.slice(2, 9)) {
            Object.defineProperty(f, 'label', {
                get() {
                    return that.$t('modules.budget_execution_monitoring.input_forms.forms_table.' + f.key)
                }
            })
        }
    }
    private async chgYear() {
        await this.loadRegion()
        await this.regionSelected()
    }
    private async regionSelected() {
        await this.loadAbpList()
        await this.loadFormList()
        this.statusList = await this.getStatusList(this.mode)
    }

    private async loadFormList() {
        const req:any = {
            user_id: this.user_id,
            userBudgetLevel: this.userBudgetLevel,
            region: this.region.code,
            year: this.currYear,
            month: this.selectedMonth.key,
            form: this.form && this.form.hasOwnProperty('code') && this.form.code ?this.form.code: null,
            type: this.repType,
            operations: this.operationCode
        }
        await axios.post(`/api-py/input-forms/forms`, req).then(resp => {
            if (resp.status == 200) {
                this.inputFormList = resp.data
                if (this.inputFormList.length) {
                    this.form = this.inputFormList[0]
                }
            }
        })
    }
    private isApp9:boolean = false;

    private async loadRegion() {
        if (!this.obl) {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        }
        const that = this
        await axios.get(`/api-py/input-forms/regions/${this.obl}/${this.user_id}/${this.repdateText}`).then(resp => {
            if (resp.status === 200) {
                that.regionBase = resp.data
                that.region = that.regionList[0]
                for (const r of that.regionList) {
                    that.regionDict[r.code] = r
                }
             }
        })
    }
    private async loadAbpList() {
        await axios.get(`/api-py/get-abp-by-user-id-region/${this.user_id}/${this.region.code}/${this.currYear}`).then(resp => {
            if (resp.status === 200) {
                this.abpBase = resp.data
                this.currAbp = this.abpList[0]
            }
        })
    }

    private async loadExecutors() {
        await axios.get('/api-py/get-realm-users/')
            .then(resp => {
                if (resp.status === 200) {
                    this.realmUsers = resp.data
                }
            }).catch((error:any) => {})
    }
    private getLocalUser(user_id: any) {
        const infoAboutUser = this.realmUsers.find((d:any) => d.id === user_id)
        if (!infoAboutUser) {
            return ''
        }
        let name = ''
        if (infoAboutUser['lastName']) {
            name += infoAboutUser['lastName']
        }
        if (infoAboutUser['firstName']) {
            name += ' ' + infoAboutUser['firstName']
        }
        return name
    }

    private async getStatusList(mode: string) {
        try {
            const that = this;
            let statusList: any[] = [];
            const response = await axios.get(`/api-py/get-status-list/${mode}`);
            if (response.status === 200) {
                statusList = response.data;
                for (const st of statusList) {
                    Object.defineProperty(st, 'name', {
                        get: function () {
                            return st[`name_${that.$i18n.locale}`]
                        }
                    })
                }
            }
            if (statusList.length) {
                that.selectedStatus = statusList[0]
            }
            return statusList;
        } catch (e) {
            console.error("Error fetching status list:", e);
            return [];
        }
    }
    private getLocalStatus(value:any) {
        const status = value.status;
        const curStatus = this.statusList.filter((item) => item.code === status);
        if (!curStatus || !curStatus.length) return status;
        const localName = curStatus[0]["name_" + this.$i18n.locale];
        return localName;
    }

    private showChildForms:boolean = false;
    private dataList:any[] = []
    private get filteredDataList() {
        let dataList = this.dataList
        if (this.isZeroReg) {
            if (dataList.length && this.showChildForms) {
                if (this.isReg) {
                    dataList = dataList[0].child_list
                } else {
                    dataList = dataList.concat(dataList[0].child_list.filter((v:any) => v.form_id === this.form.id))
                }
            }
        }
        dataList.sort((a:any, b:any) => {
            // Compare string fields
            if (a.region < b.region) return -1;
            if (a.region > b.region) return 1;

            // If string fields are equal, compare numerical fields
            if (a.abp === null && b.abp === null) return 0;      // Both null, considered equal
            if (a.abp === null) return -1;                      // 'a' has null abp, comes first
            if (b.abp === null) return 1;                       // 'b' has null abp, 'a' comes first

            //    b. If both 'abp' are not null, sort numerically
            return a.abp - b.abp;
        })
        if (this.selectedStatus && this.selectedStatus.hasOwnProperty('code') && this.selectedStatus.code) {
            return dataList.filter(v => v.status.status === this.selectedStatus.code)
        }
        return dataList
    }

    private async getData(req:any = {}) {
        const that = this
        let dataList:any[] = []
        await axios.post('/api-py/input-forms', req).then(resp => {
            if (resp.status == 200) {
                dataList = resp.data
                that.loading = false
            }
        }).catch(e => {
            that.loading = false
        })
        return dataList
    }
    loading = false
    private async loadData() {
        this.dataList = []
        this.currForm = null
        const req:any = {
            user_id: this.user_id,
            userBudgetLevel: this.userBudgetLevel,
            region: this.region.code,
            year: this.currYear,
            month: this.selectedMonth.key,
            form: this.form && this.form.hasOwnProperty('code') && this.form.code ? (this.isZeroReg && this.isReg && this.form.code === 'app8'? 'app9': this.form.code): null,
            abp: (this.repType === 2 && !this.isZeroReg && this.currAbp && this.currAbp.hasOwnProperty('abp') ? this.currAbp.abp : null),
            type: this.repType,
            operations: this.operationCode,
            edit_access: this.haveEditAccess
        }
        this.selectedFormList = []
        this.selectAll = false
        this.showChildForms = this.region.code.slice(2, 6) === '0000' || (this.region.code.slice(4, 6) === '00' && this.form.code === 'app8')
        this.loading = true
        this.dataList = await this.getData(req)
    }

    private async deleteForm(form:any) {
        const req:any = Object.assign({repdate: this.repdateText}, form)
        const that = this;
        await this.$bvModal.msgBoxConfirm(
            this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.confirm_form_deletion').toString(),
            {
                title: this.$t('modules.budget_execution_monitoring.reports.app_9.confirmation').toString(),
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.yes').toString(),
                cancelTitle: this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.cancellation').toString(),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                if (value) {
                    that.loading = true
                    await axios.post('/api-py/input-forms/delete', req).then(resp => {
                        if (resp.status === 200){
                            axios.post('/api-py/fulfillment-report/app-9/data/delete', req)
                                .then(resp => {})
                                .catch(e => {})
                            if (that.isZeroReg) {
                                that.loadData()
                            } else {
                                that.dataList = that.dataList.filter(v => v.id !== form.id)
                                that.loading = false
                            }
                        }
                    })
                }
            });

    }

    private async openHistory(agrObj: any) {
        if (agrObj !== null) {
            // eslint-disable-next-line
            // @ts-ignore
            await this.$refs.refHistModal.showEvent(agrObj);
        }
    }
    private openInputForm = false;

    private get mode() {
        if (!this.region) return 'form_msu'
        const xx = this.region.code.slice(0, 2)
        const yy = this.region.code.slice(2, 4)
        const zz = this.region.code.slice(4, 6)
        const yyzz = this.region.code.slice(2, 6)
        if (yyzz === '0000' || zz === '00') {
            return 'form_uo'
        } else {
            if (zz !== '00' && zz !== '01') {
                return 'form_msu'
            } else {
                return 'form_abp'
            }
        }
    }
    private moduleCode = '005.003.008'

    private async toggleOpen(val:any) {
        this.currForm = val
        if (!this.currForm.parent) {
            this.currForm.parent = await this.getParent(val)
        }
        if (this.statusList.length) this.childStatus = this.statusList[0]
        this.openInputForm = true;
        this.isApp9 = true
    }
    private async closeWindow() {
        this.openInputForm = false
        this.isApp9 = false
        await this.loadData()
    }

    private operationCode:any[] = []

    private async loadOperations() {
        this.operationCode = [];
        if (store.state.user.sub === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=${this.moduleCode}`))
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.user_operations_error'), error.toString());
            return;
        }
        if (result.operations) { this.operationCode = result.operations; }
    }

    private agreementData:any[] = [];
    private agreementTree:any = {};
    private async getAllAgreemenSteps() {
        await axios.get('/api-py/get-all-agreement-status/' + encodeURI(JSON.stringify({ mode_code: this.mode }))).then(resp => {
            if (resp.status === 200) {
                this.agreementData = resp.data.filter((agreement:any) => agreement.stepBack.length > 0 || agreement.stepForward.length > 0)
                    .map((agreement:any) => {
                        return {
                            agrCode: agreement.code,
                            stepBack: agreement.stepBack.map((sb:any) => sb.step_code),
                            stepForward: agreement.stepForward.map((sf:any) => sf.step_code)
                        }
                    });
                const tree:any = {}
                for (const agr of this.agreementData) {
                    tree[agr.agrCode] = {
                        agrCode: agr.agrCode,
                        stepBack: agr.stepBack.length > 0? agr.stepBack[0]: null,
                        stepForward: agr.stepForward.length > 0? agr.stepForward[0]: null
                    }
                }
                this.agreementTree = tree
            }
        })
    }

    private validateSelectedData() {
        if (this.selectedFormList.length < 2) return [true, true, true]; // No conflict with fewer than 2 items

        const firstStatus = this.selectedFormList[0].status.status;
        const { agrCode, stepBack, stepForward } = this.agreementTree[firstStatus] || {};
        let goBack = true
        let goForward = true
        for (let i = 1; i < this.selectedFormList.length; i++) {
            // if (!goBack && !goForward) break
            const stepInfo = this.agreementTree[this.selectedFormList[i].status.status];

            if (goBack && (!stepInfo || stepInfo.stepBack !== stepBack)) {
                goBack = false; // Validation failed
            }
            if (goForward && (!stepInfo || stepInfo.stepForward !== stepForward)) {
                goForward = false; // Validation failed
            }
        }
        return [goBack || goForward, goBack, goForward]; // Validation passed
    }

    private validateByChild() {
        if (!this.isZeroReg) {
            return true
        } else {
            for (const sel of this.selectedFormList) {
                for (const ch of sel.child_list) {
                    if (ch.status.status !== 26) {
                        return false
                    }
                }
            }
            return true
        }
    }

    private async getParent(val:any) {
        const code = val.region
        const [xx, xxyy, yyzz, zz] = [code.slice(0, 2), code.slice(0, 4), code.slice(2, 6), code.slice(4, 6)]
        if (yyzz === '0000') {
            return null
        } else {
            let parentL:any[] = []
            const req:any = {
                user_id: this.user_id,
                userBudgetLevel: this.userBudgetLevel,
                region: zz === '00' || yyzz === '0101'?`${xx}0000`: `${xxyy}00`,
                year: this.currYear,
                month: this.selectedMonth.key,
                form: null,
                abp: null,
                type: this.repType,
                operations: this.operationCode
            }
            parentL = await this.getData(req)
            return parentL.length? parentL[0] :null
        }
    }
    private async validateByParent() {
        for (const val of this.selectedFormList) {
            const code = val.region
            const [xx, xxyy, yyzz, zz] = [code.slice(0, 2), code.slice(0, 4), code.slice(2, 6), code.slice(4, 6)]
            const parent = await this.getParent(val)
            if (parent && (parent.status.status === 26 || parent.status.status === 31)) {
                return false
            }
        }
        return true
    }
    private agrBtnLst: any = { back: [], forward: [] };
    private agrEnabled = true;
    // кнопка действия
    private async agreementEvent(show = true) {
        const [isValidRequest, goBack, goForward] = this.validateSelectedData()
        if (!isValidRequest) {
            if (show) this.makeToast('warning', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.agreement'), this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.no_common_agreement_steps'));
            return
        }
        const params = { modeCode: this.mode, operationCode: this.operationCode, agrCode: this.selectedFormList[0].status.status };
        if (!show) { this.agrEnabled = false; }
        let result: any[] = [];
        try {
            const response = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
        } catch (error) {
            if (show) this.makeToast('danger', 'Ошибка get-agreement-step-next-back', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.agreement_steps_error'));
            return;
        }
        if (result.length === 0) {
            if (show) this.makeToast('warning', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.agreement'), this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.no_agreement_steps'));
            return;
        }
        this.agrBtnLst = { back: [], forward: [] };

        for (const el of result) {
            if (el.stepType === 1) {
                if (goBack) this.agrBtnLst.back.push(el);
            } else {
                if (goForward) this.agrBtnLst.forward.push(el);
            }
        }
        if (this.agrBtnLst.back.length || this.agrBtnLst.forward.length) {
            this.agrEnabled = true;
        }
        if (show) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refAgrModal.showEvent();
        }
    }

    private flk0Agr() {
        for (const form of this.selectedFormList) {
            if (form.flk === null || form.flk) {
                return true
            }
        }
        return false
    }
    private async agrClick(data:any) {
        if (!await this.validateByParent()) {
            this.makeToast('danger', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.agreement'), this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.validate_parent_error'))
            return
        }
        if (data.status === 31) {
            if (!this.validateByChild()) {
                this.makeToast('danger', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.agreement'), this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.validate_child_error'))
                return
            }
        }
        if (this.flk0Agr() && data.status === 31) {
            this.makeToast('danger', this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.agreement'), this.$t('modules.budget_execution_monitoring.input_forms.massage_tools.flk0_error'))
            return
        }
        await axios.post(
            '/api-py/input-forms/status',
            {
                form_id: this.selectedFormList.map(value => value.id),
                description: data.commentTxt,
                status: data.status,
                user_id: this.$store.getters.user.sub
            }
        ).then(res => {
            if (res.status === 200){
                this.loadData()
            }
        })
    }
    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }
    private get regionBreadCrumb() {
        if (this.currForm && this.currForm.hasOwnProperty('region')) {
            return this.currForm.region
        } else if (this.region && this.region.hasOwnProperty('code')) {
            return this.region.code
        } else return null
    }
    private get abpBreadCrumb() {
        if (this.isApp9 && this.currForm && this.currForm.hasOwnProperty('abp') && this.currForm.abp) {
            return this.currForm.abp
        } else if (!this.isZeroReg && this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
            return this.currAbp.abp
        } else return null
    }
    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

}
