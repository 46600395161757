import moment from "moment"

export const getLastDateOfQuarter = (year: number, quarter: number): string => {
    if (quarter < 1 || quarter > 4) {
        throw new Error("Quarter must be between 1 and 4")
    }

    // Map quarter to the last month of that quarter
    const map: Record<number, number> = {
        1: 3, // Q1 -> March
        2: 6, // Q2 -> June
        3: 9, // Q3 -> September
        4: 12 // Q4 -> December
    }

    const lastMonthOfQuarter: number = map[quarter]

    return moment([year, lastMonthOfQuarter-1, 1]).endOf('month').format('YYYY-MM-DD')
}

export const getLastDateOfMonth = (year: number, month: number): string => {
    if (month < 1 || month > 12) {
        throw new Error("Month must be between 1 and 12")
    }

    return moment([year, month-1, 1]).endOf('month').format('YYYY-MM-DD')
}
