













































import {Vue, Component, Prop} from 'vue-property-decorator';
import applicationForm9 from "@/modules/budget/monitoring/input-forms/components/application-form-9.vue";

@Component({
    components: {
        'application-form-9': applicationForm9
    }
})
export default class BEIFOpenWindow extends Vue {
    $refs!: {
        refApp9: HTMLFormElement
    }
    private cutness: any = null;
    @Prop({
        required: false,
        default: null
    })
    private region!:any;
    @Prop({
        required: false,
        default: null
    })
    private currAbp!:any
    @Prop({
        required: false,
        default: null
    })
    private date_to!:any;
    @Prop({
        required: false,
        default: ''
    })
    private user_id!:any;
    @Prop({
        required: false,
        default: null
    })
    private period!:any;
    @Prop({
        required: false,
        default: 1000, // Set the desired default here
    })
    private convert!:any;
    @Prop({
        required: true,
        default: null
    })
    private currForm!:any;
    @Prop({
        required: true,
        default: null
    })
    private app9repForm!:any;
    @Prop({
        required: true,
        default: null
    })
    private editAccess!:any;
    @Prop({
        required: true,
        default: null
    })
    private deleteAccess!:any;
    @Prop({
        required: true,
        default: null
    })
    private childStatus!:any;

    private get haveEditAccess() {
        return this.editAccess && ![31, 26].includes(this.currForm.status.status) && this.currForm.region.slice(4, 6) !== '00' && (!this.currForm.parent || (this.currForm.parent && this.currForm.parent.status && ![31, 26].includes(this.currForm.parent.status.status)))
    }
    private get haveDeleteAccess() {
        return this.haveEditAccess
    }
    private isApp9: boolean = true;

    private mounted() {
        this.isApp9 = true;
    }
    private saveForm() {
        this.$refs.refApp9.saveData()
    }
    private showActionsTab = true;

    private async onBack() {
        const isReady = await this.$refs.refApp9.onBack()
        if (isReady) this.$emit('onBack')
    }
    private onSaveReason() {
        if (this.$refs.refApp9) {
            this.$refs.refApp9.onSaveReason();
        }
    }
    private closeReasonInput() {
        this.showActionsTab = true;
    }
    private openReasonInput() {
        this.showActionsTab = false
        this.$refs.refApp9.showReasonInput = true;
    }
    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

}
