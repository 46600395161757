import { cloneDeep } from "lodash";
import { FilterOptions } from "../../../common/types/root/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/root/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/root/FilterSettingsForm";
import { Option } from "../../../common/types/Option";
import { BudgetType } from "../../../common/types/root/fields";
import { report127Expenses, report127FilterOptions, report127Indicators } from "../report-127";
import { Report127FilterSettingsForm } from "../settings-form/Report127FilterSettingsForm";
import { Report127FilterSettings } from "../settings/Report127FilterSettings";
import { Report127TableDataRequest } from "../other-types/Report127TableDataRequest";
import { getLastDateOfMonth } from "../../../common/utils/dateUtils";
import { allIncomeClassificators } from "../../../common/baseOptions";
import { Report127JournalRequest } from "../journal/Report127JournalRequest";
import { Report127Row, Report127TableBaseRow, Report127TableExpenseRow, Report127TableIncomeRow, SectionSeparator } from "../table-data/table-data-types";

export class Report127Facade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(report127FilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== '1-27') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== '1-27') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        const regions: Option<string>[] = []
        settings.regions.forEach(region => {
            const option = options.regions.find(it => it.value === region)!
            regions.push({...option})
        })

        let mutuallyRedeeming = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeeming) {
            throw new Error('Не найден mutuallyRedeeming')
        }
        mutuallyRedeeming = { ...mutuallyRedeeming }

        const budgetTypes: Option<BudgetType>[] = []
        settings.budgetTypes.forEach(budget => {
            const budgetTypeOption = options.budgetTypes.find(item => item.value === budget)!
            budgetTypes.push({...budgetTypeOption})
        })

        let measureUnit = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnit) {
            throw new Error('Не найден measureUnit')
        }
        measureUnit = { ...measureUnit }

        let budgetStructure = options.budgetStructures.find(item => item.value == settings.budgetStructure) ?? null
        if (budgetStructure) {
            budgetStructure = {...budgetStructure}
        }

        let developType = options.developTypes.find(it => it.value === settings.developType)
        if (!developType) {
            throw new Error('Не найден developType')
        }
        developType = { ...developType }

        let month = options.months.find(it => it.value == settings.month) ?? null
        if (month) {
            month = { ...month }
        }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        let expenseType = options.expenseTypes.find(it => it.value == settings.expenseType) ?? null
        if (!expenseType) {
            throw new Error('Не найден expenseType')
        }
        expenseType = { ...expenseType }

        // 2. Формирование
        const form: Report127FilterSettingsForm = {
            key: '1-27',

            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,

            regions: regions,
            mutuallyRedeeming: mutuallyRedeeming,
            budgetTypes: budgetTypes,
            measureUnit: measureUnit,
            budgetStructure: budgetStructure,
            developType: developType,
            year: settings.year,
            month: month,
            separateByRegion: settings.separateByRegion,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null,
            incomeTotal: settings.incomeTotal,
            expenseTotal: settings.expenseTotal,

            incomes: cloneDeep(settings.incomes),
            expenseType: expenseType,
            expenses: cloneDeep(settings.expenses),
            indicators: cloneDeep(settings.indicators)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== '1-27') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (form.budgetStructure == null) {
            throw new Error('Необходимо ввести "Стуктура бюджета"')
        }
        if (form.developType == null) {
            throw new Error('Необходимо ввести "Вид программы"')
        }
        if (form.year == null) {
            throw new Error('Необходимо ввести "Год"')
        }
        if (form.month == null) {
            throw new Error('Необходимо ввести "Месяц"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: Report127FilterSettings = {
            reportName: '1-27',

            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,

            regions: form.regions.map(it => it.value),
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            budgetTypes: form.budgetTypes.map(it => it.value),
            measureUnit: form.measureUnit.value,
            budgetStructure: form.budgetStructure.value,
            developType: form.developType.value ?? null,
            year: form.year,
            month: form.month.value,
            separateByRegion: form.separateByRegion,
            roundUpTo: roundUpTo,
            incomeTotal: form.incomeTotal,
            expenseTotal: form.expenseTotal,
            incomes: cloneDeep(form.incomes),
            expenseType: form.expenseType.value,
            expenses: cloneDeep(form.expenses),
            indicators: cloneDeep(form.indicators)
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(report127FilterOptions)

        const emptyForm: Report127FilterSettingsForm = {
            key: '1-27',

            reportName: options.reportNames.find(it => it.value === '1-27')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'MIXED')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'MONTH')!,

            regions: [],
            mutuallyRedeeming: options.mutuallyRedeeming.find(it => it.value === 'WITH')!,
            budgetTypes: [],
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            budgetStructure: null,
            developType: options.developTypes.find(it => it.value === null)!,
            year: (new Date().getFullYear()),
            month: null,
            separateByRegion: false,
            roundUp: false,
            roundUpTo: null,
            incomeTotal: false,
            expenseTotal: false,
            
            incomes: cloneDeep(allIncomeClassificators),
            expenses: cloneDeep(report127Expenses),
            expenseType: options.expenseTypes.find(it => it.value === 'FUNCTIONAL')!,
            indicators: cloneDeep(report127Indicators)
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций
        if (form.key !== '1-27') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.year || !form.month) {
            throw new Error('Необходимо ввести "Год" и "Месяц"')
        }

        if ('regions' in form && form.regions.length === 0) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        const activeIncomes = form.incomes.filter(it => it.active)
        if (form.mutuallyRedeeming.value !== 'WITH' && activeIncomes.length > 0) {
            const doesRegionAlignWithMutuallyRedeeming = (region: string): boolean => {
                const isOblast = ['0101', '0000'].includes(region.substring(2))
                const isRayon = ['01', '00'].includes(region.substring(4))
                if (!isOblast && !isRayon) {
                    return false
                }
                return true
            }

            form.regions.map(it => it.value).forEach(region => {
                if (!doesRegionAlignWithMutuallyRedeeming(region)) {
                    throw new Error('Для "без/только ВЗК" необходимо выбрать областной/районный регион')
                }
            })
        }

        if (form.developType == null) {
            throw new Error('Необходимо ввести "Вид программы"')
        }
        const developType = form.developType.value


        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        // 2. Формирование данных для таблицы
        const tempIncomes = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        const tempExpenses = form.expenses
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        const params: Report127TableDataRequest = {
            incomes: tempIncomes,
            expenses: tempExpenses,
            expense_type: form.expenseType.value,
            year: form.year,
            month: form.month.value,
            regions: form.regions.map(it => it.value),
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
            mutually_redeeming: form.mutuallyRedeeming.value,
            budget_structure: form.budgetStructure?.value ?? null,
            develop_type: developType,
            income_total: form.incomeTotal,
            expense_total: form.expenseTotal,
            separate_by_region: form.separateByRegion
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/1-27',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseTableData = await response.json()

        let index = 0
        // functions are defined in this scope to use 'index' variable for unique keys
        const mapExpenseRow = (item: any): Report127TableExpenseRow => {
            const temp: Report127TableExpenseRow = {
                index: index++,
                gr: item.gr ?? null,
                pgr: item.pgr ?? null,
                abp: item.abp ?? null,
                prg: item.prg ?? null,
                ppr: item.ppr ?? null,

                kat: item.kat ?? null,
                cls: item.cls ?? null,
                pcl: item.pcl ?? null,

                spf: item.spf ?? null,

                nameRu: item.name ?? null,
                utv: item.utv,
                utch: item.utch,
                plg: item.plg,
                plgp: item.plgp,
                plgo: item.plgo,
                obz: item.obz,
                nobz: item.nobz,
                sumrg: item.sumrg,
                percentage1: item.percentage1,
                percentage2: item.percentage2,
                type: 'EXPENSE'
            }
            return temp
        }
        const mapIncomeRow = (item: any): Report127TableIncomeRow => {
            const temp: Report127TableIncomeRow = {
                index: index++,
                kat: item.kat ?? null,
                cls: item.cls ?? null,
                pcl: item.pcl ?? null,
                spf: item.spf ?? null,
                nameRu: item.name ?? null,
                utv: item.utv,
                utch: item.utch,
                plg: item.plg,
                plgp: item.plgp,
                plgo: item.plgo,
                obz: item.obz,
                nobz: item.nobz,
                sumrg: item.sumrg,
                percentage1: item.percentage1,
                percentage2: item.percentage2,
                type: 'INCOME'
            }
            return temp
        }
        const mapBaseRow = (item: any): Report127TableBaseRow => {
            return {
                index: index++,
                nameRu: item.name ?? null,
                utv: item.utv,
                utch: item.utch,
                plg: item.plg,
                plgp: item.plgp,
                plgo: item.plgo,
                obz: item.obz,
                nobz: item.nobz,
                sumrg: item.sumrg,
                percentage1: item.percentage1,
                percentage2: item.percentage2,
                type: 'TOTAL'
            }
        }

        let tempTableDataArr: Report127Row[] = []

        if (form.separateByRegion) {
            const incomeRegions: Report127TableBaseRow[] = []
            const expenseRegions: Report127TableBaseRow[] = []
            responseTableData.regions.forEach((regionTableData: any) => {
                const incomeTotalData = regionTableData.income_total
                if (incomeTotalData) {
                    incomeRegions.push({
                        index: index++,
                        nameRu: regionTableData.name ?? null,
                        utv: incomeTotalData.utv,
                        utch: incomeTotalData.utch,
                        plg: incomeTotalData.plg,
                        plgp: incomeTotalData.plgp,
                        plgo: incomeTotalData.plgo,
                        obz: incomeTotalData.obz,
                        nobz: incomeTotalData.nobz,
                        sumrg: incomeTotalData.sumrg,
                        percentage1: incomeTotalData.percentage1,
                        percentage2: incomeTotalData.percentage2,
                        type: 'TOTAL'
                    })
                }
                
                const expenseTotalData = regionTableData.expense_total
                if (expenseTotalData) {
                    expenseRegions.push({
                        index: index++,
                        nameRu: regionTableData.name ?? null,
                        utv: expenseTotalData.utv,
                        utch: expenseTotalData.utch,
                        plg: expenseTotalData.plg,
                        plgp: expenseTotalData.plgp,
                        plgo: expenseTotalData.plgo,
                        obz: expenseTotalData.obz,
                        nobz: expenseTotalData.nobz,
                        sumrg: expenseTotalData.sumrg,
                        percentage1: expenseTotalData.percentage1,
                        percentage2: expenseTotalData.percentage2,
                        type: 'TOTAL'
                    })
                }
            })

            if (responseTableData.income_total) {
                const incomeSectionSeparator: SectionSeparator = { name: 'I. ДОХОДЫ', type: 'SECTION' }  
                tempTableDataArr.push(incomeSectionSeparator)
                const temp = mapBaseRow(responseTableData.income_total)
                // tempTableData.incomeTotal = temp
                tempTableDataArr.push(temp)

                // tempTableData.incomeRegions = incomeRegions
                tempTableDataArr = tempTableDataArr.concat(JSON.parse(JSON.stringify(incomeRegions)))
            }
            if (responseTableData.expense_total) {
                const expenseSectionSeparator: SectionSeparator = { name: 'II. ЗАТРАТЫ', type: 'SECTION' }  
                tempTableDataArr.push(expenseSectionSeparator)
                const temp = mapBaseRow(responseTableData.expense_total)
                // tempTableData.expenseTotal = temp
                tempTableDataArr.push(temp)

                // tempTableData.expenseRegions = expenseRegions
                tempTableDataArr = tempTableDataArr.concat(JSON.parse(JSON.stringify(expenseRegions)))
            }
        }
        else if (form.budgetStructure?.value === 'WITH') {
            const generalIncomeTotal: Report127TableBaseRow = mapBaseRow(responseTableData.general_income_total)
            const generalExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.general_expense_total)
            const generalIncome: Report127TableIncomeRow[] = responseTableData.general_income.map(mapIncomeRow)
            const generalExpense: Report127TableExpenseRow[] = responseTableData.general_expense.map(mapExpenseRow)
            const budgetLoanDelta: Report127TableBaseRow = mapBaseRow(responseTableData.budget_loan_delta)
            const budgetLoanExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.budget_loan_expense_total)
            const budgetLoanExpense: Report127TableExpenseRow[] = responseTableData.budget_loan_expense.map(mapExpenseRow)
            const budgetLoanIncomeTotal: Report127TableBaseRow = mapBaseRow(responseTableData.budget_loan_income_total)
            const budgetLoanIncome: Report127TableIncomeRow[] = responseTableData.budget_loan_income.map(mapIncomeRow)
            const financialActivesDelta: Report127TableBaseRow = mapBaseRow(responseTableData.financial_actives_delta)
            const financialActivesExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.financial_actives_expense_total)
            const financialActivesExpense: Report127TableExpenseRow[] = responseTableData.financial_actives_expense.map(mapExpenseRow)
            const financialActivesIncomeTotal: Report127TableBaseRow = mapBaseRow(responseTableData.financial_actives_income_total)
            const financialActivesIncome: Report127TableIncomeRow[] = responseTableData.financial_actives_income.map(mapIncomeRow)
            const budgetDeficit: Report127TableBaseRow = mapBaseRow(responseTableData.budget_deficit)
            const nonOilBudgetDeficit: Report127TableBaseRow = mapBaseRow(responseTableData.non_oil_budget_deficit)
            const financeDeficitBudgetDelta: Report127TableBaseRow = mapBaseRow(responseTableData.finance_deficit_budget_delta)
            const financeDeficitBudgetIncome7Total: Report127TableBaseRow = mapBaseRow(responseTableData.finance_deficit_budget_income_7_total)
            const financeDeficitBudgetIncome7: Report127TableIncomeRow[] = responseTableData.finance_deficit_budget_income_7.map(mapIncomeRow)
            const financeDeficitBudgetExpenseTotal: Report127TableBaseRow = mapBaseRow(responseTableData.finance_deficit_budget_expense_total)
            const financeDeficitBudgetExpense: Report127TableExpenseRow[] = responseTableData.finance_deficit_budget_expense.map(mapExpenseRow)
            const usedBudgetLeft: Report127TableBaseRow = mapBaseRow(responseTableData.used_budget_left)
            const accSldBeg: Report127TableBaseRow = mapBaseRow(responseTableData.acc_sld_beg)
            const accSldEnd: Report127TableBaseRow = mapBaseRow(responseTableData.acc_sld_end)

            tempTableDataArr.push({ name: 'I. ДОХОДЫ', type: 'SECTION' })
            tempTableDataArr.push(generalIncomeTotal)
            tempTableDataArr = tempTableDataArr.concat(generalIncome)

            tempTableDataArr.push({ name: 'II. ЗАТРАТЫ', type: 'SECTION' })
            tempTableDataArr.push(generalExpenseTotal)
            tempTableDataArr = tempTableDataArr.concat(generalExpense)

            tempTableDataArr.push({ name: 'III. ЧИСТОЕ БЮДЖЕТНОЕ КРЕДИТОВАНИЕ', type: 'SECTION' })
            tempTableDataArr.push(budgetLoanDelta)
            tempTableDataArr.push(budgetLoanExpenseTotal)
            tempTableDataArr = tempTableDataArr.concat(budgetLoanExpense)
            tempTableDataArr.push(budgetLoanIncomeTotal)
            tempTableDataArr = tempTableDataArr.concat(budgetLoanIncome)
            
            tempTableDataArr.push({ name: 'IV. САЛЬДО ПО ОПЕРАЦИЯМ С ФИНАНСОВЫМИ АКТИВАМИ', type: 'SECTION' })
            tempTableDataArr.push(financialActivesDelta)
            tempTableDataArr.push(financialActivesExpenseTotal)
            tempTableDataArr = tempTableDataArr.concat(financialActivesExpense)
            tempTableDataArr.push(financialActivesIncomeTotal)
            tempTableDataArr = tempTableDataArr.concat(financialActivesIncome)

            tempTableDataArr.push({ 
                name: 'V. ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА \n VI. НЕНЕФТЯНОЙ ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА \n ' + 
                'VII ФИНАНСИРОВАНИЕ ДЕФИЦИТА (ИСПОЛЬЗОВАНИЕ ПРОФИЦИТА) БЮДЖЕТА \n ' + 
                ' Справочно: Остатки бюджетных средств', 
                type: 'SECTION' 
            })
            tempTableDataArr.push(budgetDeficit)
            tempTableDataArr.push(nonOilBudgetDeficit)
            tempTableDataArr.push(financeDeficitBudgetDelta)
            tempTableDataArr.push(financeDeficitBudgetIncome7Total)
            tempTableDataArr = tempTableDataArr.concat(financeDeficitBudgetIncome7)
            tempTableDataArr.push(financeDeficitBudgetExpenseTotal)
            tempTableDataArr = tempTableDataArr.concat(financeDeficitBudgetExpense)
            tempTableDataArr = tempTableDataArr.concat(usedBudgetLeft)
            const accSldNameRow: Report127TableBaseRow = {
                index: index++,
                nameRu: 'Справочно: Остатки бюджетных средств',
                utv: 0,
                utch: 0,
                plg: 0,
                plgp: 0,
                plgo: 0,
                obz: 0,
                nobz: 0,
                sumrg: 0,
                percentage1: 0,
                percentage2: 0,
                type: 'NAME'
            }
            tempTableDataArr.push(accSldNameRow)
            tempTableDataArr.push(accSldBeg)
            tempTableDataArr.push(accSldEnd)
        }
        else if (form.budgetStructure?.value === 'WITHOUT') {
            if (responseTableData.income_total || responseTableData.general_income_total) {
                tempTableDataArr.push({ name: 'I. ДОХОДЫ', type: 'SECTION' })
            }
            if (responseTableData.income_total) {
                const temp = mapBaseRow(responseTableData.income_total)
                tempTableDataArr.push(temp)
            }
            if (responseTableData.general_income_total) {
                const temp = mapBaseRow(responseTableData.general_income_total)
                tempTableDataArr.push(temp)
                
                const temp2 = responseTableData.general_income.map(mapIncomeRow)
                tempTableDataArr = tempTableDataArr.concat(temp2)
            }

            if (responseTableData.expense_total || responseTableData.general_expense_total) {
                tempTableDataArr.push({ name: 'II. ЗАТРАТЫ', type: 'SECTION' })
            }
            if (responseTableData.expense_total) {
                const temp = mapBaseRow(responseTableData.expense_total)
                tempTableDataArr.push(temp)
            }
            if (responseTableData.general_expense_total) {
                const temp = mapBaseRow(responseTableData.general_expense_total)
                tempTableDataArr.push(temp)

                const temp2 = responseTableData.general_expense.map(mapExpenseRow)
                tempTableDataArr = tempTableDataArr.concat(temp2)
            }
        }
        else {
            throw new Error('Неподдерживаемая структура бюджета (budget_structure)')
        }
        

        // 3. Формирование параметров для выгрузки журнал
        const date = getLastDateOfMonth(form.year, form.month.value)
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: Report127JournalRequest = {
            reportName: "1-27",
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            incomes_order: form.incomes.filter(it => it.active).map(it => it.key),
            expenses_order: form.expenses.filter(it => it.active).map(it => it.key),
            expense_type: form.expenseType.value,
            income_total: form.incomeTotal,
            expense_total: form.expenseTotal,
            indicators_order: form.indicators.filter(it => it.active).map(it => it.key),
            table_data: tempTableDataArr.filter(it => ['SECTION', 'REGION'].notIncludes(it.type)),
            date: date,
            region_labels: form.regions.map(it => it.label),
            mutually_redeeming: form.mutuallyRedeeming.value,
            separate_by_region: form.separateByRegion,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit_label: form.measureUnit.label,
            round_up: form.roundUpTo ?? null,
        }

        const result: TableDataAndJournalParams = {
            tableData: tempTableDataArr,
            journalParams: journalParams
        }

        return result
    }
}
