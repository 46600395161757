import { allBudgetStructuresOptions, allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allDevelopTypesOptions, allExpensesClassificators, allExpenseTypesOptions, allIncomeClassificators, allMeasureUnitsOptions, allMonthsOptions, allMutuallyRedeemingOptions, allPeriodicitiesOptions, allQuartersOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions"
import { Classificator } from "../../common/types/Classificator"
import { Indicator } from "../../common/types/Indicator"
import { Template } from "../../common/types/Template"
import { Report127FilterOptions } from "./options/Report127FilterOptions"
import { Report127FilterSettings } from "./settings/Report127FilterSettings"
import { cloneDeep } from 'lodash'

export const report127Expenses: Classificator[] = [
    { key: 'gr',  active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: false, selected: [] },
    { key: 'prg', active: false, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'pcl', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] }
]
export const report127Indicators: Indicator[] = [
    { key: 'utv', active: true },
    { key: 'utch', active: true },
    { key: 'plg', active: true },
    { key: 'plgs', active: true }, // Родительнский показатель для plgp, plgo
    { key: 'obz', active: true },
    { key: 'nobz', active: true },
    { key: 'sumrg', active: true },
    { key: 'percentage1', active: true },
    { key: 'percentage2', active: true }
]

const fs: Report127FilterSettings = {
    reportName: '1-27',

    reportType: 'REGULATED',
    classificationType: 'MIXED',
    dataSource: 'LOADER',
    periodicity: 'MONTH',

    regions: [],
    mutuallyRedeeming: 'ONLY',
    budgetTypes: [],
    measureUnit: 'TENGE',
    budgetStructure: "WITH",
    developType: null,
    year: (new Date().getFullYear()),
    month: 1,
    separateByRegion: false,
    roundUpTo: null,
    incomeTotal: false,
    expenseTotal: false,
    incomes: cloneDeep(allIncomeClassificators),
    expenseType: 'FUNCTIONAL',
    expenses: cloneDeep(report127Expenses),
    indicators: cloneDeep(report127Indicators)
}

export const report127BaseTemplate: Template = {
    id: null,
    name: 'Отчет 1-27',
    description: null,
    category: '10',
    regions: [],
    userId: '',
    filterSettings: fs
}

export const report127FilterOptions: Report127FilterOptions = {
    // Base:
    key: '1-27',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    // Custom:
    regions: [], // Загружается с бэка
    mutuallyRedeeming: cloneDeep(allMutuallyRedeemingOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    budgetStructures: cloneDeep(allBudgetStructuresOptions),
    developTypes: cloneDeep(allDevelopTypesOptions),
    quarters: cloneDeep(allQuartersOptions),
    months: cloneDeep(allMonthsOptions),
    incomes: cloneDeep(allIncomeClassificators),
    expenseTypes: cloneDeep(allExpenseTypesOptions),
    expenses: cloneDeep(report127Expenses)
}
