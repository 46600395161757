





















































































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import store from '@/services/store';
import CGuWindow from '@/modules/budget/execution/form/tabs/ipf/GuWindow.vue';
import CInsideWindow from '@/modules/budget/execution/form/tabs/ipf/InsideWindow.vue';
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue";
import {Ax} from "@/utils";
import axios from 'axios'
import {makeToast} from "@/modules/budget/bip/bip-types";
import { EventBus } from '@/modules/budget/eventBus'
interface IFilter {
    curGr?: number;
    abp: number;
    gu?: string;
    region: string;
    year: number;
    prg?: number;
    ppr?: number;
    spf?: number;
}

@Component({
    components: {
        'c-gu-window': CGuWindow,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-inside-window': CInsideWindow
    }
})
export default class CIpf extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private realmUsers!:any[];
    @Prop({
        required: true,
        default: 'ipf'
    })
    private accessLevel!:any|string;

    private mode = 'exipf';
    private statusList:any[] = [];
    private selectedStatus: any = null;
    private moduleCode = '005.001.001'
    private regionBase: any[] = [];
    private region : any | null = null;
    private obl = null;
    private filter: null | any = null;
    private openSecondWindow = false;
    private editAccess: object | any = null;
    private isLoadInside = false;
    private isOnlyGu: boolean = true;
    private checkedGU = false;
    private isOnlyRegion: boolean = true;

    private accessLevelNum(): number {
        const nums:any = {
            'ipf': 0, 'abp': 1, 'uf': 2
        }
        if (nums.hasOwnProperty(this.accessLevel)) {
            return nums[this.accessLevel]
        }
        return 0
    }

    private getEditAccess() {
        const avModules = store.state.user.userModules
        for (const avModule of avModules) {
            if (avModule.modules === this.moduleCode) {
                this.editAccess = Object.assign({}, avModule)
                break
            }
        }
    }

    private get haveEditAccess() {
        return this.currentForm && this.currentForm.hasOwnProperty('status') && this.currentForm.status !== null && [1, 3].includes(this.currentForm.status.status) && this.editAccess && (this.editAccess.access_level === 2 || this.editAccess.access_level === 3)
    }
    private get haveDeleteAccess() {
        return this.currentForm && this.currentForm.hasOwnProperty('status') && this.currentForm.status !== null && [1, 3].includes(this.currentForm.status.status) && this.editAccess && (this.editAccess.access_level === 2 || this.editAccess.access_level === 3)
    }

    private get haveImportAccess() {
        return this.operationCode.includes("imp_execution") && this.haveEditAccess
    }

    getLocalStatus(value:any) {
        const status = value.status.status;
        const curStatus = this.statusList.filter((item) => item.code === status);
        if (!curStatus || !curStatus.length) return status;
        const localName = curStatus[0]["name_" + this.$i18n.locale];
        return localName;
    }

    private async getStatusList(mode: string) {
        try {
            let statusList: any[] = [];
            const response = await axios.get(`/api-py/get-status-list/${mode}`);
            if (response.status === 200) {
                statusList = response.data;
            }
            return statusList;
        } catch (e) {
            console.error("Error fetching status list:", e);
            return [];
        }
    }

    private async created() {
        this.getEditAccess()
        this.statusList = await this.getStatusList(this.mode)
        if (this.statusList.length) {
            this.selectedStatus = this.statusList.find(el => {
                if (el.code === 0) {
                    return el
                }
            })
        }
        this.yearList = []
        const curDate = new Date()
        this.year = curDate.getFullYear();
        for (let i = 2022; i <= this.year; i++) {
            this.yearList.push(i);
        }
        if (curDate.getMonth() > 9) {
            this.yearList.push(this.year + 1)
            this.year += 1
        }
        await this.getObl();
        await this.loadAbp()
        this.$watch('checkedGU', async () => {
            await this.chgAbp()
        })
        await this.loadOperations();
        this.$watch('currentForm', async () => {
            if (this.currentForm !== null) {
                this.filter = {
                    year: this.year,
                    curGr: this.curGr? this.curGr.gr: 1,
                    abp: this.currentForm.abp,
                    gu: this.currentForm? this.currentForm.gu: (this.curComp && this.curComp.hasOwnProperty('code') && this.curComp.code? this.curComp.code: null),
                    region: this.region.code,
                };
                await this.agreementEvent(false);
            }
        }, { deep: true });
        await this.chgData()

    }

    private async mounted() {

    }

    private formationIpfFormChangedEmiter() {
        EventBus.$emit('formationIpfFormChanged', this.currentForm)
    }

    private chgLoad(isLoad: boolean) {
        this.isLoadInside = isLoad;
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // --------- ГУ / КГУ ------------
    private year: number | null = null;
    private yearList:any [] = [];

    // --------- ГУ / КГУ ------------
    private companyBase: any[] = [];
    private curComp: any | null = null;

    private get company(): any[] {
        const result: any[] = [];
        for (const el of this.companyBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // --------- Функ гр. ------------
    private curGr: any | null = null;


    // ------------------ администратор программ abp------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) { this.curAbp = this.setNameLang(this.curAbp, 'abp'); }
        return res;
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private async chgProg() {
        this.subProgBase = [];
        this.curSubProg = null;
        await this.loadSubProg();
        await this.loadCurSpf();
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    // ----------------------------специфика------------------------
    private spfBase: any[] = [];
    private curSpf: any | null = null;


    private get spf(): any[] {
        const res: any[] = [];
        for (const el of this.spfBase) {
            res.push(this.setNameLang(el, 'spf'));
        }

        if (this.curSpf !== null) {
            this.curSpf = this.setNameLang(this.curSpf, 'spf');
        }
        return res;
    }

    private async loadAbp() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (response.length === 0) {
            await axios.get(`/api-py/get-abp-by-user-id-region/${this.usrId}/${this.region.code}/${this.year}`)
                .then(res => {
                    response = res.data
                    if (res.status === 500) {
                        throw new Error('empty_list')
                    }
                    if (response.length < 1) {
                        throw new Error('empty_list')
                    }
                })
                .catch(error => {
                    response = []
                    if (error.message === 'empty_list') {
                        this.makeToast('danger', 'Ошибка загрузки адм. программ', 'У вас нет доступных адм. программ');
                    } else if (error.response.status === 500) {
                        this.makeToast('danger', 'Ошибка загрузки адм. программ', 'у вас нет доступных адм. программ');
                    } else {
                        this.makeToast('danger', 'Ошибка загрузки адм. программ', error.toString());
                    }
                });
        }
        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        if (response && response.length > 0) {
            this.abpBase = this.setIdArr(response, 'abp');
            if (this.abpBase && this.abpBase.length > 1) {
                this.isOnlyGu = false;
            }
        }

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        await this.chgAbp();
    }

    private async chgAbp() {
        this.curComp = null;
        this.companyBase = []
        await this.loadCompany();
        // await this.chgData()
    }

    private async getObl() {
        this.regionBase = []
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                this.region = json.region;
                this.obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', error.toString());
        }


        await axios.get('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
            .then(res => {
                const json = res.data
                if (res.status === 500) {
                    this.region = null
                    throw new Error('empty_list')
                }
                if (json.length < 1) {
                    this.region = null
                    throw new Error('empty_list')
                }
                if (json.length > 0) {
                    for (const el of json) {
                        this.regionBase.push(this.setNameLang(el, 'code'))
                    }
                    this.region = this.regionBase[0]
                    this.isOnlyRegion = !(this.regionBase.length > 1);
                }
            })
            .catch(error => {
                if (error.message === 'empty_list') {
                    this.makeToast('danger', 'Ошибка запроса get-user-budget-region', 'У вас нет доступных регионов');
                } else if (error.response.status === 500) {
                    this.makeToast('danger', 'Ошибка запроса get-user-budget-region', 'У вас нет доступных регионов');
                } else {
                    this.makeToast('danger', 'Ошибка запроса get-user-budget-region', error.toString());
                }
            });
    }

    private async loadCompany() {
        // await this.getObl();
        this.loadGr()
        let result: any[] = [];
        this.curComp = null;
        await axios.get(`/api-py/get-gu-by-abp-user-id-region/${this.curAbp.abp}/${this.usrId}/${this.region.code}/${this.year}`)
            .then(res => {
                result = res.data
                if (res.status === 500) {
                    throw new Error('empty_list')
                }
                if (result.length < 1) {
                    throw new Error('empty_list')
                }

            })
            .catch(error => {
                result = [];
                if (error.message === 'empty_list') {
                    this.makeToast('danger', 'Ошибка загрузки ГУ / КГУ', 'У вас нет доступных ГУ / КГУ');
                } else if (error.response.status === 500) {
                    this.makeToast('danger', 'Ошибка загрузки ГУ / КГУ', 'У вас нет доступных ГУ / КГУ');
                } else {
                    this.makeToast('danger', 'Ошибка загрузки ГУ / КГУ', error.toString());
                }
            });

        if (result && result.length > 0) {
            if (result && result.length > 1) {``
                this.isOnlyGu = false;
            }
            if (this.curAbp && this.curAbp.hasOwnProperty('abp') && (this.accessLevel === 'ipf' || this.checkedGU)) {
                this.curComp = this.setNameLang(result[0], 'code');
            }
            // await this.getRegionByCodeGu();
        }
        this.companyBase = result;
        if (this.curComp && this.curComp.hasOwnProperty('code')) {
            await this.chgCurComp();
            // await this.chgData();
        }
    }

    private async chgCurComp() {
        if (this.curComp && this.curAbp.hasOwnProperty('code')) {
            // await this.getRegionByCodeGu();
        }
    }

    private async getRegionByCodeGu() {
        return
        if (this.curComp && this.curComp.id_region) {
            let result: any[] = [];
            try {
                result = await fetch('/api-py/get-region-by-code-gu',
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.curComp)
                    })
                    .then(response => response.json());
                if (result && result.length > 0 && result[0].code) {
                    this.region = result[0];
                }
            } catch (error) {
                result = [];
                this.makeToast('danger', 'Ошибка загрузки региона', (error as Error).toString());
            }
        }
    }

    private async loadGr() {
        let result: any[];
        this.curGr = null;
        try {
            result = await fetch(`/api-py/get-func-gr-by-user-id/${this.usrId}`)
                .then(response => response.json());
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка загрузки Функ. Гр.', error.toString());
        }
        if (result && result.length > 0) {
            this.curGr = result[0];
            // await this.loadAbp();
        }
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];

        try {
            response = await fetch(`/api/budget-execution-pf-ipf/prg?year=${this.year}&gu=${this.currentForm.gu}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки программ', error.toString());
        }
        if (response && response.length > 0) {
            response.sort((a: any, b: any) => { return (a.prg - b.prg); });
            this.progBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'prg');
        }

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
        } else {
            this.curProg = null;
        }
        await this.chgProg();
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];

        const havePrg = this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg;
        try {
            // response = await fetch(`/api-py/ppr-by-abp-and-prg/${this.curAbp.abp}/${this.curProg.prg}`);
            response = await fetch(`/api/budget-execution-pf-ipf/ppr?year=${this.year}&gu=${this.curComp.code}${havePrg? `&prg=${this.curProg.prg}`:''}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки подпрограмм', error.toString());
            response = [];
        }
        if (response && response.length > 0) {
            response.sort((a: any, b: any) => { return (a.ppr - b.ppr); });
            this.subProgBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'ppr');
        }

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
        await this.chgSubProg();
    }

    private async chgSubProg() {
        await this.loadCurSpf();
    }

    private async loadCurSpf() {
        this.spfBase = [];
        let response: any = [];

        try {
            let path = `/api/budget-execution-pf-ipf?region=${this.region.code}&abp=${this.curAbp.abp}&gu=${this.currentForm.gu}&year=${this.year}&type=obligation`

            if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) path += `&prg=${this.curProg.prg}`;
            if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) path += `&ppr=${this.curSubProg.ppr}`;
            response = await fetch(path);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса loadCurSpf()', error.toString());
        }

        response.sort((a: any, b: any) => { return (a.spf - b.spf); });

        response = response.map((el: any) => {
            return {
                name_ru: el.name_ru,
                name_kk: el.name_kk,
                spf: el.spf
            }
        });

        if (response && response.length > 0) {
            this.spfBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', spf: null}, ...response], 'spf');
        }

        if (this.spfBase.length > 0) {
            this.curSpf = this.spfBase[0];
        } else {
            this.curSpf = null;
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            if (obj[codeName]) {
                txt = obj[codeName] + ' - ' + txt;
            }
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private  setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        if (arr && arr.length > 0) {
            for (const el of arr) {
                if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({idArr: [el.id]}, el));
                }
            }
        }
        return result;
    }

    private async chgData() {
        if (typeof this.year === 'number') {
            this.filter = {
                year: this.year,
                curGr: this.curGr? this.curGr.gr: 1,
                abp: this.curAbp.abp,
                gu: this.currentForm? this.currentForm.gu: (this.curComp && this.curComp.hasOwnProperty('code') && this.curComp.code? this.curComp.code: null),
                region: this.region.code,
            };
            // this.nameGu = this.curComp.name_ru;
            if (this.openSecondWindow) {
                if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
                    this.$set(this.filter, 'prg', this.curProg.prg);
                }
                if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
                    this.$set(this.filter, 'ppr', this.curSubProg.ppr);
                }
                if (this.curSpf && this.curSpf.hasOwnProperty('spf') && this.curSpf.spf) {
                    this.$set(this.filter, 'spf', this.curSpf.spf);
                }
                const windowRef:any = this.$refs.ipfInsideWindow
                await windowRef.chgFilter()
            } else {
                if (this.statusList.length) {
                    this.selectedStatus = this.statusList.find(el => {
                        if (el.code === 0) {
                            return el
                        }
                    })
                }
                await this.loadData()
            }
        }
        const drop: any = this.$refs.drop;
        drop.hide(true);
    }

    private async toggleOpenWindowBool(row: any = null) {
        this.currentForm = row
        if (!row && !this.checkedGU && this.accessLevel !== 'ipf') {
            this.curComp = null;
        }
        if (this.openSecondWindow) {
            if (this.editAccess.access_level === 2 || this.editAccess.access_level === 3) {
                // eslint-disable-next-line
                // @ts-ignore
                const hasChanges = this.$refs.ipfInsideWindow.beforeExit()
                if (hasChanges) {
                    this.$bvModal.msgBoxConfirm(
                        'Сохранить изменения?',
                        {
                            title: 'Подтверждение',
                            size: 'lg',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            okTitle: 'ДА',
                            cancelTitle: 'Отмена',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(async value => {
                            if (value) {
                                await this.save();
                            } else {
                                this.openSecondWindow = false;
                                this.isLoadInside = false;
                                setTimeout(() => this.chgData(), 100);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
                else {
                    this.openSecondWindow = false;
                    this.isLoadInside = false;
                    setTimeout(() => this.chgData(), 100);
                }
            } else {
                this.openSecondWindow = false;
                this.isLoadInside = false;
                setTimeout(() => this.chgData(), 100);
            }
        } else {
            this.filter.gu= row.gu
            if (this.companyBase.length) {
                this.curComp = this.company.find(el => {
                    if (el.code === row.gu) {
                        return el
                    }
                })
            }
            this.openSecondWindow = true;
            setTimeout(() => this.chgData(), 100);
            await this.loadProg();
        }
        // this.chgData();
    }

    private showSaveBtn = false;
    private clickSave() {
        this.$bvModal.msgBoxConfirm(
            'Сохранить изменения?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                if (value) {
                    await this.save();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    private async save() {
        this.showSaveBtn = true;
        // eslint-disable-next-line
        // @ts-ignore
        const isSuccess = await this.$refs.ipfInsideWindow.prepareForSave();
        if (isSuccess) {
            await this.recalcKat8Data();
        }
        this.showSaveBtn = false;
    }

    private async recalcKat8Data() {
        let dataLoaded = true;
        const incomeData:any = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0
        };
        const kat8Data:any = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0
        };
        const spfObj = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0
        }
        const queryString = {
            year: this.year,
            region: this.region.code,
            type: 'payment',
        };
        await axios.get('/api/budget-execution/spf', {params: queryString})
            .then(resp => {
                if (resp.status === 200) {
                    const spfData:any[] = resp.data
                    for (const d of spfData) {
                        spfObj.january += d.january
                        spfObj.february += d.february
                        spfObj.march += d.march
                        spfObj.april += d.april
                        spfObj.may += d.may
                        spfObj.june += d.june
                        spfObj.july += d.july
                        spfObj.august += d.august
                        spfObj.september += d.september
                        spfObj.october += d.october
                        spfObj.november += d.november
                        spfObj.december += d.december
                    }
                }
            }).catch(error => {dataLoaded = false;})

        await axios.get('/api/consolidated-income-plan', {params: {year: this.year, region: this.region.code}})
            .then(resp => {
                if (resp.status === 200) {
                    const dict = resp.data;
                    for (const kat of dict) {
                        for (const cls of kat.child_list) {
                            for (const pcl of cls.child_list) {
                                for (const spf of pcl.child_list) {
                                    for (const m of Object.keys(incomeData)) {
                                        incomeData[m] += spf[m]
                                    }
                                }
                            }
                        }
                    }
                }
            }).catch(error => {dataLoaded = false;})
        kat8Data.january = parseFloat((spfObj.january - incomeData.january).toFixed(2))
        kat8Data.february = parseFloat((spfObj.february - incomeData.february).toFixed(2))
        kat8Data.march = parseFloat((spfObj.march - incomeData.march).toFixed(2))
        kat8Data.april = parseFloat((spfObj.april - incomeData.april).toFixed(2))
        kat8Data.may = parseFloat((spfObj.may - incomeData.may).toFixed(2))
        kat8Data.june = parseFloat((spfObj.june - incomeData.june).toFixed(2))
        kat8Data.july = parseFloat((spfObj.july - incomeData.july).toFixed(2))
        kat8Data.august = parseFloat((spfObj.august - incomeData.august).toFixed(2))
        kat8Data.september = parseFloat((spfObj.september - incomeData.september).toFixed(2))
        kat8Data.october = parseFloat((spfObj.october - incomeData.october).toFixed(2))
        kat8Data.november = parseFloat((spfObj.november - incomeData.november).toFixed(2))
        kat8Data.december = parseFloat((spfObj.december - incomeData.december).toFixed(2))
        if (dataLoaded) {
            kat8Data.year = this.year
            kat8Data.region = this.region.code
            kat8Data.code = ''
            kat8Data.kat = 8
            kat8Data.clss = 1
            kat8Data.pcl = 1
            kat8Data.spf = 1
            kat8Data.id = 0
            await axios.post('/api/consolidated-income-plan', [kat8Data])
                .then(resp => {
                    if (resp.status === 200) console.log('kat8DataSaved')
                }).catch(error => {})
        }

    }

    private async prepareIpfForSave() {
        const item = Object.assign({}, this.filter);
        this.$set(item, 'defective', 'Проверено');
        this.$set(item, 'user_id', this.$store.state.user.sub);
        this.$set(item, 'gr', this.curGr.gr);

        await this.saveIpf(item);
    }

    private async saveIpf(value: any, error = false) {
        try {
            const response = await fetch('/api-py/save-budget-execution-ipf-gu/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(value)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {
                // this.makeToast('success', 'Сообщение', 'Данные сохранены (ИПФ)');
            } else {
                throw 'Ошибка сохранения данных. (ИПФ)';
            }
        } catch (e) {
            this.makeToast('danger', 'Предупреждение', e.toString());
        }
    } // сохранение данных
    private async getData(type: string) {
        let values = [];
        try {
            const queryString = {...this.filter, type: type};
            queryString.level = 'prg'
            const response = await axios.get('/api/budget-execution-pf-ipf/tree', {params: queryString});
            values = response.data;
            return values;
        } catch (error) {
            return []
        }
    }

    private async flkDelete() {
        let paymentData:any = []
        let obligationData:any = []
        paymentData = await this.getData('payment')
        obligationData = await this.getData('obligation')
        return paymentData.length || obligationData.length
    }
    private async onDelete(row:any) {
        this.currentForm = row;
        this.filter = {
            year: this.year,
            curGr: this.curGr? this.curGr.gr: 1,
            abp: this.currentForm.abp,
            gu: this.currentForm? this.currentForm.gu: (this.curComp && this.curComp.hasOwnProperty('code') && this.curComp.code? this.curComp.code: null),
            region: this.region.code,
        };
        if (row && row.hasOwnProperty('status') && row.status) {
            if (row.status.status !== 1 || await this.flkDelete()) {
                this.makeToast('warning', 'Удаление', 'Данное действие не доступно для данного ИПФ')
                return;
            }
            await axios.delete(`/api/budget-execution-pf-request?requestId=${row.id}`).then(resp => {
                if (resp.status === 200) {
                    this.makeToast('info', 'Удаление', 'ИПФ успешно удален!')
                    const idx = this.ipfData.findIndex(item => item.id === row.id);
                    if (idx !== -1) {
                        this.ipfData.splice(idx, 1);
                    }
                }
            })
        }
    }
    private operationCode:any[] = []

    private async loadOperations() {
        this.operationCode = [];
        if (store.state.user.sub === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=${this.moduleCode}`))
                .then(response => response.json());
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', error.toString());
            return;
        }
        if (result.operations) { this.operationCode = result.operations; }
    }
    // кнопка действия
    private agrBtnLst: any = { back: [], forward: [] };
    private selectArr = [];
    private agrEnabled = false;
    private async agreementEvent(show = true) {
        this.selectArr = [];
        const params = { modeCode: this.mode, operationCode: this.operationCode, agrCode: this.currentForm.status.status };
        if (!show) { this.agrEnabled = false; }
        let result: any[] = [];
        try {
            const response = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
        } catch (error) {
            // makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', 'Ошибка загрузки шагов согласования!');
            return;
        }
        if (result.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
            return;
        }
        this.agrBtnLst = { back: [], forward: [] };

        for (const el of result) {
            if (el.stepType === 1) {
                this.agrBtnLst.back.push(el);
            } else {
                this.agrBtnLst.forward.push(el);
            }
        }
        if (this.agrBtnLst.back.length || this.agrBtnLst.forward.length) {
            this.agrEnabled = true;
        }
        if (show) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refAgrModal.showEvent();
        }
    }
    private flk0Agr() {
        // eslint-disable-next-line
        // @ts-ignore
        return this.$refs.ipfInsideWindow.flk0agr();
    }

    private flk1() {
        // eslint-disable-next-line
        // @ts-ignore
        const errorIPF = this.$refs.ipfInsideWindow.flk1Agreement();
        if (errorIPF.payment || errorIPF.obligation) {
            const text1 = 'Имеются расхождения сумм по обязательствам и бюджетным заявкам'
            const text2 = 'Имеются расхождения сумм по платежам и бюджетным заявкам'
            const errs = []
            if (errorIPF.payment) errs.push(text1)
            if (errorIPF.obligation) errs.push(text2)
            const message = errs.join('\n')
            this.makeToast('warning', 'Согласовать', message)
        }
    }
    // true/false: have edit access/not
    private async flk2() {
        const url = `/api/budget-execution-pf-request/ABP?year=${this.currentForm.year}&region=${this.currentForm.region}&abp=${this.currentForm.abp}`
        let abpItem:any = {}
        await axios.get(url)
            .then(async (res) => {
                if (res.status === 200) {
                    abpItem = res.data
                }
            })
            .catch(e => {})
        if (abpItem) {
            return ![31, 32, 39].includes(abpItem.status.status)
        } else {
            return false
        }
    }

    private async agrClick(data:any) {
        if (data.status === 2) {
            this.flk1()
        }
        const flk0check = this.flk0Agr()
        if (!flk0check && data.status !== 3) {
            this.makeToast('danger', 'Согласование', 'Данное действие не доступно')
            return;
        }
        const flk2check = await this.flk2()
        if (!flk2check) {
            this.makeToast('danger', 'Согласование', 'Данное действие не доступно')
            return;
        }
        await axios.post(
            '/api/budget-execution-pf-status',
            {
                budgetExecutionPfId: this.currentForm.id,
                comment: data.commentTxt,
                status: data.status
            }
        ).then(res => {
            if (res.status === 200){
                this.formationIpfFormChangedEmiter()
                this.toggleOpenWindowBool()
            }
        })
    }


    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }
    // old

    private setGuName(data: any[]) {
        for (const el of data) {
            const guEl = this.companyBase.find(gu => {
                if (el.gu === gu.code) {
                    return gu
                }
            })
            this.$set(el, 'gu_name', guEl? guEl[`name_${this.$i18n.locale}`]: el.gu)
        }
    }
    private ipfData: any[] = [];
    private get ipfList() {
        if (this.selectedStatus && this.selectedStatus.hasOwnProperty('code') && this.selectedStatus.code && !this.checkedGU) {
            return this.ipfData.filter((item) => {
                return item.status.status === this.selectedStatus.code
            })
        } else {
            return this.ipfData
        }
    }
    private currentForm: any = null;
    private loadingData = false;
    private getPFStatusUser(row:any) {
        const statusList = [row.status, ...row.statusList].sort((a, b) => a.id - b.id)
        let lastStatus = statusList[0]
        const status2 = statusList.reverse().find(v => v && v.hasOwnProperty('status') && v.status === 2)
        if (status2) {
            lastStatus = status2
        }
        return lastStatus
    }
    private getLocalUser(user_id:string) {
        const infoAboutUser = this.realmUsers.find((d:any) => d.id === user_id)
        if (!infoAboutUser) {
            return ''
        }
        let name = ''
        if (infoAboutUser['lastName']) {
            name += infoAboutUser['lastName']
        }
        if (infoAboutUser['firstName']) {
            name += ' ' + infoAboutUser['firstName']
        }
        return name
    }
    private async loadData() {
        if (this.loadingData) return;
        this.loadingData = true;
        this.ipfData = [];
        try {
            let url = `/api/budget-execution-pf-request/${this.accessLevelNum() < 1 || (this.curComp && this.curComp.hasOwnProperty('code') && this.curComp.code)? 'GU': 'ABP'}?year=${this.year}&region=${this.region.code}&abp=${this.curAbp.abp}`
            if (this.accessLevelNum() < 1 || (this.curComp && this.curComp.hasOwnProperty('code') && this.curComp.code)) {
                url += `&gu=${this.curComp.code}`
            }
            let ipfItem:any = {}
            await axios.get(url)
                .then(async (res) => {
                    if (res.status === 200) {
                        if (this.accessLevelNum() < 1 || (this.curComp && this.curComp.hasOwnProperty('code') && this.curComp.code)) {
                            ipfItem = res.data
                            this.ipfData.push(ipfItem);
                            this.currentForm = ipfItem;
                        } else {
                            this.ipfData = res.data.childList
                        }
                    }
                })
                .catch(e => {})
            if (this.ipfData.length) {
                this.setGuName(this.ipfData)
                for (const ipfEl of this.ipfData) {
                    const lastStatus:any = this.getPFStatusUser(ipfEl); // когда попросят откатит просто отзеркалить коменты
                    ipfEl.userName = this.getLocalUser(lastStatus.user_id) // когда попросят откатит просто отзеркалить коменты
                    // ipfEl.userName = this.getLocalUser(ipfEl.user_id) // когда попросят откатит просто отзеркалить коменты
                }
            }
        } catch (e) {}
        this.loadingData = false;

    }

    private downloadRepPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/1/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект индивидуального плана финансирования государственного учреждения по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepPayments()', error.toString());
            }
        );
    }


    private downloadRepIndPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/2/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования государственного учреждения по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments()', error.toString());
            }
        );
    }


    private downloadRepIndPayments3() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/3/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments3()', error.toString());
            }
        );
    }

    private downloadRepObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/4/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект индивидуального плана финансирования государственного учреждения по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepObligations()', error.toString());
            }
        );
    }

    private downloadRepIndObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/5/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования государственного учреждения по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations()', error.toString());
            }
        );
    }

    private downloadRepIndObligations6() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/6/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations6()', error.toString());
            }
        );
    }

    private downloadRepIndPayments11() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/11/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по платежам с проектами.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments11()', error.toString());
            }
        );
    }

    private downloadRepIndObligations12() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/12/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по обязательствам с проектами.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations12()', error.toString());
            }
        );
    }
}
