import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allIncomeClassificators, allMeasureUnitsOptions, allMutuallyRedeemingOptions, allPeriodicitiesOptions, allQuartersOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions"
import { Indicator } from "../../common/types/Indicator"
import { Template } from "../../common/types/Template"
import { Application2FilterOptions } from "./options/Application2FilterOptions"
import { Application2FilterSettings } from "./settings/Application2FilterSettings"
import { cloneDeep } from 'lodash'

export const application2Indicators: Indicator[] = [
    { key: 'plan', active: true },
    { key: 'fact', active: true },
    { key: 'delta1', active: true },
    { key: 'delta2', active: true },
    { key: 'reasons', active: true },
]

// Нужна как временная затычка (При созданий нового берутся пустые настроики)
const tempSettings: Application2FilterSettings = {
    reportName: 'PR2',

    reportType: 'REGULATED',
    classificationType: 'INCOMES',
    dataSource: 'LOADER',
    periodicity: 'QUARTER',

    region: '',
    mutuallyRedeeming: 'ONLY',
    budgetTypes: [],
    measureUnit: 'TENGE',
    year: (new Date().getFullYear()),
    quarter: 1,
    roundUpTo: null,
    incomes: cloneDeep(allIncomeClassificators),
    indicators: cloneDeep(application2Indicators),
}

export const application2BaseTemplate: Template = {
    id: null,
    name: 'Приложение 2',
    description: null,
    category: '10',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const application2FilterOptions: Application2FilterOptions = {
    // Base:
    key: 'PR2',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    regions: [], // Загружается с бэка;
    mutuallyRedeeming: cloneDeep(allMutuallyRedeemingOptions),
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    quarters: cloneDeep(allQuartersOptions),
    incomes: cloneDeep(allIncomeClassificators),
    indicators: cloneDeep(application2Indicators)
}
