<template>
    <div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <!---------------------Фильтр------------------------->
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <br>
                    <!---------------------Дата------------------------->
                    <b-form-group label-cols-md="3" label="Дата:">
                        <date-picker
                            placeholder="Выберите дату"
                            v-model="firstDateTo"
                            :append-to-body="false"
                            :popup-style="computedPopupStyle" >
                        </date-picker>
                    </b-form-group>
                    <!---------------------Регион------------------------->
                    <b-form-group label="Регион (Область, город, район)">
                        <multiselect
                            placeholder="Выберите регион"
                            track-by="code"
                            label="text"
                            v-model="reg"
                            :options="listReg"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            ref="regionRef">
                        </multiselect>
                    </b-form-group>
                    <!---------------------ВЗК------------------------->
                    <b-form-group label="ВЗК">
                        <multiselect
                            placeholder="Выберите ВЗК"
                            v-model="selecteds"
                            :options="r_options"
                            :searchable="false"
                            :allow-empty="false"
                            :show-labels="false"
                        >
                        </multiselect>
                    </b-form-group>
                </div>
                <div class="filter-actions">
                    <b-button variant="primary" @click="getData">
                        Применить
                    </b-button>
                </div>
            </b-dropdown>
        </div>
        <div class="table-container custom-scroll" style="overflow:auto; height: 60vh !important;">
            <table class="table b-table table-bordered b-table-no-border-collapse sticky-header">
                <thead class="thead-light text-center custom-scroll">
                    <tr>
                        <b-th></b-th>
                        <b-th>Общее</b-th>
                        <b-th colspan="2">Годовой объем финансирования</b-th>
                        <b-th colspan="3">Объем финансирования на период</b-th>
                        <b-th colspan="2">Обязательства в т.ч.</b-th>
                    </tr>
                    <tr>
                        <th v-for="header of tableFields" :key="header.key">
                            <template v-if="header.key === 'action'">
                                <b-button @click="openAll()">
                                    <i
                                        class="icon icon-chevron-circle icon-rotate-180"
                                        v-if="open"
                                    ></i>
                                    <i
                                        class="icon icon-chevron-circle"
                                        v-if="!open"
                                    ></i>
                                </b-button>
                            </template>
                            <template v-else>
                                {{ header.label }}
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) of fullData" :key="item.region + '_' + index" :style="selected == item ? 'background-color: #e0ebe0;' : ''">
                        <template v-if="item.visible">
                            <td v-for="header of tableFields" :key="header.key" @click="item.spf ? getSupplier(item) : ''" style="padding: .75rem; font-size: .7777777778rem; font-weight: 500; line-height: 1.3; color: #0d1a2e!important; text-transform: none;" :style="item.region ? 'background-color: rgba(176, 224, 255, 0.5) !important;' : item.abp ? 'background-color: #ecf7ff !important;' : item.pcl ? 'background-color: #EEF1F8 !important;' : ''">
                                <template v-if="header.key === 'action' && !item.spf">
                                    <b-button @click="openChilds(item)" v-model="item.open">
                                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="item.open"></i>
                                        <i class="icon icon-chevron-circle" v-if="!item.open"></i>
                                    </b-button>
                                </template>
                                <template v-else-if="header.key === 'name_ru'">
                                    <div v-if="item.region" class="text-left w-200px">
                                        {{ item.region }} {{ item.name_ru }}
                                    </div>
                                    <div v-else-if="item.abp" class="text-left w-200px">
                                        {{ item.abp }} {{ item.name_ru }}
                                    </div>
                                    <div v-else-if="item.pcl" class="text-left w-200px">
                                        {{ item.name_ru }}
                                    </div>
                                    <div v-else class="text-left w-200px">
                                        {{ item.func }} {{ item.spf }} {{ item.name_ru }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'god_plan'">
                                    <div>
                                        {{ $n(item.god_plan) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'god_ostatok'">
                                    <div>
                                        {{ $n(item.god_ostatok) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'period_plan'">
                                    <div>
                                        {{ $n(item.period_plan) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'period_oplacheno'">
                                    <div>
                                        {{ $n(item.period_oplacheno) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'period_ne_oplacheno'">
                                    <div>
                                        {{ $n(item.period_ne_oplacheno) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'obaz_zareg'">
                                    <div>
                                        {{ $n(item.obaz_zareg) }}
                                    </div>
                                </template>
                                <template v-else-if="header.key === 'obaz_nezareg'">
                                    <div>
                                        {{ $n(item.obaz_nezareg) }}
                                    </div>
                                </template>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="z-index:9999;">
            <hr style="border: 2px solid #6087A0;">
            <customer-table style="height: 30vh !important;" :data="suppliers"></customer-table>
        </div>
    </div>
</template>
<script>
import {Ax} from '@/utils';
import DatePicker from "vue2-datepicker";
import moment from 'moment';
import VueElementLoading from "vue-element-loading";
import CustomerTable from './customerTable.vue'
export default {
    name: 'regionBudget',
    components: { DatePicker, 'loading': VueElementLoading, CustomerTable },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'god_plan',
                    label: 'По плану'
                },
                {
                    key: 'god_ostatok',
                    label: 'Остаток по ПФ'
                },
                {
                    key: 'period_plan',
                    label: 'План'
                },
                {
                    key: 'period_oplacheno',
                    label: 'Оплачено'
                },
                {
                    key: 'period_ne_oplacheno',
                    label: 'Не оплачено'
                },
                {
                    key: 'obaz_zareg',
                    label: 'Зарег. по форме 4-20'
                },
                {
                    key: 'obaz_nezareg',
                    label: 'Не зарег. по форме 4-20'
                }
            ],
            open: false,
            firstDateTo: new Date(),
            listReg: [],
            reg: null,
            obl: null,
            r_options: [
                'с ВЗК',
                'без ВЗК',
                'только ВЗК'
            ],
            selecteds: 'с ВЗК',
            curParams: {},
            fullData: [],
            suppliers: [],
            loading: false,
            selected: null
        }
    },
    computed: {
        computedPopupStyle() {
            return {
                position: 'absolute',
                top: '100%',
            };
        }
    },
    async mounted() {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1)
        this.firstDateTo = yesterday;
        await this.getObl(); 
    },
    methods: {
        async getData() {
            this.fullData = [];
            this.suppliers = [];
            this.loading = true;
            this.curParams = {
                date_to: moment(this.firstDateTo).format('YYYY-MM-DD'),
                vzk: this.selecteds,
                obl: this.obl,
                region: this.reg ? this.reg.code : `${this.obl}0101`
            }
            const response = await fetch('/api-py/analytic-budget-region/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(this.curParams)
            });
            if (response.status === 200) {
                const data = await response.json();
                if (data.length > 0) {
                    for (const r of data) {
                        const { abp, ...d } = r;
                        d._rowVariant = 'white';
                        d.visible = true;
                        d.open = this.open;
                        this.fullData.push(d);
                        for (const abp of r.abp) {
                            const { pcl, ...i } = abp;
                            i._rowVariant = 'secondary';
                            i.open = this.open;
                            this.fullData.push(i)
                            for (const pcl of abp.pcl) {
                                const { spf, ...p } = pcl;
                                p._rowVariant = 'secondary';
                                p.open = this.open;
                                this.fullData.push(p);
                                for (const spf of pcl.spf) {
                                    const { ...s } = spf;
                                    s._rowVariant = 'white';
                                    this.fullData.push(s);
                                }
                            }
                        }
                    }
                }
            }
            this.loading = false;
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode) 
                // await fetch('/api-py/get-budget-obl/06')
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
            try {
                const response = await fetch('/api-py/get_kato_fulfill/' + this.obl + '/analytic-budget/' + this.$store.getters.user_uuid + '/' + moment(this.firstDateTo).format('YYYY-MM-DD'));
                // const response = await fetch('/api-py/get_kato_aqtobe/06' + '/' + moment(this.firstDateTo).format('YYYY-MM-DD'));
                this.listReg = await response.json();
                this.reg = this.listReg[0];
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        },
        async getSupplier(spf) {
            this.selected = spf;
            this.suppliers = [];
            this.loading = true;
            const params = {
                date_to: moment(this.firstDateTo).format('YYYY-MM-DD'),
                kbk: spf.func + spf.spf,
                func: spf.func,
                spf: spf.spf,
                region: this.curParams.region
            }
            const response = await fetch('/api-py/analytic-budget-supplier/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(params)
            });
            if (response.status === 200) {
                const data = await response.json();
                for (const d of data) {
                    const { suppliers, ...i } = d;
                    i.visible = true;
                    i.open = false;
                    this.suppliers.push(i);
                    for (const supplier of d.suppliers) {
                        const { contracts, ...s } = supplier;
                        s.open = false;
                        this.suppliers.push(s);
                        const sup = supplier.contracts;
                        for (const contract of sup) {
                            const { ...c } = contract;
                            this.suppliers.push(c);
                        }
                    }
                }
            }
            this.loading = false;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        openChilds(item) {
            item.open = !item.open;
            if (item.region) {
                if (!item.open) {
                    for (const r of this.fullData.filter(x => x.parent_region == item.region)) {
                        r.open = item.open;
                        r.visible = item.open;
                    }
                } else {
                    for (const r of this.fullData.filter(x => x.abp && (x.parent_region == item.region))) {
                        r.visible = item.open;
                    }
                }
            }
            if (item.abp) {
                if (!item.open) {
                    for (const r of this.fullData.filter(x => x.pcl && (x.parent_abp == item.abp) && (item.parent_region == x.parent_region))) {
                        r.open = item.open;
                        r.visible = item.open;
                        for (const p of this.fullData.filter(x => x.spf && (x.parent_pcl == r.pcl) && (x.parent_abp == item.abp) && (item.parent_region == x.parent_region))) {
                            p.visible = item.open;
                        }
                    }
                } else {
                    for (const r of this.fullData.filter(x => x.pcl && (x.parent_abp == item.abp) && (item.parent_region == x.parent_region))) {
                        r.visible = item.open;
                    }
                }
            }
            if (item.pcl) {
                for (const r of this.fullData.filter(x => x.spf && (x.parent_pcl == item.pcl) && (x.parent_abp == item.parent_abp) && (x.parent_region == item.parent_region))) {
                    r.visible = item.open;
                }
            }
        },
        openAll() {
            this.open = !this.open;
            for (const r of this.fullData.filter(x => x.region)) {
                r.open = this.open;
            }
            for (const r of this.fullData.filter(x => x.abp)) {
                r.open = this.open;
                r.visible = this.open;
            }
            for (const r of this.fullData.filter(x => x.pcl)) {
                r.open = this.open;
                r.visible = this.open;
            }
            for (const r of this.fullData.filter(x => x.spf)) {
                r.visible = this.open;
            }
        }
    }
}
</script>

<style>
.custom-scroll {
    position: sticky; 
    top: 0px;
}
</style>