<template>
    <div class="inner-container">
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="goodsData"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >   
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAllGoods ? getDecText('del_all_recs') : getDecText('del_selected_recs') }`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td></td>
                    <td colspan="3" class="td-numbering table-success text-center">1</td>
                    <td class="td-numbering table-danger text-center">2</td>
                    <td class="td-numbering table-info text-center">3</td>
                    <td class="td-numbering table-primary text-center">4</td>
                    <td class="td-numbering table-warning text-center">5</td>
                    <td class="td-numbering table-success text-center">6</td>
                    <td class="td-numbering table-danger text-center">7</td>
                    <td class="td-numbering table-info text-center">8</td>
                    <td class="td-numbering table-primary text-center">9</td>
                    <td class="td-numbering table-primary text-center">10</td>
                    <td class="td-numbering table-primary text-center">11</td>
                    <td class="td-numbering text-center">12</td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAllGoods" 
                            @change="e => $emit('setIsAllDelete', e, goodsData)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAllGoods = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(good_name)="data">
                    <div class="form-01-152-multiselect-group error">
                        <multiselect
                            v-b-popover.hover.topright="currGoodName ? currGoodName.name : null"
                            v-if="variantAttribute && data.item.isNew"
                            v-model="currGoodName"
                            style="width: 270px"
                            label="name"
                            :placeholder="getDecText('enter_name')"
                            :options="ensTruNamePrt"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            :internal-search="false"
                            @blur="() => ensTruNamePrt.splice(0)"
                            @search-change="(e) => $emit('searchName', e)"
                            @input="setCurrGoodDesc(data.item)"
                        >
                        <span slot="noResult">
                            {{ getDecText('name_not_found') }}
                        </span>
                        <span slot="noOptions">
                            {{ getDecText('start_typing_name') }}
                        </span>
                        </multiselect>
                        <div v-else :id="`ens-item-name-${data.item.id}`" v-bind:class="{'ens-item-non-active': !data.item.esnActive}">{{ data.item.name }}</div>
                        <b-spinner v-if="data.item.isNew && parNameLoading" small label="Small Spinner"></b-spinner>
                        <b-popover v-if="!data.item.esnActive" placement="topright" :target="`ens-item-name-${data.item.id}`" triggers="hover focus">
                            {{ getDecText('code_isnt_active') }}
                        </b-popover>

                        <template v-if="(data.item.isHasDouble)">
                            <i class="icon icon-danger decodes-error-icon"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("exist") }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(shrt_descr)="data">
                    <b-spinner class="form-01-152-spinner" v-if="data.item.isNew && descIsLoading" small label="Small Spinner"></b-spinner>
                    <multiselect
                        v-b-popover.hover.topright="data.item.currGoodDesc ? data.item.currGoodDesc.detail : null"
                        v-if="variantAttribute && data.item.ensTruName && data.item.ensTruName.length > 1"
                        v-model="data.item.currGoodDesc"
                        style="width: 270px"
                        label="detail"
                        :placeholder="getDecText('select_characteristics')"
                        :options="data.item.ensTruName"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="descSelectedNameChanged(data.item)"
                    >
                    <span slot="noOptions">
                        {{ getDecText('select_name') }}
                    </span>
                    </multiselect>

                    <div v-else>{{ data.item.shrDescr }}</div>
                </template>
                <template #cell(additional_descr)="data">
                    <b-form-textarea v-if="variantAttribute && data.item.enstru_code"
                        style="min-height: 100px"
                        v-model.trim="data.item.add_detail"
                        @change="onAdditionalDescrChanged(data.item)"
                        :formatter="formatText"
                    ></b-form-textarea>
                    <div v-else>{{ data.item.add_detail }}</div>
                </template>
                <template #cell(unit)="data">
                    <multiselect
                        v-if="variantAttribute && data.item.uom && data.item.uom.length > 1"
                        style="max-width: 130px"
                        :value="data.item.uom.filter(u => u.code === data.item.unit_code)[0]"
                        @input="v => onUnitChanged(data.item, v)"
                        label="name"
                        :options="data.item.uom"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                    >
                    </multiselect>
                    <div v-else>{{ getUnitName(data.item.unit_code) }}</div>
                </template>
                <template #cell(amount)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.amount"
                                  @change="v => {data.item.amount = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'amount', data.item.amount, 0)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.amountPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(abonent)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.abonent"
                                  @change="v => {data.item.abonent = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'abonent', data.item.abonent, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.costColsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(time_based)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.time_based"
                                  @change="v => {data.item.time_based = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'time_based', data.item.time_based, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.costColsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(payment)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.payment"
                                  @change="v => {data.item.payment = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'payment', data.item.payment, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.costColsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(cost)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.cost"
                                  @change="v => {data.item.cost = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'cost', data.item.cost, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.costColsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  :disabled="data.item.monthsDisabled"
                                  style="min-width: 40px"
                                  class="text-right"
                                  :value="data.item.months"
                                  @change="v => {data.item.months = v; onDataChanged(data.item)}"
                                  @keyup="(e) => $emit('checkNumber', e, 0, 12)"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'months', data.item.months, 0)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.monthsPassVal">{{ getErrText('not_more_12') }}</p>
                </template>
                <template #cell(rent)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.rent"
                                  @change="v => {data.item.rent = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'rent', data.item.rent, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.costColsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(traffic)="data">
                    <b-form-input v-if="variantAttribute && data.item.enstru_code"
                                  class="text-right"
                                  :value="data.item.traffic"
                                  @change="v => {data.item.traffic = v; onDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'traffic', data.item.traffic, 2)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && data.item.enstru_code && !data.item.costColsPassVal">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="() => { if (data.item.ensTruName) onFilesClick(data.item) }"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" :disabled="goodsAreSaving" v-if="variantAttribute" @click="deleteItem(`${getDecText('del_curr_rec')}`, data.item, data.index)"></i>
                </template>

                <template #bottom-row="data">
                    <td class="text-left" colspan="5">{{ getCommonText("total") }}</td>
                    <td class="text-right">{{ $n(allTotals.sumAmount) }}</td>
                    <td class="text-right">{{ isOneColumnFilled ? $n(abonentTotal) : 'x'}}</td>
                    <td class="text-right">{{ isOneColumnFilled ? $n(timeBasedTotal) : 'x' }}</td>
                    <td class="text-right">{{ isOneColumnFilled ? $n(paymentTotal) : 'x' }}</td>
                    <td class="text-right">{{ isOneColumnFilled ? $n(costTotal) : 'x' }}</td>
                    <td class="text-right">{{ $n(allTotals.maxMonth) }}</td>
                    <td class="text-right">{{ isOneColumnFilled ? $n(rentTotal) : 'x' }}</td>
                    <td class="text-right">{{ isOneColumnFilled ? $n(trafficTotal) : 'x' }}</td>
                    <td class="text-right">{{ $n(allTotals.sumTotal) }}</td>
                    
                    <td colspan="2"></td>
                </template>
                
            </b-table>

            <modal-files-management-nodecode
                ref="modalFilesManagement"
                :variant-attribute="variantAttribute"
                @triggDownloadFile="triggDownloadFile"
                @triggDownloadAllFiles="triggDownloadAllFiles"
                @fileUpload="fileUpload"
                @toggleIsAdd="toggleIsAdd($event)"
            />
            
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ModalFilesManagementNodecode from './modal-files-management-nodecode.vue'
import BpI18nHandlerMixin from '../mixins/bp-i18n-handler-mixin';

export default {
    name: 'FormTemplate5',
    components: { ModalFilesManagementNodecode },
    mixins: [BpI18nHandlerMixin],
    props: {
        goodsData: Array,
        initialGoodsData: Object,
        header: Object,
        ensTruDict: Array,
        variantAttribute: Boolean,
        currCategory: Object,
        lang: String,
        ifAllFieldsFilled: Function,
        getUnitName: Function,
        ensTruNamePrt: Array,
        ensTruName: Array,
        currUnits: Array,
        loadCurUnits: Function,
        getEnstruByName: Function,
        showFileUploadModal: Function,
        roundNumber: Function,
        setDataChanged: Function,
        parNameLoading: Boolean,
        descIsLoading: Boolean,
        allFiles: Array,
        formCode: String,
        goodsAreSaving: Boolean,
        isDoubleDecodeExist: Function
    },

    data() {
        return {
            dataTypeFilter: null,
            variantName: null,
            formFilter: null,
            budgetForm: [],
            dict: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            currUnit: null,
            currGoodName: null,
            totalFactCost: 0,
            totalArea: 0,
            totalMonths: 0,
            totalAmount: 0,
            totalTotal: 0,
            totalTotalSaved: 0,
            totalPrice: 0,
            currGoodDesc: null,
            selectAllGoods: false,
            isAdd: false,
            costsColumnsNames: ['abonent', 'time_based', 'payment', 'cost', 'rent', 'traffic']
        };
    },

    created() {
    },

    methods: {
        addItem() {
            if (this.isDoubleDecodeExist()) return;
            // проверяем все ли поля заполнены            
            if (!this.ifAllFieldsFilled()) return;
            this.currGoodName = null;     
            const item = { id: new Date() * -1 };
            this.$set(item, 'isNew', true);
            this.$set(item, 'rowToSave', true);
            this.$set(item, 'amountPassVal', false);
            this.$set(item, 'monthsPassVal', false);
            this.$set(item, 'monthsDisabled', false);
            this.$set(item, 'costColsPassVal', false);
            this.$set(item, 'currGoodDesc', null);
            this.$set(item, 'esnActive', true);
            this.$set(item, 'amount', 0)
            this.$set(item, 'abonent', 0)
            this.$set(item, 'time_based', 0)
            this.$set(item, 'payment', 0)
            this.$set(item, 'cost', 0)
            this.$set(item, 'months', 1)
            this.$set(item, 'rent', 0)
            this.$set(item, 'traffic', 0)
            this.$set(item, 'price', 0);
            this.$emit('setTotals', item);
            this.$set(item, 'esnActive', true);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'isHasDouble', false);
            this.getExistedFiles(item);
            this.$set(item, 'files', 0);
            this.goodsData.push(item);
            this.ensTruDict.splice(0);
        }, // добавление записи в таблицу

        async getExistedFiles(item) {
            try {
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/${JSON.stringify(this.header)}`);
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', this.getErrText('get_files'), error.toString());
            }
        },

        async setCurrGoodDesc(item) {
            await this.getEnstruByName(this.currGoodName.name);
            process.nextTick(() => {
                if (this.ensTruName.length) {
                    const relativeDecrIdx = this.ensTruName.findIndex(itm => itm.code === this.currGoodName.code);
                    this.$set(item, 'currGoodDesc', this.ensTruName[relativeDecrIdx !== -1 ? relativeDecrIdx : 0]);
                    this.$set(item, 'ensTruName', this.ensTruName);
                    this.descSelectedNameChanged(item);
                };
            })
        },

        makeToast(variant = null, title, msg) {
            this.$bvToast.toast(msg, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        },

        async descSelectedNameChanged(item) {
            await this.loadCurUnits(item.currGoodDesc.code)
            process.nextTick(() => {
                this.$set(item, 'enstru_code', item.currGoodDesc.code);
                const uom = [];
                this.currUnits.forEach(u => {
                    const unitName = this.getUnitName(u.unit_code);
                    uom.push({name: unitName, code: u.unit_code});
                })
                if (uom.length) {
                    this.currUnit = uom[0];
                } else this.currUnit = [];
                this.$set(item, 'unit_code', this.currUnit.code);
                this.$set(item, 'uom', uom);
                this.$set(item, 'shrDescr', item.currGoodDesc['detail_'+this.lang]);
                this.$set(item, 'name', item.currGoodDesc['name_'+this.lang]);
                this.onDataChanged(item);
                this.$emit('doublesValidationDecodes');
            });
        },

        formatText(text) {
            if (text.length > 254) {
                return text.slice(0, 254);
            } else {
                return text;
            }
        },

        inputFixed(item, field, value, digit) {
            this.$nextTick(() => {
                const valueAsFloat = parseFloat(value);
                let newVal = valueAsFloat ? this.roundNumber(valueAsFloat, digit) : 0;
                if (field === 'months') {
                    if (newVal > 12) newVal = 12;
                    if (newVal < 1) newVal = 1;
                } 

                this.$set(item, field, newVal);

                const isMonthFieldWasReset = field === 'months' && !valueAsFloat;
                if (isMonthFieldWasReset) this.validateMonthsField(item);
            })
        }, // форматирует введенное значение до digit цифр после запятой + расчет вычислемых полей

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || (!row && this.goodsData.findIndex(itm => itm.itemToDelete) === -1)) return;
            let deletingRow = null;
            this.$bvModal.msgBoxConfirm(
                `${msg}...`,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            if ((!row.id || row.id < 0) && row.rowToSave) {
                                this.goodsData.splice(index, 1); 
                                this.checkIfChangedDecodesLeft();
                            }
                            else {
                                deletingRow = this.goodsData.splice(index, 1);
                                this.deleteGood(row);
                            }
                        } else {
                            const existingRowsToDel = [];
                            for (let i = this.goodsData.length - 1; i >=0; i--) {
                                if (this.goodsData[i].itemToDelete) {
                                    const rowId = this.goodsData[i].id;
                                    if (rowId >= 0) {
                                        existingRowsToDel.push({id: rowId});
                                    };
                                    this.goodsData.splice(i, 1);
                                };
                            };
                            if (!existingRowsToDel.length) { 
                                this.selectAllGoods = false;
                                this.checkIfChangedDecodesLeft();
                            } else {
                                this.deleteGood(existingRowsToDel)
                            }
                        }
                        this.$emit('doublesValidationDecodes');
                    }
                })
                .catch(error => {
                    if (deletingRow) this.goodsData = this.goodsData.push(deletingRow).sort((a, b) => a.id - b.id);
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // удаление записи

        async deleteGood(item) {
            try {
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    this.$emit('loadCategoryData');
                    this.checkIfChangedDecodesLeft();
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.$emit('deletingAgreementTotalResultHandler', result);
                } else {
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            }
        }, // удаление данных

        checkIfChangedDecodesLeft() {
            const isAllGoodsDeleted = this.goodsData.length === 0;
            const changedItem = this.goodsData.find(item => item.rowToSave === true);
            if (!changedItem || isAllGoodsDeleted) this.$emit('setDataChanged', false);
        },

        onFilesClick(item) {
            this.$refs.modalFilesManagement.showModal(item);
            this.rowId = item.id;
        },

        getTotal() {
            return {'totalAll': this.totalTotal, 'totalSaved': this.totalTotalSaved};
        }, // передача тотала данной категории родителю

        onDataChanged(item) {
            this.$set(item, "rowToSave", true);
            this.validateTableFields(item);
            this.$emit('setDataChanged', true);
        }, 

        validateTableFields(item) {
            if (!item.amount || item.amount <= 0) {
                this.$set(item, "amountPassVal", false);
            } else this.$set(item, "amountPassVal", true);

            this.validateMonthsField(item);

            const filledCostCols = [];
            this.costsColumnsNames.forEach(colName => {
                if (item[colName] && item[colName] > 0) filledCostCols.push(colName);
            })

            const isAllCostColsEmpty = filledCostCols.length === 0;
            if (isAllCostColsEmpty) this.$set(item, "costColsPassVal", false)
            else this.$set(item, "costColsPassVal", true);
        },

        validateMonthsField(item) {
            const costsColumnsNoPayment = this.costsColumnsNames.filter((col) => col !== 'payment');
            const costsColumnsNoPaymentSum = costsColumnsNoPayment.reduce((summa, col) => summa + (item[col] ? Number(item[col]) : 0), 0)
            
            const isMonthsColShouldBeEmpty = item.amount && item.payment && costsColumnsNoPaymentSum === 0;
            if (isMonthsColShouldBeEmpty) {
                this.$set(item, "months", 0);
                this.$set(item, "monthsDisabled", true);
                this.$set(item, "monthsPassVal", true);
            } else {
                // /
                this.$set(item, "monthsDisabled", false);
                if (item.months && item.months > 0) {
                    this.$set(item, "monthsPassVal", true);
                } else {
                    this.$set(item, "monthsPassVal", false);
                }
            }
        },

        setAndValidateMonthField() {
            this.goodsData.forEach(row => {
                this.validateMonthsField(row);
            })
        },

        triggDownloadFile(filename) {
            this.$emit('triggDownloadFile', filename);
        },

        triggDownloadAllFiles(fileNamesList) {
            this.$emit('triggDownloadAllFiles', fileNamesList);
        },

        fileUpload(rowId) {
            this.$emit('fileUpload', rowId);
        },

        toggleIsAdd(return_object) {
            const curDecode = this.goodsData.find(b => b.id === this.rowId);
            curDecode['row_files'] = return_object['row_files'];
            curDecode['files'] = return_object['num_attach_files'];
            this.$set(curDecode, 'rowToSave', true);
            this.isAdd = return_object['isAdd'];
            this.$emit('setDataChanged', true);
        },

        onNewFileAdded(files) {
            this.$refs.modalFilesManagement.setDataChanged(true);
            this.$refs.modalFilesManagement.addNewFiles(files);
        },

        onAdditionalDescrChanged(item) {
            this.onDataChanged(item);
            this.$emit('doublesValidationDecodes');
        }, 

        onUnitChanged(item, val) {
            this.currUnit = val; 
            this.$set(item, 'unit_code', val.code); 
            this.onDataChanged(item);
            this.$emit('doublesValidationDecodes');
        },
        
    },
    computed: {
        getFormText() {
            return this.setFormText('form_01_152.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'good_name',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'shrt_descr',
                        label: this.getCommonText('short_info')
                    },
                    {
                        key: 'additional_descr',
                        label: this.getCommonText('add_info')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('measure')
                    },
                    {   key: 'amount',
                        label: this.getFormText('num_count')
                    },
                    {   key: 'abonent',
                        label: this.getFormText('sub_fee')
                    },
                    {   key: 'time_based',
                        label: this.getFormText('time_fee')
                    },
                    {   key: 'payment',
                        label: this.getFormText('annual_channel_cost')
                    },
                    {   key: 'cost',
                        label: this.getFormText('monthly_avg_cost')
                    },
                    {   key: 'months',
                        label: this.getFormText('month_count')
                    },
                    {   key: 'rent',
                        label: this.getFormText('monthly_rent_fee')
                    },
                    {   key: 'traffic',
                        label: this.getFormText('monthly_rate_fee')
                    },
                    {   key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    },
                ];
            };
            return [];
        },

        allTotals() {
            const totals = {
                sumAmount: 0,
                maxMonth: 0,
                sumTotal: 0,
                costsColumnsFilled: []
            };
            this.costsColumnsNames.forEach(column => {
                    totals[`sum_${column}`] = 0;
            });
            this.goodsData.forEach(item => {
                totals.sumAmount += item.amount;
                totals.maxMonth = Math.max(item.months, totals.maxMonth);
                totals.sumTotal += item.total;
                this.costsColumnsNames.forEach(column => {
                    totals[`sum_${column}`] += item[column];
                    const isColumnFilled = item[column] > 0;
                    if (isColumnFilled && !(totals.costsColumnsFilled.find(el => el === column))) totals.costsColumnsFilled.push(column);
                });
            });
            return totals;
        },

        isOneColumnFilled() {
            return this.allTotals.costsColumnsFilled.length === 1;
        },

        costsColumnTotal() {
            return this.roundNumber(
                    this.allTotals.sumTotal / 
                    this.allTotals.sumAmount / 
                    this.allTotals.maxMonth * 1000, 2);
        },

        abonentTotal() {
            if (this.isOneColumnFilled && this.allTotals.costsColumnsFilled[0] === 'abonent') return this.costsColumnTotal
            else return 0;
        },

        timeBasedTotal() {
            if (this.isOneColumnFilled && this.allTotals.costsColumnsFilled[0] === 'time_based') return this.costsColumnTotal
            else return 0;
        },

        paymentTotal() {
            if (this.isOneColumnFilled && this.allTotals.costsColumnsFilled[0] === 'payment') {
                return this.roundNumber((
                    this.allTotals.sumTotal / 
                    this.allTotals.sumAmount * 1000), 2);
            }
            else return 0;
        },

        costTotal() {
            if (this.isOneColumnFilled && this.allTotals.costsColumnsFilled[0] === 'cost') return this.costsColumnTotal
            else return 0;
        },

        rentTotal() {
            if (this.isOneColumnFilled && this.allTotals.costsColumnsFilled[0] === 'rent') return this.costsColumnTotal
            else return 0;
        },

        trafficTotal() {
            if (this.isOneColumnFilled && this.allTotals.costsColumnsFilled[0] === 'traffic') return this.costsColumnTotal
            else return 0;
        },
    }
    
}
</script>

<style scoped>
.form-01-152-multiselect-group {
    display: flex;
}

.form-01-152-multiselect-group .spinner-border {
    position: relative;
    right: 50px;
    opacity: 0.3;
    margin: auto;
    color: #7A87A0;
    z-index: 999;
}

.form-01-152-spinner {
    display: block;
    margin: auto;
    color: #7A87A0;
}

.goods-form-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}

.ens-item-non-active {
    color: #6087a099;
}

.decodes-error-icon {
    top: -20px;
    left: 0px;
}

</style>
