<template>
    <div v-show="!edit">
        <!----------------Место для шапки с фильтром--------------->
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>

                        <!---------------Фильтр по году-------------------->
                        <div class="filter-block">
                            <b-form-group :label="yearItem" class="small">
                                <multiselect
                                    v-model="currentYear"
                                    :options="yearsArr"
                                    placeholder="Год"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadProgram"
                                />
                            </b-form-group>
                        </div>
                        <!---------------Документы СГП--------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[0].name_ru" class="small">
                                <multiselect
                                    v-model="dictProgramVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Документы СГП"
                                    :options="programArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadGoalsIndicators"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------Целевые индикаторы--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[1].name_ru" class="small">
                                <multiselect
                                    v-model="dictProgramGoalVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Целевые индикаторы"
                                    :options="programGoalArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadSgpDocumentsById"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------Вышестоящий документ--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[2].name_ru" class="small">
                                <multiselect
                                    v-model="dictSgpParentDocVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Вышестоящий документ"
                                    :options="programSgpDocArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------АБП--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[3].name_ru" class="small">
                                <multiselect
                                    v-model="dictAbpVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="АБП"
                                    :options="abpArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------Взаимоувязка--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[5].name_ru" class="small">
                                <multiselect
                                    v-model="connectionVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Взаимоувязка"
                                    :options="connectArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-button variant="success" @click="chgParams">Открыть</b-button>
                        </div>
                    </div>
                </b-dropdown>
            </div>
        </div>
        <!---------Начало грид таблицы-------------->
        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered b-table-no-border-collapse">
                    <thead>
                    <tr class="text-center">
                        <th rowspan="2" class="toggle-show">
                            <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                                <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                            </button>
                        </th>
                        <th rowspan="2">СГП</th>
                        <th rowspan="2">Направление</th>
                        <th rowspan="2">Цели</th>
                        <th rowspan="2">Целевые индикаторы</th>
                        <th rowspan="2">М</th>
                        <th rowspan="2">Вышестоящий документ</th>
                        <th rowspan="2">АБП/БП</th>
                        <th rowspan="2">Ед. измерения</th>
                        <th colspan="2">Факт</th>
                        <th colspan="5">План</th>
                    </tr>
                    <tr>
                        <th class="cellAlign">{{ this.tableFieldYearOne }}</th>
                        <th class="cellAlign">{{ this.tableFieldYearTwo }}</th>
                        <th class="cellAlign">{{ this.tableFieldYearThree }}</th>
                        <th class="cellAlign">{{ this.tableFieldYearFour }}</th>
                        <th class="cellAlign">{{ this.tableFieldYearFive }}</th>
                        <th class="cellAlign">{{ this.tableFieldYearSix }}</th>
                        <th class="cellAlign">{{ this.tableFieldYearSeven }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(targetItem, targetItemIndx) of programObjArr">
                        <tr :key="'targetItemIndx'+targetItemIndx" class="table-info">
                            <td class="toggle-show">
                                <button type="button" class="btn btn-secondary" @click="collapseItems(targetItemIndx, targetItem.id)">
                                    <i class="icon icon-chevron-circle" v-if="collapseArr[targetItemIndx]"></i>
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                </button>
                            </td>
                            <td colspan="8">{{ targetItem.name_ru }}</td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                        </tr>
                        <template v-if="!collapseArr[targetItemIndx]">
                            <template v-for="(directItem, directItemIndex) of directArr">
                                <template v-if="directItem!==null && directItem.programId==targetItem.id">
                                    <tr :key="`targetItemIndx_${targetItemIndx}_${directItemIndex}`" class="table-info">
                                        <td colspan="2" class="toggle-show">
                                            <button type="button" class="btn btn-secondary" @click="collapseItemsSec(directItemIndex, directItem.id)">
                                                <i class="icon icon-chevron-circle" v-if="collapseArrSec[directItemIndex]"></i>
                                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                            </button>
                                        </td>
                                        <td colspan="7"><template v-if="directItem!==null && directItem.programId==targetItem.id">{{ directItem.name_ru }}</template></td>
                                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                    </tr>
                                    <template v-if="!collapseArrSec[directItemIndex]">
                                        <template v-for="(goalItem, goalItemIndex) of goalObjArr">
                                            <template v-if="goalItem!==null && goalItem.directionId===directItem.id && goalItem.programId === targetItem.id">
                                                <tr :key="`targetItemIndx_${targetItemIndx}_${directItemIndex}_${goalItemIndex}`" class="table-info">
                                                    <td colspan="3" class="toggle-show">
                                                        <button type="button" class="btn btn-secondary" @click="collapseItemsThird(goalItemIndex, directItem.id)">
                                                            <i class="icon icon-chevron-circle" v-if="collapseArrThird[goalItemIndex]"></i>
                                                            <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                                        </button>
                                                    </td>
                                                    <td colspan="6"><template v-if="goalItem!==null && goalItem.directionId===directItem.id">{{ goalItem.name_ru }}</template></td>
                                                    <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                                </tr>
                                                <template v-if="!collapseArrThird[goalItemIndex]">
                                                    <template v-for="(elementItem, elementItemIndex) of forecastFormArr">
                                                        <template v-if="elementItem!==null && elementItem.goalsId===goalItem.id && elementItem.directionId===directItem.id && elementItem.programId ===targetItem.id">
                                                            <tr :key="`targetItemIndx_${targetItemIndx}_${directItemIndex}_${goalItemIndex}_${elementItemIndex}`">
                                                                <td colspan="4"></td>
                                                                <td>{{ elementItem.targetIndicators }}</td>
                                                                <td style="text-align: center">{{ elementItem.macroindicator }}</td>
                                                                <td>{{ elementItem.sgpParentDoc }}</td>
                                                                <td style="text-align: left; width: 80px;">
                                                                    <div style="display: flex; align-items: center; justify-content: center">
                                                                        <div style="width: 55px;">
                                                                            <ul class="listAbpBp">
                                                                                <template v-for="(abpBp, abpBpIndex) of elementItem.abp_bp">
                                                                                    <li :key="`abpBpIndex_${abpBpIndex}`">{{ abpBp }}</li>
                                                                                </template>
                                                                            </ul>
                                                                        </div>
                                                                        <template v-if="accessLevelValue==accessLevel.readWrite">
                                                                            <div v-if="!elementItem.abp_bp.length" style="width: 10px; margin-right: 15px;">
                                                                                <i class="icon icon-plus-circle green ml-3 pointer icon-18" @click="editAndAddBtn(elementItem, 'add')"></i>
                                                                            </div>
                                                                            <div v-else>
                                                                                <i class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" @click="editAndAddBtn(elementItem, 'edit')"></i>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </td>
                                                                <td style="text-align: right">{{ elementItem.unitFromStat }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearOne && elementItem ? elementItem.yearOne.toLocaleString("ru-RU"): "" }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearTwo && elementItem ? elementItem.yearTwo.toLocaleString("ru-RU"): "" }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearThree && elementItem ? elementItem.yearThree.toLocaleString("ru-RU"): "" }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearFour && elementItem ? elementItem.yearFour.toLocaleString("ru-RU"): "" }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearFive && elementItem ? elementItem.yearFive.toLocaleString("ru-RU"): "" }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearSix && elementItem ? elementItem.yearSix.toLocaleString("ru-RU"): "" }}</td>
                                                                <td style="text-align: right">{{ elementItem.yearSeven && elementItem ? elementItem.yearSeven.toLocaleString("ru-RU"): "" }}</td>
                                                            </tr>
                                                        <!-------------------------------------------------------->
                                                        </template>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                    <tr class="row-fixed">
                        <td style="text-align: center;">&sum;</td>
                        <td style="text-align: center;">{{ totalSGP }}</td>
                        <td style="text-align: center;">{{ totalDirection }}</td>
                        <td style="text-align: center;">{{ totalGoals }}</td>
                        <td style="text-align: center;">{{ totalGoalIndicators }}</td>
                        <td style="text-align: center;">{{ totalMacroindicators }}</td>
                        <td style="text-align: center;">{{ totalParentSGP }}</td>
                        <td style="text-align: center;">{{ totalAbpBp }}</td>
                        <td style="text-align: center;" colspan="8"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
<!----------------------New form-------------------------------->
        <b-modal
            v-model="modalVisible"
            modal-class="modal-table add-abp"
            size="lg"
            centered
            title="Добавить / Редактировать (АБП / БП)"
        >
            <template v-if="reloadArr">
                <template v-for="(elementItem, elementItemIndex) of majorSelectedObjWithAbpBp">
                    <div class="add-abp-block" :key="`abpBpIndex_${elementItemIndex}`">
                        <div class="form-group">
                            <label>АБП</label>
                            <b-form-select
                                v-model="elementItem.id"
                                :options="dictAbpArr"
                                value-field="id"
                                text-field="name"
                                @change="chgAbp(elementItem, elementItemIndex)"
                            ></b-form-select>
                            <i class="icon icon-clear clear-action" @click="removeAbp(elementItem)"></i>
                        </div>
                        <template v-for="(elementBpItem, elementBpItemIndex) of elementItem.items">
                            <div :key="`bpIndex_${elementBpItemIndex}`">
                                <div class="form-group" style="padding-bottom: 10px;">
                                    <label><span v-if="elementBpItemIndex === 0">БП</span></label>
                                    <b-form-select
                                        v-model="elementBpItem.uniqId"
                                        :options="elementBpItem.bpList"
                                        value-field="uniqId"
                                        text-field="name"
                                        @change="chgBp(elementItemIndex, elementBpItemIndex, elementBpItem)"
                                    ></b-form-select>
                                    <i class="icon icon-clear clear-action" @click="removeBp(elementBpItemIndex, elementBpItem.id, elementItem, elementBpItem)"></i>
                                </div>
                            </div>
                        </template>
                        <div class="form-group add-line" @click="addBpList(elementItem, true)">
                            <div class="plus-button">
                                <i class="plus-icon">+</i>
                                <span>Добавить БП</span>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <div class="add-abp-block" @click="addAbpBpList">
                <div class="form-group add-line">
                    <div class="plus-button">
                        <i class="plus-icon">+</i>
                        <span>Добавить АБП</span>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div>
                    <b-button variant="success" size="sm" class="mr-2" @click="initSaveProcess(true)">Сохранить</b-button>
                    <b-button variant="secondary" size="sm" @click="initSaveProcess(false)">Отменить</b-button>
                </div>
            </template>
        </b-modal>
<!-----------------end------>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';

const axios = require("axios");

export default {
    name: 'interconTargetIndicator',
    components: {
        'multiselect': Multiselect
    },
    created() {
        this.currentYear = new Date().getFullYear();
        const currentYear = this.currentYear;
        this.getPlanYears(currentYear, null);
        this.$watch('collapseAll', this.collapseAllFunc);
    },
    computed: {
        // i18nString: function() {
        //     let item
        //     if (this.$i18n.locale === 'kk') {
        //         item = this.programArr.name_kk
        //     } else if (this.$i18n.locale === 'ru'){
        //         return 'ru';
        //     } else  {
        //         return 'en'
        //     }
        // }
    },
    mounted() {
        const currentYear = this.sendCurrentYear();
        this.listOfYears(currentYear);
        const userId = this.usrId();
        this.accessLevelValue = this.userAccessLevel();
        this.getObl(userId);
        this.loadProgram();
    },
    data() {
        return {
            abp: null,
            bp: null,
            modalVisible: false,
            reloadArr: false,
            multiselectTest: '',
            selectBp: null,
            bar: 0,
            userModules: {
                codeOne: '003.000',     // Стратегическое планирование
                codeTwo: '003.002'      // Взаимоувязка ЦИ и БП
            },
            accessLevel: {
                readOnly: 1,            // Просмотр
                readWrite: 2,           // Редактирование
                admin: 3                // Админ
            },
            accessLevelValue: null,
            all: {
                name_ru: 'Все',
                name_en: 'All',
                name_kk: 'Барлық',
                id: ''
            },
            dictProgramVal: null,
            dictProgramGoalVal: null,
            dictSgpParentDocVal: null,
            dictAbpVal: null,
            currentYear: null,
            currentInputYear: null,
            tableFieldYearOne: null,
            tableFieldYearTwo: null,
            tableFieldYearThree: null,
            tableFieldYearFour: null,
            tableFieldYearFive: null,
            tableFieldYearSix: null,
            tableFieldYearSeven: null,
            yearItem: 'Год',
            langObj: {
                name_ru: 'ru',
                name_en: 'en',
                name_kk: 'kk'
            },
            sgpDoc: [
                {
                    name_ru: 'Документы СГП',
                    name_kk: 'Документы СГП',
                    name_en: 'SGP Documents'
                },
                {
                    name_ru: 'Целевые индикаторы',
                    name_kk: 'Целевые индикаторы',
                    name_en: 'Target indicators'
                },
                {
                    name_ru: 'Вышестоящий документ',
                    name_kk: 'Вышестоящий документ',
                    name_en: 'Parent document'
                },
                {
                    name_ru: 'АБП',
                    name_kk: 'АБП',
                    name_en: 'ABP'
                },
                {
                    name_ru: 'Адм.уровень',
                    name_kk: 'Адм.уровень',
                    name_en: 'Administrative level'
                },
                {
                    name_ru: 'С взаимувязкой',
                    name_kk: 'С взаимувязкой',
                    name_en: 'With interconnection'
                }
            ],
            interconnection: [
                {
                    id: 1,
                    name_ru: 'С взаимоувязкой',
                    name_en: 'With interconnection',
                    name_kk: 'С взаимоувязкой',
                    value: 'notNull'
                },
                {
                    id: 2,
                    name_ru: 'Без взаимоувязки',
                    name_en: 'Unconnection',
                    name_kk: 'Без взаимоувязки',
                    'value': 'null'
                }
            ],
            connectArr: [],
            connectionVal: null,
            programArr: [],
            programGoalArr: [],
            dictAbpArr: [],
            dictBpArr: [],
            arrForAbp: [],
            arrForBp: [],
            dictBpArrDynamic: [],
            dictBpArrList: [],
            abpArr: [],
            abpBpObj: {},
            mainObjId: null,
            edit: false,
            open: true,
            header: null,
            initialArr: [],
            forecastFormArr: [],
            mainGridObjectArr: [],
            programObjArr: [],
            goalObjArr: [],
            mainArr: [],
            globalArr: [],
            dictAdmLevelVal: null,
            storeAbpArr: [],
            admLevelArr: [],
            majorSelectedObjWithAbpBp: [],
            copyObjAbpBp: [],
            listOfAbpForSaving: [],
            bpArrList: [],
            filledObjectWithBp: {},
            headParams: null,
            headOtherParams: null,
            totalSGP: 0,
            totalDirection: 0,
            totalGoals: 0,
            totalGoalIndicators: 0,
            totalMacroindicators: 0,
            totalParentSGP: 0,
            totalAbpBp: 0,
            totalABP: 0,
            totalBP: 0,
            collapseAll: true,
            collapseArr: [],
            collapseArrSec: [],
            sgpArr: [],
            directArr: [],
            collapseArrThird: [],
            collapseArrFourth: [],
            yearsArr: [],
            katoLst: [],
            region: null,
            programSgpDocArr: [],
            programSgpDocFullArr: [],
            indicatorId: null,
            isEqualArrs: true,
            countBpDuplicates: [],
            serviceParams: {
                years: '',
                programs: '',
                abps: '',
                connect: '',
                region: ''
            },
            listOfPlanYears: []
        };
    },
    methods: {
        getPlanYears(currentYear, maxYear){
            // console.log('Only header - > currentYear: ' + currentYear + ' maxYear: ' + maxYear);
            /*
                Если на форме несколько программ:
                1) Отобрать максимальный год среди документов (программ) по полю enddate
                2) Если (enddate-4)<=текущего календарного года, то в шапке по плановым годам первый год брать как enddate-4, потом enddate-3, enddate-2, enddate-1, enddate
                3) Если (enddate-4)>текущего календарного года, то в шапке первый год брать как текущий год, потом текущий год+1, текущий год+2, текущий год+3, текущий год+4
            */
            /*
                Если на форме только одна программа:
                1) Если срок реализации (enddate - startdate) < 5, то в шапке первый год брать как startdate, потом startdate+1, startdate+2, startdate+3, startdate+4
                2) Если срок реализации (enddate - startdate) >= 5 и (enddate-4)<=текущего календарного года, то в шапке по плановым годам первый год брать как enddate-4, потом enddate-3, enddate-2, enddate-1, enddate
                3) Если срок реализации (enddate - startdate) >= 5 и (enddate-4)>текущего календарного года, то в шапке первый год брать как текущий год, потом текущий год+1, текущий год+2, текущий год+3, текущий год+4
            */
            // console.log('currentYear: ' + currentYear + ' maxYear: ' + maxYear);
            // console.log('this.currentYear: ' + this.currentYear);
            if (maxYear == null){
                // console.log('currentYear: ' + currentYear)
                this.tableFieldYearOne = parseInt(currentYear) - 2;
                this.tableFieldYearTwo = parseInt(currentYear) - 1;
                this.tableFieldYearThree = parseInt(currentYear);
                this.tableFieldYearFour = parseInt(currentYear) + 1;
                this.tableFieldYearFive = parseInt(currentYear) + 2;
                this.tableFieldYearSix = parseInt(currentYear) + 3;
                this.tableFieldYearSeven = parseInt(currentYear) + 4;
            } else {
                const minYear = Math.min(...this.listOfPlanYears);
                const maxYear = Math.max(...this.listOfPlanYears);

                const yearTwo = parseInt(minYear + 1);
                const yearThree = parseInt(minYear + 2);
                const yearFour = parseInt(minYear + 3);
                const yearFive = parseInt(minYear + 4);

                // console.log('minYear: ' + minYear + ' yearTwo: ' + yearTwo + ' yearThree: ' + yearThree + ' yearFour: ' + yearFour + ' yearFive: ' + yearFive);
                this.tableFieldYearOne = parseInt(this.currentYear) - 2;
                this.tableFieldYearTwo = parseInt(this.currentYear) - 1;

                this.tableFieldYearThree = parseInt(minYear);
                this.tableFieldYearFour = parseInt(yearTwo);
                this.tableFieldYearFive = parseInt(yearThree);
                this.tableFieldYearSix = parseInt(yearFour);
                this.tableFieldYearSeven = parseInt(yearFive);
            }
        },
        padLeadingZeros(num, size) {    // -------добавление нулей после значения в зависимости от размера значения
            let s = String(num);
            while (s.length < size) { s = s + '0'; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        padLeadingZerosInFront(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера
        //-----Кнопка для добавления и редактирования АБП и БП
        editAndAddBtn(param, btn){
          this.reloadArr = true;
          this.mainObjId = null;
          this.dictBpArrDynamic = [];
          this.majorSelectedObjWithAbpBp = [];
          this.copyObjAbpBp = [];
          this.dictBpArrDynamic = this.dictBpArr;

          if (param && this.reloadArr){
            const getAbp = this.getInitialArr(param);
            this.mainObjId = param.id;
            //----Создается массив со списком только АБП и добавляем в него пустой массив-----
            let abpArr = this.generateNewAbpArr(param);
            //------Перебираю весь массив АБП и добавляю в пустой массив доп.свойства и элементы----------
            // Создаём Map для быстрого поиска
            const abpMap = param.listAbpBpOnly;
            // Перебираем abpArr и добавляем соответствующие элементы из abpMap
            for (const abpInit of abpArr){
              for (const abpItem of param.listAbpBpOnly){
                if (abpInit.abp === abpItem.abp){
                  abpInit.items.push(abpItem);
                }
              }
            }
            //--------Перебираю массив АБП и его подмассив с новыми свойствами и элементами---
            abpArr = this.addAdditionItems(abpArr);
            this.modalVisible = true;
            this.majorSelectedObjWithAbpBp = JSON.parse(JSON.stringify(abpArr));
            this.copyObjAbpBp = JSON.parse(JSON.stringify(getAbp));
          }
        },

        generateNewAbpArr(param) {
          const abpArr = [];
          for (const paramElement of param.abpList) {
            const newParams = {
              id: paramElement.id,
              item: paramElement.item,
              abp: paramElement.id,
              items: []
            }
            abpArr.push(newParams);
          }
          return abpArr;
        },

        getInitialArr(params){
          this.mainObjId = params.id;
          //----Создается массив со списком только АБП и добавляем в него пустой массив-----
          // Получаем список абп, предварительно преобразованный
          const abpListMap = params.abpList.map(paramEl => {
            return {
              id: paramEl.id,
              item: paramEl.item,
              abp: paramEl.id,
              items: []
            };
          });
          if (abpListMap.length > 0){
            for (const abp of abpListMap) {
              for (const abpAndBp of params.listAbpBpOnly){
                if (abp.id === abpAndBp.abp){
                  abp.items.push(abpAndBp);
                }
              }
            }
          }
          return abpListMap;
        },

        //---Добавляются элементы справочников АБП и БП при нажатии на кнопку "+"
        addAbpBpList(){
            // console.log('this.abpBpObj: ' + JSON.stringify(this.abpBpObj));
            const additionalElements = {
                id: null,
                item: null,
                abp: null,
                items: [
                    {
                        id: null,
                        abp: null,
                        bp: null,
                        gr: null,
                        pgr: null,
                        ppr: null,
                        uniqId: null,
                        bpList: []
                    }
                ]
            }
            this.majorSelectedObjWithAbpBp.push(additionalElements);
        },

        addBpList(elementItem, status){
            const abp = elementItem;
            const bp = {
                id: null,
                abp: null,
                bp: null,
                gr: null,
                pgr: null,
                ppr: null,
                uniqId: null,
                bpList: []
            }
            bp.bpList = this.selectListBpById(abp);

            if (this.majorSelectedObjWithAbpBp.length > 0){
                for (const mainEl of this.majorSelectedObjWithAbpBp) {
                    if (mainEl.id === abp.id){
                        if (status === true) {
                            mainEl.items.push(bp);
                        } else {
                            mainEl.items = [];
                            mainEl.items.push(bp);
                        }
                    }
                }
            }
        },
        //-----Удалить АБП
        removeAbp(elementItem){
            if (elementItem !== null){
                const newArr = this.majorSelectedObjWithAbpBp.filter(function(f) { return f !== elementItem });
                this.majorSelectedObjWithAbpBp = newArr;
            }
        },
        //-----Удалить БП
        removeBp(indx, bpId, abpItem, bpElements){
            let newArray = [];
            for (const arrEl of this.majorSelectedObjWithAbpBp) {
                // console.log('arrEl: ' + JSON.stringify(arrEl));
                if (arrEl.items.length > 0 && (indx !== null && arrEl.id === abpItem.id)){
                    const curBp = arrEl.items[indx];
                    // console.log('curBp: ' + JSON.stringify(curBp));
                    newArray = abpItem.items.filter(function(f) { return f !== arrEl.items[indx] });
                    arrEl.items = newArray;
                    const tempArr = this.countBpDuplicates;
                    const newTempArr = tempArr.filter(function (f) { return f !== curBp});
                    this.countBpDuplicates = [];
                    this.countBpDuplicates = newTempArr;
                }
            }
            //-----Если все БП были удалены, то автоматически добавляется новый элемент для добавления БП
            if (abpItem.items.length === 0){
                this.addBpList(abpItem, true);
            }
        },

        usrId() {
            if (store.state.user.sub === undefined) { return null; }
            return store.state.user.sub;
        },
        //-----Уровень доступа пользователя
        userAccessLevel() {
            // console.log('store.state userModules: ' + JSON.stringify(store.state.user.userModules));
            if (store.state.user === undefined || store.state.user.userModules.length == 0) {return null;}
            const levelArr = [];
            let maxLevel = 0;
            // console.log('maxLevel: ' + maxLevel);
            for (const level of store.state.user.userModules){
                if (level.modules == this.userModules.codeOne || level.modules == this.userModules.codeTwo){
                    levelArr.push(level.access_level);
                }
            }
            maxLevel = Math.max(...levelArr);
            return maxLevel;
        },
        // --АБП
        async abpDict() {
            this.dictAbpArr = [];
            let response = [];
            const result = [];
            let uniqRes = [];
            const url = '/api/forecast/dict-ebk-func-abp';
            try {
                response = await fetch(url);
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
            }
            for (const el of response) {
                result.push(this.setNameLangs(el, 'abp'));
            }
            uniqRes = this.getOnlyObjects(result);
            // console.log('resultABP: ' + JSON.stringify(result));
            return uniqRes;
        },
        // --БП
        async bpDict() {
            let response = [];
            const result = [];
            let uniqRes = [];
            const url = '/api/forecast/dict-ebk-func-forecast';
            try {
                response = await fetch(url);
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки БП', error.toString());
            }
            for (const el of response) {
                result.push(this.setNameLangs(el, 'prg'));
            }
            uniqRes = result;
            return uniqRes;
        },
        // --Проверяет массив на наличие abp для конкретной записи
        checkAbp(params){
            if (params){
                for (const arrEl of this.dictAbpArr){
                    if (parseInt(params) === parseInt(arrEl.abp)){
                        return true;
                    }
                }
            }
            return false;
        },
        //---Извлекает список БП при выборе АБП
        selectListBpById(bpItem) {
          // Избегаем многократного перебора dictBpArrList
          return this.dictBpArrList.filter(bps =>
              parseInt(bpItem.abp) === parseInt(bps.abp)
          );
        },
        //---Передаются параметры при изменении АБП и БП
        chgAbp(elementItem, indx){
            if (elementItem !== null && this.majorSelectedObjWithAbpBp.length > 0){
                const curAbp = this.getCurrentAbp(elementItem);
                const prevAbp = cleanDuplicates(this.copyObjAbpBp);
                const check = this.isCurrentAbpInMainArr(curAbp);
                if (!check){
                    this.countBpDuplicates = [];
                    this.selectABP(curAbp);
                    // console.log('Добавился');
                }
                else {
                    this.makeToast('danger', 'Ошибка', 'Выбранный АБП уже используется');
                    if (indx > prevAbp.length-1) {
                      this.cleanDataInCurrentObject(indx);
                    } else {
                      this.majorSelectedObjWithAbpBp = this.copyObjAbpBp;
                    }
                }
            }

            function cleanDuplicates(array){
              // Убираем объекты с null значениями в `item` и `id`
              const cleanedArray = array.filter(item => item.id !== null && item.item !== null);
              // Убираем дубликаты по `id`
              return cleanedArray.filter((value, index, self) =>
                      index === self.findIndex((t) => (
                          t.id === value.id
                      ))
              );
            }
        },
        //-----Получить объект текущего(только что выбранного) АБП
        getCurrentAbp(elementItem){
            const curAbp = Object.assign({}, this.majorSelectedObjWithAbpBp.find(it => it.id === elementItem.id));
            if (curAbp.item === null && curAbp.abp === null){
                for (const dictAbp of this.dictAbpArr) {
                    if (curAbp.id === dictAbp.id){
                        curAbp.item = dictAbp.id + ' - ' + dictAbp.nameRu;
                        curAbp.abp = dictAbp.id;
                        curAbp.items = [];
                    }
                }
            } else if (curAbp.id !== curAbp.abp){
                for (const dictAbp of this.dictAbpArr) {
                    if (curAbp.id === dictAbp.id){
                        curAbp.item = dictAbp.id + ' - ' + dictAbp.nameRu;
                        curAbp.abp = dictAbp.id;
                        curAbp.items = [];
                    }
                }
            }
            return curAbp
        },
        //----Проверка на наличие текущего объекта в основном массиве "Модального окна"
        isCurrentAbpInMainArr(curAbp){
            let check = false;
            for (const mainArr of this.majorSelectedObjWithAbpBp) {
                if (curAbp.abp === mainArr.abp) {
                    check = true;
                    break;
                }
            }
            return check;
        },
        //----Обнуление элементов текущего АБП в основном массиве
        cleanDataInCurrentObject(indx){
            this.majorSelectedObjWithAbpBp[indx].id = null;
            this.majorSelectedObjWithAbpBp[indx].items = [];
            const bp = {
                id: null,
                abp: null,
                bp: null,
                gr: null,
                pgr: null,
                ppr: null,
                uniqId: null,
                bpList: []
            }
            this.majorSelectedObjWithAbpBp[indx].items.push(bp);
        },
        //---При выборе АБП обнуляется зависимый раздел БП согласно индекса записи в грид таблице
        selectABP(curAbp){
            if (curAbp !== null){
                //-----Добавить значения к элементам АБП
                for (const mainArr of this.majorSelectedObjWithAbpBp) {
                    for (const dictAbp of this.dictAbpArr) {
                        if (dictAbp.id === mainArr.id) {
                            mainArr.item = dictAbp.id + ' - ' + dictAbp.nameRu;
                            mainArr.abp = dictAbp.id;
                        }
                    }
                }
                //-----Если все БП были удалены, то автоматически добавляется новый элемент для добавления БП
                if (curAbp.items.length === 0){
                    this.addBpList(curAbp, false);
                }
            }
        },
        //---Выбор БП
        chgBp(abpIndex, bpIndex) {
            if (abpIndex !== null && bpIndex !== null) {
                const curElIndexes = {
                    'abpIndex': abpIndex,
                    'bpIndex': bpIndex
                }
                const curBp = this.getCurrentBpInCurAbp(curElIndexes);
                if (Object.keys(curBp).length !== 0){
                    //---Есть ли дубли в только сформированном массиве
                    const isBpDuplicatesInAbp = this.isCurAbpHasBpDuplicatesInInitArr(curBp, curElIndexes);
                    if (!isBpDuplicatesInAbp){
                        this.countBpDuplicates.push(curBp); //---Создан дополнительный массив, который считывает новый БП в обоих массивах
                        //---Есть ли дубли в новом массиве с изменениями
                        const isBpDuplInNewArr = this.isCurAbpHasBpDuplicatesInNewArr(curBp, curElIndexes, this.getOnlyItemsOfObjects(this.countBpDuplicates));
                        if (!isBpDuplInNewArr){
                            this.addOrRemoveBp(isBpDuplInNewArr, curBp, curElIndexes);
                        } else {
                            this.addOrRemoveBp(isBpDuplInNewArr, curBp, curElIndexes);
                        }
                    } else {
                        //---Есть ли в новом массиве элемент из начального массива (в случае, если нечайно изменил ранее сохраненный БП на другой, и решил восстановить)
                        const isInBothArrs = this.isCurAbpHasBpInBothArrs(curBp, curElIndexes);
                        if (!isInBothArrs) {
                            this.addOrRemoveBp(isInBothArrs, curBp, curElIndexes);
                        } else {
                            this.addOrRemoveBp(isInBothArrs, curBp, curElIndexes);
                        }
                    }

                }
            }
        },
        //---Извлечь выбранный БП из текущего АБП
        getCurrentBpInCurAbp(curElIndexes){
            let currentBpElement = {};
            const curAbp = this.majorSelectedObjWithAbpBp[curElIndexes.abpIndex];
            const curBp = curAbp.items[curElIndexes.bpIndex];
            if (curBp){
                currentBpElement = this.fillingBpElements(curBp);
            }
            return currentBpElement
        },
        //-----Проверка на наличие дублей БП в выбранном АБП
        isCurAbpHasBpDuplicatesInInitArr(curBp, curElIndexes){
            if (this.copyObjAbpBp.length > 0){
                const curAbp = Object.assign({}, this.copyObjAbpBp[curElIndexes.abpIndex]);
                if (Object.keys(curAbp).length !== 0 && curAbp.items.length > 0) {
                    for (const arrEl of curAbp.items) {
                        if (arrEl.id === curBp.id && arrEl.abp === curBp.abp && arrEl.bp === curBp.bp) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        //----Проверяет, если решил восстановить измененный БП на первичное состояние
        isCurAbpHasBpInBothArrs(curBp, curElIndexes){
            if (this.majorSelectedObjWithAbpBp.length > 0){
                const curAbp = Object.assign({}, this.majorSelectedObjWithAbpBp[curElIndexes.abpIndex]);
                if (curAbp.items.length > 0) {
                    let count = 0;
                    for (const arrEl of curAbp.items) {
                        if (arrEl.id === curBp.id && arrEl.abp === curBp.abp && arrEl.bp === curBp.bp) {
                            count += 1;
                        }
                    }
                    if (count > 1){
                        return true;
                    }
                }
            }
            return false;
        },
        isCurAbpHasBpDuplicatesInNewArr(curBp, curElIndexes, tempArr){
            let count = 0;
            if (tempArr.length>0){
                for (const bpEl of tempArr) {
                    if (bpEl.id === curBp.id && bpEl.abp === curBp.abp && bpEl.bp === curBp.bp) {
                        count += 1;
                    }
                }
                if (count > 1){
                    return true;
                }
            }
            return false;
        },
        //-----Добавляет БП либо удаляет если такой БП уже имеется
        addOrRemoveBp(isOk, curBpInAbp, curElIndexes){
            const curAbp = this.majorSelectedObjWithAbpBp[curElIndexes.abpIndex];
            if (curAbp && curAbp.items.length > 0) {
                let curBp = curAbp.items[curElIndexes.bpIndex];
                if (curBp) {
                    if (!isOk) {    //----Если нет дубликата, то БП добавляется
                        curBp = curBpInAbp;
                    } else {
                        const newArray = curAbp.items.filter(function (f) { return f !== curBp });
                        curAbp.items = newArray;
                        this.makeToast('danger', 'Ошибка', 'Выбранный БП уже используется');
                    }
                }
            }
        },
        //-----Заполнение элементов новой записи БП
        fillingBpElements(curBp){
            if (this.dictBpArrList.length > 0) {
                for (const bpEl of this.dictBpArrList) {
                    if (curBp.uniqId === bpEl.uniqId) {
                        curBp.id = bpEl.id;
                        curBp.abp = bpEl.abp;
                        curBp.bp = bpEl.prg;
                        curBp.gr = bpEl.gr;
                        curBp.pgr = bpEl.pgr;
                        curBp.ppr = bpEl.ppr;
                        curBp.uniqId = bpEl.abp+''+bpEl.prg;
                        curBp.bpList = this.selectListBpById(bpEl);
                    }
                }
            }
            return curBp;
        },
      //---Закрыть форму добавления и редактирования
        async initSaveProcess(param) {
            if (!param) {//----В случае отмены
                this.modalVisible = false;
                this.reloadArr = false;
                this.countBpDuplicates = [];
                this.majorSelectedObjWithAbpBp = this.copyObjAbpBp;
            } else {//----В случае сохранения
                this.countBpDuplicates = [];
                //----Есть ли хоть какие-нить изменения
                const isArrsEqual = JSON.stringify(this.majorSelectedObjWithAbpBp) === JSON.stringify(this.copyObjAbpBp);
                console.log('isArrsEqual: ' + JSON.stringify(isArrsEqual));
                if (!isArrsEqual){
                  await this.saveProcess(isEmptyAbpOrBpInArr, fullfillObjects);
                } else {
                    this.makeToast('danger', 'Ошибка', 'Нет обновленных данных для сохранения');
                }
            }
            //----Проверяет осн. массив на наличие пустого АБП либо БП
            function isEmptyAbpOrBpInArr(arr){
                for (const mainEl of arr) {
                  // console.log('mainEl: ' + JSON.stringify(mainEl));
                    if (mainEl && mainEl.id === null){
                        return true;
                    } else {
                        if (mainEl.items.length > 0){
                            for (const item of mainEl.items) {
                              // console.log('item: ' + JSON.stringify(item));
                                if ((item && item.id === null) || (item.abp === null && item.bp === null && item.gr === null && item.pgr === null && item.ppr === null)){
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            }
            function fullfillObjects(forecastAbpBp, mainObjId) {
                const forecast_ebk_func = {
                    id: forecastAbpBp ? forecastAbpBp.id : null,
                    gr: forecastAbpBp ? forecastAbpBp.gr : null,
                    pgr: forecastAbpBp ? forecastAbpBp.pgr : null,
                    abp: forecastAbpBp ? forecastAbpBp.abp : null,
                    prg: forecastAbpBp ? forecastAbpBp.bp : null,
                    ppr: forecastAbpBp ? forecastAbpBp.ppr : null,
                    forecast: mainObjId
                }
                return forecast_ebk_func;
            }
        },

        addAdditionItems(abpArr) {
          // Кэшируем список БП для каждого АБП
          const bpListCache = new Map();
          // Проверка на пустой массив сразу, чтобы избежать лишних циклов
          if (abpArr.length) {
            abpArr.forEach(abpEl => {
              if (abpEl) {
                abpEl.items.forEach(item => {
                  // Проверяем, что item и item.abp существуют
                  if (item && this.checkAbp(item.abp)) {
                    // Используем кэш, чтобы избежать повторных запросов
                    if (!bpListCache.has(item.abp)) {
                      bpListCache.set(item.abp, this.selectListBpById(item));
                    }
                    item.bpList = bpListCache.get(item.abp);
                  }
                });
              }
            });
          }
          return abpArr;
        },

        async saveProcess(isEmptyAbpOrBpInArr, fullfillObjects) {
          const initListOfAbpBp = [];
          const listOfAbpBp = [];
          //---Проверка на пустые значения АБП и БП среди элементов для сохранения
          const isAbpOrBpNull = isEmptyAbpOrBpInArr(this.majorSelectedObjWithAbpBp);
          if (!isAbpOrBpNull) {
            if (this.majorSelectedObjWithAbpBp.length > 0) {
              for (const mainEl of this.majorSelectedObjWithAbpBp) {
                if (mainEl && mainEl.items.length > 0) {
                  for (const item of mainEl.items) {
                    listOfAbpBp.push(fullfillObjects(item, this.mainObjId));
                  }
                }
              }

              for (const mainEl of this.copyObjAbpBp) {
                if (mainEl && mainEl.items.length > 0) {
                  for (const item of mainEl.items) {
                    initListOfAbpBp.push(fullfillObjects(item, this.mainObjId));
                  }
                }
              }
            } else {  //  сохранять пустые АБП-БП
              listOfAbpBp.push(fullfillObjects(null, this.mainObjId));
            }
          } else {
            this.makeToast('danger', 'Ошибка', 'Не все поля заполнены');
          }
          //---отправка массива для добавления в БД
          if (listOfAbpBp.length > 0) {
            await this.sendToSaveDB(listOfAbpBp);
          }
        },
        //-----Сохранить данные в БД
        async sendToSaveDB(listOfAbpBpUniq) {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const result = await axios.post(`/api/forecast/abp-bp/${this.mainObjId}`, listOfAbpBpUniq, {headers});
                if (result.status === 200) {
                    this.makeToast('success', 'Сохранение', 'Данные сохранены!');
                    this.chgParams();
                    await this.initSaveProcess(false);
                } else {
                    this.makeToast('danger', 'Сохранение', `Ошибка ${result.status} ${result.statusText}`);
                }
            } catch (error) {
                console.log(error);
            }
        },
// -----Функция для очистки массива "АБП-БП" от дубликатов
      async getObl(usrId) {
            const region = store.state._instanceCode;
            if (region===null || region.length===0) {
                this.region = '55';
            } else {
                this.region = region;
            }
            return region;
        },
        //-----Список годов в фильтрации по годам----//
        listOfYears(currentYear){
            this.yearsArr = [];
            if (currentYear!==null){
                for (let i=(currentYear-1); i<=(currentYear+1); i++){
                    this.yearsArr.push(i);
                }
            }
        },
        // ----Извлекается справочник программ----//
        async loadProgram() {   // ---Извлекается справочник Программ---//
            let response = [];
            const result = [];
            this.programArr = [];
            this.dictSgpParentDocVal = '';
            const params = this.currentYear;
            if (params !== null && params !== '') {
                try {
                    const region = this.padLeadingZeros(this.region, 9);
                    response = await fetch('/api/forecast/year-programs?year=' + params + '&codeKato=' + region);
                    response = await response.json();
                    // console.log('response: ' + JSON.stringify(response));
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки Программ', error.toString());
                }
                if (response.length > 0) {
                    result.push(this.setNameLangs(this.all, 'program'));
                    for (const el of response) {
                        result.push(this.setNameLangs(el, 'program'));
                    }
                }
                if (this.dictProgramVal !== null) {
                    this.dictProgramVal = this.setNameLangs(this.all, 'program');
                    this.dictProgramVal = this.setNameLangs(this.dictProgramVal, 'program');
                }
                this.programArr = result;
                this.getListOfInterconnection();
                await this.loadProgramGoals();
                await this.loadSgpDocuments();
                await this.loadAbp();
                await this.getListOfAdmLevel();

                this.dictAbpArr = await this.abpDict();
                this.dictBpArrList = await this.bpDict();
            }
        },
        // ----Отображение справочника "Целей" в зависимости от выбора программ----//
        async loadGoalsIndicators() {
            let response = [];
            const result = [];
            this.programGoalArr = [];   // ---массив для справочника Целей
            this.indicatorId = null;
            this.dictSgpParentDocVal = '';
            this.dictProgramGoalVal = null;
            if (this.dictProgramVal && this.dictProgramVal.id) {
                try {
                    response = await fetch('/api/forecast/program-indicators?id=' + this.dictProgramVal.id);
                    response = await response.json();
                } catch (error) {
                    this.makeToast('Information', 'справочник фильтра "Цели" - пуст', error.toString());
                }
                if (Object.keys(response).length !== 0) {
                    // console.log('response: ' + JSON.stringify(response));
                    const goalsIndicatorArr = response;  // ---Извлекается весь подфильтр справочника "Цели" в массив
                    if (goalsIndicatorArr.length > 0) {
                        result.push(this.setNameLangs(this.all, 'goal'));
                        for (const goals of goalsIndicatorArr) {
                            // console.log('goals: ' + JSON.stringify(goals));
                            result.push(this.setNameLangs(goals, 'goal'));   // ---Создается массив с элементом 'goal'
                        }
                        if (this.dictProgramGoalVal !== null && this.dictProgramGoalVal !== '') {    // ---должен отображаться выбранный элемент из спрвочника
                            this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'goal');
                        }
                        this.programGoalArr = result;   // --Присвоение глобальному массиву весь список после фильтра справочника "Программа"
                    } else { // ---Если запрос пуст в базе, то происходит очистка параметров
                        this.dictProgramGoalVal = null;
                    }
                }
                await this.loadSgpDocumentsById(); //------Вышестоящий документ----//
            } else if (this.dictProgramVal.id === '') {
                await this.loadProgramGoals();
                await this.loadSgpDocuments();
                const tempProgramGoalArr = this.programGoalArr;
                this.programGoalArr = tempProgramGoalArr;

            }
        },
        async loadSgpDocumentsById(){
            this.indicatorId = this.dictProgramGoalVal;
            this.dictSgpParentDocVal = '';
            const sgpId = this.dictProgramVal !== null && this.dictProgramVal!=='' ? this.dictProgramVal.id : '';
            const indicatorId = this.indicatorId !== null && this.indicatorId!=='' ? this.indicatorId.id : '';
            let tempProgramSgpDocArr = [];
            // console.log('sgpId: ' + sgpId + ' indicatorId: ' + indicatorId);
            const sgpList = [];
            if ((indicatorId!=='' && indicatorId!==null) && (sgpId!=='' && sgpId!==null)){
                // console.log('sgpId: ' + sgpId + ' indicatorId: ' + indicatorId);
                sgpList.push(this.setNameLangs(this.all, 'sgpParentDoc'));
                for (const sgp of this.programSgpDocFullArr){
                    if (sgp.id === sgpId && sgp.indicatorId === indicatorId){
                        // console.log('sgp: ' + JSON.stringify(sgp));
                        sgpList.push(this.setNameLangs(sgp, 'sgpParentDoc'));
                    }
                }
            } else if ((indicatorId==='' || indicatorId===null) && (sgpId!=='' && sgpId!==null)){
                // console.log('sgpId: ' + sgpId);
                sgpList.push(this.setNameLangs(this.all, 'sgpParentDoc'));
                for (const sgp of this.programSgpDocFullArr){
                    if (sgp.id === sgpId){
                        // console.log('sgp: ' + JSON.stringify(sgp));
                        sgpList.push(this.setNameLangs(sgp, 'sgpParentDoc'));
                    }
                }
            } else if ((indicatorId!=='' && indicatorId!==null) && (sgpId==='' || sgpId===null)){
                // console.log('indicatorId: ' + indicatorId);
                sgpList.push(this.setNameLangs(this.all, 'sgpParentDoc'));
                for (const sgp of this.programSgpDocFullArr){
                    if (sgp.indicatorId === indicatorId){
                        // console.log('sgp: ' + JSON.stringify(sgp));
                        sgpList.push(this.setNameLangs(sgp, 'sgpParentDoc'));
                    }
                }
            } else if (sgpId === '' || (sgpId === '' && indicatorId.id === '')) {
                await this.loadSgpDocuments();
                tempProgramSgpDocArr = this.programSgpDocArr;
            }
            // console.log('sgpList: ' + JSON.stringify(sgpList));
            if (sgpList.length > 0) {
                this.programSgpDocArr = sgpList;
            } else if (tempProgramSgpDocArr.length > 0) {
                this.programSgpDocArr = tempProgramSgpDocArr;
                // console.log('this.programSgpDocArr: ' + JSON.stringify(this.programSgpDocArr));
            } else {
                this.programSgpDocArr = [];
            }
        },
        // ----Извлекается справочник целевых индикаторов----//
        async loadProgramGoals() {
            // console.log('Checking');
            let response = [];
            const result = [];
            this.programGoalArr = [];
            try {
                response = await fetch('/api/dict/indicator');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Целевых индикаторов', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'target'));
                for (const el of response) {
                    result.push(this.setNameLangs(el, 'target'));
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictProgramGoalVal !== null) {
                this.dictProgramGoalVal = this.setNameLangs(this.all, 'target');
                this.dictProgramGoalVal = this.setNameLangs(this.dictProgramGoalVal, 'target');
            }
            this.programGoalArr = result;
        },
        //-----Извлекается справочник "Вышестоящий документ"------//
        async loadSgpDocuments(){
            let response = [];
            const result = [];
            this.programSgpDocArr = [];
            const curYear = this.currentYear;
            try {
                const region = this.padLeadingZeros(this.region, 9);
                response = await fetch('/api/forecast/sgp-parent-id?year=' + curYear + '&codeKato=' + region);
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки справочника "Вышестоящий документ"', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'sgpParentDoc'));
                for (const el of response) {
                    result.push(this.setNameLangs(el, 'sgpParentDoc'));
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictSgpParentDocVal !== null) {
                this.dictSgpParentDocVal = this.setNameLangs(this.all, 'sgpParentDoc');
                this.dictSgpParentDocVal = this.setNameLangs(this.dictSgpParentDocVal, 'sgpParentDoc');
            }
            this.programSgpDocArr = result;
            this.programSgpDocFullArr = result;
        },
        // ----Извлекается справочник АБП------//
        async loadAbp() {
            let response = [];
            const result = [];
            this.abpArr = [];
            this.storeAbpArr = [];
            try {
                response = await fetch('/api/forecast/dict-ebk-func-abp');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'abp'));
                // console.log('response: ' + JSON.stringify(response));
                for (const el of response) {
                    this.storeAbpArr.push(el);
                    result.push(this.setNameLangs(el, 'abp'));
                }
                // console.log('response: ' + JSON.stringify(result));
            }
            if (this.dictAbpVal !== null) {
                this.dictAbpVal = this.setNameLangs(this.all, 'abp');
                this.dictAbpVal = this.setNameLangs(this.dictAbpVal, 'abp');
            }
            this.abpArr = result;
        },
        // ----Извлекает Справочник Адм.уровень-----------//
        async getListOfAdmLevel() {
            let response = [];
            const result = [];
            this.admLevelArr = [];
            try {
                response = await fetch('/api/stat-dicts/dict_budget_level');
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Адм.уровень', error.toString());
            }
            if (response.length > 0) {
                result.push(this.setNameLangs(this.all, 'admlevel'));
                for (const el of response) {
                    result.push(this.setNameLangs(el, 'admlevel'));
                }
            }
            if (this.dictAdmLevelVal !== null) {
                this.dictAdmLevelVal = this.setNameLangs(this.all, 'admlevel');
                this.dictAdmLevelVal = this.setNameLangs(this.dictAdmLevelVal, 'admlevel');
            }
            this.admLevelArr = result;

            // ------------------------------------------------//
            // const connect = this.interconnection;
            // console.log('connect: ' + JSON.stringify(connect));
        },
        getListOfInterconnection() {
            this.connectArr = [];
            const connection = this.interconnection;
            // console.log('connection: ' + JSON.stringify(connection));
            if (connection.length > 0) {
                this.connectArr.push(this.setNameLangs(this.all, 'interconnection'));
                for (const con of connection) {
                    this.connectArr.push(this.setNameLangs(con, 'interconnection'));
                }
            }
            // console.log('interconnection: ' + JSON.stringify(this.connectArr));
            if (this.connectionVal !== null) {
                this.connectionVal = this.setNameLangs(this.connectionVal, 'interconnection');
            }
        },
        // ----Создание объектов на основе переданных данных из справочников----//
        setNameLangs(obj, codeName) {
            // console.log('i18nString: ' + this.i18nString);
            // console.log('obj: ' + JSON.stringify(obj) +' codeName: ' + codeName)
            // let txt = obj['name_' + this.$i18n.locale];
            let txt = '';
            if (codeName === 'abp' || codeName === 'prg') {
                txt = obj.nameRu;
            } else {
                txt = obj.name_ru;
            }
            // console.log('txt: ' + txt)
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
                // console.log('txt: ' + txt);
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            // console.log('el: ' + JSON.stringify(el));
            return el;
        },
        // ----Передача параметров из шапки с фильтрами----//
        chgParams() {   // передаются параметры фильтра на гриду
            this.headParams = { year: this.currentYear, program: this.dictProgramVal, sgpParentDoc: this.dictSgpParentDocVal, abp: this.dictAbpVal, target: this.dictProgramGoalVal, adm: this.dictAdmLevelVal, connection: this.connectionVal };
            const data = this.headParams;
            this.bar = 40;
            // console.log('data: ' + JSON.stringify(data));
            this.getFieldsOfTable(data);
            this.collapseAll = true;
        },
        collapseAllFunc() {
            // console.log('this.collapseAll: ' + this.collapseAll);
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapseAll;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapseAll;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrThird.length; i++) {
                this.collapseArrThird[i] = this.collapseAll;
            }
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },
        collapseItems(targetItemIndx, programId) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            const rowIndex = targetItemIndx;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // console.log('collapseArr: ' + JSON.stringify(this.collapseArr));
        },
        collapseItemsSec(targetItemIndx, directionId) {  // ---------Раскрытие третьего уровня при нажатии на стрелку вниз
            const rowIndex = targetItemIndx;
            this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            // console.log('collapseArrSec: ' + JSON.stringify(this.collapseArrSec));
        },
        collapseItemsThird(goalItemIndx, goalId) {  // ---------Раскрытие четвертого уровня при нажатии на стрелку вниз
            const rowIndex = goalItemIndx;
            this.collapseArrThird[rowIndex] = !this.collapseArrThird[rowIndex];
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
            // console.log('collapseArrThird: ' + JSON.stringify(this.collapseArrThird));
        },
        // ----Извлекаются данные из фильтра: Целевые индикаторы, Адм.уровень, Вышестоящий документ, взаимоувязка
        extractInternalQueryArr(arr, targets, admLevel, sgpParentDoc, connect) {
            const filteredArr = [];
            if (arr.length > 0) {
                // console.log('Entered')
                for (const init of arr) {
                    // console.log('init: ' + JSON.stringify(init));
                    //----Условия фильтра
                    //----Документы СГП = not null, Целевые индикаторы = not null, Вышестоящий документ = null, АБП = null, С взаимоувязкой = null
                    if ((targets !== '' && targets !== null) && (sgpParentDoc === undefined || sgpParentDoc === '' || sgpParentDoc === null) && (connect === '' || connect === null)) {
                        // console.log('connect === null');
                        if (init.indicator.id === targets) {
                            // console.log('First_targets_init: ' + JSON.stringify(init));
                            filteredArr.push(init);
                        }
                    //----Документы СГП = not null, Целевые индикаторы = not null, С взаимоувязкой = not null, Вышестоящий документ = null, АБП = null
                    } else if ((targets !== '' && targets !== null) && (connect !== '') && (sgpParentDoc === undefined || sgpParentDoc === '' || sgpParentDoc === null)) {
                        // console.log('sgpParentDoc == null'); console.log('Правильное условие!');
                            // console.log('init.dictEbkFuncDto: ' + JSON.stringify(init.abps.length));
                            if (init.indicator.id === targets && (connect !== '' && connect === 'notNull') && init.abps.length !== 0){
                                // console.log('notNull_Second_targets_init: ' + JSON.stringify(init));
                                filteredArr.push(init);
                            } else if (init.indicator.id === targets && (connect !== '' && connect === 'null') && init.abps.length === 0) {
                                // console.log('null_Second_targets_init: ' + JSON.stringify(init));
                                filteredArr.push(init);
                            }
                    //----Документы СГП = not null, Целевые индикаторы = not null, С взаимоувязкой = not null, Вышестоящий документ =  not null, АБП = null
                    } else if ((targets !== '' && targets !== null) && (connect !== '') && (sgpParentDoc !== undefined && sgpParentDoc !== '' && sgpParentDoc !== null)) {
                        // console.log('sgpParentDoc.dictEbkFuncDto: ' + JSON.stringify(init.abps.length));
                        if (init.indicator.id === targets && sgpParentDoc === init.sgpParentDoc.id && (connect !== '' && connect === 'notNull') && init.abps.length !== 0){
                            // console.log('notNull_Second_sgpParentDoc: ' + JSON.stringify(init));
                            filteredArr.push(init);
                        } else if (init.indicator.id === targets && sgpParentDoc === init.sgpParentDoc.id && (connect !== '' && connect === 'null') && init.abps.length === 0) {
                            // console.log('null_Second_sgpParentDoc: ' + JSON.stringify(init));
                            filteredArr.push(init);
                        }
                    //----Документы СГП = (not null || null), Целевые индикаторы = not null, С взаимоувязкой = null, Вышестоящий документ =  not null, АБП = null
                    } else if ((targets !== '' && targets !== null) && (connect === '') && (sgpParentDoc !== undefined && sgpParentDoc !== '' && sgpParentDoc !== null)) {
                        // console.log('connect === null остальное не null');
                        if ((init.sgpParentDoc && sgpParentDoc === init.sgpParentDoc.id) && (init.indicator && init.indicator.id === targets)){
                            filteredArr.push(init);
                        }
                    //----Вышестоящий документ =  not null, Документы СГП = null, Целевые индикаторы = null, С взаимоувязкой = null, АБП = null
                    } else if ((sgpParentDoc !== undefined && sgpParentDoc !== '' && sgpParentDoc !== null) && (connect === '') && (targets === '' || targets === null || targets.length === 0)) {
                        // console.log('target === null, connect === null');
                        if (init.sgpParentDoc && sgpParentDoc === init.sgpParentDoc.id){
                            filteredArr.push(init);
                        }
                    //----Вышестоящий документ =  not null, С взаимоувязкой = not null, Документы СГП = null, Целевые индикаторы = null, АБП = null
                    } else if ((sgpParentDoc !== undefined && sgpParentDoc !== '' && sgpParentDoc !== null) && (connect !== '') && (targets === '' || targets === null || targets.length === 0)) {
                        // console.log('target === null');
                        // console.log('sgpParentDoc: ' + sgpParentDoc + ' connect: ' + connect + ' targets: ' + targets);
                        if ((init.sgpParentDoc && sgpParentDoc === init.sgpParentDoc.id) && connect === 'notNull' && init.abps.length !== 0){
                            // console.log('notNull_Second_sgpParentDoc: ' + JSON.stringify(init));
                            filteredArr.push(init);
                        } else if (init.sgpParentDoc && sgpParentDoc === init.sgpParentDoc.id && connect === 'null' && init.abps.length === 0) {
                            // console.log('null_Second_sgpParentDoc: ' + JSON.stringify(init));
                            filteredArr.push(init);
                        }
                    }
                }
                // console.log('filteredArr: ' + JSON.stringify(filteredArr));
            }
            return filteredArr;
        },
        // -----Извлекается выбранный год из фильтра----//
        sendCurrentYear() {
            const curYear = this.currentYear;
            // console.log('curYear: ' + curYear);
            return curYear;
        },
        // -----Извлекается первичный массив со всеми данными согласно фильтра----//
        async getFieldsOfTable(header) {
            const result = [];
            let response = [];
            const listOfPrograms = [];
            if (header !== '') {

                // ----Извлекаются параметры фильтра для построения гриды----//
                const region = this.padLeadingZeros(this.region, 9);
                const years = header.year !== null ? parseInt(header.year) : '';
                const programs = header.program !== null ? header.program.id : '';
                const sgpParentDoc = header.sgpParentDoc && true && header.sgpParentDoc.sgpParentDocId ? header.sgpParentDoc.sgpParentDocId : '';
                const abps = header.abp !== null ? header.abp.id : '';
                const targets = header.target && header.target.id !== null && header.target.id !== '' ? header.target.id : '';
                const admLevel = header.adm && header.adm.id !== null && header.adm.id !== '' ? header.adm.id : '';
                const connect = header.connection && header.connection.id !== null && header.connection.id !== '' ? header.connection.value : '';
                // console.log('year: ' + years + ' program: ' + programs + ' abp: ' + abps + ' targets: ' + targets + ' admLevel: ' + admLevel + ' connect: ' + connect);
                // --------Извлекаются все данные из json согласно параметрам-----//
                this.serviceParams.years = years;
                this.serviceParams.programs = programs;
                this.serviceParams.sgpParentDoc = sgpParentDoc;
                this.serviceParams.abps = abps;
                this.serviceParams.targets = targets;
                this.serviceParams.connect = connect;
                this.serviceParams.region = region;
                try {
                    response = await fetch('/api/stat-dicts/all_sgp_target_indicators?year=' + years + '&program=' + programs + '&abp=' + abps + '&connect=' + connect + '&codeKato=' + region);
                    response = await response.json();
                    if (response.length > 0) {
                        // console.log('response: ' + JSON.stringify(response));
                        for (const el of response) {
                            if (el.years !== null && el.program !== null && el.direction !== null && el.goals !== null && el.indicator !== null) {
                                const programI = {
                                    'id': el.program.id,
                                    'start_date': new Date(+el.program.startDate).getFullYear(),
                                    'end_date': new Date(+el.program.endDate).getFullYear()
                                }
                                listOfPrograms.push(programI);
                                result.push(el);
                            }
                        }
                    }
                } catch (error) {
                    response = [];
                    this.makeToast('danger', 'Ошибка загрузки таблицы ЦИ', error.toString());
                }

                //-----------Сформировывается массив по "годам" для столбцов в разделе "План"-----------------------------//
                this.listOfPlanYears = [];
                const programsArr = this.getOnlyProgramsWithDates(listOfPrograms);
                // console.log('programsArr: ' + JSON.stringify(programsArr));
                this.listOfPlanYears = getStartAndEndDateData(programsArr);
                // console.log('this.listOfPlanYears: ' + JSON.stringify(this.listOfPlanYears));

                const minYear = Math.min(...this.listOfPlanYears);
                const maxYear = Math.max(...this.listOfPlanYears);
                // console.log('minYear: ' + minYear + ' maxYear: ' + maxYear);
                this.getPlanYears(minYear, maxYear); //---Изменяются названия столбцов Плана с годами

                if (targets !== '' || (sgpParentDoc !== '' && sgpParentDoc!== undefined)) {
                    const enternalArr = this.extractInternalQueryArr(result, targets, admLevel, sgpParentDoc, connect);
                        this.objectPreparation(enternalArr);
                        // console.log('enternalArr: ' + JSON.stringify(enternalArr));
                } else {
                    this.objectPreparation(result);
                    // console.log('extractInternalQueryArr Не отработал! ' + JSON.stringify(result));
                }

                function getStartAndEndDateData(programsArr){
                    const yearsOfPlan = [];
                    const listOfStartDate = [];
                    const listOfEndDate = [];

                    // console.log('programsArr: ' + JSON.stringify(programsArr));
                    if (programsArr.length > 0) {
                        for (const programs of programsArr){
                            listOfEndDate.push(programs.end_date);
                        }
                        //------------Определяю StartDate и EndDate таблицы DictProgram
                        const currentYear = new Date().getFullYear();
                        const endDate = Math.max(...listOfEndDate); //---Извлекается максимальный EndDate из таблицы DictProgram согласно фильтра
                        const valForMany = parseInt(4);
                        const valForOne = parseInt(5);

                        // console.log('endDate: ' + endDate + ' currentYear: ' + currentYear + ' programsArrSize: ' + programsArr.length);
                        if (programsArr.length > parseInt(1)){
                        /*
                            Если на форме несколько программ:
                            1) Отобрать максимальный год среди документов (программ) по полю enddate
                            2) Если (enddate-4)<=текущего календарного года, то в шапке по плановым годам первый год брать как enddate-4, потом enddate-3, enddate-2, enddate-1, enddate
                            3) Если (enddate-4)>текущего календарного года, то в шапке первый год брать как текущий год, потом текущий год+1, текущий год+2, текущий год+3, текущий год+4
                        */
                            if ((parseInt(endDate)-parseInt(valForMany)) <= parseInt(currentYear)){
                                for (let i=parseInt(endDate)-parseInt(valForMany); i<=parseInt(endDate); i++){
                                    yearsOfPlan.push(i);
                                }
                            } else {
                                for (let i=parseInt(currentYear); i<(parseInt(currentYear)+parseInt(valForMany)); i++){
                                    yearsOfPlan.push(i);
                                }
                            }
                        } else {
                        /*
                            Если на форме только одна программа:
                            1) Если срок реализации (enddate - startdate) < 5, то в шапке первый год брать как startdate, потом startdate+1, startdate+2, startdate+3, startdate+4
                            2) Если срок реализации (enddate - startdate) >= 5 и (enddate-4)<=текущего календарного года, то в шапке по плановым годам первый год брать как enddate-4, потом enddate-3, enddate-2, enddate-1, enddate
                            3) Если срок реализации (enddate - startdate) >= 5 и (enddate-4)>текущего календарного года, то в шапке первый год брать как текущий год, потом текущий год+1, текущий год+2, текущий год+3, текущий год+4
                        */
                            const startDate = programsArr[0].start_date
                            // console.log('startDate: ' + parseInt(startDate) + ' endDate: ' + parseInt(endDate));
                            if ((parseInt(endDate) - parseInt(startDate)) < parseInt(valForOne)){
                                for (let i = parseInt(startDate); i <= parseInt(startDate) + parseInt(valForOne-1); i++) {
                                    yearsOfPlan.push(i);
                                }
                            } else if (((parseInt(endDate) - parseInt(startDate)) >= parseInt(valForOne)) && (parseInt(endDate) - parseInt(valForMany)) <= currentYear){
                                for (let i = parseInt(endDate)-parseInt(valForMany); i <= parseInt(endDate); i++) {
                                    yearsOfPlan.push(i);
                                }
                            } else if (((parseInt(endDate) - parseInt(startDate)) >= parseInt(valForOne) && (parseInt(endDate) - parseInt(valForMany)) > currentYear)){
                                for (let i = parseInt(currentYear); i <= parseInt(currentYear) + parseInt(valForMany); i++) {
                                    yearsOfPlan.push(i);
                                }
                            }
                        }
                    }
                    return yearsOfPlan;
                }
            }
            this.bar = 100;
        },
        // ----------Создается основной массив с фильтрацией (которая ранее обращалась в БД) для грид таблицы-------//
        objectPreparation(arr) {
            this.forecastFormArr = [];
            this.mainGridObjectArr = [];
            if (arr.length > 0) {
                for (const el of arr) {
                    const elem = this.getForecastElementsByParams(el); // ----Извлекаются объекты строк массива для гриды
                    this.forecastFormArr.push(elem);
                    // ----
                    this.mainGridObjectArr.push(el);// Массив с объектами для извлечения массивов с объектами из "Program" и "Goals"
                }
            }
            this.preparationForGrid();// ---Создаются массивы с объектами без дубликатов из "Program" и "Goals"
        },
        generateYearsForGrid(elements, chosenYear){
          const years = {
            yearFactOne: '',
            yearFactTwo: '',
            yearPlanOne: '',
            yearPlanTwo: '',
            yearPlanThree: '',
            yearPlanFour: '',
            yearPlanFive: ''
          }
          const minYear = Math.min(...this.listOfPlanYears);
          const maxYear = Math.max(...this.listOfPlanYears);
          const yearTwo = parseInt(minYear + 1) < parseInt(maxYear) ? parseInt(minYear + 1) : maxYear;
          const yearThree = parseInt(minYear + 2) < parseInt(maxYear) ? parseInt(minYear + 2) : maxYear;
          const yearFour = parseInt(minYear + 3) < parseInt(maxYear) ? parseInt(minYear + 3) : maxYear;
          let yearFive = parseInt(minYear + 4) < parseInt(maxYear) ? parseInt(minYear + 4) : maxYear;
          if (parseInt(yearFive) == parseInt(yearFour)){
            yearFive = parseInt(yearFive + 1);
          }
          // ---------Условия фильтра по годам-------//
          for (const year of elements.years) {
            if ((chosenYear - 1) == year.year) {
              years.yearFactTwo = year.fact;
            }
            if ((chosenYear - 2) == year.year) {
              years.yearFactOne = year.fact;
            }

            if (minYear == year.year) {
              years.yearPlanOne = year.plan;
            }
            if ((yearTwo) == year.year) {
              years.yearPlanTwo = year.plan;
            }
            if ((yearThree) == year.year) {
              years.yearPlanThree = year.plan;
            }
            if ((yearFour) == year.year) {
                // console.log('yearFour: ' + yearFour + ' year.plan: ' + year.plan);
              years.yearPlanFour = year.plan;
            }
            if ((yearFive) == year.year) {
                // console.log('yearFive: ' + yearFive + ' year.plan: ' + year.plan);
              years.yearPlanFive = year.plan;
            }
          }
          return years;
        },
        // ------Построение объектов строк гриды----//
        getForecastElementsByParams(elements) {
          const chosenYear = this.sendCurrentYear();  // ----Год выбранный из фильтра---//
          if (chosenYear == null) return {};
          const ulArr = [];
          const bpUlArr = [];
          let yearColumns = {};
          const listAbpBpOnly = [];
          const abpAndBp = [];
          const abpAndBpOnly = [];
          if (elements !== null && elements !== '') {
            if (elements.years.length > 0) {
              yearColumns = this.generateYearsForGrid(elements, chosenYear);
              if (elements.abps && elements.abps.length > 0) {
                for (const ab of elements.abps) {
                  const tempArr = {};
                  const bpArr = {};
                  const abpBpArr = {};
                  bpArr.id = ab.id;
                  bpArr.item = ab.prg + ' - ' + ab.dictEbkFuncNameRu;
                  abpBpArr.id = ab.id;
                  abpBpArr.abp = ab.abp;
                  abpBpArr.bp = ab.prg;
                  abpBpArr.gr = ab.gr;
                  abpBpArr.pgr = ab.pgr;
                  abpBpArr.ppr = ab.ppr;
                  abpBpArr.uniqId = ab.uniqId;
                  listAbpBpOnly.push(abpBpArr);
                  bpUlArr.push(bpArr);
                  abpAndBp.push(ab.abp + '.' + this.padLeadingZerosInFront(ab.prg, 3));
                  const abpAndBpObj = {
                    abp: ab.abp,
                    bp: ab.prg
                  };
                  abpAndBpOnly.push(abpAndBpObj);
                  for (const elAbp of this.storeAbpArr) {
                    if (ab.abp === elAbp.abp) {
                      tempArr.id = elAbp.abp;
                      tempArr.item = elAbp.abp + ' - ' + elAbp.nameRu;
                      ulArr.push(tempArr);
                    }
                  }
                }
              }
            }
          }
          return this.generateGridObject(elements, yearColumns, ulArr, bpUlArr, abpAndBpOnly, abpAndBp, listAbpBpOnly);
        },
        generateGridObject(elements, yearColumns, ulArr, bpUlArr, abpAndBpOnly, abpAndBp, listAbpBpOnly) {
          let countABP = 0;
          let countBP = 0;
          const noDuplicates = this.getOnlyObjects(ulArr);
          for (const abpEl of noDuplicates) {
            countABP += 1;
          }
          const noBpDuplicates = this.getOnlyObjects(bpUlArr);
          for (const bpEl of noBpDuplicates) {
            countBP += 1;
          }
          return {
            id: elements.id !== '' && elements.id !== null ? elements.id : '',
            programId: elements.program !== '' && elements.program !== null ? elements.program.id : '',
            sgp: elements.program !== '' && elements.program !== null ? elements.program.name_ru : '',
            directionId: elements.direction !== '' && elements.direction !== null ? elements.direction.id : '',
            goals: elements.goals !== '' && elements.goals !== null ? elements.goals.name_ru : '',
            targetIndicators: elements.indicator !== '' && elements.indicator !== null ? elements.indicator.name_ru : '',
            macroindicator: elements.macroindicator !== '' && elements.macroindicator !== null && elements.macroindicator == true ? 'M' : '',
            sgpParentDoc: elements.sgpParentDoc !== '' && elements.sgpParentDoc !== null ? elements.sgpParentDoc.name_ru : '',
            abp_and_bp: abpAndBpOnly,
            abp_bp: abpAndBp,
            abpList: noDuplicates,
            bpList: noBpDuplicates,
            listAbpBpOnly: listAbpBpOnly,
            countABP: countABP,
            countBP: countBP,
            unitFromStat: elements.statUnit != null && elements.statUnit != '' ? (elements.statUnit.nationalSymbol!= null && elements.statUnit.nationalSymbol != '' ? elements.statUnit.nationalSymbol : elements.statUnit.name_ru) : '',
            yearOne: yearColumns.yearFactOne,
            yearTwo: yearColumns.yearFactTwo,
            yearThree: yearColumns.yearPlanOne,
            yearFour: yearColumns.yearPlanTwo,
            yearFive: yearColumns.yearPlanThree,
            yearSix: yearColumns.yearPlanFour,
            yearSeven: yearColumns.yearPlanFive,
            goalsId: elements.goals != null ? elements.goals.id : '',
            levels: 3
          };
        },
        // -----Дробление массива по объектам для извлечения группированных объектов из справочника "Program" и "Goals"
        preparationForGrid() {
            const programListArr = [];
            const directionArr = [];
            const goalListArr = [];
            this.collapseArr = [];
            this.collapseArrSec = [];
            this.collapseArrThird = [];
            this.collapseArrFourth = [];
            // -------------------Подсчёт итоговых показателей--------------------------//
            let sumSGP = 0;
            let sumDirection = 0;
            let sumGoals = 0;
            let sumGoalIndicators = 0;
            let sumMacroindicators = 0;
            let sumParentSGP = 0;
            let sumABP = 0;
            let sumBP = 0;
            let sumAbpBp = 0;
            const withConnection = 0;
            const withoutConnection = 0;
            // ----Извлекаются объекты без дубликатов по Программам
            // console.log('this.mainGridObjectArr: ' + JSON.stringify(this.mainGridObjectArr));
            for (const mainEl of this.mainGridObjectArr) {
                if (mainEl.goals !== null) {
                    mainEl.program.isGoal = true;
                } else {
                    mainEl.program.isGoal = false;
                }
                if (mainEl.indicator !== null) {
                    mainEl.program.isIndicator = true;
                } else {
                    mainEl.program.isIndicator = false;
                }
                programListArr.push(mainEl.program);
            }
            this.programObjArr = this.getOnlyObjects(programListArr); // ----Извлечение массива с объектами "Программ" без дубликатов
            if (this.programObjArr.length > 0) {
                for (const programs of this.programObjArr) {
                    this.collapseArr.push(true);
                    sumSGP += 1;
                }
            }
            // console.log('ProgramIsFirstLevel: ' + JSON.stringify(this.programObjArr));
            // ----Извлекаются объекты по "Направлениям"
            for (const directionEl of this.mainGridObjectArr) {
                if (directionEl.direction !== null) {
                    directionEl.direction.programId = directionEl.program.id;
                    directionArr.push(directionEl.direction);
                    // this.collapseArrSec.push(true);
                }
            }
            // ----Извлекаются объекты по Целям
            for (const goalEl of this.mainGridObjectArr) {
                if (goalEl.goals !== null) {
                    goalEl.goals['programId'] = goalEl.program.id;
                    goalEl.goals.directionId = goalEl.direction.id;
                    // console.log('goalEl.goals: ' + JSON.stringify(goalEl.goals));
                    goalListArr.push(goalEl.goals);
                }
            }
            this.directArr = this.getOnlyDirectionObjects(directionArr); // ----Извлечение массива с объектами "Направление" без дубликатов
            const directionSumArr = [];
            if (this.directArr.length > 0) {
                for (const direct of this.directArr) {
                    this.collapseArrSec.push(true);
                    directionSumArr.push(direct);
                    // sumDirection += 1;
                }
            }
            this.goalObjArr = this.getOnlyGoalObjects(goalListArr); // ----Извлечение массива с объектами "Целей" без дубликатов
            const goalArr = [];
            if (this.goalObjArr.length > 0) {
                for (const goal of this.goalObjArr) {
                    this.collapseArrThird.push(true);
                    // sumGoals += 1;
                    goalArr.push(goal);
                }
            }

            const sumAbpArr = [];
            const sumBpArr = [];
            const sumGoalIndicatorsArr = [];
            const sumParentSGPArr = [];
            if (this.forecastFormArr.length > 0) {   // -----Вкладывается в каждый последний элемент статус true (это значит что последние выпадающий лист закрыт для каждого уровня)
                for (const lastLevel of this.forecastFormArr) {
                    this.collapseArrFourth.push(true);

                    if (lastLevel.targetIndicators!=null){
                        // sumGoalIndicators += 1;
                        if (lastLevel.targetIndicators && lastLevel.sumGoalIndicatorsArr!==''){
                            sumGoalIndicatorsArr.push(lastLevel.targetIndicators);
                        }
                        if (lastLevel.macroindicator!==null && lastLevel.macroindicator==='M'){
                            sumMacroindicators += 1;
                        }
                        if (lastLevel.sgpParentDoc!==null && lastLevel.sgpParentDoc!==''){
                            sumParentSGPArr.push(lastLevel.sgpParentDoc);
                            // sumParentSGP +=1;
                            // console.log('lastLevel.sgpParentDoc: ' + JSON.stringify(lastLevel.sgpParentDoc) + ' sumParentSGP: ' + sumParentSGP);
                        }
                        if (lastLevel.abpList!==null){
                            for (const abp of lastLevel.abpList){
                                sumAbpArr.push(abp);
                            }
                        }
                        if (lastLevel.bpList!=null){
                            for (const bp of lastLevel.bpList){
                                sumBpArr.push(bp);
                            }
                        }
                    }
                }
            }
            const dirSumArr = this.getOnlyObjects(directionSumArr);
            const goalSumArr = this.getOnlyObjects(goalArr);
            const parentDoctArr = this.getOnlyItemsOfObjects(sumParentSGPArr);
            const sumGoalIndicator = this.getOnlyItemsOfObjects(sumGoalIndicatorsArr);
            const abpSumArr = this.getOnlyObjects(sumAbpArr);
            const bpSumArr = this.getOnlyObjects(sumBpArr);
            // console.log('abpSumArr: ' + JSON.stringify(abpSumArr));
            // console.log('bpSumArr: ' + JSON.stringify(bpSumArr));
            // console.log('sumParentSGPArr: ' + JSON.stringify(sumParentSGPArr));
            if (dirSumArr.length > 0) {
                for (const dir of dirSumArr){
                    sumDirection += 1;
                }
            }
            if (goalArr.length > 0){
                for (const goal of goalSumArr){
                    sumGoals += 1;
                }
            }
            if (sumGoalIndicator.length > 0){
                for (const goals of sumGoalIndicator){
                    sumGoalIndicators += 1;
                }
            }
            if (parentDoctArr.length > 0){
                for (const parentDoc of parentDoctArr){
                    sumParentSGP +=1;
                }
            }
            if (abpSumArr.length>0){
                for (const abps of abpSumArr){
                    sumABP +=1;
                }
            }
            if (bpSumArr.length>0){
                for (const bps of bpSumArr){
                    sumBP +=1;
                    sumAbpBp +=1;
                }
            }
            this.totalCounter(sumSGP, sumDirection, sumGoals, sumGoalIndicators, sumMacroindicators, sumParentSGP, sumAbpBp, sumABP, sumBP, withConnection, withoutConnection);
            let abpObj = {};
            if (this.abpArr.length > 0){
                for (const abp of this.abpArr){
                    if (this.serviceParams.abps === abp.abp){
                        abpObj = Object.assign(abpObj, abp);
                    }
                }
            }
            this.$emit('listSgpDocuments', this.mainGridObjectArr,  this.headParams, this.serviceParams, abpObj);
        },
        // ------Счётчик итоговых показателей----------//
        totalCounter(sumSGP, sumDirection, sumGoals, sumGoalIndicators, sumMacroindicators, sumParentSGP, sumAbpBp, sumABP, sumBP, withConnection, withoutConnection) {
            // console.log('withConnection: ' + withConnection);
            // console.log('withoutConnection: ' + withoutConnection);

            this.totalSGP = sumSGP;
            this.totalDirection = sumDirection;
            this.totalGoals = sumGoals;
            this.totalGoalIndicators = sumGoalIndicators;
            this.totalMacroindicators = sumMacroindicators;
            this.totalParentSGP = sumParentSGP;
            this.totalAbpBp = sumAbpBp;
            this.totalABP = sumABP;
            this.totalBP = sumBP;
            // ----------------------------------========-----------------------------------//
        },
        // -----Функция для очистки массива Направление от дубликатов
        getOnlyDirectionObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id && item.programId === current.programId);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива Цели от дубликатов
        getOnlyGoalObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id && item.programId === current.programId && item.directionId === current.directionId);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyProgramsWithDates(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id && item.start_date === current.start_date && item.end_date === current.end_date);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyItemsOfObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item === current);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // ------Метод для отображения сообщения с ошибками----//
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                variant: variant,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
};
</script>

<style>
.is-hidden {
    display: none !important;
}
.listAbpBp {
    width: 100px;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listAbpBp li{
    margin-bottom: 15px;
}
.cellAlign{
    text-align: center;
}
</style>