<template>
    <div class="inner-container budget-form">
        <div class="section-title budget">
            <i class="icon icon-calculator-coins"></i
            ><span>Бюджетное планирование</span>
        </div>
        <div class="section-subtitle">
            <i class="icon icon-grid"></i>Формирование бюджета
        </div>
        <b-tabs v-model="tabIndex" v-if="listReg" @input="loadTab">
            <b-tab :lazy="!tabs.includes(tabIndex)"
                    :title="getBipTitle('grade')"
                    v-if="[1, 2, 3].includes(gradeLevel)"
                    @click="tabRef = 'bipgrade'">
                <template v-show="tabRef === 'bipgrade' && location !== null">
                    <bip-grade ref="bipgrade"
                               :location="location"
                               :levels="levels"
                               :reg-levels="regLevels"
                               :region-budget-list="listReg"
                               :region-list="regionList"
                               :version-list="listVariants"
                               :detail-fields="detailFields"
                               :data-type-list="dataTypeList"
                               :direction-list="directionList"
                               :object-list="objectList"
                               :status-list="statusList"
                               :gp-list="gpList"
                               :exp-types="expTypes"
                               :bip-cif-list="bipCifList"
                               :criteria-values="criteriaValues"
                               :bip-link-criterias="bipLinkCriterias"
                               :bip-link-types="bipLinkTypes"
                               :dict-unit="dictUnit"
                               @submitReport="submitPriority">
                    </bip-grade>
                </template>
            </b-tab>
            <b-tab :lazy="!tabs.includes(tabIndex)"
                    :title="getBipTitle('uebp')"
                    v-if="[1, 2, 3].includes(uebpLevel)"
                    @click="tabRef = 'bipuebp'">
                <template v-show="tabRef === 'bipuebp' && location !== null">
                    <bip-uebp ref="bipuebp"
                              :location="location"
                              :levels="levels"
                              :reg-levels="regLevels"
                              :region-budget-list="listReg"
                              :region-list="regionList"
                              :version-list="listVariants"
                              :detail-fields="detailFields"
                              :data-type-list="dataTypeList"
                              :direction-list="directionList"
                              :object-list="objectList"
                              :status-list="statusList"
                              :gp-list="gpList"
                              :exp-types="expTypes"
                              :bip-cif-list="bipCifList"
                              :criteria-values="criteriaValues"
                              :bip-link-criterias="bipLinkCriterias"
                              :bip-link-types="bipLinkTypes"
                              :dict-unit="dictUnit"
                              @chgData="chgData"
                              @submitReport="submitReport"
                              @submitReport68="submitReport68">
                    </bip-uebp>
                </template>
            </b-tab>
            <b-tab v-if="[1, 2, 3].includes(cdfLevel)"
                   :lazy="!tabs.includes(tabIndex)"
                   :title="getCdfTitle('title')"
                   @click="tabRef = 'costdataform'">
                <template v-if="tabs.includes(tabIndex)">
                    <cost-data-form ref="costdataform"
                                    :user-level="cdfLevel"
                                    :location="location"
                                    :region-budget-list="listReg"
                                    :versionList="listVariants"
                                    :data-type-list="dataTypeList"
                                    :user-gu-list="userGuList"
                                    @curFilter="curFilter">
                    </cost-data-form>
                </template>
            </b-tab>
            <b-tab :lazy="!tabs.includes(tabIndex)" title="Доходы">
                <template v-if="tabs.includes(tabIndex)">
                    <income-form
                        ref="incomeform"
                        :year="parseInt(year)"
                        :obl="location.obl"
                        :reg="location.region"
                        :list-reg="listReg"
                        :list-variants-inc="listVariantsInc"
                        :access-level="incLevel"
                    ></income-form>
                </template>
            </b-tab>
            <b-tab v-if="false"
                   :lazy="!tabs.includes(tabIndex)"
                   title="Версия бюджета">
                <budget-variants></budget-variants>
            </b-tab>
            <b-tab
                lazy
                title="Заключение по БП"
                >
                <router-view
                    ref="bpChildView"
                    @setDownloadDisabled="setDownloadDisabled"
                    @setBpInEditMode="setBpInEditMode"
                ></router-view>
            </b-tab>
        </b-tabs>
        <div class="actions-tab in-tab">
            <b-dropdown v-if="[0, 1, 2, 4].includes(tabIndex) && !bpInEditMode"
                        variant="link"
                        right
                        toggle-class="text-decoration-none"
                        no-caret
                        :disabled="tabIndex === 4 && isDownloadDisabled">
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <b-spinner v-if="isDownloadDisabled"
                                           variant="success"
                                           small
                                           label="Small Spinner">
                                </b-spinner>
                                <i v-if="tabIndex === 4 && !isDownloadDisabled" class="icon icon-download"></i>
                                {{ getBipTitle('btn.download') }}
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item v-if="tabIndex === 0" @click="reportPP">
                    {{ getBipTitle('report.prj_priority') }}
                </b-dropdown-item>
                <template v-if="tabRef === 'bipuebp'">
                    <b-dropdown-item @click="downloadZProtocol">
                        {{ getBipTitle('report.protocol_dis') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="reportDB">{{ getBipTitle('projects') }}</b-dropdown-item>
                    <b-dropdown-item @click="report68">{{ getBipTitle('app') + ' 68' }}
                    </b-dropdown-item>
                </template>
                <template v-if="tabRef === 'costdataform'">
                    <b-dropdown-item @click="downloadCurExpenses">
                        {{ getCdfTitle('title') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="repLimits">
                        {{ getCdfTitle('report.cur_exp_limit') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="repBZAbp">
                        {{ getCdfTitle('report.cur_exp_bzabp') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="downloadStatuses">
                        {{ getCdfTitle('report.approval_statuses') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="downloadDProtocol">
                        {{ getBipTitle('report.protocol_dis') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="repPkgConclusion">
                        {{ getCdfTitle('report.batch_upload_conclusion') }}
                    </b-dropdown-item>
                </template>
                <template v-if="tabIndex === 4">
                    <b-dropdown-item
                        @click="onDownloadBudgetProgramReport(3)"
                        >Заключение по АБП
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="onDownloadBudgetProgramReport(4)"
                        >Заключение по АБП без РБ
                    </b-dropdown-item>
                </template>
            </b-dropdown>
            <b-dropdown v-if="[0, 1, 2, 3].includes(tabIndex)"
                        variant="link"
                        class="info"
                        right
                        toggle-class="text-decoration-none"
                        no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left"><i class="icon icon-info"></i></span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-button">
                        <span v-if="tabIndex === 0" @click="infoCostForm(20)">
                            {{ getBipTitle('instruction') }}
                            <i class="icon icon-folder"></i></span>
                        <span v-if="tabIndex === 0" @click="infoCostForm(21)">
                            {{ getBipTitle('grade_method') }}
                            <i class="icon icon-folder"></i></span>
                        <span v-if="tabIndex === 1" @click="infoCostForm(24)">
                            {{ getBipTitle('instruction') }}
                            <i class="icon icon-folder"></i></span>
                        <span v-if="tabIndex === 2" @click="infoCostForm(1)">
                            {{ getBipTitle('instruction') }}
                            <i class="icon icon-folder"></i></span>
                        <span v-if="tabIndex === 3" @click="infoCostForm(2)">
                            Режим «Формирование бюджета»
                            <i class="icon icon-folder"></i></span>
                    </div>
                    <!-- <div class="info-button">
                        <span>Видеоинструкция <i class="icon icon-video"></i></span>
                    </div> -->
                    <div class="info-text" v-if="tabIndex === 0">
                        <p><span class="red bold">{{ getBipTitle('report.grade') }}</span></p>
                        <p>{{ getBipTitle('report.grade_1') }}</p>
                        <p>{{ getBipTitle('report.grade_2') }}</p>
                    </div>
                    <div class="info-text" v-if="tabIndex === 1">
                        <p><span class="red bold">{{ getBipTitle('report.uebp') }}</span></p>
                        <p>{{ getBipTitle('report.uebp_1') }}</p>
                    </div>
                    <div class="info-text" v-if="tabIndex === 2">
                        <p><span class="red bold">{{ getCdfTitle('sector') }}</span></p>
                        <p>{{ getCdfTitle('report.cdf_1') }}</p>
                    </div>
                    <div class="info-text" v-if="tabIndex === 3">
                        <p>
                            Данный режим предназначен для ввода, корректировки и
                            просмотра данных по бюджету на очередной плановый
                            период.
                        </p>
                        <p>
                            Режим содержит данные до уровня бюджетных
                            программ/подпрограмм по расходной части и специфик
                            по поступлениям.
                        </p>
                        <p>Режим состоит из следующих разделов:</p>
                        <p>
                            <span class="red bold">Расход</span><br />
                            – формирование расходной части бюджета
                        </p>
                        <p>
                            <span class="red bold">Доход</span><br />
                            – формирование доходной части бюджета
                        </p>
                        <p>
                            <span class="red bold">Баланс</span><br />
                            – отображение данных по структуре бюджета
                        </p>
                    </div>
                </b-dropdown-text>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "../../services/store";
import { Ax } from "@/utils";
import i18nService from "@/services/i18n";
import IncomeForm from "@/modules/budget/IncomeForm";
import CostDataForm from "@/modules/budget/CostDataForm";
import BipGrade from "@/modules/budget/bip/bip-grade.vue";
import BipUebp from "@/modules/budget/bip/bip-uebp.vue";
import BudgetVariants from "@/modules/budget/BudgetVariants";
import { CUR_YEAR } from "../budget-request/components/js/budgetCurYear";
import { loadVariants } from "@/modules/budget/budgetVariantService";
import {
    getRowKey,
    makeToast,
    sortByField,
    findItem,
    nameLocale,
    getBipTitle,
    itemNameLocale,
    getTFieldTitle, getSmsTitle, getFilterTitle, getCommonTitle
} from "./bip/bip-types";

export default {
    name: "BudgetForm",
    components: {
        BipGrade,
        BipUebp,
        IncomeForm,
        CostDataForm,
        BudgetVariants,
    },
    $refs: {
        bipgrade: HTMLFormElement,
        bipuebp: HTMLFormElement
    },
    data() {
        return {
            location: null,
            year: null,
            variant: "f1340fa7-e90d-42a2-92ae-a6055dc1d278",
            listReg: [],
            dataType: 1,
            selectedABP: { id: 0, child: [] },
            selectedPRG: { id: 0, child: [] },
            selectedPPR: { id: 0 },
            tabIndex: 0,
            tabs: [],
            tabRef: "",
            repParams: null,
            repPriority: null,
            repParams68: null,
            listVariants: [],
            listVariantsInc: [],
            isDownloadDisabled: false,
            bpInEditMode: false,

            curParams: {},
            headers: {},

            gradeLevel: 0,
            uebpLevel: 0,
            cdfLevel: 0,
            incLevel: 0,

            expTypes: [
                { value: 0, name_ru: "Переходящие проекты", name_kk: "Ауыспалы жобалар", name_en: "Transition projects" },
                { value: 1, name_ru: "Новые инициативы", name_kk: "Жаңа бастамалар", name_en: "New initiatives" }
            ],
            levels: [],
            regLevels: [],
            regionList: [],
            dataTypeList: [],
            directionList: [],
            objectList: [],
            statusList: [],
            gpList: [],

            bipCifList: [],
            criteriaValues: new Map(),
            bipLinkCriterias: [],
            bipLinkTypes: [],
            dictUnit: [],
            userGuList: []
        };
    },

    created() {
        this.getLocation();
        this.checkUrl();
    },

    mounted() {
        this.year = CUR_YEAR;

        loadVariants().then((data) => {
            this.listVariantsInc = data;
            this.listVariants = data.map((v) => {
                itemNameLocale(v);
                const str = v.status
                    ? getFilterTitle(this, 'apprvd') + (v.attribute ? ', ' + getFilterTitle(this, 'act') : '')
                    : v.attribute ? getFilterTitle(this, 'act') : '';
                Object.defineProperty(v, 'text', {
                    get: function () {
                        return v['name'] + (str.length > 0 ? " (" + str + ")" : "");
                    }
                });
                return v;
            });
        });
        this.loadUserLevels();
        this.loadBudgetLevel();
        this.loadBipPackageDicts();
        this.loadUserGus();
        nameLocale(this.expTypes);
    },

    computed: {
        userUiid() {
            return store.state.user.sub;
         },

        detailFields() {
            return [
                {
                    key: "nomer",
                    label: "№"
                },
                {
                    key: "name",
                    label: getTFieldTitle(this, 'project')
                }
            ]
        }
    },

    methods: {
        async getLocation() {
            try {
                const response = await fetch("/api-py/get-budget-obl/" + store.state._instanceCode)
                    .then((response) => response.json());
                this.location = response;
            } catch (error) {
                makeToast(this, "danger",
                    getBipTitle(this, 'sms.error_request') + ' /get-budget-obl', error.toString());
            } finally {
                console.log(new Date().toISOString(), "bf location", this.location, i18nService.locale);

                if (this.location && this.userUiid) {
                    await this.loadRegionList();
                    try {
                        const response = await fetch("/api-py/get-user-regions-by-obl/"
                            + this.location.obl + "/" + this.userUiid)
                            .then((response) => response.json());
                        this.listReg = await response;
                        nameLocale(this.listReg);
                    } catch (error) {
                        makeToast(this, "danger",
                            getBipTitle(this, 'sms.error_request') + ' /get-user-regions-by-obl', error.toString());
                    }
                }
            }
        }, // запрос локации по глобальному коду области

        async chgData(data) {
            try {
                this.headers = {
                    lang: `${i18nService.locale}`
                };
                this.headers = Object.assign(this.headers, data)
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'warning'),
                    "Ошибка обновления параметров " + error.toString());
            }
        }, // обновление параметров

        async curFilter(data) {
            try {
                this.curParams = data;
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'warning'),
                    "Ошибка обновления параметров " + error.toString());
            }
        }, // обновление параметров с раздела Текущих расходов

        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: "/api-py/get-info/" + info_id, //  Тело файла
                    method: "POST",
                    data: null,
                    responseType: "blob",
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    let file = "";
                    if (info_id == 1) {
                        file = "РП Формирование расходной части";
                    }
                    if (info_id == 2) {
                        file = "РП Формирование доходной части";
                    }
                    if (info_id == 20) {
                        file = getBipTitle(this, 'report.instr_grade');
                    }
                    if (info_id == 21) {
                        file = getBipTitle(this, 'grade_method');
                    }
                    if (info_id == 24) {
                        file = getBipTitle(this, 'report.instr_uebp');
                    }
                    link.setAttribute("download", file + ".pdf"); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                },
            );
        },
        // формирование отчета
        downloadAgrExcel() {
            const that = this;
            Ax(
                {
                    url:
                        "/api-py/agr-form-download/" +
                        that.location.region +
                        "/" +
                        that.variant +
                        "/" +
                        that.year +
                        "/" +
                        that.selectedABP.id +
                        "/" +
                        that.selectedPRG.id, //  Тело файла
                    method: "POST",
                    data: that.ajxParams,
                    responseType: "blob",
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Бюджет в агрегированной форме.xls",
                    ); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                },
            );
        },
        // формирование отчета
        downloadExcel() {
            const that = this;
            Ax(
                {
                    url:
                        "/api-py/summary-form-download/" +
                        that.location.region +
                        "/" +
                        that.variant +
                        "/" +
                        that.year +
                        "/" +
                        that.selectedABP.id +
                        "/" +
                        that.selectedPRG.id, //  Тело файла
                    method: "POST",
                    data: that.ajxParams,
                    responseType: "blob",
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Бюджет на предстоящий период.xls",
                    ); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                },
            );
        },
        // формирование отчета
        downloadVedmExcel() {
            const that = this;
            Ax(
                {
                    url:
                        "/api-py/ved-form-download/" +
                        that.location.region +
                        "/" +
                        that.variant +
                        "/" +
                        that.year +
                        "/" +
                        that.selectedABP.id +
                        "/" +
                        that.selectedPRG.id, //  Тело файла
                    method: "POST",
                    data: that.ajxParams,
                    responseType: "blob",
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Бюджет по ведомственной классиф.xls",
                    ); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                },
            );
        },

        // формирование протокола расхождения (текущие расходы)
        async downloadDProtocol() {
            const params = {
                lang: `${i18nService.locale}`,
                year: this.curParams.version.year,
                dataType: this.curParams.version.data_type,
                region: this.curParams.version.region_code,
                variant: this.curParams.version.variant_uuid
            };
            Ax(
                {
                    url: "/api-py/dp_count/" + JSON.stringify(params),
                    method: "GET"
                },
                (data) => {
                    if (data > 0) {
                        Ax(
                            {
                                url: "/api-py/dprotocol-download/" + JSON.stringify(params), //  Тело файла
                                method: "POST",
                                responseType: "blob"
                            },
                            (data) => {
                                const url = window.URL.createObjectURL(new Blob([data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download",
                                    this.getCdfTitle('report.protocol_dis') + ' (' + this.getCdfTitle('title') + ').xls'); // or any other extension
                                document.body.appendChild(link);
                                link.click();
                            },
                            (error) => {
                                makeToast(this, 'danger', getSmsTitle(this, 'attention'), error.toString());
                            }
                        );
                    } else {
                        makeToast(this, "warning", getSmsTitle(this, 'attention'),
                            this.getCdfTitle('no_dis_between_ABP_UEBP'));
                    }
                },
                (error) => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'), error.toString());
                }
            );
        },

        // формирование протокола расхождения (расходы развития)
        async downloadZProtocol() {
            const that = this;
            Ax(
                {
                    url: "/api-py/zp_count/" + JSON.stringify(that.headers),
                    method: "GET"
                },
                (data) => {
                    if (data > 0) {
                        this.$refs.bipuebp.pBar = 10;
                        Ax(
                            {
                                url: "/api-py/zprotocol-download/" + JSON.stringify(that.headers), //  Тело файла
                                method: "POST",
                                responseType: "blob"
                            },
                            (data) => {
                                this.$refs.bipuebp.pBar = 100;
                                const url = window.URL.createObjectURL(new Blob([data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download",
                                    getBipTitle(this, 'report.protocol_dis')
                                    + '(' + getBipTitle(this, 'uebp') + ')' + '.xls'); // or any other extension
                                document.body.appendChild(link);
                                link.click();
                            },
                            (error) => {
                                makeToast(this, "danger", getSmsTitle(this, 'attention'),
                                    error.toString());
                            }
                        );
                    } else {
                        makeToast(this, "warning", getSmsTitle(this, 'attention'),
                            this.getCdfTitle('no_dis_between_ABP_UEBP'));
                    }
                }, (error) => {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        error.toString());
                },
            );
        },

        // формирование отчета (Текущие расходы)
        async downloadCurExpenses() {
            const params = {
                lang: `${i18nService.locale}`,
                year: this.curParams.version.year,
                dataType: this.curParams.version.data_type,
                region: this.curParams.version.region_code,
                variant: this.curParams.version.variant_uuid,
                abp: (this.curParams.abp ? this.curParams.abp : 0)
            };

            this.$refs.costdataform.pBar = 10;
            Ax(
                {
                    url: "/api-py/rep-currexp/" + JSON.stringify(params), //  Тело файла
                    method: "POST",
                    responseType: "blob"
                }, (data) => {
                    this.$refs.costdataform.pBar = 100;
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",
                        this.getCdfTitle('title') + '_' + params.year + '-' + (params.year + 2) + '.xls'); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                }, (error) => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'), error.toString());
                }
            );
        },

        // формирование отчета (Текущие расходы с учетом Лимита)
        async repLimits() {
            const params = {
                lang: `${i18nService.locale}`,
                year: this.curParams.version.year,
                data_type: this.curParams.version.data_type,
                region: this.curParams.version.region_code,
                variant: this.curParams.version.variant_uuid,
                prev_variant: this.curParams.version.prev_variant,
                abp: (this.curParams.abp ? this.curParams.abp : 0)
            };

            this.$refs.costdataform.pBar = 10;
            Ax(
                {
                    url: "/api-py/cur-exp-limit/" + JSON.stringify(params), //  Тело файла
                    method: "POST",
                    responseType: "blob"
                }, (data) => {
                    this.$refs.costdataform.pBar = 100;
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",
                        this.getCdfTitle('report.cur_exp_limit') + '_' + params.year + '-' + (params.year + 2) + '.xls'); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                }, (error) => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'), error.toString());
                }
            );
        },

        // формирование отчета (Текущие расходы с учетом БЗ АБП)
        async repBZAbp() {
            const params = {
                lang: `${i18nService.locale}`,
                year: this.curParams.version.year,
                data_type: this.curParams.version.data_type,
                region: this.curParams.version.region_code,
                variant: this.curParams.version.variant_uuid,
                prev_variant: this.curParams.version.prev_variant,
                abp: (this.curParams.abp ? this.curParams.abp : 0)
            };

            this.$refs.costdataform.pBar = 10;
            Ax(
                {
                    url: "/api-py/cur-exp-bzabp/" + JSON.stringify(params), //  Тело файла
                    method: "POST",
                    responseType: "blob"
                }, (data) => {
                    this.$refs.costdataform.pBar = 100;
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",
                        this.getCdfTitle('report.cur_exp_bzabp') + '_' + params.year + '-' + (params.year + 2) + '.xls'); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                }, (error) => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'), error.toString());
                }
            );
        },

        // формирование отчета (статусы согласования)
        async downloadStatuses() {
            const params = {
                lang: `${i18nService.locale}`,
                year: this.curParams.version.year,
                data_type: this.curParams.version.data_type,
                region: this.curParams.version.region_code,
                variant: this.curParams.version.variant_uuid,
                abp: (this.curParams.abp ? this.curParams.abp : 0)
            };

            Ax(
                {
                    url: "/api-py/report-status/" + JSON.stringify(params), //  Тело файла
                    method: "POST",
                    responseType: "blob"
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",
                        this.getCdfTitle('report.approval_statuses') + '_' + params.year + "-" + (params.year + 2) + '.xls');
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'), error.toString());
                }
            );
        },

        loadTab() {
            if (this.tabs.length > 0) {
                this.setUrl(this.tabIndex);
            }
            if (!this.tabs.includes(this.tabIndex)) {
                this.tabs.push(this.tabIndex);
            }

        }, // загрузка при открытии вкладки

        async loadUserLevels() {
            if (this.userUiid) {
                try {
                    const response = await fetch(
                        "/api-py/user-modules/" + this.userUiid,
                    ).then((response) => response.json());
                    for (const row of response) {
                        switch (row.modules) {
                            case "004.003.001":
                                this.gradeLevel = row.access_level;
                                break;
                            case "004.003.002":
                                this.uebpLevel = row.access_level;
                                break;
                            case "004.003.003":
                                this.cdfLevel = row.access_level;
                                break;
                            case "004.003.004":
                                this.incLevel = row.access_level;
                                break;
                            default:
                                break;
                        }
                    }
                } catch (error) {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_request') + ' /api-py/user-modules/');
                } finally {
                    console.log("budget gradeLevel = ", this.gradeLevel);
                    console.log("budget uebpLevel = ", this.uebpLevel);
                    console.log("budget cdfLevel = ", this.cdfLevel);
                }
            }
        },

        // Пакетная выгрузка (Заключение)
        repPkgConclusion() {
            const params = {
                lg: `${i18nService.locale}`,
                year: this.curParams.version.year,
                dataType: this.curParams.version.data_type,
                region: this.curParams.version.region_code,
                variant: this.curParams.version.variant_uuid,
                abp: (this.curParams.abp ? this.curParams.abp : 0),
                datas: this.curParams.datas,
                pprMap: this.curParams.pprMap
            };

            if (!params.abp || params.abp === 0) {
                makeToast(this, 'warning', getSmsTitle(this, 'attention'), this.getCdfTitle('sel_ABP'));
            }
            const urls_list = []

            if (params.datas) {
                for (const prg of params.datas.child) {

                    if (prg.value['pprChild']) {
                        for (const ppr of prg.value.child) {

                            if (ppr.value.child.length > 0) {
                                this.newUrl(params, ppr.value.abp, ppr.value.prg, ppr.value.ppr,
                                    prg.value['abp'] + '.' + prg.value['prg'] + ' ' + prg.value['name_' + params.lg],
                                    ppr.value['ppr'] + ' ' + ppr.value['name_' + params.lg],
                                    ppr.value.child, urls_list);

                                // if (urls_list[urls_list.length-1]) {
                                //     const body = urls_list[urls_list.length-1].body;
                                //
                                //     if ((body.prg ===  prg.value['abp'] + '.' + prg.value['prg'] + ' ' + prg.value['name_' + params.lg])
                                //         && (body.ppr === ppr.value['ppr'] + ' ' + ppr.value['name_' + params.lg])) {
                                //
                                //         Ax({
                                //                 url: "/api-py/pkg-conclusion/",
                                //                 method: "POST",
                                //                 data: body,
                                //                 responseType: "blob"
                                //             },
                                //             (data) => {
                                //                 if (data) {
                                //                     const url = window.URL.createObjectURL(new Blob([data]));
                                //                     const link = document.createElement("a");
                                //                     link.href = url;
                                //                     link.setAttribute("download",
                                //                         getBipTitle(this, 'report.conclusion.title') + '_'
                                //                         + params.abp + '_' + ppr.value['prg'] + '_' + ppr.value['ppr'] + '.xls');
                                //                     document.body.appendChild(link);
                                //                     link.click();
                                //                 }
                                //             },
                                //             (error) => {
                                //                 makeToast(this, "danger", getSmsTitle(this, 'attention'),
                                //                     getSmsTitle(this, 'error_request') + ' /pkg-conclusion ' + error.toString());
                                //             }
                                //         );
                                //     }
                                // }
                            }
                        }
                    } else {
                        if (prg.value.child.length > 0) {
                            this.newUrl(params, prg.value.abp, prg.value.prg, null,
                                prg.value['abp'] + '.' + prg.value['prg'] + ' ' + prg.value['name_' + params.lg],
                                null, prg.value.child, urls_list);

                            // if (urls_list[urls_list.length-1]) {
                            //
                            //     const body = urls_list[urls_list.length-1].body;
                            //
                            //     if (body.prg ===  prg.value['abp'] + '.' + prg.value['prg'] + ' ' + prg.value['name_' + params.lg]) {
                            //
                            //         Ax({
                            //                 url: "/api-py/pkg-conclusion/",
                            //                 method: "POST",
                            //                 data: body,
                            //                 responseType: "blob"
                            //             },
                            //             (data) => {
                            //                 if (data) {
                            //
                            //                     const url = window.URL.createObjectURL(new Blob([data]));
                            //                     const link = document.createElement("a");
                            //                     link.href = url;
                            //                     link.setAttribute("download",
                            //                         getBipTitle(this, 'report.conclusion.title') + '_'
                            //                         + params.abp + '_' + prg.value['prg'] + '.xls');
                            //                     document.body.appendChild(link);
                            //                     link.click();
                            //                 }
                            //             },
                            //             (error) => {
                            //                 makeToast(this, "danger", getSmsTitle(this, 'attention'),
                            //                     getSmsTitle(this, 'error_request') + ' /pkg-conclusion ' + error.toString());
                            //             }
                            //         );
                            //     }
                            // }
                        }
                    }
                }

                if (urls_list.length > 0) {
                    console.log('urls_list', urls_list)
                    Ax({
                            url: "/api-py/download-post-archive/",
                            method: "POST",
                            data: {
                                archive_name: "archive.zip",
                                urls_list: urls_list
                            },
                            responseType: "blob"
                        },
                        (data) => {
                            if (data) {
                                const url = window.URL.createObjectURL(new Blob([data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download',
                                    'БЗ_Прогноз_' + params.abp + '_' + params.year + '-' + (params.year+2) + '.zip');// or any other extension
                                document.body.appendChild(link);
                                link.click();
                            }
                        },
                        (error) => {
                            makeToast(this, "danger", getSmsTitle(this, 'attention'),
                                getSmsTitle(this, 'error_request') + ' /download-post-archive ' + error.toString());
                        }
                    );
                } else {
                    makeToast(this, 'warning', getSmsTitle(this, 'attention'), getSmsTitle(this, 'ABP_datas_absence'))
                }
            }
        },

        newUrl(params, abp, prg, ppr, prg_name, ppr_name, list, urls_list) {
            const report = {
                lg: params.lg,
                year: params.year,
                region: params.region,
                variant: params.variant,
                abp: params.abp
            }

            const datas = []
            for (const row of list) {
                const ppr = params.pprMap.get(getRowKey(row.value, ["abp", "prg", "ppr", "spf"]))

                if (ppr && ppr.request1 + ppr.request2 + ppr.request3
                    + ppr.plan1 + ppr.plan2 + ppr.plan3 > 0
                    && ppr.forms !== null) {
                    datas.push(ppr);
                }
            }

            if (datas.length > 0) {
                this.$set(report, 'prg', prg_name);
                this.$set(report, 'ppr', ppr_name);
                this.$set(report, 'datas', datas);

                const item = {
                    filename: getBipTitle(this, 'report.conclusion.title') + '_'
                        + abp + '_' + prg + (ppr === null ? '' : '_' + ppr) + '.xls',
                    url: window.location.origin  + '/api-py/pkg-conclusion/',
                    body: report
                }
                console.log(report)
                urls_list.push(item);
            }
        },

        report68() {
            this.$refs.bipuebp.submitReport68();
            if (
                this.repParams68 === null ||
                this.repParams68.abp === null ||
                this.repParams68.prg === null
            ) {
                this.$bvModal
                    .msgBoxOk(getSmsTitle(this, 'sel_ABP_BP'),
                        {
                            title: getSmsTitle(this, 'confirmation'),
                            size: "md",
                            buttonSize: "sm",
                            okVariant: "primary",
                            okTitle: getBipTitle(this, 'btn.ok'),
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true
                    }).then((value) => {
                        return;
                    }).catch((error) => {
                        makeToast(this, "danger", "Ошибка проверки фильтра", error.toString());
                    });
            } else {
                if (this.repParams68.sign_code.length === 1 &&
                    this.repParams68.sign_code[0].id_user.length == 0) {
                    makeToast(this, "warning", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'absent_signatories'));
                }

                const { sign_code, ...params } = this.repParams68;
                Ax(
                    {
                        url: "/api-py/bip-application-68",
                        method: "GET",
                        params,
                        responseType: "blob"
                    },
                    (data) => {
                        if (data) {
                            const url = window.URL.createObjectURL(
                                new Blob([data]),
                            );
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute(
                                "download",
                                getBipTitle(this, 'app')+ '_68_'
                                + params.abp + "_" + params?.prg
                                + "_" + params.data_type
                                + "_" + params.year + ".xls");
                            document.body.appendChild(link);
                            link.click();
                        }
                    },
                    (error) => {
                        makeToast(this, "danger", getSmsTitle(this, 'attention'),
                            getSmsTitle(this, 'error_request') + ' /api-py/bip-application-68');
                    }
                );
            }
        },

        reportDB() {
            this.$refs.bipuebp.submitReport();
            Ax(
                {
                    url: "/api-py/bip-application-db/",
                    method: "POST",
                    data: this.repParams,
                    responseType: "blob"
                },
                (data) => {
                    if (data) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download",
                            getBipTitle(this, 'uebp') + '_' + this.repParams.planPeriod.name + ".xls");
                        document.body.appendChild(link);
                        link.click();
                    }
                },
                (error) => {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_request') + ' /bip-application-db');
                }
            );
        },

        reportPP() {
            this.$refs.bipgrade.submitReport();
            Ax(
                {
                    url: "/api-py/bip-application-pp/",
                    method: "POST",
                    data: this.repPriority,
                    responseType: "blob"
                },
                (data) => {
                    if (data) {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download",
                            getBipTitle(this, 'report.prj_priority') + '_' + this.repPriority.year + ".xls");
                        document.body.appendChild(link);
                        link.click();
                    }
                },
                (error) => {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_request') + ' /api-py/bip-application-pp/');
                }
            );
        },

        submitReport(data) {
            this.repParams = data;
        },

        submitReport68(data) {
            this.repParams68 = data;
        },

        submitPriority(data) {
            this.repPriority = data;
        },

        setDownloadDisabled(val) {
            this.isDownloadDisabled = val;
        },

        setBpInEditMode(val) {
            this.bpInEditMode = val;
        },

        async loadBipPackageDicts() {
            let bipCriteriaValues = [];
            let response;

            const params = [
                "budget_data_types",
                "bip_project_type_list",
                "bip_project_object_list",
                "agreement_status",
                "program",
                "bip_cif_list",
                "unit",
                "bip_link_types",
                "bip_link_criterias",
                "bip_criteria_values"
            ];
            try {
               response = await fetch(encodeURI("/api-py/bip-package-dicts/" + JSON.stringify(params)))
                    .then((response) => response.json());

                this.dataTypeList = response["budget_data_types"];
                this.directionList = response["bip_project_type_list"];
                this.objectList = response["bip_project_object_list"];
                this.statusList = response["agreement_status"].sort(sortByField("name_ru"));
                this.gpList = response["program"];

                this.dictUnit = response["unit"].sort(sortByField("code"));
                this.bipCifList = response["bip_cif_list"].sort(sortByField("code"));
                this.bipLinkTypes = response["bip_link_types"].sort(sortByField("id"));
                this.bipLinkCriterias = response["bip_link_criterias"];
                bipCriteriaValues = response["bip_criteria_values"];
            } catch (error) {
                makeToast(this, "danger", getBipTitle(this, 'sms.error_load') + ' /bip-package-dicts', error.toString());
            } finally {
                console.log(new Date().toISOString(), "budget BipPackageDicts", response);

                nameLocale(this.dataTypeList);
                nameLocale(this.directionList);
                for (const item of this.directionList) {
                    nameLocale(item.object_types);
                }
                nameLocale(this.objectList);
                nameLocale(this.statusList);
                nameLocale(this.gpList);
                nameLocale(this.dictUnit);
                nameLocale(this.bipCifList);
                nameLocale(this.bipLinkTypes);
                nameLocale(this.bipLinkCriterias);
                nameLocale(bipCriteriaValues);

                await this.addDetailFields(this.bipCifList);

                for (const row of bipCriteriaValues) {
                    this.criteriaValues.set(getRowKey(row, ["criteria", "link", "year"]), row);
                }

                for (const blc of this.bipLinkCriterias) {
                    try {
                        const cif = this.bipCifList.filter(
                            (row) => row.code === blc.criteria,
                        );
                        if (cif.length > 0) {
                            for (const [key, value] of Object.entries(cif[0])) {
                                if (!["id", "begin_date", "end_date"].includes(key)) {
                                    this.$set(blc, key, value);
                                }
                            }
                            this.$set(blc, "unit_item", findItem(blc.unit, "national_symbol", this.dictUnit)
                            );
                        }

                        const blt = this.bipLinkTypes.filter((row) => row.id === blc.link);
                        if (blt.length > 0) {
                            for (const [key, value] of Object.entries(blt[0])) {
                                if (key !== "id") {
                                    this.$set(blc, key, value);
                                }
                            }

                            this.$set(blc, "begin_year", new Date(blt[0].begin_date).getFullYear());
                            if (blt[0].end_date !== null) {
                                this.$set(blc, "end_year", new Date(blt[0].end_date).getFullYear());
                            } else {
                                this.$set(blc, "end_year", null);
                            }
                        }
                    } catch (error) {
                        console.log("err(criteria) =>", blc.id, error.toString());
                    }
                }
            }
        },

        async loadBudgetLevel() {
            const budgetLevel = [];
            try {
                const result = await fetch(
                    encodeURI("/api-py/get-user-budget-level/" + this.userUiid),
                ).then((response) => response.json());
                for (const el of result) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    const indx = el["budget_level"].lastIndexOf("_");
                    if (indx >= 0) {
                        budgetLevel.push(
                            parseInt(el["budget_level"].substr(indx + 1)),
                        );
                    }
                }
                this.levels = [];
                this.regLevels = [];
                if (budgetLevel.includes(2)) {
                    this.levels.push(2);
                    this.regLevels.push(3);
                }
                if (budgetLevel.includes(3)) {
                    this.levels.push(2);
                    this.levels.push(3);
                    this.regLevels.push(3);
                    this.regLevels.push(4);
                }
                if (budgetLevel.includes(4)) {
                    this.levels.push(4);
                    this.levels.push(3);
                    this.regLevels.push(4);
                }
            } catch (error) {
                makeToast(this, "danger", getBipTitle(this, 'sms.error_load') + ' /get-user-budget-level', error.toString());
                return;
            } finally {
                console.log(new Date().toISOString(), "budget budgetLevel = ", budgetLevel.length);
            }
            if (budgetLevel.length === 0) {
                makeToast(this, "warning", getSmsTitle(this, 'attention'),
                    getBipTitle(this, 'sms.no_available_user_budget_levels'));
            }
        },

        async loadRegionList() {
            try {
                const response = await fetch("/api-py/get-kato-regions/" + this.location.code)
                    .then((response) => response.json());

                for (const region of response) {
                    if (region["code"].substring(2, 3) !== "0") {
                        try {
                            const locals = await fetch("/api-py/get-kato-localities/" + region["code"].substring(0, 4))
                                .then((response) => response.json());
                            nameLocale(locals);
                            locals.sort(sortByField("name"));
                            this.$set(region, "localityList", locals);
                            this.regionList.push(region);
                        } catch (error) {
                            makeToast(this, "danger", getBipTitle(this, 'sms.error_load') + ' /get-kato-localities/'
                                + region["code"].substring(0, 4), error.toString());
                        }
                    }
                }
            } catch (error) {
                makeToast(this, "danger", getBipTitle(this, 'sms.error_load') + ' /get-kato-regions', error.toString());
            } finally {
                console.log(new Date().toISOString(), "budget regionList = ", this.regionList.length);
                nameLocale(this.regionList);

                // setTimeout(() => {
                //     if (this.location && this.regionList.length === 0) {
                //         this.loadRegionList();
                //     }
                // }, 2000);
            }
        }, // загрузка районов по области

        async loadUserGus() {
            if (this.userUiid) {
                try {
                    const response = await fetch('/api-py/user-gu/' + this.userUiid)
                        .then((response) => response.json());
                    for (const item of response) {
                        this.userGuList.push(item.gu);
                    }
                } catch (error) {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /user-gu',
                        error.toString());
                } finally {
                    console.log(new Date().toISOString(), 'budget userGuList = ', this.userGuList.length);
                }
            }
        },

        async addDetailFields(response) {
            try {
                for (const item of response) {
                    const el = {};
                    this.$set(el, "key", item["code"]);
                    this.$set(el, "label", item["name"]);
                    this.$set(el, "criteria_type", item["criteria_type"]);
                    this.detailFields.push(el);
                }
                this.detailFields.push({
                    key: "total",
                    label: getTFieldTitle(this, 'point')
                });
            } finally {
                console.log(new Date().toISOString(), "budget detailFields = ", this.detailFields.length);
            }
        },

        getCdfTitle(field) {
            return getCommonTitle(this, 'modules.costdataform.' + field)
        },

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        checkUrl() {
            if (this.$route.name === 'budget-form-concl-bp') {
                this.tabIndex = 4;
            }
        },

        setUrl(tabIndex) {
            switch (tabIndex) {
                case 4:
                    if (this.$route.name === 'budget-form-concl-bp') break;
                    this.$router.push({
                        name: 'budget-form-concl-bp',
                    });
                    break;
                default:
                    if (this.$route.name === 'budget-form') break;
                    this.$router.push({
                        name: 'budget-form',
                    });
            }
        },

        onDownloadBudgetProgramReport(repId) {
            this.$refs.bpChildView.bpReportGeneration(repId, true);
        }
    }
};
</script>

<style>
.is-hidden {
    display: none !important;
}
</style>
