<template>
    <div class="inner-container">
      <loading
          :active="loading"
          is-full-screen
          spinner="bar-fade-scale"
          color="#6495ED"
      />
        <div class="section-title program"><i class="icon icon-binocular-graphic"></i><span>Стратегическое планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>Целевые индикаторы</span></div>
        <div v-show="!edit">
            <!----------------Место для шапки с фильтром--------------->
            <div class="filter-container">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>

                        <!---------------Фильтр по году-------------------->
                        <div class="filter-block">
                            <b-form-group :label="yearItem" class="small">
                                <multiselect
                                    v-model="currentYear"
                                    :options="yearsArr"
                                    placeholder="Год"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadProgram"
                                />
                            </b-form-group>
                        </div>

                        <!---------------Документы СГП--------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[0].name_ru" class="small">
                                <multiselect
                                    v-model="dictProgramVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Документы СГП"
                                    :options="programArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadGoals"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>

                        <!---------------Цели--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[1].name_ru">
                                <multiselect
                                    v-model="dictProgramGoalVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="Цели"
                                    :options="programGoalArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadAbp"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!---------------АБП--------------------------->
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[2].name_ru">
                                <multiselect
                                    v-model="dictAbpVal"
                                    track-by="name"
                                    label="name"
                                    placeholder="АБП"
                                    :options="abpArr"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="loadGridAfterAbpSelection"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span v-if="option.name.length<16">{{ option.name }}</span>
                                            <span v-else :title="option.name">{{option.name.slice(0, 16) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
                <div class="filter-actions">
                    <b-button v-show="!edit" variant="primary" @click="addClk">
                        <i class="icon icon-plus-circle"></i>{{ addRecords.name_ru }}
                    </b-button>
                </div>
            </div>

        <!---------Начало грид таблицы-------------->
            <div class="table-container">
                <div class="b-table-sticky-header table-responsive-true">
                    <table class="table b-table table-bordered b-table-no-border-collapse">
                        <thead>
                        <tr class="text-center">
                            <th rowspan="2">
                                <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                                    <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                </button>
                            </th>
                            <th rowspan="2">СГП</th>
                            <th rowspan="2">Цели</th>
                            <th rowspan="2">Целевые индикаторы</th>
                            <th rowspan="2">АБП</th>
                            <th rowspan="2">БП</th>
                            <th rowspan="2">Ед. измерения</th>
                            <th colspan="2">Факт</th>
                            <th colspan="5">План</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th class="cellAlign">{{ this.currentYear-2 }}</th>
                            <th class="cellAlign">{{ this.currentYear-1 }}</th>
                            <th class="cellAlign">{{ this.currentYear }}</th>
                            <th class="cellAlign">{{ this.currentYear+1 }}</th>
                            <th class="cellAlign">{{ this.currentYear+2 }}</th>
                            <th class="cellAlign">{{ this.currentYear+3 }}</th>
                            <th class="cellAlign">{{ this.currentYear+4 }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(targetItem, targetItemIndx) of programObjArr">
                            <tr :key="'targetItemIndx'+targetItemIndx" class="table-info">
                                <td style="text-align: center">
                                    <button type="button" class="btn btn-secondary" @click="collapseItems(targetItemIndx, targetItem.id)">
                                        <i class="icon icon-chevron-circle" v-if="collapseArr[targetItemIndx]"></i>
                                        <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                    </button>
                                </td>
                                <td colspan="5">{{ targetItem.name_ru }}</td>
                                <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            </tr>
                            <template v-if="!collapseArr[targetItemIndx]">
                                <template v-for="(goalItem, goalItemIndex) of goalObjArr">
                                    <template v-if="goalItem!==null && goalItem.programId === targetItem.id">
                                        <tr :key="`targetItemIndx_${targetItemIndx}_${goalItemIndex}`" class="table-info">
                                            <td colspan="2" style="text-align: right">
                                                <button type="button" class="btn btn-secondary" @click="collapseItemsSec(goalItemIndex, targetItem.id)">
                                                    <i class="icon icon-chevron-circle" v-if="collapseArrSec[goalItemIndex]"></i>
                                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                                </button>
                                            </td>
                                            <td colspan="4"><template v-if="goalItem!==null">{{ goalItem.name_ru }}</template></td>
                                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                        </tr>
                                        <template v-if="!collapseArrSec[goalItemIndex]">
                                            <template v-for="(elementItem, elementItemIndex) of forecastFormArr">
                                                <template v-if="elementItem!==null && elementItem.goalsId===goalItem.id && elementItem.programId ===targetItem.id">
                                                    <tr :key="`targetItemIndx_${targetItemIndx}_${goalItemIndex}_${elementItemIndex}`">
                                                        <td colspan="3"></td>
                                                        <td>{{ elementItem.targetIndicators }}</td>
                                                        <td style="text-align: left">
                                                            <ul class="listABP">
                                                                <template v-for="(abps, abpIndex) of elementItem.abpList">
                                                                    <li :key="`abpIndex_${abpIndex}`">{{ abps.item }}</li>
                                                                </template>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: left">
                                                            <ul class="listBP">
                                                                <template v-for="(bps, bpIndex) of elementItem.bpList">
                                                                    <li :key="`bpIndex_${bpIndex}`">{{ bps.item }}</li>
                                                                </template>
                                                            </ul>
                                                        </td>
                                                        <td style="text-align: center">{{ elementItem.unitFromStat }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearOne && elementItem ? elementItem.yearOne.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearTwo && elementItem ? elementItem.yearTwo.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearThree && elementItem ? elementItem.yearThree.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearFour && elementItem ? elementItem.yearFour.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearFive && elementItem ? elementItem.yearFive.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearSix && elementItem ? elementItem.yearSix.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: right">{{ elementItem.yearSeven && elementItem ? elementItem.yearSeven.toLocaleString('ru-RU'): "" }}</td>
                                                        <td style="text-align: center">
                                                            <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                                                <template v-slot:button-content>
                                                                    <i class="icon icon-more"></i>
                                                                </template>
                                                                <template>
                                                                <b-dropdown-item @click="editClk(elementItem)">
                                                                    Редактировать
                                                                </b-dropdown-item>
                                                                <b-dropdown-item @click="addClk()">
                                                                    Добавить
                                                                </b-dropdown-item>
                                                                <b-dropdown-item @click="deleteClk(elementItem)">
                                                                    Удалить
                                                                </b-dropdown-item>
                                                                </template>
                                                            </b-dropdown>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        <tr class="row-fixed">
                            <td style="text-align: center;">&sum;</td>
                            <td style="text-align: center;">{{ totalSGP }}</td>
                            <td style="text-align: center;">{{ totalGoals }}</td>
                            <td style="text-align: center;">{{ totalGoalIndicators }}</td>
                            <td style="text-align: center;"> {{ totalABP }}</td>
                            <td style="text-align: center;">{{ totalBP }}</td>
                            <td style="text-align: center;" colspan="8"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!--------------------------Компонент Тимура - для добавления/редактирования------------------->
        <div v-show="edit">
            <c-forecast-edit :currentForecast="currentForecast" @closeEdit="edit=false" @saved="saved"/>
        </div>
        <!-------------------------------------------------------------->
    </div>
</template>

<script>
import CForecastEdit from '@/modules/forecast/ForecastEdit.vue';
import Multiselect from 'vue-multiselect'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueElementLoading from 'vue-element-loading';

export default {
    name: "ForecastFormNew",
    components: {
        'loading': VueElementLoading,
        'multiselect': Multiselect,
        'c-forecast-edit': CForecastEdit
    },
    created() {
        this.currentYear = new Date().getFullYear();
        this.$watch('collapseAll', this.collapseAllFunc);
    },
    mounted() {
        const currentYear = this.sendCurrentYear();
        this.listOfYears(currentYear);
        this.getFieldsOfTable(currentYear);
    },
    data() {
        return {
            all: {
                name_ru: 'Все',
                name_en: 'All',
                name_kk: 'Барлық',
                id: ''
            },
            yearItem: 'Год',
            currentYear: null,
            loading: false,
            collapseAll: true,
            collapseArr: [],
            collapseArrSec: [],
            collapseArrThird: [],
            yearsArr: [],
            programArr: [],
            abpArr: [],
            abpArrInit: [],
            dictProgramVal: null,
            dictProgramGoalVal: null,
            dictAbpVal: null,
            sgpDoc: [
                {
                    name_ru: 'Документы СГП',
                    name_kk: 'Документы СГП',
                    name_en: 'SGP Documents'
                },
                {
                    name_ru: 'Цели',
                    name_kk: 'Цели',
                    name_en: 'Goals'
                },
                {
                    name_ru: 'АБП',
                    name_kk: 'АБП',
                    name_en: 'ABP'
                },
            ],
            addRecords: {
                name_ru: 'Добавить',
                name_en: 'Add',
                name_kk: 'Добавить'
            },
            programGoalArr: [],
            programGoalArrSec: [],
            storeAbpArr: [],
            forecastFormArr: [],
            mainGridObjectArr: [],
            mainGridObjectArrInit: [],
            programObjArr: [],
            goalObjArr: [],
            storeForEditAndDelete: [],
            edit: false,
            currentForecast: null,
            openMore: false,
            totalSGP: 0,
            totalGoals: 0,
            totalGoalIndicators: 0,
            totalABP: 0,
            totalBP: 0
        }
    },
    methods: {
        collapseAllFunc() {
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapseAll;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapseAll;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },
        collapseItems(targetItemIndx, programId) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            const rowIndex = targetItemIndx;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // console.log('collapseArr: ' + JSON.stringify(this.collapseArr));
        },
        collapseItemsSec(targetItemIndx, directionId) {  // ---------Раскрытие третьего уровня при нажатии на стрелку вниз
              const rowIndex = targetItemIndx;
              this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
              this.collapseArrSec.push(false);
              this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
              // console.log('collapseArrSec: ' + JSON.stringify(this.collapseArrSec));
          },
        //-----Извлекается массив со всеми данными для грид таблицы----//
        async getFieldsOfTable(year) {
          this.loading = true;
          const result = [];
          this.forecastFormArr = [];
          this.mainGridObjectArr = [];
          this.mainGridObjectArrInit = [];
          this.storeForEditAndDelete = [];

          try {
            const response = await fetch(`/api/stat-dicts/sgp_ti?year=${year}`);
            const data = await response.json();

            if (data.length > 0) {
              data.forEach((el) => {
                if (el.program && el.goals && el.years && el.years.length > 0 && el.indicator) {
                  result.push(el);
                  this.storeForEditAndDelete.push(el);
                }
              });
            }
          } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки таблицы ЦИ', error.toString());
          }

          // Обновление состояния
          this.mainGridObjectArrInit = result;
          this.mainGridObjectArr = result;
          this.generateFilters(result);
          this.objectPreparation(result);
          this.loading = false;
        },
        generateFilters(arr) {
        this.dictProgramGoalVal = '';
        this.dictProgramVal = '';
        this.dictAbpVal = '';

        if (arr.length === 0) return;

        const programList = arr.map(el => ({ ...el.program, name: el.program.name_ru }));
        const goalList = arr.map(el => ({ ...el.goals, name: el.goals.name_ru }));
        const abpList = arr.flatMap(el => el.abp.map(abpItem => ({ ...abpItem, name: abpItem.nameRu })));

        this.generateFilterLists(programList, goalList, abpList);
      },
        // ----------Создается основной массив с фильтрацией (которая ранее обращалась в БД) для грид таблицы-------//
        objectPreparation(arr) {
          if (arr.length > 0) {
            this.forecastFormArr = arr.map(el => this.getForecastElementsByParams(el)); // Преобразование массива с помощью map
          } else {
            this.forecastFormArr = []; // Очистка массива, если входной пуст
          }
          this.preparationForGrid(arr); // ---Создаются массивы с объектами без дубликатов из "Program" и "Goals"
        },
        //----Генерирую списки для справочников фильтрации
        generateFilterLists(programList, goalList, abpList) {
          // Универсальная функция для обновления массивов
          const updateArray = (list, param, dictKey, arrKey) => {
            list = getUniqList(list, param);
            this[arrKey] = [this.setNameLangs(this.all, param), ...list];
            if (this[dictKey] !== null) {
              this[dictKey] = this[arrKey][0];
              this[dictKey] = this.setNameLangs(this[dictKey], param);
            }
          };

          // Обновление каждого массива через универсальную функцию
          updateArray(programList, 'program', 'dictProgramVal', 'programArr');
          updateArray(goalList, 'goal', 'dictProgramGoalVal', 'programGoalArr');
          updateArray(goalList, 'goal', 'dictProgramGoalVal', 'programGoalArrSec');
          updateArray(abpList, 'abp', 'dictAbpVal', 'abpArr');
          updateArray(abpList, 'abp', 'dictAbpVal', 'abpArrInit');

          // Универсальная функция для фильтрации уникальных элементов
          function getUniqList(list, param) {
            return list.reduce((enteredArr, current) => {
              let x;
              if (param === 'program') {
                x = enteredArr.find(item => item.id === current.id);
              } else if (param === 'goal') {
                x = enteredArr.find(item => item.id === current.id && item.programId === current.programId);
              } else if (param === 'abp') {
                x = enteredArr.find(item => item.abp === current.abp && item.programId === current.programId && item.goalId === current.goalId);
              }
              return x ? enteredArr : [...enteredArr, current];
            }, []);
          }
        },
        // ------Построение объектов строк гриды----//
        getForecastElementsByParams(elements) {
          let el = {};
          const chosenYear = this.sendCurrentYear();  // ----Год выбранный из фильтра---//
          if (!elements || !elements.years || elements.years.length === 0 || chosenYear === null) {
            return el;
          }
          const yearData = this.updateYearData(elements.years, chosenYear); // Объект для хранения данных по годам
          // Получение данных ABP и BP
          let abp = '';
          let bp = '';
          let noDuplicates = [];
          let noBpDuplicates = [];
          let countABP = 0;
          let countBP = 0;

          if (elements.abps && elements.abps.length > 0) {
            const ulArr = [];
            const bpUlArr = [];

            elements.abps.forEach(ab => {
              const bpArr = {
                id: ab.id,
                item: `${ab.prg} - ${ab.dictEbkFuncNameRu}`
              };
              bpUlArr.push(bpArr);

              this.abpArr.forEach(elAbp => {
                if (ab.abp === elAbp.abp) {
                  ulArr.push({id: elAbp.abp, item: `${elAbp.abp} - ${elAbp.nameRu}`});
                }
              });
            });

            // Удаление дубликатов
            noDuplicates = this.getOnlyObjects(ulArr);
            noBpDuplicates = this.getOnlyObjects(bpUlArr);

            // Генерация HTML списков
            abp = `<ul class="listABP">${noDuplicates.map(abpEl => {
              countABP += 1;
              return `<li>${abpEl.item}</li>`;
            }).join('')}</ul>`;

            bp = `<ul class="listBP">${noBpDuplicates.map(bpEl => {
              countBP += 1;
              return `<li>${bpEl.item}</li>`;
            }).join('')}</ul>`;
          }

          // Формирование конечного объекта
          const {id, program, indicator, goals, statUnit} = elements;

          el = {
            id: id || '',
            programId: program ? program.id : '',
            sgp: program ? program.name_ru : '',
            goals: goals ? goals.name_ru : '',
            targetIndicators: indicator ? indicator.name_ru : '',
            abp: abp,
            bp: bp,
            abpList: noDuplicates,
            bpList: noBpDuplicates,
            countABP: countABP,
            countBP: countBP,
            unitFromStat: statUnit ? statUnit.nationalSymbol : '',
            yearOne: yearData.find(item => item.year === chosenYear - 2) ? yearData.find(item => item.year === chosenYear - 2).fact : null,
            yearTwo: yearData.find(item => item.year === chosenYear - 1) ? yearData.find(item => item.year === chosenYear - 1).fact : null,
            yearThree: yearData.find(item => item.year === chosenYear) ? yearData.find(item => item.year === chosenYear).plan : null,
            yearFour: yearData.find(item => item.year === chosenYear + 1) ? yearData.find(item => item.year === chosenYear + 1).plan : null,
            yearFive: yearData.find(item => item.year === chosenYear + 2) ? yearData.find(item => item.year === chosenYear + 2).plan : null,
            yearSix: yearData.find(item => item.year === chosenYear + 3) ? yearData.find(item => item.year === chosenYear + 3).plan : null,
            yearSeven: yearData.find(item => item.year === chosenYear + 4) ? yearData.find(item => item.year === chosenYear + 4).plan : null,
            goalsId: goals ? goals.id : '',
            levels: 3
          };
          return el;
        },
        getPlanFactArr(yearsList, chosenYear) {
          return yearsList.map(planFact => {
            const yearOffset = planFact.year - chosenYear;
            const obj = {
              year: planFact.year,
              fact: planFact.fact,
              plan: planFact.plan,
              matched: yearOffset >= -2 && yearOffset <= 4 // Проверка на диапазон от -2 до 4
            };
            return obj;
          });
        },

      // Функция для обновления значений по годам
        updateYearData(yearsList, chosenYear) {
            const planFactArr = this.getPlanFactArr(yearsList, chosenYear);
            const planFactList = [];
            for (const item of planFactArr) {
              if (item.matched) {
                planFactList.push(item);
              }
            }
            return planFactList;
        },
        // -----Дробление массива по объектам для извлечения группированных объектов из справочника "Program" и "Goals"
        preparationForGrid(arr) {
          // Инициализация переменных
          this.collapseArr = [];
          this.collapseArrSec = [];
          this.collapseArrThird = [];

          let sumSGP = 0;
          let sumGoals = 0;
          let sumGoalIndicators = 0;
          let sumABP = 0;
          let sumBP = 0;

          // Извлечение уникальных объектов
          const programList = arr.map(mainEl => {
            mainEl.program.isGoal = mainEl.goals !== null;
            mainEl.program.isIndicator = mainEl.indicator !== null;
            return mainEl.program;
          });

          this.programObjArr = this.getOnlyObjects(programList);
          this.collapseArr = this.programObjArr.map(() => true);
          sumSGP = this.programObjArr.length;

          // Обработка целей
          const goalList = arr.filter(goalEl => goalEl.goals !== null)
              .map(goalEl => {
                goalEl.goals['programId'] = goalEl.program.id;
                return goalEl.goals;
              });

          this.goalObjArr = this.getOnlyGoalObjects(goalList);
          this.collapseArrSec = this.goalObjArr.map(() => true);

          const sumAbpArr = [];
          const sumBpArr = [];
          const sumGoalIndicatorsArr = [];

          if (this.forecastFormArr.length > 0) {
            this.forecastFormArr.forEach(lastLevel => {
              this.collapseArrThird.push(true);
              if (lastLevel.targetIndicators && lastLevel.sumGoalIndicatorsArr !== '') {
                sumGoalIndicatorsArr.push(lastLevel.targetIndicators);
              }
              if (lastLevel.abpList) {
                sumAbpArr.push(...lastLevel.abpList);
              }
              if (lastLevel.bpList) {
                sumBpArr.push(...lastLevel.bpList);
              }
            });
          }

          const arrForCounting = this.getOnlyObjects(goalList);
          const abpSumArr = this.getOnlyObjects(sumAbpArr);
          const bpSumArr = this.getOnlyObjects(sumBpArr);

          sumGoals = arrForCounting.length;
          sumGoalIndicators = this.getOnlyItemsOfObjects(sumGoalIndicatorsArr).length;
          sumABP = abpSumArr.length;
          sumBP = bpSumArr.length;

          this.totalCounter(sumSGP, sumGoals, sumGoalIndicators, sumABP, sumBP);
        },
        // ------Счётчик итоговых показателей----------//
        totalCounter(sumSGP, sumGoals, sumGoalIndicators, sumABP, sumBP) {
            this.totalSGP = sumSGP;
            this.totalGoals = sumGoals;
            this.totalGoalIndicators = sumGoalIndicators;
            this.totalABP = sumABP;
            this.totalBP = sumBP;
            // ----------------------------------========-----------------------------------//
        },
        //-----Список годов в фильтрации по годам----//
        listOfYears(currentYear){
            this.yearsArr = [];
            if (currentYear!==null){
                for (let i=(currentYear-1); i<=(currentYear+1); i++){
                    this.yearsArr.push(i);
                }
            }
            // console.log('this.yearsArr: ' + JSON.stringify(this.yearsArr));
        },
        // -----Извлекается выбранный год из фильтра----//
        sendCurrentYear() {
            const curYear = this.currentYear;
            // console.log('curYear: ' + curYear);
            return curYear;
        },
        //----Извлекается справочник программ----//
        async loadProgram() {   // ---Извлекается справочник Программ---//
          await this.getFieldsOfTable(this.currentYear);
        },
        //----Отображение справочника "Целей" в зависимости от выбора программ----//
        async loadGoals() {
          const tempArr = this.programGoalArrSec;
          const isProgramValid = this.dictProgramVal && this.dictProgramVal.id;
          const programId = isProgramValid ? parseInt(this.dictProgramVal.id) : null;
          // Фильтруем только те цели, которые соответствуют programId, если он есть
          this.programGoalArr = tempArr.filter(goal => !programId || parseInt(goal.programId) === programId);
          // Добавляем setNameLangs
          const isAllIn = checkIsAllIn(this.programGoalArr);
          this.programGoalArr = !isAllIn ? [this.setNameLangs(this.all, 'goal'), ...this.programGoalArr] : this.programGoalArr;
          // this.programGoalArr = [this.setNameLangs(this.all, 'goal'), ...this.programGoalArr];
          // Генерируем данные с фильтром
          this.generateGridDataWithFilter(this.dictProgramVal, 'program', isProgramValid);

          function checkIsAllIn(programGoalArr) {
            if (programGoalArr.length > 0) {
              for (const goal of programGoalArr) {
                if (goal.name_en === 'All'){
                  return true;
                }
              }
            }
            return false;
          }
        },
        //----Извлекается справочник АБП------//
        async loadAbp() {
          const tempArr = this.abpArrInit;
          const programId = this.dictProgramGoalVal && this.dictProgramGoalVal.programId ? parseInt(this.dictProgramGoalVal.programId) : null;
          const goalId = this.dictProgramGoalVal && this.dictProgramGoalVal.id ? parseInt(this.dictProgramGoalVal.id) : null;

          if (this.dictProgramGoalVal && programId && goalId) {
            this.abpArr = tempArr.filter(abp => parseInt(abp.programId) === programId && parseInt(abp.goalId) === goalId);
            this.abpArr = [this.setNameLangs(this.all, 'abp'), ...this.abpArr];
            // Генерируем данные с фильтром
            this.generateGridDataWithFilter(this.dictProgramGoalVal, 'goal', goalId);
          } else {
            this.abpArr = [...tempArr];
            this.generateGridDataWithFilter(this.dictProgramGoalVal, 'goal', goalId);
          }
        },
        //----Событие в основной грид таблице после выбора значения АБП в фильтре
        loadGridAfterAbpSelection(){
          const abpId = this.dictAbpVal && this.dictAbpVal.id ? parseInt(this.dictAbpVal.id) : null;
          this.generateGridDataWithFilter(this.dictAbpVal, 'abp', abpId);
        },

        generateGridDataWithFilter(dictVal, param, val) {
          if (this.mainGridObjectArrInit.length > 0) {
            let conditionMet = false;

            switch (param) {
              case 'program':
                conditionMet = !!val;
                break;
              case 'goal':
                conditionMet = !!val || this.dictProgramVal.id;
                break;
              case 'abp':
                conditionMet = !!val || this.dictProgramVal.id || this.dictAbpVal.id;
                break;
            }

            if (conditionMet) {
              this.getMainGridAfterFiltration(dictVal, param);
            } else {
              this.recoverMainGrid();
            }
          }
        },

        getMainGridAfterFiltration(dictVal, param){
          const arr = [];
          for (const item of this.mainGridObjectArrInit) {
            if (param === 'program') {
              if (parseInt(dictVal.id) === parseInt(item.program.id)) {
                arr.push(item);
              }
            } else if (param === 'goal') {
              if (parseInt(this.dictProgramVal.id) === parseInt(item.program.id) && dictVal.id === '') {
                arr.push(item);
              } else if (parseInt(dictVal.id) === parseInt(item.goals.id) && parseInt(dictVal.programId) === parseInt(item.program.id)) {
                arr.push(item);
              } else if (this.dictProgramVal.id === '' && parseInt(dictVal.id) === parseInt(item.goals.id)){
                arr.push(item);
              }
            } else if (param === 'abp') {
              const programId = this.dictProgramVal.id;
              const goalId = this.dictProgramGoalVal.id ? this.dictProgramGoalVal.id || this.dictProgramGoalVal.id!== 'undefined' : '';
              const abpId = this.dictAbpVal.id;
              if (parseInt(programId) === parseInt(item.program.id) && parseInt(goalId) === parseInt(item.goals.id) && abpId !== '') {
                const isAbpIn = isAbpList(item, abpId);
                if (isAbpIn) {
                  arr.push(item);
                }
              } else if (parseInt(programId) === parseInt(item.program.id) && parseInt(goalId) === parseInt(item.goals.id) && abpId === '') {
                arr.push(item);
              } else if (parseInt(programId) === parseInt(item.program.id) && goalId === '' && abpId === '') {
                arr.push(item);
              } else if (programId === '' && goalId === '' && abpId !== '') {
                const isAbpIn = isAbpList(item, abpId);
                if (isAbpIn) {
                  arr.push(item);
                }
              } else if (programId === '' && parseInt(goalId) === parseInt(item.goals.id) && abpId !== '') {
                const isAbpIn = isAbpList(item, abpId);
                if (isAbpIn) {
                  arr.push(item);
                }
              } else if (parseInt(programId) === parseInt(item.program.id) && goalId === '' && abpId !== '') {
                const isAbpIn = isAbpList(item, abpId);
                if (isAbpIn) {
                  arr.push(item);
                }
              }
            }
          }
          this.objectPreparation(arr);

          function isAbpList(item, abpId){
            if (item.abp.length>0){
              for (const itemAbp of item.abp) {
                if (parseInt(itemAbp.id) === parseInt(abpId) && parseInt(item.id) === parseInt(itemAbp.forecast)) {
                  return true;
                }
              }
            }
            return false;
          }
        },

        recoverMainGrid(){
          this.objectPreparation(this.mainGridObjectArrInit);
          this.dictProgramVal = this.programArr[0];
          this.dictProgramGoalVal = this.programGoalArr[0];
          this.dictAbpVal = this.abpArr[0];
        },

        saved(savedObj) {
            // console.log('Сохранённый объект', savedObj);
            if (savedObj){
                this.getFieldsOfTable(this.currentYear);
            }
        },
        // ----Создание объектов на основе переданных данных из справочников----//
        setNameLangs(obj, codeName) {
            let txt = '';
            if (codeName === 'abp') {
                txt = obj.nameRu;
            } else {
                txt = obj.name_ru;
            }
            // console.log('txt: ' + txt)
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
                // console.log('txt: ' + txt);
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            // console.log('el: ' + JSON.stringify(el));
            return el;
        },
        padLeadingZeros(num, size) {    // -------добавление нулей после значения в зависимости от размера значения
            let s = String(num);
            while (s.length < size) { s = s + '0'; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера
        editClk(param) {
            // console.log('param: ' + JSON.stringify(param));
            // console.log('param.id: ' + param.id);
            if (param.id){
                const obj = this.extractObjectById(param.id, 'edit');
                // console.log('EditObject: ' + JSON.stringify(obj));
                this.currentForecast = obj;
                this.edit = true;
            }
        },
        addClk(){
            this.edit = true;
            this.currentForecast = null;
        },
        deleteClk(param){
            if (param.id){
                // console.log(param.id)
                const obj = this.extractObjectById(param.id, 'delete');
                if (obj) {
                    this.$bvModal.msgBoxConfirm(
                        'Удалить запись?',
                        {
                            title: 'Подтверждение',
                            size: 'lg',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            okTitle: 'Да',
                            cancelTitle: 'Отмена',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(value => {
                            if (value === true) {
                                // console.log('DeleteObject: ' + JSON.stringify(obj));

                                fetch('/api/forecast?id=' + obj.id, {
                                    method: 'DELETE',
                                })
                                    .then(res => res.text()) // or res.json()
                                    .then(res => {
                                          this.getFieldsOfTable(this.currentYear);
                                        // console.log(res)
                                    })
                            }
                        })
                        .catch(error => {
                            this.makeToast('danger', 'Ошибка удаления записи', error.toString());
                        });
                }
                // this.edit = true;
            }
        },
        extractObjectById(id, item){
            let itemObject = {};
            if (id){
                if (this.storeForEditAndDelete.length>0){
                    for (const arr of this.storeForEditAndDelete){
                        if (arr.id){
                            if (arr.id === id) {
                                // console.log('id: ' + arr.id + ' indicator: ' + arr.indicatorCustomName);
                                if (item === 'edit'){
                                    itemObject = arr;
                                } else if (item === 'delete'){
                                    itemObject = arr;
                                }
                            }
                        }
                    }
                }
            }
            return itemObject;
        },

        // -----Функция для очистки массива Цели от дубликатов
        getOnlyGoalObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id && item.programId === current.programId);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // -----Функция для очистки массива от дубликатов
        getOnlyItemsOfObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item === current);
                if (!x) {
                    return enteredArr.concat([current]);
                }
                return enteredArr;
            }, []);
            return filteredObjectArr;
        },
        // ------Метод для отображения сообщения с ошибками----//
        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
}
</script>

<style>
.is-hidden {
    display: none !important;
}
.listABP {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listABP li{
    margin-bottom: 15px;
}
.listBP {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.listBP li{
    margin-bottom: 15px;
}
.cellAlign{
    text-align: center;
}
</style>